import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StoreService } from './services/store.service';
import { HotelWebConfig } from './@types/app';
import { UTILS } from './helpers/utils';
import { TenantService } from './services/tenant.service';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject, catchError, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Location } from '@angular/common';
import { Auth2Service } from './services/auth2.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  title = 'nelson-management-ui';
  token:String = '';
  hotelConfig:HotelWebConfig = {} as HotelWebConfig;
  showHotelConfigError:boolean = false;
  loading:boolean = true;
  constructor(
    private auth2Service: Auth2Service, 
    private http: HttpClient,
    private storeService: StoreService,
    private tenantService: TenantService,
    private location: Location,
    public utils: UTILS,
    private route: ActivatedRoute,
  ) {

  }
  getProdConfig():Observable<boolean> {
    return forkJoin({
      hotel: this.http.get(`/config/hotel-config.json`, {responseType:"json"}),
      feature: this.http.get(`/config/feature-config.json`, {responseType:"json"}),
      MUIfeature: this.http.get(`/config/mui-config.json`, {responseType:"json"}),
    }).pipe(
      switchMap((config:any) => {
        this.storeService.updateConfig(config as HotelWebConfig);
        this.hotelConfig = this.storeService.getConfig();
        return of(true);
      }),
      catchError((err) => {
        return of(false);
      })
    )
  }
  ngOnInit(): void {
    this.getProdConfig().subscribe({
      next: (complete) => {
        if(complete) {
          this.route.queryParamMap.subscribe({
            next: (params) => {
              let paramKeys = params.keys;
              let checkBasicParams = ['forceSignOut'].every((key:any) => paramKeys.includes(key));
              if(!checkBasicParams) {
                setTimeout(() => {
                  this.auth2Service.checkCurrentSession().subscribe({
                    next: (result) => {
                      this.loading = false;
                      if(result !== "complete") {
                        this.backToAdminLogin();
                      }
                    },
                    error: (err) => {
                      console.log(err)
                      this.backToAdminLogin();
                    }
                  });
                }, 100);
              } else {
                this.loading = false;
              }
            },
          })
        } else {
          this.loading = false;
          this.showHotelConfigError = true;
        }
      },
      error: (err) => {
        this.loading = false;
        this.showHotelConfigError = true;
      }
    });
  }
  backToAdminLogin() {
    const isLoginPage = ["/login", "/forgot"].includes(this.location.path(true));
    let adminLoginUrl = "https://admin.nelson.management/#/login?forceSignOut=true"
    if(this.utils.isLocalEnvironment()) {
      adminLoginUrl = "http://localhost:4000/#/login?forceSignOut=true"
    }
    if(!isLoginPage || this.hotelConfig.hotel.hotelChainName !== "adminPortal") {
      this.loading = false;
      window.open(adminLoginUrl, "_self");
    } else {
      this.loading = false;
    }
  }
  reloadPage() {
    window.location.reload();
  }
}
