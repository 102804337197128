<app-header activePage="internal-tools-mui"></app-header>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Internal tools (Client)</div>
        </div>
      </div>
      <inline-alert alertMessage="WARNING: Internal tools is only available to Nelson Manager users and is used for various modification to hotel clients data. Please read the descriptions of each tools before using them."></inline-alert>
    </div>
  </div>
  <div class="mui-container">
    <app-beonx-hotel-notif-export [hotels]="hotels"></app-beonx-hotel-notif-export>
    <app-manual-external-retrieve [hotels]="hotels"></app-manual-external-retrieve>
  </div>
</div>