import { Component, Input } from '@angular/core';
import { FaIconType } from '../fa-icon/fa-icon.component';

@Component({
  selector: 'inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrl: './inline-alert.component.sass'
})
export class InlineAlertComponent {
  @Input() iconType:FaIconType = "solid";
  @Input() iconItem:string = "circle-exclamation";
  @Input() alertType:string = "warning";
  @Input() alertMessage:string = "";
}
