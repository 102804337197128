import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AllotmentsComponent } from './components/allotments/allotments.component';
import { BreakfastComponent } from './components/breakfast/breakfast.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { HotelsComponent } from './components/hotels/hotels.component';
import { LoginComponent } from './components/login/login.component';
import { MaintenanceDetailsComponent } from './components/maintenance/maintenance-details/maintenance-details.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { UsageComponent } from './components/usage/usage.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { ReportsService } from './services/reports.service';
import { CustomerDataExportComponent } from './components/customer-data-export/customer-data-export.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { ReservationDetailComponent } from './components/reservations/reservation-detail/reservation-detail.component';
import { AllotmentComponent } from './components/allotment/allotment.component';
import { AllotmentDetailComponent } from './components/allotment-detail/allotment-detail.component';
import { UserComponent } from './components/user/user.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { LocksComponent } from './components/locks/locks.component';
import { LockDetailComponent } from './components/lock-detail/lock-detail.component';
import { LockUsageComponent } from './components/lock-usage/lock-usage.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { InternalToolsComponent } from './components/internal-tools/internal-tools.component';
import { InternalToolsMuiComponent } from './components/internal-tools-mui/internal-tools-mui.component';
import { PageNotFoundComponent } from './components/error/page-not-found/page-not-found.component';
import { authenticateGuard } from './helpers/authenticate.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reservations', component: ReservationsComponent, canActivate: [authenticateGuard] },
  { path: 'reservations/:request', component: ReservationDetailComponent, canActivate: [authenticateGuard]},
  { path: 'customers', component: CustomersComponent, canActivate: [authenticateGuard] },
  { path: 'customers/customer-dashboard', component: CustomerDashboardComponent, canActivate: [authenticateGuard] },
  { path: 'customers/customer-data-export', component: CustomerDataExportComponent, canActivate: [authenticateGuard] },
  { path: 'customer-card/:email/:mobile', component: CustomerCardComponent},
  { path: 'customer', component: CustomerComponent},
  { path: 'customer/:id', component: CustomerDetailComponent},
  { path: 'customer/:email/:mobile', component: CustomerComponent},
  { path: 'breakfast', component: BreakfastComponent, canActivate: [authenticateGuard] },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [authenticateGuard] },
  { path: 'maintenance/:request', component: MaintenanceDetailsComponent, canActivate: [authenticateGuard] },
  { path: 'pricing', component: PricingComponent, canActivate: [authenticateGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [authenticateGuard] },
  { path: 'usage', component: UsageComponent, canActivate: [authenticateGuard] },
  { path: 'allotments', component: AllotmentsComponent, canActivate: [authenticateGuard] },
  { path: 'allotment', component: AllotmentComponent},
  { path: 'allotment/:id', component: AllotmentDetailComponent},
  { path: 'voucher', component: VoucherComponent, canActivate: [authenticateGuard] },
  { path: 'hotels', component: HotelsComponent, canActivate: [authenticateGuard] },
  { path: 'users', component: UserComponent, canActivate: [authenticateGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [authenticateGuard] },
  { path: 'internal-tools', component: InternalToolsComponent, canActivate: [authenticateGuard] },
  { path: 'internal-tools-mui', component: InternalToolsMuiComponent, canActivate: [authenticateGuard] },
  { path: 'activity-log/:reservationCode/:reservationUuid', component: ActivityLogComponent},
  { path: 'locks', component: LocksComponent, canActivate: [authenticateGuard] },
  { path: 'locks/lock', component: LockDetailComponent, canActivate: [authenticateGuard] },
  { path: 'locks/code-usage', component: LockUsageComponent, canActivate: [authenticateGuard] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    JwtModule.forRoot({
      config: {
        tokenGetter: function  tokenGetter() { 
          return localStorage.getItem('jwt');
        }
      }
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
