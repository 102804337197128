import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FinnishSSN } from 'finnish-ssn';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { CustomersService } from 'src/app/services/customers.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { MenuType, DialCodeMenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.sass']
})
export class CustomerComponent implements OnInit {
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  email!: string;
  mobile!: string;
  isCustomer: boolean = false;
  isFirstName: boolean = true;
  isLastName: boolean = true;
  isMobile: boolean = true;
  isEmail: boolean = true;
  isSSN: boolean = true;
  isPassport: boolean = true;

  selectedState?:MenuType;
  stateList:MenuType[] = [
    { label: 'Blocked', value: 'BLOCKED', disabled: false },
    { label: 'Unblocked', value: 'UNBLOCKED', disabled: false }
  ];

  dialCodeList:DialCodeMenuType[] = [];
  selectedDialCode?:DialCodeMenuType;

  contentAlerts = {
    saveSuccess: { type: 'success', message: 'Changes saved.' },
    saveError: { type: 'danger', message: 'Customer already exists!' },
    missingFields: { type: 'danger', message: 'Please fill in missing fields!' },
    wrongPhoneNumber: { type: 'danger', message: 'Incorrect phone number!' },
    wrongEmail: { type: 'danger', message: 'Incorrect email!' },
  }
  addCustomerForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[^@!#$%&*^()_+=/?|\\]+$/i)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[^@!#$%&*^()_+=/?|\\]+$/i)]),
    mobile: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(CONSTANT.REGEX_EMAIL)]),
    ssn: new UntypedFormControl('', [this.validateSSN.bind(this)]),
    passport: new UntypedFormControl(''),
    reason: new UntypedFormControl(''),
  });

  constructor(private route: ActivatedRoute,
      public utils: UTILS,
      private router: Router,
      private toast: CustomToastService,
      private customersService: CustomersService,
      private storeService: StoreService
    ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = this.receivedEmail();
      this.mobile = this.receivedMobile();
      this.isCustomer = !!this.email;
    });
    this.dialCodeList = this.utils.getDialCodeListForMenu();
    if (this.email && this.mobile) {
      this.customersService.customerCard(this.email,this.mobile).subscribe(data => {
        this.selectDialCodeBasedOnValue(this.findPhoneNumberCountry(data.customer.mobile));
        this.addCustomerForm.patchValue({
          firstName: data.customer.firstName,
          lastName: data.customer.lastName,
          mobile: this.separatePhoneNumber(data.customer.mobile),
          email: data.customer.email,
          ssn: data.customer.ssn,
          passport: data.customer.passportNumber,
          reason: data.customer.reason ? data.customer.reason : "",
        });
        if(data.customer.state) {
          this.selectStateBasedOnValue(data.customer.state);
        } else {
          this.selectState(this.stateList[0]);
        }
        this.setAllIs(true);
      });
    } else {
      this.addCustomerForm.patchValue({
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        ssn: "",
        passport: "",
        reason: "",
      });
      this.selectDialCodeBasedOnValue();
      this.selectState(this.stateList[0])
      this.setAllIs(true);
    }
  }

  selectDialCode(item:DialCodeMenuType) {
    this.selectedDialCode = item;
    this.mobileOnChanges(this.addCustomerForm.value.mobile);
  }
  selectDialCodeBasedOnValue(value?:string) {
    const findDialCode = this.dialCodeList.find(dialCode => {
      if(value !== undefined) {
        return dialCode.value === value.toString();
      } else {
        return dialCode.value === this.hotelConfig.feature.defaultGuestNationality;
      }
    });
    if(findDialCode) {
      this.selectDialCode(findDialCode);
    }
  }

  selectState(item:MenuType) {
    this.selectedState = item;
  }
  selectStateBasedOnValue(value:string) {
    const findState = this.stateList.find(state => state.value === value);
    if(findState) {
      this.selectState(findState)
    }
  }

  receivedEmail(): any {
    let routeParams = this.route.snapshot.paramMap;
    return routeParams.get('email') ? String(routeParams.get('email')) : "";
  }
  receivedMobile(): any {
    let routeParams = this.route.snapshot.paramMap;
    return routeParams.get('mobile') ? String(routeParams.get('mobile')) : "";
  }

  get ssn() { return this.addCustomerForm.get('ssn')!; }
  validateSSN(control:AbstractControl): {[key:string]:boolean} | null {
    let ssn = control.value;
    if(ssn === ""){
      return null;
    }
    if(!FinnishSSN.validate(ssn)) {
      this.isSSN = false;
      return {'invalidSSN':true};
    }
    this.isSSN = true;
    return null;
  }
  
  onSubmit(): void {
    if(this.selectedDialCode) {
      this.setAllIs(true);
      // Check SSN before sent a request of create/update customer
      const firstNameValid = this.f.firstName.valid;
      const lastNameValid = this.f.lastName.valid;

      const mobileValid = this.validatePhoneNumber(`+${this.selectedDialCode.dialCode}${this.addCustomerForm.value.mobile}`);
      
      const emailValid = this.f.email.valid;
      const ssnValid = !this.addCustomerForm.value.ssn || this.f.ssn.valid;
      const passportValid = this.f.passport.valid;

      if(!firstNameValid) {
        this.isFirstName = false;
      }
      if(!lastNameValid) {
        this.isLastName = false;
      }
      if(!firstNameValid || !lastNameValid) {
        this.toast.error("Name is required", 'Error!');
      }
      if(!mobileValid) {
        this.isMobile = false;
        this.toast.error("Mobile number is invalid", 'Error!');
      }
      if(!emailValid) {
        this.isEmail = false;
        this.toast.error("Email is invalid", 'Error!');
      }
      if(!ssnValid) {
        this.isSSN = false;
        this.toast.error("SSN is invalid", 'Error!');
      }

      if(firstNameValid && lastNameValid && mobileValid && emailValid && ssnValid && passportValid && this.selectedState) {
        const formRequest = {
          email: this.addCustomerForm.value.email,
          firstName: this.addCustomerForm.value.firstName,
          lastName: this.addCustomerForm.value.lastName,
          passportNumber: this.addCustomerForm.value.passport,
          ssn: this.addCustomerForm.value.ssn,
          mobile: this.addCustomerForm.value.mobile ? `+${this.selectedDialCode.dialCode}${this.addCustomerForm.value.mobile}` : "",
          currentState: this.selectedState.value,
          blocklistHistories: [{
            state: this.selectedState.value,
            reason: this.addCustomerForm.value.reason,
          }]
        };
        this.customersService.saveCustomer(formRequest).subscribe(
          response => {
            this.toast.success(this.contentAlerts.saveSuccess.message, 'Success!');
            this.router.navigateByUrl("/customer/" + response.id);
          },
          err => {
            this.toast.error(err.error.description, 'Error!');
        });
      }
    }
  }
  get f() {
    return this.addCustomerForm.controls;
  }
  firstNameOnChanges(): void {
    this.isFirstName = this.f.firstName.valid;
  }
  lastNameOnChanges(): void {
    this.isLastName = this.f.lastName.valid;
  }
  mobileOnChanges(number: any) {
    if(this.selectedDialCode) {
      this.isMobile = this.validatePhoneNumber(`+${this.selectedDialCode.dialCode}${number}`);
    }
  }
  emailOnChanges() {
    this.isEmail = !this.addCustomerForm.controls.email.invalid;
  }
  ssnOnChanges() {
    this.isSSN = !this.addCustomerForm.controls.ssn.invalid;
  }
  passportOnChanges() {
    this.isPassport = !this.addCustomerForm.controls.passport.invalid;
  }
  separatePhoneNumber(phoneNumber: any): any {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const phoneCountryCode = parsedPhoneNumber.country;
    if(phoneCountryCode) {
      const findCountry = this.dialCodeList.find(dialCode => dialCode.code === phoneCountryCode);
      if(findCountry) {
        const separatedNumber = parsedPhoneNumber.number.split(`+${findCountry.dialCode}`)[1];
        return separatedNumber
      }
    }
  }
  findPhoneNumberCountry(phoneNumber:string) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const phoneCountryCode = parsedPhoneNumber.country;
    if(phoneCountryCode) {
      const findCountry = this.dialCodeList.find(dialCode => dialCode.code === phoneCountryCode);
      if(findCountry) {
        return findCountry.value
      }
    }
  }
  validatePhoneNumber(phoneNumber:string) {
    if(this.selectedDialCode) {
      const getDialCode:any = this.selectedDialCode.code;
      return isValidPhoneNumber(phoneNumber, getDialCode);
    } else {
      return false;
    }
  }
  backToCustomers(): void {
    if (this.email && this.mobile) {
      this.router.navigate(["/customer-card/",this.email,this.mobile]);
    } else {
      this.router.navigateByUrl("/customers");
    }
  }
  setAllIs(isBoolean: boolean): void {
    this.isFirstName = isBoolean;
    this.isLastName = isBoolean;
    this.isMobile = isBoolean;
    this.isEmail = isBoolean;
    this.isSSN = isBoolean;
    this.isPassport = isBoolean;
  }
  clearAll(): void {
    if (!!this.email && !!this.mobile) {
      this.customersService.customerCard(this.email,this.mobile).subscribe(data => {
        if (data.customer && data.customer !== null) {
          this.selectDialCodeBasedOnValue(this.findPhoneNumberCountry(data.customer.mobile));
          this.addCustomerForm.patchValue({
            firstName: data.customer.firstName,
            lastName: data.customer.lastName,
            mobile: this.separatePhoneNumber(data.customer.mobile),
            email: data.customer.email,
            ssn: data.customer.ssn,
            passport: data.customer.passportNumber,
            reason: data.customer.reason ? data.customer.reason : "",
          });
          if(data.customer.state) {
            this.selectStateBasedOnValue(data.customer.state);
          } else {
            this.selectState(this.stateList[0]);
          }
          this.setAllIs(true);
        } else {
          this.toast.error('Not found information due to ' + this.email + ' does not exist.', 'Error!');
          this.router.navigateByUrl("/customer");
        }
      });
    } else {
      this.addCustomerForm.patchValue({
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        ssn: "",
        passport: "",
        reason: "",
      });
      this.selectDialCodeBasedOnValue();
      this.selectState(this.stateList[0])
      this.setAllIs(true);
    }
  }
}