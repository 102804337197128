import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { CustomersService } from 'src/app/services/customers.service';
import { AnimationOptions } from 'ngx-lottie';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.sass','../../../styles.sass']
})
export class CustomerDashboardComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  datepickerConfig: any = CONSTANT.datepickerConfig;
  alerts: any = [];
  fromDate: any = new Date();
  toDate: any = new Date();
  isValidFromDate: boolean = true;
  isValidToDate: boolean = true;
  maxDate: any = new Date();
  loading: boolean = false;
  isDashboardData: boolean = false;
  isFormValid:boolean = false;
  customerDashboard = {
    customer: {
      growthRate: "0",
      previousTotal: 0,
      total: 0
    },
    member: {
      growthRate: "0",
      previousTotal: 0,
      total: 0
    },
    subscriber: {
      growthRate: "0",
      previousTotal: 0,
      total: 0
    }
  };

  compareList:MenuType[] = [
    { label: "Previous period", value: "PREVIOUS_PERIOD", disabled: false },
    { label: "Previous year", value: "PREVIOUS_YEAR", disabled: false }
  ];
  selectedCompare?:MenuType;
  tooltipContents = {
    customers: "Booker and non-booking guest emails from reservations created in the selected time window. Cancelled reservations are excluded. An email is only counted once (emails are distinct). However, an individual might obviously have multiple emails on file. Only real emails are included, not virtual emails.",
    members: "New memberships created during the selected time window.",
    subscribers: "Customers (as defined in the CRM) that checked the marketing permission box for a reservation created in the selected time window. Note that a box can be checked by a non-booking guest at a time outside of the time window. Cancelled reservations are excluded.",
  }

  datepickerCustom: DatepickerDateCustomClasses[];

  constructor(private router: Router,
    private customersService: CustomersService) {
      this.datepickerCustom = [];
    }

  ngOnInit(): void {
    this.initDataForm();
    const request = this.castRequestData();
    this.sendRequest(request);
  }
  sendRequest(request: any): void {
    this.loading = true;
    this.customersService.applyCustomerDashboard(request).subscribe(
      data => {
        this.loading = false;
        this.isDashboardData = true;
        this.customerDashboard = data;
      },
      err => {
        this.loading = false;
        this.isDashboardData = false;
        console.log(err);
      });
  }
  applyFilter(): void {
    const request = this.castRequestData();
    this.sendRequest(request);
  }
  castRequestData(): any {
    if(this.selectedCompare) {
      const request:any = {
        previousComparisonType: this.selectedCompare.value,
        fromDate: formatDate(this.fromDate, "YYYY-MM-dd", "en-us"),
        toDate: formatDate(this.toDate, "YYYY-MM-dd", "en-us")
      };
      return request;
    } else {
      return undefined;
    }
  }
  initDataForm(): void {
    this.selectCompare(this.compareList[0]);
    this.fromDate = new Date(Date.now() - 7 * CONSTANT.ONE_DAY_MS);
    this.toDate = new Date();
  }
  updateDatepicker() {
    let custom = [
      {date: this.fromDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.fromDate.getTime() + 24*3600*1000;
    while(tmpTime < this.toDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.toDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }
  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.fromDate && this.toDate && this.fromDate != "Invalid Date" && this.toDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.fromDate && this.toDate && this.fromDate.getTime() > this.toDate.getTime()) {
          this.toDate = new Date(this.fromDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.fromDate && this.toDate && this.fromDate.getTime() > this.toDate.getTime()) {
          this.fromDate = new Date(this.toDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  selectCompare(item: MenuType) {
    this.selectedCompare = item;
  }
  backToCustomers(): void {
    this.router.navigateByUrl("/customers");
  }
  numberWithCommas(total: any): any {
    return UTILS.numberWithCommas(total);
  }
  absForGrowthRate(growthRate: any) {
    return Math.abs(growthRate);
  }
  numberNotIsNaN(number: any): boolean {
    if (number === "NaN" || Number.isNaN(number)) {
      return false;
    }
    return true;
  }
  numberBiggerZero(number: any): boolean {
    if (number > 0) {
      return true;
    }
    return false;
  }
  redirectToCustomerDataExport(): void {
    this.router.navigateByUrl("/customers/customer-data-export");
  }
}