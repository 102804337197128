import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { LOCKDUMMY } from 'src/app/helpers/dummylock';
import { UTILS } from 'src/app/helpers/utils';
import { LockService } from 'src/app/services/lock.service';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-locks',
  templateUrl: './locks.component.html',
  styleUrls: ['./locks.component.sass','../../../styles.sass']
})
export class LocksComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  title = "Door Codes";
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;
  
  minEnd:any = new Date();
  startDate:any = new Date(new Date().setHours(16,0,0,0));
  endDate:any = new Date(new Date().setHours(22,0,0,0) + 24*3600*1000);
  prevStartDate:any = null;
  prevEndDate:any = null;
  isFormValid:boolean = false;
  datepickerCustom: DatepickerDateCustomClasses[];
  filters:any = CONSTANT.FILTER_LOCK;
  isSearching:boolean = false;
  showResult:boolean = false;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  lockSearch = new UntypedFormGroup({
    searchQuery: new UntypedFormControl('', Validators.required),
    selectedFilters: new UntypedFormControl('', [Validators.required]),
  });

  searchResult:any[] = [];
  prevRequest:any;
  totalCount:number = 0;
  currentCount:number = 0;
  remainingCount:number = 0;

  constructor(
    public utils: UTILS,
    public lockService: LockService,
    private storeService: StoreService,
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const processData = (data:any) => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectHotel(tmpHotelList[0]);

      setTimeout(() => {
        this.retrieveSearchFields();
      }, 100);
    }
    if(this.hotelConfig.MUIfeature.dummyLock) {
      processData(LOCKDUMMY.DUMMY_LOCK_HOTEL);
    } else {
      this.lockService.getHotels().subscribe(data => {
        if(data && data.length) {
          processData(data)
        }
      })
    }
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    this.validateForm();
  }
  selectHotelByValue(value:string) {
    const findHotel = this.hotelList.find(item => item.value === value);
    if(findHotel) {
      this.selectHotel(findHotel)
    }
  }

  disableSearch() {
    this.isFormValid = false;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  validateForm():void {
    this.validateDate();
  }

  validateDate() {
    if(this.startDate === "Invalid Date") {
      this.startDate = null;
    }
    if(this.endDate === "Invalid Date") {
      this.endDate = null;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(!this.prevStartDate) {
          this.prevStartDate = this.startDate;
        } else {
          const prevStartMonth = this.prevStartDate.getMonth();
          const newStartMonth = this.startDate.getMonth();
          if(prevStartMonth !== newStartMonth) {
            this.startDate = new Date(this.startDate.setHours(this.prevStartDate.getHours(), this.prevStartDate.getMinutes(), 0, 0));
            this.prevStartDate = this.startDate;
          } else {
            this.prevStartDate = this.startDate;
          }
        }
        if(this.startDate.getTime() >= this.endDate.getTime()) {
          let tmpStartDate = new Date(this.startDate.getTime())
          this.endDate = new Date(tmpStartDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0) + 24*3600*1000);
          this.minEnd = this.startDate;
        }
        break;
      case 'endDate':
        if(!this.prevEndDate) {
          this.prevEndDate = this.endDate;
        } else {
          const prevEndMonth = this.prevEndDate.getMonth();
          const newEndMonth = this.endDate.getMonth();
          if(prevEndMonth !== newEndMonth) {
            this.endDate = new Date(this.endDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0));
            this.prevEndDate = this.endDate;
          } else {
            this.prevEndDate = this.endDate;
          }
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  setDateFromDropdown(value:Date, type:string) {
    switch (type) {
      case "startDate":
        this.startDate = value;
        break;
      case "endDate":
        this.endDate = value;
        break;
      default:
        break;
    }
  }
  updateDatepicker() {
    if(this.startDate && this.endDate) {
      let custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.startDate.getTime() + 24*3600*1000;
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }

  changeFilter(index:number, event:any) {
    this.filters[index].checked = event.target.checked;
    if (this.filters[index].value == "SSN") {
      this.filters.forEach((item:any) => {
        if(item.value != "SSN") {
          item.checked = false;
        }
      });    
    } else {
      this.filters.forEach((item:any) => {
        if(item.value == "SSN") {
          item.checked = false;
        }
      });   
    }
    this.getCheckedFilterLabel();
  }
  getCheckedFilterLabel() {
    let defaultFilters:any = [];
    for (let i = 0; i < 6; i++) {
      defaultFilters.push(this.filters[i]);
    }
    let selectedFilters:any = [];
    this.filters.forEach((item:any) => {
      if(item.checked) {
        selectedFilters.push(item);
      }
    });
  }
  clearAllFilter() {
    this.filters.forEach((item:any) => {
      item.checked = false;
    });
  }
  getThreeFilter(){
    return this.getAllFilter().slice(0, 3);
  }
  getAllFilter(){
    return this.filters.filter((f: any) => f.checked);
  }
  removeFilter(filter: any, e: any) {
    e.stopPropagation();
    this.filters.filter((f: any) => f.value == filter.value)[0].checked = false;
  }

  getCheckedFilters(item:any) {
    if(item.checked) return item.value; else return 0;
  }

  storeSearchFields() {
    if(this.selectedHotel) {
      localStorage.setItem('lockFields', JSON.stringify({
        searchQuery: this.lockSearch.controls.searchQuery.value,
        hotel: this.selectedHotel.value,
        startDate: this.startDate,
        endDate: this.endDate,
        filters: this.filters,
      }))
    }
  }

  retrieveSearchFields() {
    let getStoredField = localStorage.getItem('lockFields');
    if(getStoredField) {
      let getFields = JSON.parse(getStoredField);
      this.startDate = getFields.startDate ? new Date(getFields.startDate) : new Date(new Date().setHours(16,0,0,0));
      if(getFields.endDate) {
        this.endDate = new Date(getFields.endDate)
      } else {
        this.endDate = new Date(new Date().setHours(22,0,0,0) + 24*3600*1000);
      }
      this.filters = getFields.filters;
      this.getCheckedFilterLabel();
      this.selectHotelByValue(getFields.hotel);
      this.lockSearch.patchValue({
        searchQuery: getFields.searchQuery
      })
    }
  }

  getHotelObj(label?:string): any {
    let hotelObj:any = {};
    if(label) {
      hotelObj = this.hotels.find((obj:any) => {
        return obj.label == label;
      });
    } else if (this.selectedHotel) {
      let hotelId = Number(this.selectedHotel.value);
      hotelObj = this.hotels.find((obj:any) => {
        return obj.hotelId == hotelId;
      });
    }
    return hotelObj;
  }

  getHotelId(label:string) {
    const hotelObj = this.hotels.find((obj:any) => {
      return this.hotelConfig.MUIfeature.useHotelCode ? obj.label == label : obj.name === label;
    });
    return hotelObj.hotelId;
  }

  backToTopPage(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  onSearch(type:string):void {
    let searchRequest:any;
    this.isSearching = true;
    if(type == 'new') {
      this.storeSearchFields();
      this.showResult = false;
      this.searchResult = [];
      searchRequest = {
        'searchValue': this.lockSearch.controls.searchQuery.value,
        'startDate': this.startDate ? formatDate(this.startDate, "yyyy-MM-dd", "en-US") : null,
        'startTime': this.startDate ? formatDate(this.startDate, "HH:mm", "en-US") : "00:00",
        'endDate': this.endDate ? formatDate(this.endDate, "yyyy-MM-dd", "en-US") : null,
        'endTime': this.endDate ? formatDate(this.endDate, "HH:mm", "en-US") : "00:00",
        'limit': 20,
        'offset': 0,
        'hotelLabel': null,
        'filters': _.map(this.filters,this.getCheckedFilters).filter((item:any) => {return item != 0;}),
      }
      searchRequest.hotelLabel = this.getHotelObj().label;
      this.prevRequest = searchRequest;
    } else {
      this.prevRequest.offset += 20;
      searchRequest = this.prevRequest;
    }
    const processData = (data:any) => {
      data.items.forEach((item:any) => {
        const getResultHotel = this.getHotelObj(item.hotelLabel);
        let tmpItem:any = {
          token: item.credentialToken,
          doorList: item.doorNumberList.join(", "),
          hotel: this.utils.hotelDisplayByHotel(this.hotelConfig, getResultHotel),
          name: item.contactName,
          reservationCode: item.reservationCode,
          doorCode: item.doorCode,
          startDate: moment(item.startTime).tz(getResultHotel.zone).format('DD.MM.YYYY HH:mm'),
          endDate: moment(item.endTime).tz(getResultHotel.zone).format('DD.MM.YYYY HH:mm'),
        }
        this.searchResult.push(tmpItem)
      })
      this.totalCount = data.total;
      this.currentCount = this.searchResult.length;
      const remaining = data.total - this.currentCount
      this.remainingCount = remaining >= 20 ? 20 : remaining;
      this.isSearching = false;
      this.showResult = true;
    }
    if(this.hotelConfig.MUIfeature.dummyLock) {
      processData(LOCKDUMMY.DUMMY_LOCK_SEARCH)
    } else {
      this.lockService.searchLock(searchRequest).subscribe(data => {
        if(data) {
          processData(data);
        }
      }) 
    }
  }
}
