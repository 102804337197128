import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalUserSession } from '../helpers/globalAuth';

const loadTenantUrl = '/api/tenant/listtenants';
@Injectable({
    providedIn: 'root'
})
export class TenantService {
    constructor(private http: HttpClient) {}
    loadTenants(request: any): Observable<any> {
        return this.http.get(loadTenantUrl, { params: request });
    }
}