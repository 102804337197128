import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomToastService } from 'src/app/services/custom-toast.service';

export type ToastType = {
  id: number,
  type: string,
  title: string,
  desc: string,
  timeOut: number
}
export type ToastRequestType = {
  type: string,
  title: string,
  desc: string,
  timeOut?: number
}

@Component({
  selector: 'custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrl: './custom-toast.component.sass',
})

export class CustomToastComponent implements OnInit, OnDestroy {
  private subscription!: Subscription;

  constructor(
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    this.subscription = this.toastService.trigger$.subscribe(toastRequest => {
      this.addToast(toastRequest)
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  currentId:number = 0;
  toastList:ToastType[] = [];
  addToast(request:ToastRequestType) {
    this.toastList.unshift({
      id: this.currentId,
      type: request.type,
      title: request.title,
      desc: request.desc,
      timeOut: request.timeOut ? request.timeOut : 5000
    })
    this.currentId++;
    if(this.currentId > 50) {
      this.currentId = 0;
    }
  }
  removeToast(id:number) {
    const findToastIndex = this.toastList.findIndex(toast => toast.id === id);
    if(findToastIndex > -1) {
      this.toastList.splice(findToastIndex, 1);
    }
  }
}
