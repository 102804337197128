import { HotelWebConfig } from "../@types/app";
import { UserAuthType } from "../@types/auth";

let GlobalUserSession:UserAuthType | null = null;
const setGlobalUserSession = (data:UserAuthType | null) => {
  GlobalUserSession = data;
}

export {
  GlobalUserSession,
  setGlobalUserSession,
}