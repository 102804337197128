<app-header activePage="maintenance"></app-header>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          @if (!isCreate) {
            <div class="view-title">Maintenance details</div>
          }
          @if (isCreate) {
            <div class="view-title">Create maintenance</div>
          }
        </div>
        @if (!isCreate) {
          <div class="page-twb-button">
            <button class="btn btn-green" (click)="openUsage()"><fa-icon type="solid" icon="list-timeline"></fa-icon>View Usage</button>
            <button class="btn btn-red" (click)="cancelMaintenance()"><fa-icon type="solid" icon="trash-can"></fa-icon>Cancel</button>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="container-fluid">

    <form [formGroup]="maintenanceDetailForm">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            @if (hotels.length > 1) {
              <div class="col-6 margin-top">
                <label for="top-margin">Hotel</label>
                <dropdown-menu
                  [menuList]="hotelList"
                  [selectedItem]="selectedHotel"
                  [fullWidth]="true"
                  (selectItem)="selectHotel($event)"
                ></dropdown-menu>
              </div>
            }
            <div class="col-6 margin-top">
              <label for="top-margin">Floor</label>
              <dropdown-menu
                [menuList]="floorList"
                [selectedItem]="selectedFloor"
                [fullWidth]="true"
                (selectItem)="selectFloor($event)"
              ></dropdown-menu>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <label class="top-margin">Available Rooms</label>
              <div class="room-select-container" id="leftRoom">
                @for (room of rooms; track room; let i = $index) {
                  @if (checkRoom(room,'left')) {
                    <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label"
                      (click)="selectListRoom($event,'leftRoom')">Room <b>{{room.label}}</b>
                    </div>
                  }
                }
              </div>
            </div>
            <div class="col-sm-2">
              <div class="room-arrow-container">
                <div class="room-select-arrow">
                  <button type="button" class="btn btn-green icon-only" (click)="selectRoom('add')"><fa-icon type="solid" icon="chevron-right"></fa-icon></button>
                  <button type="button" class="btn btn-green icon-only" (click)="selectRoom('remove')"><fa-icon type="solid" icon="chevron-left"></fa-icon></button>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="row">
                <div class="col-8">
                  <label class="top-margin">Selected Rooms</label>
                </div>
                <div class="col-4">
                  @if (!isCreate && notAllowedEditRoom) {
                    <label class="top-margin done-col">Done</label>
                  }
                </div>
              </div>
              <div class="room-select-container" id="rightRoom">
                @for (room of rooms; track room; let i = $index) {
                  @if (checkRoom(room,'right')) {
                    <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label"
                      (click)="selectListRoom($event,'rightRoom')">
                      Room <b>{{room.label}}</b>
                      @if (!isCreate) {
                        <div class="normal-checkbox">
                          <input type="checkbox" [checked]="checkDone(room.status)">
                          <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        </div>
                      }
                    </div>
                  }
                }
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-sm-6 col-md-12">
              <label class="top-margin">Start date</label>
              <div class="row no-gutters">
                <div class="col-7">
                  <div class="datepicker-input">
                    <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                      [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableUpdate()" (blur)="validateForm()"
                      (bsValueChange)="dateChange('startDate')"
                      [dateCustomClasses]="datepickerCustom"
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                    <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                      <fa-icon type="regular" icon="calendar-days"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="col-5">
                  <dropdown-menu
                    menuType="timepicker"
                    [initialDate]="startDate"
                    alignment="center"
                    [fullWidth]="true"
                    (selectDate)="setDateFromDropdown($event, 'startDate')"
                  ></dropdown-menu>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-12">
              <label class="top-margin">End date</label>
              <div class="row no-gutters">
                <div class="col-7">
                  <div class="datepicker-input">
                    <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                      bsDatepicker #endDateInput="bsDatepicker" (input)="disableUpdate()" (blur)="validateForm()"
                      (bsValueChange)="dateChange('endDate')"
                      [dateCustomClasses]="datepickerCustom"
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', minDate: minEnd, customTodayClass:'datepicker-today' }">
                    <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                      <fa-icon type="regular" icon="calendar-days"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="col-5">
                  <dropdown-menu
                    menuType="timepicker"
                    [initialDate]="endDate"
                    alignment="center"
                    [fullWidth]="true"
                    (selectDate)="setDateFromDropdown($event, 'endDate')"
                  ></dropdown-menu>
                </div>
              </div>
            </div>
          </div>

          <label for="top-margin">Maintenance Category</label>
          <dropdown-menu
            [menuList]="categoryList"
            [selectedItem]="selectedCategory"
            [fullWidth]="true"
            (selectItem)="selectCategory($event)"
          ></dropdown-menu>

          <label class="top-margin">Reason</label>
          <textarea rows="3" class="custom-textarea" formControlName="reason" (ngModelChange)="validateForm()"></textarea>

          @if (isCreate) {
            <button type="submit" class="btn btn-green center top-margin" style="margin-bottom:30px"
            [disabled]="!(isFormValid) || disabledBtnWhileCallingAPI" (click)="onUpdateMaintenance()">Create</button>
          }
          @if (!isCreate) {
            <button type="submit" class="btn btn-green center top-margin" style="margin-bottom:30px"
              [disabled]="!(isFormValid) || disabledBtnWhileCallingAPI" (click)="onUpdateMaintenance()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Update</button>
            }
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-footer></app-footer>