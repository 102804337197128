import { Component, Input, ViewChild, OnInit, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MonetaryDetailsComponent } from '../monetary-details/monetary-details.component';
import { CONSTANT } from 'src/app/helpers/constants';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { CustomModalComponent } from 'src/app/components/common/custom-modal/custom-modal.component';

@Component({
  selector: 'app-monetary-table',
  templateUrl: './monetary-table.component.html',
  styleUrls: ['../../voucher.component.sass','../../../../../styles.sass']
})
export class MonetaryTableComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  @Input() monetaryResult:any;
  @Input() monetaryCount:any;
  @Output() showMore = new EventEmitter<any>();
  @ViewChild(MonetaryDetailsComponent)
  private monetaryDetails!: MonetaryDetailsComponent;

  sendVoucher:any = null;

  minDate:any = new Date(new Date().setHours(0,0,0,0));
  validUntil:any = null;
  
  //Status
  isLoading:boolean = false;
  selectedMonetary:number = -1;
  editDetail:boolean = false;
  disabledBtnWhileCallingAPI: boolean = false;
  constructor(
    private voucherService: VoucherService,
    public utils: UTILS,
    private modalService: BsModalService,
    private toast: CustomToastService,
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
  }

  callShowMore() {
    this.showMore.emit('showMore');
  }

  copyVoucher(text:any) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.toast.success("Voucher copied!", 'Success!');
  }

  getInputValue(name:string, type?:string) {
    let getInput, tmpValue:any;
    if(name == 'message') {
      getInput = document.querySelector<HTMLInputElement>(`.monetary-table textarea`);
    } else {
      getInput = document.querySelector<HTMLInputElement>(`.monetary-table input[name=${name}]`);
    }
    if(getInput) tmpValue = getInput.value;
    if(type == 'number') {
      return parseInt(tmpValue);
    } else {
      return tmpValue;
    }
  }

  validateInput(name:string, e:any) {
    let inputValue = e.target.value;
    let patternCheck;
    if(inputValue != "") {
      patternCheck = true;
    } else {
      patternCheck = false;
    }
    let getInput = document.querySelector<HTMLInputElement>(`.monetary-table input[name=${name}]`);
    if(!patternCheck) {
      if(getInput) {
        getInput.classList.add('input-error');
        return false;
      } else {
        return false;
      }
    } else {
      if(getInput) {
        getInput.classList.remove('input-error');
        return true;
      } else {
        return false;
      }
    }
  }

  validateAllDetails() {
    let getInput = document.querySelectorAll<HTMLInputElement>(`.monetary-table input`);
    getInput.forEach(input => {
      if(input.value == "") {
        input.classList.add('input-error');
      }
    });
    let getRecipient = document.querySelector<HTMLInputElement>(`.monetary-table input[name=recipientEmail]`);
    if(getRecipient!.value == "") {
      getRecipient?.classList.remove('input-error');
    }

    let getInputError = document.querySelectorAll<HTMLInputElement>(`.monetary-table .input-error`);
    if(getInputError.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  updateMonetary() {
    if(this.validateAllDetails()) {
      this.disabledBtnWhileCallingAPI = true;
      let voucher = this.sendVoucher;
      voucher.customerEmail = this.getInputValue('customerEmail');
      voucher.recipientEmail = this.getInputValue('recipientEmail');
      voucher.greetingText = this.getInputValue('message');
      voucher.customerMobile = this.getInputValue('phone');
      voucher.customerFirstName = this.getInputValue('customerFirstName');
      voucher.customerLastName = this.getInputValue('customerLastName');
      voucher.validUntil = this.utils.convertDate(this.validUntil, 'YYYY-MM-dd');
      this.voucherService.updateMonetary(this.sendVoucher).subscribe(
        data => {
          this.toast.success("Giftcard updated!", 'Success!');
          this.deselectMonetary();
          this.disabledBtnWhileCallingAPI = false;
        },
        err => {
          this.toast.error(err.error.description, "Cannot update gift card!");
          this.disabledBtnWhileCallingAPI = false;
        }
      )
    }
  }

  viewMonetary(monetary:any) {
    if((this.selectedMonetary != monetary.voucherID) && this.editDetail) {
      this.toast.error("The other voucher is being edited!", "Cannot view voucher!");
    } else {
      this.sendVoucher = monetary;
      this.selectedMonetary = monetary.voucherID;
      this.monetaryDetails.viewDetail = true;

      let getAllVoucherRow = document.querySelectorAll<HTMLInputElement>(`.table-input.monetary-table tbody tr.active`);
      getAllVoucherRow.forEach(item => {
        item.classList.remove('active');
      });

      let getVoucherRow = document.querySelector<HTMLInputElement>(`.table-input.monetary-table tbody tr[data-monetaryId='${monetary.voucherID}']`);
      if(getVoucherRow) getVoucherRow.classList.add('active');
    }
  }

  deselectMonetary() {
    setTimeout(() => {
      this.sendVoucher = null;
      this.selectedMonetary = -1;
      this.monetaryDetails.viewDetail = false;

      this.editDetail = false;
      this.monetaryDetails.editDetail = false;

      let getAllVoucherRow = document.querySelectorAll<HTMLInputElement>(`.table-input.monetary-table tbody tr.active`);
      getAllVoucherRow.forEach(item => {
        item.classList.remove('active');
      });
    }, 100);
  }

  editMonetary(monetary:any) {
    setTimeout(() => {
      if((this.selectedMonetary == monetary.voucherID) && !this.editDetail) {
        this.editDetail = true;
        this.monetaryDetails.editDetail = true;
        this.validUntil = new Date(monetary.validUntil);
        this.monetaryDetails.customerEmail = monetary.customerEmail;
        this.monetaryDetails.recipientEmail = monetary.recipientEmail;
      }
    }, 100);
  }

}
