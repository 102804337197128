import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { CustomersService } from 'src/app/services/customers.service';

@Component({
  selector: 'app-customer-data-export',
  templateUrl: './customer-data-export.component.html',
  styleUrls: ['./customer-data-export.component.sass']
})
export class CustomerDataExportComponent implements OnInit {
  datepickerConfig: any = CONSTANT.datepickerConfig;
  alerts: any = [];
  fromDate: any = new Date();
  toDate: any = new Date();
  isValidFromDate: boolean = true;
  isValidToDate: boolean = true;
  hasFields: boolean = false;
  maxDate: any = new Date();
  loading: boolean = false;
  isAllFilters: boolean = false;
  selectedFields: string = "";
  selectedFieldLabels: any = [];
  selectedValueFields: any = [];
  isFormValid:boolean = false;
  form = new UntypedFormGroup({
    fields: new UntypedFormControl('', Validators.required),
  });
  fields = [
    { label: "Member join date", value: "MEMBER_JOIN_DATE", checked: false },
    { label: "Lifetime bookings", value: "LIFETIME_BOOKINGS", checked: false },
    { label: "Lifetime spend", value: "LIFETIME_SPEND", checked: false },
    { label: "Visits as guest only", value: "VISITS_AS_GUEST_ONLY", checked: false },
    { label: "Last stay (check-out date)", value: "LAST_STAY", checked: false },
    { label: "Discount codes", value: "DISCOUNT_CODES", checked: false },
    { label: "Cancellation rate", value: "CANCELLATION_RATE", checked: false }
  ];

  datepickerCustom: DatepickerDateCustomClasses[];


  constructor(private router: Router,
    private customersService: CustomersService) {
      this.datepickerCustom = [];
    }

  ngOnInit(): void {
    this.initDataForm();
  }
  onSubmit(): void {
    let request = this.castRequestData();
    this.exportData(request);
  }
  exportData(request: any): void {
    this.customersService.customerDataExport(request).subscribe(
      response => {
        let url;
        let request = this.castRequestData();
        if (request.fromDate) {
          url = '&fromDate=' + request.fromDate;
        }
        if (request.toDate) {
          url = '&toDate=' + request.toDate;
        }
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.setAttribute("download", 'customer-data-export-'
          + this.fileNameDate(request.fromDate) + '-'
          + this.fileNameDate(request.toDate)
          + '.xlsx');
        link.click();
      });
  }
  castRequestData(): any {
    const request = cloneDeep(this.form.value);
    request.fromDate = formatDate(this.fromDate, "YYYY-MM-dd", "en-us");
    request.toDate = formatDate(this.toDate, "YYYY-MM-dd", "en-us");
    request.fields = this.selectedValueFields;
    return request;
  }
  initDataForm(): void {
    this.form.patchValue({
      fields: "",
    });
    this.selectedFields = "";
    this.fromDate = new Date(Date.now() - 1 * CONSTANT.ONE_DAY_MS);
    this.toDate = new Date();
    this.validateDate();
  }
  updateDatepicker() {
    let custom = [
      {date: this.fromDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.fromDate.getTime() + 24*3600*1000;
    while(tmpTime < this.toDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.toDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }
  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.fromDate && this.toDate && this.fromDate != "Invalid Date" && this.toDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.fromDate && this.toDate && this.fromDate.getTime() > this.toDate.getTime()) {
          this.toDate = new Date(this.fromDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.fromDate && this.toDate && this.fromDate.getTime() > this.toDate.getTime()) {
          this.fromDate = new Date(this.toDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  fieldChange(field: any): void {
    field.checked = !field.checked;
    this.selectedOptionalFilters();
  }
  selectAll(): void {
    for (let item of this.fields) {
      item.checked = true;
    }
    this.selectedOptionalFilters();
  }
  clearAll(): void {
    for (let item of this.fields) {
      item.checked = false;
    }
    this.selectedOptionalFilters();
  }
  selectedOptionalFilters(): void {
    this.isAllFilters = false;
    this.hasFields = false;
    this.selectedFieldLabels = [];
    this.selectedValueFields = [];
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].checked) {
        this.hasFields = true;
        this.selectedFieldLabels.push(this.fields[i].label);
        this.selectedValueFields.push(this.fields[i].value);
      }
    }
    this.selectedFieldLabels.join(", ");
    if (this.selectedFieldLabels.length === this.fields.length) {
      this.hasFields = true;
      this.isAllFilters = true;
      this.selectedFieldLabels = [];
      this.selectedFieldLabels.push("All");
    }
  }
  fileNameDate(date: any): any {
    return date ? formatDate(date, 'YYYYMMdd', "en-us") : "X";
  }
  backToCustomerDashboard(): void {
    this.router.navigateByUrl("/customers/customer-dashboard");
  }
}