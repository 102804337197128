import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild, Input, AfterViewChecked, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CONSTANT } from 'src/app/helpers/constants';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { CustomModalComponent } from 'src/app/components/common/custom-modal/custom-modal.component';

@Component({
  selector: 'app-monetary-details',
  templateUrl: './monetary-details.component.html',
  styleUrls: ['../../voucher.component.sass']
})
export class MonetaryDetailsComponent implements OnInit, AfterViewInit {

  @Input() sendVoucher:any;
  @Input() loading:boolean = false;
  @Output() updateMonetary = new EventEmitter<any>();

  messageChars:number = 0;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  //Status
  viewDetail:boolean = false;
  editDetail:boolean = false;
  showMessage:boolean = false;
  showPayments:boolean = false;

  validCustomerEmail:boolean = false;
  validRecipientEmail:boolean = false;
  checkedCustomer:boolean = true;
  checkedRecipient:boolean = false;
  resendValid:boolean = false;

  //Temp mail
  customerEmail:any;
  recipientEmail:any;
  disabledBtnWhileCallingAPI: boolean = false;

  constructor(
    private voucherService: VoucherService,
    private modalService: BsModalService,
    private toast: CustomToastService,
    public utils: UTILS,
    private storeService: StoreService,
  ) { }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }

  ngOnInit(): void {
  }

  submitUpdate() {
    this.updateMonetary.emit();
  }

  openResendModal() {
    this.openCustomModal("resendModal");
    setTimeout(() => {
      this.validateResend();
    }, 100);
  }

  resendCheck(name:string, e:any) {
    switch(name) {
      case 'customer':
        this.checkedCustomer = e.target.checked;
        break;
      case 'recipient':
        this.checkedRecipient = e.target.checked;
        break;
    }
    this.validateResend();
  }

  validateResend() {
    if(this.checkedCustomer && !this.checkedRecipient) {
      let getInput = document.querySelector<HTMLInputElement>(`.custom-modal-voucher input[name=resendRecipientEmail]`)?.classList.remove('input-error');
      let validateCustomer = this.validateDetailInput('resendCustomerEmail',this.getInputValue('resendCustomerEmail',true),true);
      if(validateCustomer) {
        this.resendValid = true;
      } else {
        this.resendValid = false;
      }
    } else if(!this.checkedCustomer && this.checkedRecipient) {
      let getInput = document.querySelector<HTMLInputElement>(`.custom-modal-voucher input[name=resendCustomerEmail]`)?.classList.remove('input-error');
      let validateRecipient = this.validateDetailInput('resendRecipientEmail',this.getInputValue('resendRecipientEmail',true),true);
      if(validateRecipient) {
        this.resendValid = true;
      } else {
        this.resendValid = false;
      }
    } else {
      let validateCustomer = this.validateDetailInput('resendCustomerEmail',this.getInputValue('resendCustomerEmail',true),true);
      let validateRecipient = this.validateDetailInput('resendRecipientEmail',this.getInputValue('resendRecipientEmail',true),true);
      if(validateCustomer && validateRecipient) {
        this.resendValid = true;
      } else {
        this.resendValid = false;
      }
    }
  }

  sendNotification() {
    this.disabledBtnWhileCallingAPI = true;
    let request = {
      'payerEmail': this.getInputValue('resendCustomerEmail',true),
      'recipientEmail': this.getInputValue('resendRecipientEmail',true),
    }
    let orderNumber = this.sendVoucher.giftCardOrderNumber;
    this.voucherService.sendEmailtMonetary(orderNumber, request).subscribe(
      data => {
        this.toast.success("Email sent!", 'Success!');
        this.closeCustomModal("resendModal");
        this.disabledBtnWhileCallingAPI = false;
      },
      err => {
        this.toast.error(err.error.description, "Cannot send email!");
        this.disabledBtnWhileCallingAPI = false;
      }
    )
  }

  getInputValue(name:string, resend?:boolean) {
    let getInput, tmpValue:any;
    if(resend) {
      getInput = document.querySelector<HTMLInputElement>(`.custom-modal-voucher input[name=${name}]`);
    } else {
      getInput = document.querySelector<HTMLInputElement>(`.monetary-table input[name=${name}]`);
    }
    if(getInput) tmpValue = getInput.value;
    return tmpValue;
  }

  checkPattern(name:string, value:any) {
    const emailPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    const phonePattern = new RegExp(/^[0-9]*$/);
    switch (name) {
      case 'resendCustomerEmail':
        if(this.checkedCustomer) {
          return emailPattern.test(value);
        } else {
          return true;
        }
      case 'resendRecipientEmail':
        if(this.checkedRecipient) {
          return emailPattern.test(value);
        } else {
          return true;
        }
      case 'customerEmail':
        this.validCustomerEmail = emailPattern.test(value);
        return emailPattern.test(value);
      case 'recipientEmail':
        this.validRecipientEmail = emailPattern.test(value);
        return emailPattern.test(value);
      case 'phone':
        let tmpSplit = value.split("+")
        return (phonePattern.test(tmpSplit[1]) && value != "" && value != "+");
      default:
        return false;
    }
  }

  validateDetailInput(name:string, e:any, resend?:boolean) {
    let inputValue;
    if(typeof e == 'object') {
      inputValue = e.target.value;
    } else {
      inputValue = e;
    }
    let patternCheck = this.checkPattern(name, inputValue);
    let getInput;
    if(resend) {
      getInput = document.querySelector<HTMLInputElement>(`.custom-modal-voucher input[name=${name}]`);
    } else {
      getInput = document.querySelector<HTMLInputElement>(`.detail-table input[name=${name}]`);
    }
    if(!patternCheck) {
      if(getInput) {
        getInput.classList.add('input-error');
        return false;
      } else {
        return false;
      }
    } else {
      if(getInput) {
        getInput.classList.remove('input-error');
        return true;
      } else {
        return false;
      }
    }
  }

  countMessage(e:any) {
    let value = e.target.value;
    let getInput = document.querySelector<HTMLInputElement>(`.monetary-table textarea`);
    if(value.length > 255) {
      getInput!.classList.add('input-error');
    } else {
      getInput!.classList.remove('input-error');
    }
  }

  toggleDetail(type:string) {
    switch(type) {
      case 'message':
        this.showMessage ? this.showMessage = false : this.showMessage = true;
        break;
      case 'payments':
        this.showPayments ? this.showPayments = false : this.showPayments = true;
        break;
    }
  }

  updateInputEmail(type:string, e:any) {
    switch(type) {
      case 'customer':
        this.customerEmail = e.target.value;
        this.validateDetailInput('customerEmail', e.target.value);
        break;
      case 'recipient':
        this.recipientEmail = e.target.value;
        this.validateDetailInput('recipientEmail', e.target.value);
        break;
    }
  }

}