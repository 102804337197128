<div
  class="btn-group"
  [ngClass]="mainMenuClasses"
  dropdown #dropdown="bs-dropdown"
  [insideClick]="searchable || menuType === 'timepicker'"
  (isOpenChange)="toggleMenu($event)"
>
  @if (checkCompatibleMenuUI('mui-menu')) {
    <button
      dropdownToggle
      #menuButton
      type="button"
      [ngClass]="{'disabled': disableMenu}"
      class="btn dropdown-toggle"
      [style]="{fontSize: buttonFontSize}"
    >
      <!-- General menu UI -->
      @if (menuList.length) {
        <div class="button-container">
          <div class="button-title">{{selectedItem ? selectedItem.label : ""}}</div>
          <div class="button-arrow"><fa-icon type="solid" icon="chevron-down"></fa-icon></div>
        </div>
      }
      <!-- Dial code UI -->
      @if (selectedDialCodeItem) {
        <div class="dial-code-item">
          <div class="item-dial">+{{selectedDialCodeItem.dialCode}}</div>
          <div class="item-label"><span class="mobile-hide">{{ selectedDialCodeItem.label }}</span></div>
          <div class="button-arrow">
            <fa-icon type="solid" icon="chevron-down"></fa-icon>
          </div>
        </div>
      }
    </button>
  }
  @if (checkCompatibleMenuUI('menu-action')) {
    <button
      dropdownToggle
      #menuButton
      class="btn btn-green toggle-btn"
      [ngClass]="{'icon-only': !buttonTitle}"
      [disabled]="disableMenu"
    >
      <fa-icon [type]="buttonIconType" [icon]="buttonIcon"></fa-icon>{{buttonTitle}}
    </button>
  }
  @if (checkCompatibleMenuUI('menu-timepicker')) {
    <button
      dropdownToggle
      #menuButton
      type="button"
      [ngClass]="{'disabled': disableMenu}"
      class="btn dropdown-toggle"
      [style]="{fontSize: buttonFontSize}"
    >
      <div class="button-container">
        <div class="button-title">{{displayCurrentDate}}</div>
        <div class="button-icon"><fa-icon type="regular" icon="clock"></fa-icon></div>
      </div>
    </button>
  }
  <div
    scrollTracker
    [enableScrollCheck]="menuOpened"
    [trackType]="adaptiveType"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    [class]="alignment + '-align'"
    [ngClass]="{'custom-show': menuOpened}"
    [style]="{minWidth: minWidth, marginTop: offsetTop > 0 ? 'calc(-' + offsetTop +'px - env(safe-area-inset-bottom))' : offsetTop + 'px', marginLeft: 'calc(' + offsetLeft +'px - env(safe-area-inset-left))'}"
    (scrollPosition)="checkMenuPos()"
    #customDropdown
  >
    <div
      class="menu-content"
      [style]="{transformOrigin: menuOrigin + ' ' + (offsetTop > 0 ? (offsetTop - (btnHeight ? btnHeight/2 : 0)) : 0) + 'px'}"
    >
      <div class="content-main">
        @if (checkCompatibleMenuUI('menu-action')) {
          <div class="custom-action-header">
            {{actionTitle}}
          </div>
          @if (!showData) {
            <div class="menu-item-loading">
              <div class="loading-spinner"></div>
            </div>
          }
          @if (showData) {
            <div class="custom-action-body">
              @for (item of menuActionList; track item) {
                @if (item.show) {
                  <div class="body-item" (click)="selectMenuItem(item)">
                    <div class="item-container">
                      <div class="item-icon">
                        <fa-icon [type]="item.iconType" [icon]="item.icon" width="35"></fa-icon>
                      </div>
                      <div class="item-title">
                        {{item.title}}
                        <div class="item-desc">{{item.desc}}</div>
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          }
        }
  
        @if (checkCompatibleMenuUI('mui-menu')) {
          @if (searchable) {
            <div class="menu-search">
              <input type="text" name="custom-menu-search" class="custom-input form-control" placeholder="Search item" [(ngModel)]="searchKeyword" (ngModelChange)="searchListChange()">
            </div>
          }
          @if (!showData) {
            <div class="menu-item-loading">
              <div class="loading-spinner"></div>
            </div>
          }
          @if (showData) {
            <div class="menu-item-scroll" (click)="dropdown.hide()" [attr.id]="'select-menu-' + menuId">
              @if (menuList.length) {
                @for (item of menuListFiltered; track item) {
                  <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedItem && selectedItem.value === item.value, 'disabled': item.disabled}" [attr.id]="menuId + item.value">
                    {{ item.label }}
                  </div>
                }
              }
              @if (menuDialCodeList.length) {
                @for (item of menuDialCodeListFiltered; track item) {
                  <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedDialCodeItem && selectedDialCodeItem.value === item.value}" [attr.id]="menuId + item.value">
                    <div class="dial-code-item">
                      <div class="item-dial">+{{item.dialCode}}</div>
                      <div class="item-label">{{ item.label }}</div>
                    </div>
                  </div>
                }
              }
            </div>
          }
        }
  
        @if (checkCompatibleMenuUI('menu-timepicker')) {
          <timepicker [(ngModel)]="currentDate" [showMeridian]="false" [hourStep]="1" [minuteStep]="1" (ngModelChange)="timepickerChange()"></timepicker>
        }
      </div>
    </div>
  </div>
</div>