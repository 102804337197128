import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FaIconType } from '../fa-icon/fa-icon.component';
import { format } from 'date-fns/format';
import _ from 'lodash';

export type MenuType = {
  label: string,
  value: string,
  disabled: boolean
}
export type DialCodeMenuType = {
  code: string,
  label: string,
  value: string,
  dialCode: string
}
export type ActionMenuType = {
  icon: string,
  iconType: FaIconType,
  title: string,
  desc: string,
  value: string,
  show: boolean
}
export type LoadingAlertType = {
  title: string,
  desc?: string
}
export type ResultAlertType = {
  type: string,
  title: string,
  desc: string,
  hideFooter?: boolean
}
enum MenuKind {
  MenuType = "MenuType",
  DialCodeMenuType = "DialCodeMenuType",
  ActionMenuType = "ActionMenuType"
}
type SupportedMenuType = "general" | "dialCode" | "action" | "timepicker";

type MainMenuParamType = {
  [key:string]: boolean
}

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.sass']
})

export class DropdownMenuComponent implements OnInit, OnChanges {
  @ViewChild('customDropdown', { static: false }) customDropdown!: ElementRef;
  @ViewChild('menuButton', { static: false }) menuButton!: ElementRef;

  // General menu
  @Input() menuList:MenuType[] = [];
  @Input() selectedItem?:MenuType;
  @Output() selectItem:EventEmitter<MenuType> = new EventEmitter<MenuType>();

  // Menu dial code
  @Input() menuDialCodeList:DialCodeMenuType[] = [];
  @Input() selectedDialCodeItem?:DialCodeMenuType;
  @Output() selectDialCodeItem:EventEmitter<DialCodeMenuType> = new EventEmitter<DialCodeMenuType>();

  // Menu action
  @Input() buttonTitle:string = "";
  @Input() buttonIcon:string = "plus";
  @Input() buttonIconType:FaIconType = "solid";
  @Input() actionTitle:string = "";
  @Input() menuActionList:ActionMenuType[] = [];
  @Input() selectedActionItem?:ActionMenuType;
  @Output() selectActionItem:EventEmitter<string> = new EventEmitter<string>();

  // Menu timepicker
  @Input() initialDate?:Date;
  @Output() selectDate:EventEmitter<Date> = new EventEmitter<Date>();

  // All menus
  @Input() menuType:SupportedMenuType = "general";
  @Input() menuId:string = "";
  @Input() disableMenu:boolean = false;
  @Input() fullWidth:boolean = false;
  @Input() searchable:boolean = false;
  @Input() buttonFontSize:string = "18px";
  @Input() minWidth:string = "0";
  @Input() alignment:string = "";
  @Input() highlightError:boolean = false;
  @Input() inlineMenu:boolean = false;
  @Input() adaptiveType:string = "window";

  // Menu
  menuListFiltered:MenuType[] = [];
  menuDialCodeListFiltered:DialCodeMenuType[] = [];

  searchKeyword:string = ""
  menuOpened:boolean = false;
  offsetTop:number = 0;
  offsetLeft:number = 0;
  btnHeight?:number;
  btnMenuMargin?:number;
  showData:boolean = true;
  firstOpen:boolean = false;
  menuOrigin:string = "center";
  mainMenuClasses?:MainMenuParamType;

  currentDate:Date = new Date();
  prevDate:Date = new Date();
  displayCurrentDate:string = format(new Date(), "HH:mm");

  constructor() { }

  ngOnInit(): void {
    this.mainMenuClasses = {
      'full-width': this.fullWidth,
      'highlight-error': this.highlightError,
      'inline-menu': this.inlineMenu,
      'mui-menu': this.checkCompatibleMenuUI("mui-menu"),
      'menu-action': this.checkCompatibleMenuUI("menu-action"),
      'menu-timepicker': this.checkCompatibleMenuUI("menu-timepicker"),
    }
    if(this.initialDate) {
      const tmpInitialDate = _.cloneDeep(this.initialDate);
      this.currentDate = tmpInitialDate;
      this.displayCurrentDate = format(this.currentDate, "HH:mm")
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.menuType === "timepicker") {
      this.currentDate = changes.initialDate.currentValue;
      this.displayCurrentDate = format(this.currentDate, "HH:mm")
    }
  }

  checkCompatibleMenuUI(type:string) {
    switch (type) {
      case "mui-menu":
        return ['general', 'dialCode'].includes(this.menuType);
      case "menu-action":
        return ['action'].includes(this.menuType)
      case "menu-timepicker":
        return ['timepicker'].includes(this.menuType)
      default:
        return false
    }
  }

  toggleMenu(state:boolean) {
    if(state) {
      this.resetMenuFilter();
      if(!this.firstOpen) {
        this.firstOpen = true;
        setTimeout(() => {
          this.checkMenuPos();
          this.openSearch();
        }, 0);
      } else {
        if(!this.showData) {
          this.showData = true;
        }
        this.checkMenuPos();
        this.openSearch();
      }
    } else {
      this.menuOpened = false;
      setTimeout(() => {
        this.searchKeyword = "";
        this.resetMenuFilter();
      }, 300);
    }
  }

  resetMenuFilter() {
    switch (this.menuType) {
      case "general":
        this.menuListFiltered = this.menuList;
        break;
      case "dialCode":
        this.menuDialCodeListFiltered = this.menuDialCodeList;
        break;
      default:
        break;
    }
  }
  checkMenuType(item:MenuType | DialCodeMenuType | ActionMenuType) {
    if("disabled" in item) {
      return MenuKind.MenuType;
    }
    if("dialCode" in item) {
      return MenuKind.DialCodeMenuType;
    }
    if("show" in item) {
      return MenuKind.ActionMenuType;
    }
  }

  timepickerChange() {
    if(this.currentDate) {
      this.displayCurrentDate = format(this.currentDate, "HH:mm")
      this.prevDate = this.currentDate;
    } else {
      const tmpPrevDate = _.cloneDeep(this.prevDate);
      this.currentDate = tmpPrevDate;
    }
    this.selectDate.emit(this.currentDate)
  }

  selectMenuItem(item:MenuType | DialCodeMenuType | ActionMenuType) {
    const menuKind = this.checkMenuType(item);
    switch (menuKind) {
      case MenuKind.MenuType:
        this.selectItem.emit(item as MenuType)
        break;
      case MenuKind.DialCodeMenuType:
        this.selectDialCodeItem.emit(item as DialCodeMenuType)
        break;
      case MenuKind.ActionMenuType:
        const tmpActionItem = item as ActionMenuType;
        this.selectActionItem.emit(item.value)
        break; 
      default:
        console.error("Invalid menu type");
        break;
    }
  }

  searchListChange() {
    if(this.searchKeyword === "") {
      this.resetMenuFilter();
    } else {
      switch (this.menuType) {
        case "general":
          const tmpMenuFilter = this.menuList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()))
          this.menuListFiltered = tmpMenuFilter
          break;
        case "dialCode":
          const tmpDialCodeMenuFilter = this.menuDialCodeList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()) || item.dialCode.toString().includes(this.searchKeyword))
          this.menuDialCodeListFiltered = tmpDialCodeMenuFilter
          break;
        default:
          break;
      }
    }
  }

  openSearch() {
    this.searchKeyword = "";
    this.resetMenuFilter();
    setTimeout(() => {
      if(this.selectedItem || this.selectedDialCodeItem) {
        const dropdownContainer = document.getElementById(`select-menu-${this.menuId}`);
        let selectedItem:HTMLElement | null = null;
        if(this.selectedDialCodeItem) {
          selectedItem = document.getElementById(this.menuId + this.selectedDialCodeItem.value)
        }
        if(this.selectedItem) {
          selectedItem = document.getElementById(this.menuId + this.selectedItem.value)
        }
        if(dropdownContainer && selectedItem) {
          dropdownContainer.scrollTop = selectedItem.offsetTop - (this.searchable ? 130 : 100);
        }
      }
    }, 0);
  }

  checkMenuPos() {
    this.menuOpened = true;
    const menu = this.customDropdown.nativeElement.getBoundingClientRect();
    const button = this.menuButton.nativeElement.getBoundingClientRect();
    if(this.btnMenuMargin === undefined) {
      this.btnMenuMargin = menu.top - button.bottom;
    }
    if(this.btnHeight === undefined) {
      this.btnHeight = button.height
    }
    let targetEl:any;
    switch (this.adaptiveType) {
      case "window":
        targetEl = document.querySelector('body')?.getBoundingClientRect();
        break;
      case "modal":
        targetEl = document.querySelector('.custom-modal-container.show .custom-modal-body')?.getBoundingClientRect();
        break;
      default:
        break;
    }
    if(menu.width > button.width) {
      if(targetEl && this.alignment === "center") {
        const targetRight = targetEl.right;
        const targetLeft = targetEl.left;
        let originalPosRight = (menu.width - button.width) / 2 + button.right;
        let originalPosLeft = ((menu.width - button.width) / 2 - button.left) * -1;
        if(originalPosRight > targetRight + 15) {
          this.offsetLeft = (originalPosRight - targetRight + 15) * -1;
          this.menuOrigin = "right";
        } else if(originalPosLeft < targetLeft + 15) {
          this.offsetLeft = originalPosLeft * -1 + 15;
          this.menuOrigin = "left";
        } else {
          this.offsetLeft = 0;
          this.menuOrigin = "center"
        }
      } else {
        if(this.alignment === "right") {
          this.menuOrigin = "right";
        } else {
          this.menuOrigin = "left"
        }
      }
    } else {
      this.menuOrigin = this.alignment ? this.alignment : "center"
    }
    if(this.btnMenuMargin !== undefined) {
      let originalPosTop:number = 0;
      let isBeyondViewport:boolean = false;
      let maxOffsetTop:number = 0;
      switch (this.adaptiveType) {
        case "window":
          originalPosTop = menu.height + button.bottom + this.btnMenuMargin
          isBeyondViewport = originalPosTop > window.innerHeight - 15;
          maxOffsetTop = (this.btnMenuMargin * 2 + menu.height + button.height) * -1;
          if (isBeyondViewport) {
            const tmpOffsetTop = window.innerHeight - originalPosTop - 15;
            if(tmpOffsetTop < maxOffsetTop) {
              this.offsetTop = maxOffsetTop * -1;
            } else {
              this.offsetTop = tmpOffsetTop * -1;
            }
          } else {
            this.offsetTop = 0;
          }
          break;
        case "modal":
          const modalBodyEl = document.querySelector('.custom-modal-container.show .custom-modal-body')?.getBoundingClientRect();
          if(modalBodyEl) {
            originalPosTop = menu.height + button.bottom + this.btnMenuMargin
            isBeyondViewport = originalPosTop > modalBodyEl.bottom - 15;
            maxOffsetTop = (this.btnMenuMargin * 2 + menu.height + button.height) * -1;
            if (isBeyondViewport) {
              const tmpOffsetTop = modalBodyEl.bottom - originalPosTop - 15;
              if(tmpOffsetTop < maxOffsetTop) {
                this.offsetTop = maxOffsetTop * -1;
              } else {
                this.offsetTop = tmpOffsetTop * -1;
              }
            } else {
              this.offsetTop = 0;
            }
          }
          break;
        default:
          break;
      }
    }
  }

}
