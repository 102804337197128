import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HotelWebConfig } from 'src/app/@types/app';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';
import { Auth2Service } from 'src/app/services/auth2.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.sass'
})
export class PageNotFoundComponent {
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(
    private router: Router,
    private storeService: StoreService,
    private auth2Service: Auth2Service,
  ) {}

  goBackToPage() {
    if(GlobalUserSession) {
      if(this.hotelConfig.hotel.hotelChainName === "adminPortal") {
        this.router.navigateByUrl('/dashboard');
      } else {
        const token = localStorage.getItem('jwt');
        if(token) {
          let menuItems = this.auth2Service.getMenuList("client");;
          if(menuItems.length) {
            this.router.navigateByUrl(menuItems[0].pageName);
          } else {
            this.auth2Service.logOutToAdminLogin();
          }
        } else {
          this.auth2Service.logOutToAdminLogin();
        }
      }
    } else {
      this.auth2Service.logOutToAdminLogin();
    }
  }
}
