[
  {
      "code": "AF",
      "name": "Afghanistan",
      "iso3": "AFG",
      "dial_code": "93"
  },
  {
      "code": "AX",
      "name": "Aland Islands",
      "iso3": "ALA",
      "dial_code": "358"
  },
  {
      "code": "AL",
      "name": "Albania",
      "iso3": "ALB",
      "dial_code": "355"
  },
  {
      "code": "DZ",
      "name": "Algeria",
      "iso3": "DZA",
      "dial_code": "213"
  },
  {
      "code": "AS",
      "name": "American Samoa",
      "iso3": "ASM",
      "dial_code": "1684"
  },
  {
      "code": "AD",
      "name": "Andorra",
      "iso3": "AND",
      "dial_code": "376"
  },
  {
      "code": "AO",
      "name": "Angola",
      "iso3": "AGO",
      "dial_code": "244"
  },
  {
      "code": "AI",
      "name": "Anguilla",
      "iso3": "AIA",
      "dial_code": "1264"
  },
  {
      "code": "AG",
      "name": "Antigua and Barbuda",
      "iso3": "ATG",
      "dial_code": "1268"
  },
  {
      "code": "AR",
      "name": "Argentina",
      "iso3": "ARG",
      "dial_code": "54"
  },
  {
      "code": "AM",
      "name": "Armenia",
      "iso3": "ARM",
      "dial_code": "374"
  },
  {
      "code": "AW",
      "name": "Aruba",
      "iso3": "ABW",
      "dial_code": "297"
  },
  {
      "code": "AU",
      "name": "Australia",
      "iso3": "AUS",
      "dial_code": "61"
  },
  {
      "code": "AT",
      "name": "Austria",
      "iso3": "AUT",
      "dial_code": "43"
  },
  {
      "code": "AZ",
      "name": "Azerbaijan",
      "iso3": "AZE",
      "dial_code": "994"
  },
  {
      "code": "BS",
      "name": "Bahamas",
      "iso3": "BHS",
      "dial_code": "1242"
  },
  {
      "code": "BH",
      "name": "Bahrain",
      "iso3": "BHR",
      "dial_code": "973"
  },
  {
      "code": "BD",
      "name": "Bangladesh",
      "iso3": "BGD",
      "dial_code": "880"
  },
  {
      "code": "BB",
      "name": "Barbados",
      "iso3": "BRB",
      "dial_code": "1246"
  },
  {
      "code": "BY",
      "name": "Belarus",
      "iso3": "BLR",
      "dial_code": "375"
  },
  {
      "code": "BE",
      "name": "Belgium",
      "iso3": "BEL",
      "dial_code": "32"
  },
  {
      "code": "BZ",
      "name": "Belize",
      "iso3": "BLZ",
      "dial_code": "501"
  },
  {
      "code": "BJ",
      "name": "Benin",
      "iso3": "BEN",
      "dial_code": "229"
  },
  {
      "code": "BM",
      "name": "Bermuda",
      "iso3": "BMU",
      "dial_code": "1441"
  },
  {
      "code": "BT",
      "name": "Bhutan",
      "iso3": "BTN",
      "dial_code": "975"
  },
  {
      "code": "BO",
      "name": "Bolivia",
      "iso3": "BOL",
      "dial_code": "591"
  },
  {
      "code": "BQ",
      "name": "Bonaire, Saint Eustatius and Saba",
      "iso3": "BES",
      "dial_code": "599"
  },
  {
      "code": "BA",
      "name": "Bosnia and Herzegovina",
      "iso3": "BIH",
      "dial_code": "387"
  },
  {
      "code": "BW",
      "name": "Botswana",
      "iso3": "BWA",
      "dial_code": "267"
  },
  {
      "code": "BR",
      "name": "Brazil",
      "iso3": "BRA",
      "dial_code": "55"
  },
  {
      "code": "IO",
      "name": "British Indian Ocean Territory",
      "iso3": "IOT",
      "dial_code": "246"
  },
  {
      "code": "VG",
      "name": "British Virgin Islands",
      "iso3": "VGB",
      "dial_code": "1284"
  },
  {
      "code": "BN",
      "name": "Brunei",
      "iso3": "BRN",
      "dial_code": "673"
  },
  {
      "code": "BG",
      "name": "Bulgaria",
      "iso3": "BGR",
      "dial_code": "359"
  },
  {
      "code": "BF",
      "name": "Burkina Faso",
      "iso3": "BFA",
      "dial_code": "226"
  },
  {
      "code": "BI",
      "name": "Burundi",
      "iso3": "BDI",
      "dial_code": "257"
  },
  {
      "code": "KH",
      "name": "Cambodia",
      "iso3": "KHM",
      "dial_code": "855"
  },
  {
      "code": "CM",
      "name": "Cameroon",
      "iso3": "CMR",
      "dial_code": "237"
  },
  {
      "code": "CA",
      "name": "Canada",
      "iso3": "CAN",
      "dial_code": "1"
  },
  {
      "code": "CV",
      "name": "Cape Verde",
      "iso3": "CPV",
      "dial_code": "238"
  },
  {
      "code": "KY",
      "name": "Cayman Islands",
      "iso3": "CYM",
      "dial_code": "1345"
  },
  {
      "code": "CF",
      "name": "Central African Republic",
      "iso3": "CAF",
      "dial_code": "236"
  },
  {
      "code": "TD",
      "name": "Chad",
      "iso3": "TCD",
      "dial_code": "235"
  },
  {
      "code": "CL",
      "name": "Chile",
      "iso3": "CHL",
      "dial_code": "56"
  },
  {
      "code": "CN",
      "name": "China",
      "iso3": "CHN",
      "dial_code": "86"
  },
  {
      "code": "CX",
      "name": "Christmas Island",
      "iso3": "CXR",
      "dial_code": "61"
  },
  {
      "code": "CC",
      "name": "Cocos Islands",
      "iso3": "CCK",
      "dial_code": "61"
  },
  {
      "code": "CO",
      "name": "Colombia",
      "iso3": "COL",
      "dial_code": "57"
  },
  {
      "code": "KM",
      "name": "Comoros",
      "iso3": "COM",
      "dial_code": "269"
  },
  {
      "code": "CD",
      "name": "Democratic Republic of the Congo",
      "iso3": "COD",
      "dial_code": "243"
  },
  {
      "code": "CG",
      "name": "Republic of the Congo",
      "iso3": "COG",
      "dial_code": "242"
  },
  {
      "code": "CK",
      "name": "Cook Islands",
      "iso3": "COK",
      "dial_code": "682"
  },
  {
      "code": "CR",
      "name": "Costa Rica",
      "iso3": "CRI",
      "dial_code": "506"
  },
  {
      "code": "HR",
      "name": "Croatia",
      "iso3": "HRV",
      "dial_code": "385"
  },
  {
      "code": "CU",
      "name": "Cuba",
      "iso3": "CUB",
      "dial_code": "53"
  },
  {
      "code": "CW",
      "name": "Curaçao",
      "iso3": "CUW",
      "dial_code": "599"
  },
  {
      "code": "CY",
      "name": "Cyprus",
      "iso3": "CYP",
      "dial_code": "357"
  },
  {
      "code": "CZ",
      "name": "Czech Republic",
      "iso3": "CZE",
      "dial_code": "420"
  },
  {
      "code": "DK",
      "name": "Denmark",
      "iso3": "DNK",
      "dial_code": "45"
  },
  {
      "code": "DJ",
      "name": "Djibouti",
      "iso3": "DJI",
      "dial_code": "253"
  },
  {
      "code": "DM",
      "name": "Dominica",
      "iso3": "DMA",
      "dial_code": "1767"
  },
  {
      "code": "DO",
      "name": "Dominican Republic",
      "iso3": "DOM",
      "dial_code": "1"
  },
  {
      "code": "TL",
      "name": "Timor-Leste",
      "iso3": "TLS",
      "dial_code": "670"
  },
  {
      "code": "EC",
      "name": "Ecuador",
      "iso3": "ECU",
      "dial_code": "593"
  },
  {
      "code": "EG",
      "name": "Egypt",
      "iso3": "EGY",
      "dial_code": "20"
  },
  {
      "code": "SV",
      "name": "El Salvador",
      "iso3": "SLV",
      "dial_code": "503"
  },
  {
      "code": "GQ",
      "name": "Equatorial Guinea",
      "iso3": "GNQ",
      "dial_code": "240"
  },
  {
      "code": "ER",
      "name": "Eritrea",
      "iso3": "ERI",
      "dial_code": "291"
  },
  {
      "code": "EE",
      "name": "Estonia",
      "iso3": "EST",
      "dial_code": "372"
  },
  {
      "code": "ET",
      "name": "Ethiopia",
      "iso3": "ETH",
      "dial_code": "251"
  },
  {
      "code": "FK",
      "name": "Falkland Islands",
      "iso3": "FLK",
      "dial_code": "500"
  },
  {
      "code": "FO",
      "name": "Faroe Islands",
      "iso3": "FRO",
      "dial_code": "298"
  },
  {
      "code": "FJ",
      "name": "Fiji",
      "iso3": "FJI",
      "dial_code": "679"
  },
  {
      "code": "FI",
      "name": "Finland",
      "iso3": "FIN",
      "dial_code": "358"
  },
  {
      "code": "FR",
      "name": "France",
      "iso3": "FRA",
      "dial_code": "33"
  },
  {
      "code": "GF",
      "name": "French Guiana",
      "iso3": "GUF",
      "dial_code": "594"
  },
  {
      "code": "PF",
      "name": "French Polynesia",
      "iso3": "PYF",
      "dial_code": "689"
  },
  {
      "code": "GA",
      "name": "Gabon",
      "iso3": "GAB",
      "dial_code": "241"
  },
  {
      "code": "GM",
      "name": "Gambia",
      "iso3": "GMB",
      "dial_code": "220"
  },
  {
      "code": "GE",
      "name": "Georgia",
      "iso3": "GEO",
      "dial_code": "995"
  },
  {
      "code": "DE",
      "name": "Germany",
      "iso3": "DEU",
      "dial_code": "49"
  },
  {
      "code": "GH",
      "name": "Ghana",
      "iso3": "GHA",
      "dial_code": "233"
  },
  {
      "code": "GI",
      "name": "Gibraltar",
      "iso3": "GIB",
      "dial_code": "350"
  },
  {
      "code": "GR",
      "name": "Greece",
      "iso3": "GRC",
      "dial_code": "30"
  },
  {
      "code": "GL",
      "name": "Greenland",
      "iso3": "GRL",
      "dial_code": "299"
  },
  {
      "code": "GD",
      "name": "Grenada",
      "iso3": "GRD",
      "dial_code": "1473"
  },
  {
      "code": "GP",
      "name": "Guadeloupe",
      "iso3": "GLP",
      "dial_code": "590"
  },
  {
      "code": "GU",
      "name": "Guam",
      "iso3": "GUM",
      "dial_code": "1671"
  },
  {
      "code": "GT",
      "name": "Guatemala",
      "iso3": "GTM",
      "dial_code": "502"
  },
  {
      "code": "GG",
      "name": "Guernsey",
      "iso3": "GGY",
      "dial_code": "44"
  },
  {
      "code": "GN",
      "name": "Guinea",
      "iso3": "GIN",
      "dial_code": "224"
  },
  {
      "code": "GW",
      "name": "Guinea-Bissau",
      "iso3": "GNB",
      "dial_code": "245"
  },
  {
      "code": "GY",
      "name": "Guyana",
      "iso3": "GUY",
      "dial_code": "592"
  },
  {
      "code": "HT",
      "name": "Haiti",
      "iso3": "HTI",
      "dial_code": "509"
  },
  {
      "code": "HN",
      "name": "Honduras",
      "iso3": "HND",
      "dial_code": "504"
  },
  {
      "code": "HK",
      "name": "Hong Kong",
      "iso3": "HKG",
      "dial_code": "852"
  },
  {
      "code": "HU",
      "name": "Hungary",
      "iso3": "HUN",
      "dial_code": "36"
  },
  {
      "code": "IS",
      "name": "Iceland",
      "iso3": "ISL",
      "dial_code": "354"
  },
  {
      "code": "IN",
      "name": "India",
      "iso3": "IND",
      "dial_code": "91"
  },
  {
      "code": "ID",
      "name": "Indonesia",
      "iso3": "IDN",
      "dial_code": "62"
  },
  {
      "code": "IR",
      "name": "Iran",
      "iso3": "IRN",
      "dial_code": "98"
  },
  {
      "code": "IQ",
      "name": "Iraq",
      "iso3": "IRQ",
      "dial_code": "964"
  },
  {
      "code": "IE",
      "name": "Ireland",
      "iso3": "IRL",
      "dial_code": "353"
  },
  {
      "code": "IM",
      "name": "Isle of Man",
      "iso3": "IMN",
      "dial_code": "44"
  },
  {
      "code": "IL",
      "name": "Israel",
      "iso3": "ISR",
      "dial_code": "972"
  },
  {
      "code": "IT",
      "name": "Italy",
      "iso3": "ITA",
      "dial_code": "39"
  },
  {
      "code": "CI",
      "name": "Ivory Coast",
      "iso3": "CIV",
      "dial_code": "225"
  },
  {
      "code": "JM",
      "name": "Jamaica",
      "iso3": "JAM",
      "dial_code": "1876"
  },
  {
      "code": "JP",
      "name": "Japan",
      "iso3": "JPN",
      "dial_code": "81"
  },
  {
      "code": "JE",
      "name": "Jersey",
      "iso3": "JEY",
      "dial_code": "44"
  },
  {
      "code": "JO",
      "name": "Jordan",
      "iso3": "JOR",
      "dial_code": "962"
  },
  {
      "code": "KZ",
      "name": "Kazakhstan",
      "iso3": "KAZ",
      "dial_code": "7"
  },
  {
      "code": "KE",
      "name": "Kenya",
      "iso3": "KEN",
      "dial_code": "254"
  },
  {
      "code": "KI",
      "name": "Kiribati",
      "iso3": "KIR",
      "dial_code": "686"
  },
  {
      "code": "KP",
      "name": "Democratic People's Republic of Korea",
      "iso3": "PRK",
      "dial_code": "850"
  },
  {
      "code": "KR",
      "name": "Republic of Korea",
      "iso3": "KOR",
      "dial_code": "82"
  },
  {
      "code": "XK",
      "name": "Kosovo",
      "iso3": "XKX",
      "dial_code": "383"
  },
  {
      "code": "KW",
      "name": "Kuwait",
      "iso3": "KWT",
      "dial_code": "965"
  },
  {
      "code": "KG",
      "name": "Kyrgyzstan",
      "iso3": "KGZ",
      "dial_code": "996"
  },
  {
      "code": "LA",
      "name": "Laos",
      "iso3": "LAO",
      "dial_code": "856"
  },
  {
      "code": "LV",
      "name": "Latvia",
      "iso3": "LVA",
      "dial_code": "371"
  },
  {
      "code": "LB",
      "name": "Lebanon",
      "iso3": "LBN",
      "dial_code": "961"
  },
  {
      "code": "LS",
      "name": "Lesotho",
      "iso3": "LSO",
      "dial_code": "266"
  },
  {
      "code": "LR",
      "name": "Liberia",
      "iso3": "LBR",
      "dial_code": "231"
  },
  {
      "code": "LY",
      "name": "Libya",
      "iso3": "LBY",
      "dial_code": "218"
  },
  {
      "code": "LI",
      "name": "Liechtenstein",
      "iso3": "LIE",
      "dial_code": "423"
  },
  {
      "code": "LT",
      "name": "Lithuania",
      "iso3": "LTU",
      "dial_code": "370"
  },
  {
      "code": "LU",
      "name": "Luxembourg",
      "iso3": "LUX",
      "dial_code": "352"
  },
  {
      "code": "MO",
      "name": "Macao",
      "iso3": "MAC",
      "dial_code": "853"
  },
  {
      "code": "MK",
      "name": "Macedonia",
      "iso3": "MKD",
      "dial_code": "389"
  },
  {
      "code": "MG",
      "name": "Madagascar",
      "iso3": "MDG",
      "dial_code": "261"
  },
  {
      "code": "MW",
      "name": "Malawi",
      "iso3": "MWI",
      "dial_code": "265"
  },
  {
      "code": "MY",
      "name": "Malaysia",
      "iso3": "MYS",
      "dial_code": "60"
  },
  {
      "code": "MV",
      "name": "Maldives",
      "iso3": "MDV",
      "dial_code": "960"
  },
  {
      "code": "ML",
      "name": "Mali",
      "iso3": "MLI",
      "dial_code": "223"
  },
  {
      "code": "MT",
      "name": "Malta",
      "iso3": "MLT",
      "dial_code": "356"
  },
  {
      "code": "MH",
      "name": "Marshall Islands",
      "iso3": "MHL",
      "dial_code": "692"
  },
  {
      "code": "MQ",
      "name": "Martinique",
      "iso3": "MTQ",
      "dial_code": "596"
  },
  {
      "code": "MR",
      "name": "Mauritania",
      "iso3": "MRT",
      "dial_code": "222"
  },
  {
      "code": "MU",
      "name": "Mauritius",
      "iso3": "MUS",
      "dial_code": "230"
  },
  {
      "code": "YT",
      "name": "Mayotte",
      "iso3": "MYT",
      "dial_code": "262"
  },
  {
      "code": "MX",
      "name": "Mexico",
      "iso3": "MEX",
      "dial_code": "52"
  },
  {
      "code": "FM",
      "name": "Micronesia",
      "iso3": "FSM",
      "dial_code": "691"
  },
  {
      "code": "MD",
      "name": "Moldova",
      "iso3": "MDA",
      "dial_code": "373"
  },
  {
      "code": "MC",
      "name": "Monaco",
      "iso3": "MCO",
      "dial_code": "377"
  },
  {
      "code": "MN",
      "name": "Mongolia",
      "iso3": "MNG",
      "dial_code": "976"
  },
  {
      "code": "ME",
      "name": "Montenegro",
      "iso3": "MNE",
      "dial_code": "382"
  },
  {
      "code": "MS",
      "name": "Montserrat",
      "iso3": "MSR",
      "dial_code": "1664"
  },
  {
      "code": "MA",
      "name": "Morocco",
      "iso3": "MAR",
      "dial_code": "212"
  },
  {
      "code": "MZ",
      "name": "Mozambique",
      "iso3": "MOZ",
      "dial_code": "258"
  },
  {
      "code": "MM",
      "name": "Myanmar",
      "iso3": "MMR",
      "dial_code": "95"
  },
  {
      "code": "NA",
      "name": "Namibia",
      "iso3": "NAM",
      "dial_code": "264"
  },
  {
      "code": "NR",
      "name": "Nauru",
      "iso3": "NRU",
      "dial_code": "674"
  },
  {
      "code": "NP",
      "name": "Nepal",
      "iso3": "NPL",
      "dial_code": "977"
  },
  {
      "code": "NL",
      "name": "Netherlands",
      "iso3": "NLD",
      "dial_code": "31"
  },
  {
      "code": "NC",
      "name": "New Caledonia",
      "iso3": "NCL",
      "dial_code": "687"
  },
  {
      "code": "NZ",
      "name": "New Zealand",
      "iso3": "NZL",
      "dial_code": "64"
  },
  {
      "code": "NI",
      "name": "Nicaragua",
      "iso3": "NIC",
      "dial_code": "505"
  },
  {
      "code": "NE",
      "name": "Niger",
      "iso3": "NER",
      "dial_code": "227"
  },
  {
      "code": "NG",
      "name": "Nigeria",
      "iso3": "NGA",
      "dial_code": "234"
  },
  {
      "code": "NU",
      "name": "Niue",
      "iso3": "NIU",
      "dial_code": "683"
  },
  {
      "code": "NF",
      "name": "Norfolk Island",
      "iso3": "NFK",
      "dial_code": "672"
  },
  {
      "code": "MP",
      "name": "Northern Mariana Islands",
      "iso3": "MNP",
      "dial_code": "1670"
  },
  {
      "code": "NO",
      "name": "Norway",
      "iso3": "NOR",
      "dial_code": "47"
  },
  {
      "code": "OM",
      "name": "Oman",
      "iso3": "OMN",
      "dial_code": "968"
  },
  {
      "code": "PK",
      "name": "Pakistan",
      "iso3": "PAK",
      "dial_code": "92"
  },
  {
      "code": "PW",
      "name": "Palau",
      "iso3": "PLW",
      "dial_code": "680"
  },
  {
      "code": "PS",
      "name": "Palestinian Territory",
      "iso3": "PSE",
      "dial_code": "970"
  },
  {
      "code": "PA",
      "name": "Panama",
      "iso3": "PAN",
      "dial_code": "507"
  },
  {
      "code": "PG",
      "name": "Papua New Guinea",
      "iso3": "PNG",
      "dial_code": "675"
  },
  {
      "code": "PY",
      "name": "Paraguay",
      "iso3": "PRY",
      "dial_code": "595"
  },
  {
      "code": "PE",
      "name": "Peru",
      "iso3": "PER",
      "dial_code": "51"
  },
  {
      "code": "PH",
      "name": "Philippines",
      "iso3": "PHL",
      "dial_code": "63"
  },
  {
      "code": "PN",
      "name": "Pitcairn",
      "iso3": "PCN",
      "dial_code": "870"
  },
  {
      "code": "PL",
      "name": "Poland",
      "iso3": "POL",
      "dial_code": "48"
  },
  {
      "code": "PT",
      "name": "Portugal",
      "iso3": "PRT",
      "dial_code": "351"
  },
  {
      "code": "PR",
      "name": "Puerto Rico",
      "iso3": "PRI",
      "dial_code": "1"
  },
  {
      "code": "QA",
      "name": "Qatar",
      "iso3": "QAT",
      "dial_code": "974"
  },
  {
      "code": "RE",
      "name": "Reunion",
      "iso3": "REU",
      "dial_code": "262"
  },
  {
      "code": "RO",
      "name": "Romania",
      "iso3": "ROU",
      "dial_code": "40"
  },
  {
      "code": "RU",
      "name": "Russia",
      "iso3": "RUS",
      "dial_code": "7"
  },
  {
      "code": "RW",
      "name": "Rwanda",
      "iso3": "RWA",
      "dial_code": "250"
  },
  {
      "code": "BL",
      "name": "Saint Barthelemy",
      "iso3": "BLM",
      "dial_code": "590"
  },
  {
      "code": "SH",
      "name": "Saint Helena",
      "iso3": "SHN",
      "dial_code": "290"
  },
  {
      "code": "KN",
      "name": "Saint Kitts and Nevis",
      "iso3": "KNA",
      "dial_code": "1869"
  },
  {
      "code": "LC",
      "name": "Saint Lucia",
      "iso3": "LCA",
      "dial_code": "1758"
  },
  {
      "code": "MF",
      "name": "Saint Martin",
      "iso3": "MAF",
      "dial_code": "590"
  },
  {
      "code": "PM",
      "name": "Saint Pierre and Miquelon",
      "iso3": "SPM",
      "dial_code": "508"
  },
  {
      "code": "VC",
      "name": "Saint Vincent and the Grenadines",
      "iso3": "VCT",
      "dial_code": "1784"
  },
  {
      "code": "WS",
      "name": "Samoa",
      "iso3": "WSM",
      "dial_code": "685"
  },
  {
      "code": "SM",
      "name": "San Marino",
      "iso3": "SMR",
      "dial_code": "378"
  },
  {
      "code": "ST",
      "name": "Sao Tome and Principe",
      "iso3": "STP",
      "dial_code": "239"
  },
  {
      "code": "SA",
      "name": "Saudi Arabia",
      "iso3": "SAU",
      "dial_code": "966"
  },
  {
      "code": "SN",
      "name": "Senegal",
      "iso3": "SEN",
      "dial_code": "221"
  },
  {
      "code": "RS",
      "name": "Serbia",
      "iso3": "SRB",
      "dial_code": "381"
  },
  {
      "code": "SC",
      "name": "Seychelles",
      "iso3": "SYC",
      "dial_code": "248"
  },
  {
      "code": "SL",
      "name": "Sierra Leone",
      "iso3": "SLE",
      "dial_code": "232"
  },
  {
      "code": "SG",
      "name": "Singapore",
      "iso3": "SGP",
      "dial_code": "65"
  },
  {
      "code": "SX",
      "name": "Sint Maarten",
      "iso3": "SXM",
      "dial_code": "599"
  },
  {
      "code": "SK",
      "name": "Slovakia",
      "iso3": "SVK",
      "dial_code": "421"
  },
  {
      "code": "SI",
      "name": "Slovenia",
      "iso3": "SVN",
      "dial_code": "386"
  },
  {
      "code": "SB",
      "name": "Solomon Islands",
      "iso3": "SLB",
      "dial_code": "677"
  },
  {
      "code": "SO",
      "name": "Somalia",
      "iso3": "SOM",
      "dial_code": "252"
  },
  {
      "code": "ZA",
      "name": "South Africa",
      "iso3": "ZAF",
      "dial_code": "27"
  },
  {
      "code": "SS",
      "name": "South Sudan",
      "iso3": "SSD",
      "dial_code": "211"
  },
  {
      "code": "ES",
      "name": "Spain",
      "iso3": "ESP",
      "dial_code": "34"
  },
  {
      "code": "LK",
      "name": "Sri Lanka",
      "iso3": "LKA",
      "dial_code": "94"
  },
  {
      "code": "SD",
      "name": "Sudan",
      "iso3": "SDN",
      "dial_code": "249"
  },
  {
      "code": "SR",
      "name": "Suriname",
      "iso3": "SUR",
      "dial_code": "597"
  },
  {
      "code": "SJ",
      "name": "Svalbard and Jan Mayen",
      "iso3": "SJM",
      "dial_code": "47"
  },
  {
      "code": "SZ",
      "name": "Swaziland",
      "iso3": "SWZ",
      "dial_code": "268"
  },
  {
      "code": "SE",
      "name": "Sweden",
      "iso3": "SWE",
      "dial_code": "46"
  },
  {
      "code": "CH",
      "name": "Switzerland",
      "iso3": "CHE",
      "dial_code": "41"
  },
  {
      "code": "SY",
      "name": "Syria",
      "iso3": "SYR",
      "dial_code": "963"
  },
  {
      "code": "TW",
      "name": "Taiwan",
      "iso3": "TWN",
      "dial_code": "886"
  },
  {
      "code": "TJ",
      "name": "Tajikistan",
      "iso3": "TJK",
      "dial_code": "992"
  },
  {
      "code": "TZ",
      "name": "Tanzania",
      "iso3": "TZA",
      "dial_code": "255"
  },
  {
      "code": "TH",
      "name": "Thailand",
      "iso3": "THA",
      "dial_code": "66"
  },
  {
      "code": "TG",
      "name": "Togo",
      "iso3": "TGO",
      "dial_code": "228"
  },
  {
      "code": "TK",
      "name": "Tokelau",
      "iso3": "TKL",
      "dial_code": "690"
  },
  {
      "code": "TO",
      "name": "Tonga",
      "iso3": "TON",
      "dial_code": "676"
  },
  {
      "code": "TT",
      "name": "Trinidad and Tobago",
      "iso3": "TTO",
      "dial_code": "1868"
  },
  {
      "code": "TN",
      "name": "Tunisia",
      "iso3": "TUN",
      "dial_code": "216"
  },
  {
      "code": "TR",
      "name": "Turkey",
      "iso3": "TUR",
      "dial_code": "90"
  },
  {
      "code": "TM",
      "name": "Turkmenistan",
      "iso3": "TKM",
      "dial_code": "993"
  },
  {
      "code": "TC",
      "name": "Turks and Caicos Islands",
      "iso3": "TCA",
      "dial_code": "1649"
  },
  {
      "code": "TV",
      "name": "Tuvalu",
      "iso3": "TUV",
      "dial_code": "688"
  },
  {
      "code": "VI",
      "name": "U.S. Virgin Islands",
      "iso3": "VIR",
      "dial_code": "1340"
  },
  {
      "code": "UG",
      "name": "Uganda",
      "iso3": "UGA",
      "dial_code": "256"
  },
  {
      "code": "UA",
      "name": "Ukraine",
      "iso3": "UKR",
      "dial_code": "380"
  },
  {
      "code": "AE",
      "name": "United Arab Emirates",
      "iso3": "ARE",
      "dial_code": "971"
  },
  {
      "code": "GB",
      "name": "United Kingdom",
      "iso3": "GBR",
      "dial_code": "44"
  },
  {
      "code": "US",
      "name": "United States",
      "iso3": "USA",
      "dial_code": "1"
  },
  {
      "code": "UY",
      "name": "Uruguay",
      "iso3": "URY",
      "dial_code": "598"
  },
  {
      "code": "UZ",
      "name": "Uzbekistan",
      "iso3": "UZB",
      "dial_code": "998"
  },
  {
      "code": "VU",
      "name": "Vanuatu",
      "iso3": "VUT",
      "dial_code": "678"
  },
  {
      "code": "VA",
      "name": "Vatican",
      "iso3": "VAT",
      "dial_code": "379"
  },
  {
      "code": "VE",
      "name": "Venezuela",
      "iso3": "VEN",
      "dial_code": "58"
  },
  {
      "code": "VN",
      "name": "Vietnam",
      "iso3": "VNM",
      "dial_code": "84"
  },
  {
      "code": "WF",
      "name": "Wallis and Futuna",
      "iso3": "WLF",
      "dial_code": "681"
  },
  {
      "code": "EH",
      "name": "Western Sahara",
      "iso3": "ESH",
      "dial_code": "212"
  },
  {
      "code": "YE",
      "name": "Yemen",
      "iso3": "YEM",
      "dial_code": "967"
  },
  {
      "code": "ZM",
      "name": "Zambia",
      "iso3": "ZMB",
      "dial_code": "260"
  },
  {
      "code": "ZW",
      "name": "Zimbabwe",
      "iso3": "ZWE",
      "dial_code": "263"
  }
]