export class RolePermission {
  public static adminPortalAccessRoles = [
    'NELSON_MANAGER',
    'CLIENT_ADMIN',
  ]
  public static adminPortalPageList = [
    {
      pageName: 'dashboard',
      friendlyName: 'Dashboard',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'CLEANER',
        'REVENUE_MANAGER',
        'ACCOUNTING',
      ],
    },
    {
      pageName: 'users',
      friendlyName: 'Users',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
    },
    {
      pageName: 'internal-tools',
      friendlyName: 'Internal tools',
      requiredRoles: ['NELSON_MANAGER'],
    }
  ];
  public static clientPageList = [
    {
      pageName: 'reservations',
      friendlyName: 'Reservations',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'REVENUE_MANAGER',
        'ACCOUNTING',
      ],
    },
    {
      pageName: 'usage',
      friendlyName: 'Usage',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'CLEANER',
        'REVENUE_MANAGER',
      ],
    },
    {
      pageName: 'maintenance',
      friendlyName: 'Maintenance',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'CLEANER',
      ],
    },
    {
      pageName: 'reports',
      friendlyName: 'Reports',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'CLEANER',
        'REVENUE_MANAGER',
        'ACCOUNTING',
      ],
    },
    {
      pageName: 'customers',
      friendlyName: 'Customers',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
      ],
    },
    {
      pageName: 'pricing',
      friendlyName: 'Pricing',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER'],
    },
    {
      pageName: 'allotments',
      friendlyName: 'Allotments',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'REVENUE_MANAGER',
      ],
    },
    {
      pageName: 'voucher',
      friendlyName: 'Vouchers',
      requiredRoles: [
        'NELSON_MANAGER',
        'CLIENT_ADMIN',
        'CUSTOMER_SERVICE',
        'REVENUE_MANAGER',
      ],
    },
    {
      pageName: 'breakfast',
      friendlyName: 'Breakfast',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
    },
    {
      pageName: 'hotels',
      friendlyName: 'Settings',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
    },
    {
      pageName: 'locks',
      friendlyName: 'Locks',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
    },
    {
      pageName: 'internal-tools-mui',
      friendlyName: 'Internal tools',
      requiredRoles: ['NELSON_MANAGER'],
    }
  ];
}