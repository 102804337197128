import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scrollTracker]'
})
export class ScrollTrackerDirective implements OnInit {
  @Input() enableScrollCheck: boolean = true;
  @Input() trackType:string = "window";
  @Output() scrollPosition = new EventEmitter<void>();

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if (this.trackType === "modal") {
      const tmpElement = document.querySelector('.custom-modal-container.show .custom-modal-body');
      if (tmpElement) {
        this.renderer.listen(tmpElement, 'scroll', () => this.onElementScroll());
      }
    }
  }
  private onElementScroll() {
    if (this.trackType === "modal" && this.enableScrollCheck) {
      this.scrollPosition.emit();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.trackType === "window" && this.enableScrollCheck) {
      this.scrollPosition.emit();
    }
  }
}
