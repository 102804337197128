<app-header activePage="reservations"></app-header>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">

      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">{{ title }}</div>
        </div>
        @if (editAllowed) {
          <div class="page-twb-button">
            <dropdown-menu
              buttonTitle="New reservation"
              buttonIconType="solid"
              buttonIcon="plus"
              actionTitle="Select reservation type"
              menuType="action"
              alignment="right"
              [menuActionList]="newReservationActionMenu"
              (selectActionItem)="openCreateReservation($event)"
            ></dropdown-menu>
          </div>
        }
      </div>

      <form [formGroup]="reservationSearch">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-6">
            <label>Search for</label>
            <input class="form-control custom-input searchQuery" formControlName="searchQuery" (keyup.enter)="search('search')">
          </div>
          @if (hotels.length > 1) {
            <div class="col-12 col-sm-4 col-md-2">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <div class="col-6 col-sm-3 col-md-2">
            <label>Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker [(bsValue)]="startDate"
                #startDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red reservation-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <label>End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control" bsDatepicker [(bsValue)]="endDate"
                #endDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red reservation-end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6">
            <label>Filters</label>
            <div class="select-menu" dropdown>
              <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                <div class="menu-selected">
                  @for (filter of getPartialFilter(); track filter; let i = $index) {
                    @if (filter.checked) {
                      <div class="filter-content">
                        <div class="filter-item">
                          <div class="filter-label">{{filter.label}}</div>
                          <div (click)="removeFilter(filter, $event)">
                            <fa-icon type="solid" icon="xmark"></fa-icon>
                          </div>
                        </div>
                      </div>
                    }
                  }
                  @if (getAllFilter().length > getPartialFilter().length) {
                    <div class="filter-content">
                      <span class="filter-item">
                        <span class="filter-label"> + {{getAllFilter().length - getPartialFilter().length}}</span>
                      </span>
                    </div>
                  }
                </div>
                <div class="menu-arrow">
                  <fa-icon type="solid" icon="chevron-down"></fa-icon>
                </div>
              </div>
              <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                (click)="utils.preventClose($event)">
                <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                @for (filter of filters; track filter; let i = $index) {
                  <div class="normal-checkbox" (click)="changeFilter(i,$event)">
                    <input type="checkbox" [checked]="filter.checked">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    <div class="checkbox-label">{{filter.label}}</div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-2">
            <label for="select-report">Booking channel</label>
            <dropdown-menu
              [menuList]="channelList"
              [selectedItem]="selectedChannel"
              [fullWidth]="true"
              (selectItem)="selectChannel($event)"
            ></dropdown-menu>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <label>Date selection</label>
            <div class="box-selector">
              @for (mode of dateMode; track mode) {
                <div class="custom-radio">
                  <input type="radio" name="date-mode" [checked]="selectedDateMode.value == mode.value"
                    (click)="changeDateMode(mode)">
                  <div class="radio-box">
                    <div class="box-text">{{mode.label}}</div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </form>

      <div class="bottom-filter">
        <div class="bottom-filter-left">
          <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big" (click)="search('departingToday')"><fa-icon type="solid" icon="left-from-bracket"></fa-icon> Departing today</button>
          <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big left-margin" (click)="search('arrivingToday')"><fa-icon type="solid" icon="right-to-bracket"></fa-icon> Arriving today</button>
          <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big left-margin" (click)="search('stayingTonight')"><fa-icon type="solid" icon="house-night"></fa-icon> Staying tonight</button>
        </div>
        <div class="bottom-filter-right">
          <button type="button" class="btn btn-none-outline-green" (click)="resetSearchFields()"><fa-icon type="regular" icon="trash-can"></fa-icon>Clear search</button>
          <button type="button" [disabled]="isLoading || isMoreLoading || hotels.length < 1" class="btn btn-green btn-big" (click)="search('search')"><fa-icon type="solid" icon="magnifying-glass"></fa-icon> Search</button>
        </div>
      </div>

    </div>
  </div>

        <div class="wrapper-outer-table">
          <div class="outer-table">
            <div class="container-fluid">
              <div>
                <!-- <label class="clickable" (click)="toggleStatusFilter()">{{showStatusFilter ? 'Hide' : 'Show'}} status filters</label> -->
                <div class="container-title">Search results</div>
                <ng-container>
                  <div class="filter-checkbox-group">
                    <label>Filters</label>
                    @for (status of reservationStatus; track status; let i = $index) {
                      <div class="filter-radio">
                        <input type="checkbox" name="reservation" [checked]="status.checked" (click)="changeStatus(i,$event)">
                        <div class="radio-box">
                          @if (status.checked) {
                            <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          }
                          {{status.label}}
                        </div>
                      </div>
                    }
                  </div>
                </ng-container>
              </div>
              <div class="custom-table-wrapper">
                @if (!isLoading && searchResult && searchResult.reservations.length > 0) {
                  <table class="custom-table">
                    <colgroup>
                    <col>
                    <col>
                    <col>
                    <col>
                    <col>
                    <col style="min-width: 50%;">
                    <col>
                    <col>
                    </colgroup>
                    <thead>
                      <th><div class="padding">Reservation</div></th>
                      <th [ngClass]="{sortActive: orderBy == 'CONTACT_NAME'}">
                        <div class="flex-container padding">
                          <div class="table-title">Booker Information</div>
                          <div class="table-sort" (click)="toggleSorting('CONTACT_NAME')">
                            <fa-icon type="solid" [icon]="orderBy == 'CONTACT_NAME' && orderDir == 'DESC' ? 'arrow-up-wide-short' : 'arrow-down-short-wide'"></fa-icon>
                          </div>
                        </div>
                      </th>
                      <th><div class="padding">Hotel</div></th>
                      <th [ngClass]="{sortActive: orderBy == 'CHECK_IN'}">
                        <div class="flex-container padding">
                          <div class="table-title">Check in</div>
                          <div class="table-sort" (click)="toggleSorting('CHECK_IN')">
                            <fa-icon type="solid" [icon]="orderBy == 'CHECK_IN' && orderDir == 'DESC' ? 'arrow-up-wide-short' : 'arrow-down-short-wide'"></fa-icon>
                          </div>
                        </div>
                      </th>
                      <th [ngClass]="{sortActive: orderBy == 'CHECK_OUT'}">
                        <div class="flex-container padding">
                          <div class="table-title">Check out</div>
                          <div class="table-sort" (click)="toggleSorting('CHECK_OUT')">
                            <fa-icon type="solid" [icon]="orderBy == 'CHECK_OUT' && orderDir == 'DESC' ? 'arrow-up-wide-short' : 'arrow-down-short-wide'"></fa-icon>
                          </div>
                        </div>
                      </th>
                      <th><div class="padding">Guests</div></th>
                      <th><div class="padding">Fully refundable</div></th>
                      <th><div class="padding">Status</div></th>
                    </thead>
                    <tbody>
                      @for (result of searchResult.reservations; track result) {
                        <tr [ngClass]="{graybg: result.state != 'CONFIRMED'}">
                          <td>
                            <a routerLink="/reservations/{{result.uuid}}"></a>
                            <b>{{result.reservationCode}}</b>
                            <br>{{getChannelLabel(result.bookingChannel)}}
                              @if (result.bookingChannelReservationId) {
                                <br><br>External channel reservation number: <b>{{result.bookingChannelReservationId}}</b>
                              }
                            </td>
                            <td>
                              <a routerLink="/reservations/{{result.uuid}}"></a>
                              {{result.customer.firstName+' '+result.customer.lastName}}
                              @if ((result.customer.emailReal)) {
                                <br>
                                  }{{result.customer.emailReal}}
                                  @if ((result.customer.emailVirtual)) {
                                    <br>
                                      }{{result.customer.emailVirtual}}
                                      <br>{{result.customer.mobile}}
                                      </td>
                                      <td><a routerLink="/reservations/{{result.uuid}}"></a>{{showHotelBaseOnConfig(result.dropdownHotelName)}}</td>
                                      <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.startDate}}</td>
                                      <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.endDate}}</td>
                                      <td>
                                        <a class="no-absolute" routerLink="/reservations/{{result.uuid}}"></a>
                                        @if (checkEmptyGuests(result.guestsByRoomLabel)) {
                                          @for (room of result.guestsByRoomLabel | keyvalue; track room; let roomIndex = $index) {
                                            <div>
                                              <b>Room {{room.key}}</b>
                                              @if (roomIndex === 0) {
                                                <div>{{result.customer.firstName + ' ' + result.customer.lastName }}</div>
                                              }
                                              @if (roomIndex !== 0) {
                                                @for (guest of convertToArray(room.value); track guest) {
                                                  <div>{{guest.firstName + ' ' +
                                                  guest.lastName }}</div>
                                                }
                                              }
                                              <br>
                                              </div>
                                            }
                                          }
                                          @if (!checkEmptyGuests(result.guestsByRoomLabel)) {
                                            @for (room of result.guestsByRoomLabel | keyvalue; track room) {
                                              <div>
                                                <b>Room {{room.key}}</b>
                                                @for (guest of convertToArray(room.value); track guest) {
                                                  <div>
                                                    <div>{{guest.firstName + ' ' +
                                                    guest.lastName }}</div>
                                                  </div>
                                                  }<br>
                                                </div>
                                              }
                                            }
                                          </td>
                                          <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.bookingChannelReservationId ? 'n/a' : (result.isFullyRefund ? 'Yes' : 'No')}}</td>
                                          <td>
                                            <a routerLink="/reservations/{{result.uuid}}"></a>
                                            <span [style]="{color: result.state === 'INITIAL' ? 'red' : '#424F68'}">{{utils.showReservationStatus(result.state)}}</span>
                                            <br>{{utils.convertStringDate(result.created)}}
                                            </td>
                                          </tr>
                                        }
                                      </tbody>
                                    </table>
                                  }
                                </div>

                              </div>

                              @if (!isLoadingCountSearch && totalCount && totalCount > limitSearchReservations) {
                                <div class="show-more">
                                  <label>Showing {{currentCount}}/{{totalCount}}</label>
                                  @if (currentCount != totalCount) {
                                    <button class="btn btn-green large-btn show-more-btn" (click)="search('showMore')"
                                      [disabled]="isMoreLoading"><fa-icon type="regular" icon="circle-ellipsis"></fa-icon>Show
                                    {{remainingCount}} more</button>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                          @if (isLoading) {
                            <div class="center-item">
                              <ng-lottie
                                [options]="options"
                                width="200px"
                                height="200px"
                              ></ng-lottie>
                            </div>
                          }

                          @if (isMoreLoading) {
                            <div class="center-item">
                              <ng-lottie
                                [options]="options"
                                width="200px"
                                height="200px"
                              ></ng-lottie>
                            </div>
                          }

                        </div>