import { Component, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { InternalToolsService } from 'src/app/services/internal-tools.service';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-manual-external-retrieve',
  templateUrl: './manual-external-retrieve.component.html',
  styleUrls: ['../internal-tools-mui.component.sass']
})
export class ManualExternalRetrieveComponent implements OnInit {

  constructor(
    private storeService: StoreService,
    public utils: UTILS,
    private internalToolsService: InternalToolsService,
    private toast: CustomToastService,
  ) { }

  @Input() hotels:any;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  
  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;
  
  channelList: MenuType[] = [];
  selectedChannel?:MenuType;

  responseSuccess:boolean = false;

  retrieveForm = new UntypedFormGroup({
    externalNumber: new UntypedFormControl('', Validators.required),
  });
  get externalNumber() {
    return this.retrieveForm.get("externalNumber")
  }

  ngOnInit(): void {
    let tmpChannelList:MenuType[] = [];
    CONSTANT.CHANNEL_BOOKING.forEach(channel => {
      if((CONSTANT.OTA_CHANNEL.includes(channel.value) && this.hotelConfig.MUIfeature.OTAServices.includes(channel.value))) {
        tmpChannelList.push({
          label: channel.label,
          value: channel.value,
          disabled: false
        });
      }
    })
    this.channelList = tmpChannelList;
    this.selectChannel(tmpChannelList[0]);
  }

  ngOnChanges() {
    if(this.hotels.length) {
      let tmpHotelList:MenuType[] = [];
      this.hotels.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.label,
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectHotel(tmpHotelList[0])
    }
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectChannel(item:MenuType) {
    this.selectedChannel = item;
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error.description) errorMessage = err.error.description;
    if(err.error.message) errorMessage = err.error.message;
    if(!errorMessage) errorMessage = "Unknown error occurred!";
    this.toast.error(errorMessage, 'Error!');
  }

  retrieve() {
    this.responseSuccess = false;
    this.retrieveForm.markAllAsTouched();
    if(this.retrieveForm.valid && this.externalNumber && this.selectedHotel && this.selectedChannel) {
      this.internalToolsService.retrieveOTAreservation(this.selectedChannel.value, this.externalNumber.value, this.selectedHotel.value).subscribe(
        data => {
          this.responseSuccess = true;
        },
        error => {
          this.displayAPIerror(error)
        }
      )
    }
  }

}
