import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANT } from 'src/app/helpers/constants';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { UTILS } from 'src/app/helpers/utils';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { Auth2Service, NavMenuListType } from 'src/app/services/auth2.service';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';
import { RolePermission } from 'src/app/helpers/rolesPermission';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  email?: string;
  menuItems: NavMenuListType[] = [];
  adminPortalAccess?: boolean = false;
  adminDashboardAccess?: boolean = false;
  hotelName: string = '';
  avatarText:string = "";
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  @Input() activePage:string = "";

  constructor(
    private utils: UTILS,
    private auth2Service: Auth2Service,
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    if(GlobalUserSession) {
      this.avatarText = this.utils.createAvatarFromName(GlobalUserSession.name);
      this.menuItems = this.auth2Service.getMenuList("client");
      if(RolePermission.adminPortalAccessRoles.includes(GlobalUserSession.role)) {
        this.adminPortalAccess = true;
      }
      if(GlobalUserSession.allowedTenantIds.length > 1) {
        this.adminDashboardAccess = true;
      }
      this.hotelName = GlobalUserSession.envName;
    }
  }
  logout(): void {
    this.auth2Service.logOutToAdminLogin();
  }
  editCurrentUser() {
    // Update Client name when redirect from MUI to User management
    if(this.adminPortalAccess) {
      let adminEnv = "https://admin.nelson.management";
      if (this.utils.isLocalEnvironment()) {
        adminEnv = "http://localhost:4000"
      }
      window.open(`${adminEnv}/#/users?editCurrentUser=true`, "_self");
    }
  }
  backToUserManagement() {
    // Update Client name when redirect from MUI to User management
    let adminEnv = "https://admin.nelson.management";
    if (this.utils.isLocalEnvironment()) {
      adminEnv = "http://localhost:4000"
    }
    window.open(`${adminEnv}/#/dashboard`, "_self");
  }
}
