[
  {
    "name": "Afghanistan",
    "name_fr": "Afghanistan",
    "ISO3166-1-Alpha-2": "AF",
    "ISO3166-1-Alpha-3": "AFG",
    "ISO3166-1-numeric": "004",
    "ITU": "AFG",
    "MARC": "af",
    "WMO": "AF",
    "DS": "AFG",
    "Dial": "93",
    "FIFA": "AFG",
    "FIPS": "AF",
    "GAUL": "1",
    "IOC": "AFG",
    "currency_alphabetic_code": "AFN",
    "currency_country_name": "AFGHANISTAN",
    "currency_minor_unit": "2",
    "currency_name": "Afghani",
    "currency_numeric_code": "971",
    "is_independent": "Yes"
  },
  {
    "name": "Albania",
    "name_fr": "Albanie",
    "ISO3166-1-Alpha-2": "AL",
    "ISO3166-1-Alpha-3": "ALB",
    "ISO3166-1-numeric": "008",
    "ITU": "ALB",
    "MARC": "aa",
    "WMO": "AB",
    "DS": "AL",
    "Dial": "355",
    "FIFA": "ALB",
    "FIPS": "AL",
    "GAUL": "3",
    "IOC": "ALB",
    "currency_alphabetic_code": "ALL",
    "currency_country_name": "ALBANIA",
    "currency_minor_unit": "2",
    "currency_name": "Lek",
    "currency_numeric_code": "008",
    "is_independent": "Yes"
  },
  {
    "name": "Algeria",
    "name_fr": "Algérie",
    "ISO3166-1-Alpha-2": "DZ",
    "ISO3166-1-Alpha-3": "DZA",
    "ISO3166-1-numeric": "012",
    "ITU": "ALG",
    "MARC": "ae",
    "WMO": "AL",
    "DS": "DZ",
    "Dial": "213",
    "FIFA": "ALG",
    "FIPS": "AG",
    "GAUL": "4",
    "IOC": "ALG",
    "currency_alphabetic_code": "DZD",
    "currency_country_name": "ALGERIA",
    "currency_minor_unit": "2",
    "currency_name": "Algerian Dinar",
    "currency_numeric_code": "012",
    "is_independent": "Yes"
  },
  {
    "name": "American Samoa",
    "name_fr": "Samoa Américaines",
    "ISO3166-1-Alpha-2": "AS",
    "ISO3166-1-Alpha-3": "ASM",
    "ISO3166-1-numeric": "016",
    "ITU": "SMA",
    "MARC": "as",
    "WMO": " ",
    "DS": "USA",
    "Dial": "1-684",
    "FIFA": "ASA",
    "FIPS": "AQ",
    "GAUL": "5",
    "IOC": "ASA",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "AMERICAN SAMOA",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of US"
  },
  {
    "name": "Andorra",
    "name_fr": "Andorre",
    "ISO3166-1-Alpha-2": "AD",
    "ISO3166-1-Alpha-3": "AND",
    "ISO3166-1-numeric": "020",
    "ITU": "AND",
    "MARC": "an",
    "WMO": " ",
    "DS": "AND",
    "Dial": "376",
    "FIFA": "AND",
    "FIPS": "AN",
    "GAUL": "7",
    "IOC": "AND",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "ANDORRA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Angola",
    "name_fr": "Angola",
    "ISO3166-1-Alpha-2": "AO",
    "ISO3166-1-Alpha-3": "AGO",
    "ISO3166-1-numeric": "024",
    "ITU": "AGL",
    "MARC": "ao",
    "WMO": "AN",
    "DS": " ",
    "Dial": "244",
    "FIFA": "ANG",
    "FIPS": "AO",
    "GAUL": "8",
    "IOC": "ANG",
    "currency_alphabetic_code": "AOA",
    "currency_country_name": "ANGOLA",
    "currency_minor_unit": "2",
    "currency_name": "Kwanza",
    "currency_numeric_code": "973",
    "is_independent": "Yes"
  },
  {
    "name": "Anguilla",
    "name_fr": "Anguilla",
    "ISO3166-1-Alpha-2": "AI",
    "ISO3166-1-Alpha-3": "AIA",
    "ISO3166-1-numeric": "660",
    "ITU": "AIA",
    "MARC": "am",
    "WMO": " ",
    "DS": " ",
    "Dial": "1-264",
    "FIFA": "AIA",
    "FIPS": "AV",
    "GAUL": "9",
    "IOC": "AIA",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "ANGUILLA",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Antarctica",
    "name_fr": "Antarctique",
    "ISO3166-1-Alpha-2": "AQ",
    "ISO3166-1-Alpha-3": "ATA",
    "ISO3166-1-numeric": "010",
    "ITU": " ",
    "MARC": "ay",
    "WMO": "AA",
    "DS": " ",
    "Dial": "672",
    "FIFA": "ROS",
    "FIPS": "AY",
    "GAUL": "10",
    "IOC": " ",
    "currency_alphabetic_code": "",
    "currency_country_name": "ANTARCTICA",
    "currency_minor_unit": "",
    "currency_name": "No universal currency",
    "currency_numeric_code": "",
    "is_independent": "International"
  },
  {
    "name": "Antigua and Barbuda",
    "name_fr": "Antigua-Et-Barbuda",
    "ISO3166-1-Alpha-2": "AG",
    "ISO3166-1-Alpha-3": "ATG",
    "ISO3166-1-numeric": "028",
    "ITU": "ATG",
    "MARC": "aq",
    "WMO": "AT",
    "DS": " ",
    "Dial": "1-268",
    "FIFA": "ATG",
    "FIPS": "AC",
    "GAUL": "11",
    "IOC": "ANT",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "ANTIGUA AND BARBUDA",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Argentina",
    "name_fr": "Argentine",
    "ISO3166-1-Alpha-2": "AR",
    "ISO3166-1-Alpha-3": "ARG",
    "ISO3166-1-numeric": "032",
    "ITU": "ARG",
    "MARC": "ag",
    "WMO": "AG",
    "DS": "RA",
    "Dial": "54",
    "FIFA": "ARG",
    "FIPS": "AR",
    "GAUL": "12",
    "IOC": "ARG",
    "currency_alphabetic_code": "ARS",
    "currency_country_name": "ARGENTINA",
    "currency_minor_unit": "2",
    "currency_name": "Argentine Peso",
    "currency_numeric_code": "032",
    "is_independent": "Yes"
  },
  {
    "name": "Armenia",
    "name_fr": "Arménie",
    "ISO3166-1-Alpha-2": "AM",
    "ISO3166-1-Alpha-3": "ARM",
    "ISO3166-1-numeric": "051",
    "ITU": "ARM",
    "MARC": "ai",
    "WMO": "AY",
    "DS": "AM",
    "Dial": "374",
    "FIFA": "ARM",
    "FIPS": "AM",
    "GAUL": "13",
    "IOC": "ARM",
    "currency_alphabetic_code": "AMD",
    "currency_country_name": "ARMENIA",
    "currency_minor_unit": "2",
    "currency_name": "Armenian Dram",
    "currency_numeric_code": "051",
    "is_independent": "Yes"
  },
  {
    "name": "Aruba",
    "name_fr": "Aruba",
    "ISO3166-1-Alpha-2": "AW",
    "ISO3166-1-Alpha-3": "ABW",
    "ISO3166-1-numeric": "533",
    "ITU": "ABW",
    "MARC": "aw",
    "WMO": "NU",
    "DS": " ",
    "Dial": "297",
    "FIFA": "ARU",
    "FIPS": "AA",
    "GAUL": "14",
    "IOC": "ARU",
    "currency_alphabetic_code": "AWG",
    "currency_country_name": "ARUBA",
    "currency_minor_unit": "2",
    "currency_name": "Aruban Florin",
    "currency_numeric_code": "533",
    "is_independent": "Part of NL"
  },
  {
    "name": "Australia",
    "name_fr": "Australie",
    "ISO3166-1-Alpha-2": "AU",
    "ISO3166-1-Alpha-3": "AUS",
    "ISO3166-1-numeric": "036",
    "ITU": "AUS",
    "MARC": "at",
    "WMO": "AU",
    "DS": "AUS",
    "Dial": "61",
    "FIFA": "AUS",
    "FIPS": "AS",
    "GAUL": "17",
    "IOC": "AUS",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "AUSTRALIA",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Yes"
  },
  {
    "name": "Austria",
    "name_fr": "Autriche",
    "ISO3166-1-Alpha-2": "AT",
    "ISO3166-1-Alpha-3": "AUT",
    "ISO3166-1-numeric": "040",
    "ITU": "AUT",
    "MARC": "au",
    "WMO": "OS",
    "DS": "A",
    "Dial": "43",
    "FIFA": "AUT",
    "FIPS": "AU",
    "GAUL": "18",
    "IOC": "AUT",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "AUSTRIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Azerbaijan",
    "name_fr": "Azerbaïdjan",
    "ISO3166-1-Alpha-2": "AZ",
    "ISO3166-1-Alpha-3": "AZE",
    "ISO3166-1-numeric": "031",
    "ITU": "AZE",
    "MARC": "aj",
    "WMO": "AJ",
    "DS": "AZ",
    "Dial": "994",
    "FIFA": "AZE",
    "FIPS": "AJ",
    "GAUL": "19",
    "IOC": "AZE",
    "currency_alphabetic_code": "AZN",
    "currency_country_name": "AZERBAIJAN",
    "currency_minor_unit": "2",
    "currency_name": "Azerbaijanian Manat",
    "currency_numeric_code": "944",
    "is_independent": "Yes"
  },
  {
    "name": "Bahamas",
    "name_fr": "Bahamas",
    "ISO3166-1-Alpha-2": "BS",
    "ISO3166-1-Alpha-3": "BHS",
    "ISO3166-1-numeric": "044",
    "ITU": "BAH",
    "MARC": "bf",
    "WMO": "BA",
    "DS": "BS",
    "Dial": "1-242",
    "FIFA": "BAH",
    "FIPS": "BF",
    "GAUL": "20",
    "IOC": "BAH",
    "currency_alphabetic_code": "BSD",
    "currency_country_name": "BAHAMAS",
    "currency_minor_unit": "2",
    "currency_name": "Bahamian Dollar",
    "currency_numeric_code": "044",
    "is_independent": "Yes"
  },
  {
    "name": "Bahrain",
    "name_fr": "Bahreïn",
    "ISO3166-1-Alpha-2": "BH",
    "ISO3166-1-Alpha-3": "BHR",
    "ISO3166-1-numeric": "048",
    "ITU": "BHR",
    "MARC": "ba",
    "WMO": "BN",
    "DS": "BRN",
    "Dial": "973",
    "FIFA": "BHR",
    "FIPS": "BA",
    "GAUL": "21",
    "IOC": "BRN",
    "currency_alphabetic_code": "BHD",
    "currency_country_name": "BAHRAIN",
    "currency_minor_unit": "3",
    "currency_name": "Bahraini Dinar",
    "currency_numeric_code": "048",
    "is_independent": "Yes"
  },
  {
    "name": "Bangladesh",
    "name_fr": "Bangladesh",
    "ISO3166-1-Alpha-2": "BD",
    "ISO3166-1-Alpha-3": "BGD",
    "ISO3166-1-numeric": "050",
    "ITU": "BGD",
    "MARC": "bg",
    "WMO": "BW",
    "DS": "BD",
    "Dial": "880",
    "FIFA": "BAN",
    "FIPS": "BG",
    "GAUL": "23",
    "IOC": "BAN",
    "currency_alphabetic_code": "BDT",
    "currency_country_name": "BANGLADESH",
    "currency_minor_unit": "2",
    "currency_name": "Taka",
    "currency_numeric_code": "050",
    "is_independent": "Yes"
  },
  {
    "name": "Barbados",
    "name_fr": "Barbade",
    "ISO3166-1-Alpha-2": "BB",
    "ISO3166-1-Alpha-3": "BRB",
    "ISO3166-1-numeric": "052",
    "ITU": "BRB",
    "MARC": "bb",
    "WMO": "BR",
    "DS": "BDS",
    "Dial": "1-246",
    "FIFA": "BRB",
    "FIPS": "BB",
    "GAUL": "24",
    "IOC": "BAR",
    "currency_alphabetic_code": "BBD",
    "currency_country_name": "BARBADOS",
    "currency_minor_unit": "2",
    "currency_name": "Barbados Dollar",
    "currency_numeric_code": "052",
    "is_independent": "Yes"
  },
  {
    "name": "Belarus",
    "name_fr": "Bélarus",
    "ISO3166-1-Alpha-2": "BY",
    "ISO3166-1-Alpha-3": "BLR",
    "ISO3166-1-numeric": "112",
    "ITU": "BLR",
    "MARC": "bw",
    "WMO": "BY",
    "DS": "BY",
    "Dial": "375",
    "FIFA": "BLR",
    "FIPS": "BO",
    "GAUL": "26",
    "IOC": "BLR",
    "currency_alphabetic_code": "BYR",
    "currency_country_name": "BELARUS",
    "currency_minor_unit": "0",
    "currency_name": "Belarussian Ruble",
    "currency_numeric_code": "974",
    "is_independent": "Yes"
  },
  {
    "name": "Belgium",
    "name_fr": "Belgique",
    "ISO3166-1-Alpha-2": "BE",
    "ISO3166-1-Alpha-3": "BEL",
    "ISO3166-1-numeric": "056",
    "ITU": "BEL",
    "MARC": "be",
    "WMO": "BX",
    "DS": "B",
    "Dial": "32",
    "FIFA": "BEL",
    "FIPS": "BE",
    "GAUL": "27",
    "IOC": "BEL",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "BELGIUM",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Belize",
    "name_fr": "Belize",
    "ISO3166-1-Alpha-2": "BZ",
    "ISO3166-1-Alpha-3": "BLZ",
    "ISO3166-1-numeric": "084",
    "ITU": "BLZ",
    "MARC": "bh",
    "WMO": "BH",
    "DS": "BZ",
    "Dial": "501",
    "FIFA": "BLZ",
    "FIPS": "BH",
    "GAUL": "28",
    "IOC": "BIZ",
    "currency_alphabetic_code": "BZD",
    "currency_country_name": "BELIZE",
    "currency_minor_unit": "2",
    "currency_name": "Belize Dollar",
    "currency_numeric_code": "084",
    "is_independent": "Yes"
  },
  {
    "name": "Benin",
    "name_fr": "Bénin",
    "ISO3166-1-Alpha-2": "BJ",
    "ISO3166-1-Alpha-3": "BEN",
    "ISO3166-1-numeric": "204",
    "ITU": "BEN",
    "MARC": "dm",
    "WMO": "BJ",
    "DS": "DY",
    "Dial": "229",
    "FIFA": "BEN",
    "FIPS": "BN",
    "GAUL": "29",
    "IOC": "BEN",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "BENIN",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Bermuda",
    "name_fr": "Bermudes",
    "ISO3166-1-Alpha-2": "BM",
    "ISO3166-1-Alpha-3": "BMU",
    "ISO3166-1-numeric": "060",
    "ITU": "BER",
    "MARC": "bm",
    "WMO": "BE",
    "DS": " ",
    "Dial": "1-441",
    "FIFA": "BER",
    "FIPS": "BD",
    "GAUL": "30",
    "IOC": "BER",
    "currency_alphabetic_code": "BMD",
    "currency_country_name": "BERMUDA",
    "currency_minor_unit": "2",
    "currency_name": "Bermudian Dollar",
    "currency_numeric_code": "060",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Bhutan",
    "name_fr": "Bhoutan",
    "ISO3166-1-Alpha-2": "BT",
    "ISO3166-1-Alpha-3": "BTN",
    "ISO3166-1-numeric": "064",
    "ITU": "BTN",
    "MARC": "bt",
    "WMO": " ",
    "DS": " ",
    "Dial": "975",
    "FIFA": "BHU",
    "FIPS": "BT",
    "GAUL": "31",
    "IOC": "BHU",
    "currency_alphabetic_code": "INR",
    "currency_country_name": "BHUTAN",
    "currency_minor_unit": "2",
    "currency_name": "Indian Rupee",
    "currency_numeric_code": "356",
    "is_independent": "Yes"
  },
  {
    "name": "Bolivia, Plurinational State of",
    "name_fr": "Bolivie, l'État Plurinational de",
    "ISO3166-1-Alpha-2": "BO",
    "ISO3166-1-Alpha-3": "BOL",
    "ISO3166-1-numeric": "068",
    "ITU": "BOL",
    "MARC": "bo",
    "WMO": "BO",
    "DS": "BOL",
    "Dial": "591",
    "FIFA": "BOL",
    "FIPS": "BL",
    "GAUL": "33",
    "IOC": "BOL",
    "currency_alphabetic_code": "BOB",
    "currency_country_name": "BOLIVIA, PLURINATIONAL STATE OF",
    "currency_minor_unit": "2",
    "currency_name": "Boliviano",
    "currency_numeric_code": "068",
    "is_independent": "Yes"
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "name_fr": "Bonaire, Saint-Eustache et Saba",
    "ISO3166-1-Alpha-2": "BQ",
    "ISO3166-1-Alpha-3": "BES",
    "ISO3166-1-numeric": "535",
    "ITU": "ATN",
    "MARC": "ca",
    "WMO": "NU",
    "DS": "NA",
    "Dial": "599",
    "FIFA": "ANT",
    "FIPS": "NL",
    "GAUL": "176",
    "IOC": "AHO",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "BONAIRE, SINT EUSTATIUS AND SABA",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Part of NL"
  },
  {
    "name": "Bosnia and Herzegovina",
    "name_fr": "Bosnie-Herzégovine",
    "ISO3166-1-Alpha-2": "BA",
    "ISO3166-1-Alpha-3": "BIH",
    "ISO3166-1-numeric": "070",
    "ITU": "BIH",
    "MARC": "bn",
    "WMO": "BG",
    "DS": "BIH",
    "Dial": "387",
    "FIFA": "BIH",
    "FIPS": "BK",
    "GAUL": "34",
    "IOC": "BIH",
    "currency_alphabetic_code": "BAM",
    "currency_country_name": "BOSNIA AND HERZEGOVINA",
    "currency_minor_unit": "2",
    "currency_name": "Convertible Mark",
    "currency_numeric_code": "977",
    "is_independent": "Yes"
  },
  {
    "name": "Botswana",
    "name_fr": "Botswana",
    "ISO3166-1-Alpha-2": "BW",
    "ISO3166-1-Alpha-3": "BWA",
    "ISO3166-1-numeric": "072",
    "ITU": "BOT",
    "MARC": "bs",
    "WMO": "BC",
    "DS": "BW",
    "Dial": "267",
    "FIFA": "BOT",
    "FIPS": "BC",
    "GAUL": "35",
    "IOC": "BOT",
    "currency_alphabetic_code": "BWP",
    "currency_country_name": "BOTSWANA",
    "currency_minor_unit": "2",
    "currency_name": "Pula",
    "currency_numeric_code": "072",
    "is_independent": "Yes"
  },
  {
    "name": "Bouvet Island",
    "name_fr": "Bouvet, Île",
    "ISO3166-1-Alpha-2": "BV",
    "ISO3166-1-Alpha-3": "BVT",
    "ISO3166-1-numeric": "074",
    "ITU": " ",
    "MARC": "bv",
    "WMO": "BV",
    "DS": " ",
    "Dial": "47",
    "FIFA": "",
    "FIPS": "BV",
    "GAUL": "36",
    "IOC": " ",
    "currency_alphabetic_code": "NOK",
    "currency_country_name": "BOUVET ISLAND",
    "currency_minor_unit": "2",
    "currency_name": "Norwegian Krone",
    "currency_numeric_code": "578",
    "is_independent": "Territory of NO"
  },
  {
    "name": "Brazil",
    "name_fr": "Brésil",
    "ISO3166-1-Alpha-2": "BR",
    "ISO3166-1-Alpha-3": "BRA",
    "ISO3166-1-numeric": "076",
    "ITU": "B",
    "MARC": "bl",
    "WMO": "BZ",
    "DS": "BR",
    "Dial": "55",
    "FIFA": "BRA",
    "FIPS": "BR",
    "GAUL": "37",
    "IOC": "BRA",
    "currency_alphabetic_code": "BRL",
    "currency_country_name": "BRAZIL",
    "currency_minor_unit": "2",
    "currency_name": "Brazilian Real",
    "currency_numeric_code": "986",
    "is_independent": "Yes"
  },
  {
    "name": "British Indian Ocean Territory",
    "name_fr": "Océan Indien, Territoire Britannique de l'",
    "ISO3166-1-Alpha-2": "IO",
    "ISO3166-1-Alpha-3": "IOT",
    "ISO3166-1-numeric": "086",
    "ITU": "BIO",
    "MARC": "bi",
    "WMO": " ",
    "DS": " ",
    "Dial": "246",
    "FIFA": "",
    "FIPS": "IO",
    "GAUL": "38",
    "IOC": " ",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "BRITISH INDIAN OCEAN TERRITORY",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Brunei Darussalam",
    "name_fr": "Brunei Darussalam",
    "ISO3166-1-Alpha-2": "BN",
    "ISO3166-1-Alpha-3": "BRN",
    "ISO3166-1-numeric": "096",
    "ITU": "BRU",
    "MARC": "bx",
    "WMO": "BD",
    "DS": "BRU",
    "Dial": "673",
    "FIFA": "BRU",
    "FIPS": "BX",
    "GAUL": "40",
    "IOC": "BRU",
    "currency_alphabetic_code": "BND",
    "currency_country_name": "BRUNEI DARUSSALAM",
    "currency_minor_unit": "2",
    "currency_name": "Brunei Dollar",
    "currency_numeric_code": "096",
    "is_independent": "Yes"
  },
  {
    "name": "Bulgaria",
    "name_fr": "Bulgarie",
    "ISO3166-1-Alpha-2": "BG",
    "ISO3166-1-Alpha-3": "BGR",
    "ISO3166-1-numeric": "100",
    "ITU": "BUL",
    "MARC": "bu",
    "WMO": "BU",
    "DS": "BG",
    "Dial": "359",
    "FIFA": "BUL",
    "FIPS": "BU",
    "GAUL": "41",
    "IOC": "BUL",
    "currency_alphabetic_code": "BGN",
    "currency_country_name": "BULGARIA",
    "currency_minor_unit": "2",
    "currency_name": "Bulgarian Lev",
    "currency_numeric_code": "975",
    "is_independent": "Yes"
  },
  {
    "name": "Burkina Faso",
    "name_fr": "Burkina Faso",
    "ISO3166-1-Alpha-2": "BF",
    "ISO3166-1-Alpha-3": "BFA",
    "ISO3166-1-numeric": "854",
    "ITU": "BFA",
    "MARC": "uv",
    "WMO": "HV",
    "DS": "BF",
    "Dial": "226",
    "FIFA": "BFA",
    "FIPS": "UV",
    "GAUL": "42",
    "IOC": "BUR",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "BURKINA FASO",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Burundi",
    "name_fr": "Burundi",
    "ISO3166-1-Alpha-2": "BI",
    "ISO3166-1-Alpha-3": "BDI",
    "ISO3166-1-numeric": "108",
    "ITU": "BDI",
    "MARC": "bd",
    "WMO": "BI",
    "DS": "RU",
    "Dial": "257",
    "FIFA": "BDI",
    "FIPS": "BY",
    "GAUL": "43",
    "IOC": "BDI",
    "currency_alphabetic_code": "BIF",
    "currency_country_name": "BURUNDI",
    "currency_minor_unit": "0",
    "currency_name": "Burundi Franc",
    "currency_numeric_code": "108",
    "is_independent": "Yes"
  },
  {
    "name": "Cambodia",
    "name_fr": "Cambodge",
    "ISO3166-1-Alpha-2": "KH",
    "ISO3166-1-Alpha-3": "KHM",
    "ISO3166-1-numeric": "116",
    "ITU": "CBG",
    "MARC": "cb",
    "WMO": "KP",
    "DS": "K",
    "Dial": "855",
    "FIFA": "CAM",
    "FIPS": "CB",
    "GAUL": "44",
    "IOC": "CAM",
    "currency_alphabetic_code": "KHR",
    "currency_country_name": "CAMBODIA",
    "currency_minor_unit": "2",
    "currency_name": "Riel",
    "currency_numeric_code": "116",
    "is_independent": "Yes"
  },
  {
    "name": "Cameroon",
    "name_fr": "Cameroun",
    "ISO3166-1-Alpha-2": "CM",
    "ISO3166-1-Alpha-3": "CMR",
    "ISO3166-1-numeric": "120",
    "ITU": "CME",
    "MARC": "cm",
    "WMO": "CM",
    "DS": "CAM",
    "Dial": "237",
    "FIFA": "CMR",
    "FIPS": "CM",
    "GAUL": "45",
    "IOC": "CMR",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "CAMEROON",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Canada",
    "name_fr": "Canada",
    "ISO3166-1-Alpha-2": "CA",
    "ISO3166-1-Alpha-3": "CAN",
    "ISO3166-1-numeric": "124",
    "ITU": "CAN",
    "MARC": "xxc",
    "WMO": "CN",
    "DS": "CDN",
    "Dial": "1",
    "FIFA": "CAN",
    "FIPS": "CA",
    "GAUL": "46",
    "IOC": "CAN",
    "currency_alphabetic_code": "CAD",
    "currency_country_name": "CANADA",
    "currency_minor_unit": "2",
    "currency_name": "Canadian Dollar",
    "currency_numeric_code": "124",
    "is_independent": "Yes"
  },
  {
    "name": "Cape Verde",
    "name_fr": "Cap-Vert",
    "ISO3166-1-Alpha-2": "CV",
    "ISO3166-1-Alpha-3": "CPV",
    "ISO3166-1-numeric": "132",
    "ITU": "CPV",
    "MARC": "cv",
    "WMO": "CV",
    "DS": " ",
    "Dial": "238",
    "FIFA": "CPV",
    "FIPS": "CV",
    "GAUL": "47",
    "IOC": "CPV",
    "currency_alphabetic_code": "CVE",
    "currency_country_name": "CABO VERDE",
    "currency_minor_unit": "2",
    "currency_name": "Cabo Verde Escudo",
    "currency_numeric_code": "132",
    "is_independent": "Yes"
  },
  {
    "name": "Cayman Islands",
    "name_fr": "Caïmans, Îles",
    "ISO3166-1-Alpha-2": "KY",
    "ISO3166-1-Alpha-3": "CYM",
    "ISO3166-1-numeric": "136",
    "ITU": "CYM",
    "MARC": "cj",
    "WMO": "GC",
    "DS": " ",
    "Dial": "1-345",
    "FIFA": "CAY",
    "FIPS": "CJ",
    "GAUL": "48",
    "IOC": "CAY",
    "currency_alphabetic_code": "KYD",
    "currency_country_name": "CAYMAN ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Cayman Islands Dollar",
    "currency_numeric_code": "136",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Central African Republic",
    "name_fr": "Centrafricaine, République",
    "ISO3166-1-Alpha-2": "CF",
    "ISO3166-1-Alpha-3": "CAF",
    "ISO3166-1-numeric": "140",
    "ITU": "CAF",
    "MARC": "cx",
    "WMO": "CE",
    "DS": "RCA",
    "Dial": "236",
    "FIFA": "CTA",
    "FIPS": "CT",
    "GAUL": "49",
    "IOC": "CAF",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "CENTRAL AFRICAN REPUBLIC",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Chad",
    "name_fr": "Tchad",
    "ISO3166-1-Alpha-2": "TD",
    "ISO3166-1-Alpha-3": "TCD",
    "ISO3166-1-numeric": "148",
    "ITU": "TCD",
    "MARC": "cd",
    "WMO": "CD",
    "DS": "TCH",
    "Dial": "235",
    "FIFA": "CHA",
    "FIPS": "CD",
    "GAUL": "50",
    "IOC": "CHA",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "CHAD",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Chile",
    "name_fr": "Chili",
    "ISO3166-1-Alpha-2": "CL",
    "ISO3166-1-Alpha-3": "CHL",
    "ISO3166-1-numeric": "152",
    "ITU": "CHL",
    "MARC": "cl",
    "WMO": "CH",
    "DS": "RCH",
    "Dial": "56",
    "FIFA": "CHI",
    "FIPS": "CI",
    "GAUL": "51",
    "IOC": "CHI",
    "currency_alphabetic_code": "CLP",
    "currency_country_name": "CHILE",
    "currency_minor_unit": "0",
    "currency_name": "Chilean Peso",
    "currency_numeric_code": "152",
    "is_independent": "Yes"
  },
  {
    "name": "China",
    "name_fr": "Chine",
    "ISO3166-1-Alpha-2": "CN",
    "ISO3166-1-Alpha-3": "CHN",
    "ISO3166-1-numeric": "156",
    "ITU": "CHN",
    "MARC": "cc",
    "WMO": "CI",
    "DS": " ",
    "Dial": "86",
    "FIFA": "CHN",
    "FIPS": "CH",
    "GAUL": "53",
    "IOC": "CHN",
    "currency_alphabetic_code": "CNY",
    "currency_country_name": "CHINA",
    "currency_minor_unit": "2",
    "currency_name": "Yuan Renminbi",
    "currency_numeric_code": "156",
    "is_independent": "Yes"
  },
  {
    "name": "Christmas Island",
    "name_fr": "Christmas, Île",
    "ISO3166-1-Alpha-2": "CX",
    "ISO3166-1-Alpha-3": "CXR",
    "ISO3166-1-numeric": "162",
    "ITU": "CHR",
    "MARC": "xa",
    "WMO": "KI",
    "DS": "AUS",
    "Dial": "61",
    "FIFA": "CXR",
    "FIPS": "KT",
    "GAUL": "54",
    "IOC": " ",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "CHRISTMAS ISLAND",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Territory of AU"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "name_fr": "Cocos (Keeling), Îles",
    "ISO3166-1-Alpha-2": "CC",
    "ISO3166-1-Alpha-3": "CCK",
    "ISO3166-1-numeric": "166",
    "ITU": "ICO",
    "MARC": "xb",
    "WMO": "KK",
    "DS": "AUS",
    "Dial": "61",
    "FIFA": "CCK",
    "FIPS": "CK",
    "GAUL": "56",
    "IOC": " ",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "COCOS (KEELING) ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Territory of AU"
  },
  {
    "name": "Colombia",
    "name_fr": "Colombie",
    "ISO3166-1-Alpha-2": "CO",
    "ISO3166-1-Alpha-3": "COL",
    "ISO3166-1-numeric": "170",
    "ITU": "CLM",
    "MARC": "ck",
    "WMO": "CO",
    "DS": "CO",
    "Dial": "57",
    "FIFA": "COL",
    "FIPS": "CO",
    "GAUL": "57",
    "IOC": "COL",
    "currency_alphabetic_code": "COP",
    "currency_country_name": "COLOMBIA",
    "currency_minor_unit": "2",
    "currency_name": "Colombian Peso",
    "currency_numeric_code": "170",
    "is_independent": "Yes"
  },
  {
    "name": "Comoros",
    "name_fr": "Comores",
    "ISO3166-1-Alpha-2": "KM",
    "ISO3166-1-Alpha-3": "COM",
    "ISO3166-1-numeric": "174",
    "ITU": "COM",
    "MARC": "cq",
    "WMO": "IC",
    "DS": " ",
    "Dial": "269",
    "FIFA": "COM",
    "FIPS": "CN",
    "GAUL": "58",
    "IOC": "COM",
    "currency_alphabetic_code": "KMF",
    "currency_country_name": "COMOROS",
    "currency_minor_unit": "0",
    "currency_name": "Comoro Franc",
    "currency_numeric_code": "174",
    "is_independent": "Yes"
  },
  {
    "name": "Congo",
    "name_fr": "Congo",
    "ISO3166-1-Alpha-2": "CG",
    "ISO3166-1-Alpha-3": "COG",
    "ISO3166-1-numeric": "178",
    "ITU": "COG",
    "MARC": "cf",
    "WMO": "CG",
    "DS": "RCB",
    "Dial": "242",
    "FIFA": "CGO",
    "FIPS": "CF",
    "GAUL": "59",
    "IOC": "CGO",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "CONGO",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Congo, the Democratic Republic of the",
    "name_fr": "Congo, la République Démocratique du",
    "ISO3166-1-Alpha-2": "CD",
    "ISO3166-1-Alpha-3": "COD",
    "ISO3166-1-numeric": "180",
    "ITU": "COD",
    "MARC": "cg",
    "WMO": "ZR",
    "DS": "ZRE",
    "Dial": "243",
    "FIFA": "COD",
    "FIPS": "CG",
    "GAUL": "68",
    "IOC": "COD",
    "currency_alphabetic_code": "",
    "currency_country_name": "",
    "currency_minor_unit": "",
    "currency_name": "",
    "currency_numeric_code": "",
    "is_independent": "Yes"
  },
  {
    "name": "Cook Islands",
    "name_fr": "Cook, Îles",
    "ISO3166-1-Alpha-2": "CK",
    "ISO3166-1-Alpha-3": "COK",
    "ISO3166-1-numeric": "184",
    "ITU": "CKH",
    "MARC": "cw",
    "WMO": "KU",
    "DS": "NZ",
    "Dial": "682",
    "FIFA": "COK",
    "FIPS": "CW",
    "GAUL": "60",
    "IOC": "COK",
    "currency_alphabetic_code": "NZD",
    "currency_country_name": "COOK ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "New Zealand Dollar",
    "currency_numeric_code": "554",
    "is_independent": "Associated with NZ"
  },
  {
    "name": "Costa Rica",
    "name_fr": "Costa Rica",
    "ISO3166-1-Alpha-2": "CR",
    "ISO3166-1-Alpha-3": "CRI",
    "ISO3166-1-numeric": "188",
    "ITU": "CTR",
    "MARC": "cr",
    "WMO": "CS",
    "DS": "CR",
    "Dial": "506",
    "FIFA": "CRC",
    "FIPS": "CS",
    "GAUL": "61",
    "IOC": "CRC",
    "currency_alphabetic_code": "CRC",
    "currency_country_name": "COSTA RICA",
    "currency_minor_unit": "2",
    "currency_name": "Costa Rican Colon",
    "currency_numeric_code": "188",
    "is_independent": "Yes"
  },
  {
    "name": "Croatia",
    "name_fr": "Croatie",
    "ISO3166-1-Alpha-2": "HR",
    "ISO3166-1-Alpha-3": "HRV",
    "ISO3166-1-numeric": "191",
    "ITU": "HRV",
    "MARC": "ci",
    "WMO": "RH",
    "DS": "HR",
    "Dial": "385",
    "FIFA": "CRO",
    "FIPS": "HR",
    "GAUL": "62",
    "IOC": "CRO",
    "currency_alphabetic_code": "HRK",
    "currency_country_name": "CROATIA",
    "currency_minor_unit": "2",
    "currency_name": "Croatian Kuna",
    "currency_numeric_code": "191",
    "is_independent": "Yes"
  },
  {
    "name": "Cuba",
    "name_fr": "Cuba",
    "ISO3166-1-Alpha-2": "CU",
    "ISO3166-1-Alpha-3": "CUB",
    "ISO3166-1-numeric": "192",
    "ITU": "CUB",
    "MARC": "cu",
    "WMO": "CU",
    "DS": "C",
    "Dial": "53",
    "FIFA": "CUB",
    "FIPS": "CU",
    "GAUL": "63",
    "IOC": "CUB",
    "currency_alphabetic_code": "CUP",
    "currency_country_name": "CUBA",
    "currency_minor_unit": "2",
    "currency_name": "Cuban Peso",
    "currency_numeric_code": "192",
    "is_independent": "Yes"
  },
  {
    "name": "Curaçao",
    "name_fr": "Curaçao",
    "ISO3166-1-Alpha-2": "CW",
    "ISO3166-1-Alpha-3": "CUW",
    "ISO3166-1-numeric": "531",
    "ITU": "",
    "MARC": "co",
    "WMO": "",
    "DS": "",
    "Dial": "599",
    "FIFA": "",
    "FIPS": "UC",
    "GAUL": "",
    "IOC": "",
    "currency_alphabetic_code": "ANG",
    "currency_country_name": "CURAÇAO",
    "currency_minor_unit": "2",
    "currency_name": "Netherlands Antillean Guilder",
    "currency_numeric_code": "532",
    "is_independent": "Part of NL"
  },
  {
    "name": "Cyprus",
    "name_fr": "Chypre",
    "ISO3166-1-Alpha-2": "CY",
    "ISO3166-1-Alpha-3": "CYP",
    "ISO3166-1-numeric": "196",
    "ITU": "CYP",
    "MARC": "cy",
    "WMO": "CY",
    "DS": "CY",
    "Dial": "357",
    "FIFA": "CYP",
    "FIPS": "CY",
    "GAUL": "64",
    "IOC": "CYP",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "CYPRUS",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Czech Republic",
    "name_fr": "Tchèque, République",
    "ISO3166-1-Alpha-2": "CZ",
    "ISO3166-1-Alpha-3": "CZE",
    "ISO3166-1-numeric": "203",
    "ITU": "CZE",
    "MARC": "xr",
    "WMO": "CZ",
    "DS": "CZ",
    "Dial": "420",
    "FIFA": "CZE",
    "FIPS": "EZ",
    "GAUL": "65",
    "IOC": "CZE",
    "currency_alphabetic_code": "CZK",
    "currency_country_name": "CZECH REPUBLIC",
    "currency_minor_unit": "2",
    "currency_name": "Czech Koruna",
    "currency_numeric_code": "203",
    "is_independent": "Yes"
  },
  {
    "name": "Côte d'Ivoire",
    "name_fr": "Côte d'Ivoire",
    "ISO3166-1-Alpha-2": "CI",
    "ISO3166-1-Alpha-3": "CIV",
    "ISO3166-1-numeric": "384",
    "ITU": "CTI",
    "MARC": "iv",
    "WMO": "IV",
    "DS": "CI",
    "Dial": "225",
    "FIFA": "CIV",
    "FIPS": "IV",
    "GAUL": "66",
    "IOC": "CIV",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "CÔTE D'IVOIRE",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Denmark",
    "name_fr": "Danemark",
    "ISO3166-1-Alpha-2": "DK",
    "ISO3166-1-Alpha-3": "DNK",
    "ISO3166-1-numeric": "208",
    "ITU": "DNK",
    "MARC": "dk",
    "WMO": "DN",
    "DS": "DK",
    "Dial": "45",
    "FIFA": "DEN",
    "FIPS": "DA",
    "GAUL": "69",
    "IOC": "DEN",
    "currency_alphabetic_code": "DKK",
    "currency_country_name": "DENMARK",
    "currency_minor_unit": "2",
    "currency_name": "Danish Krone",
    "currency_numeric_code": "208",
    "is_independent": "Yes"
  },
  {
    "name": "Djibouti",
    "name_fr": "Djibouti",
    "ISO3166-1-Alpha-2": "DJ",
    "ISO3166-1-Alpha-3": "DJI",
    "ISO3166-1-numeric": "262",
    "ITU": "DJI",
    "MARC": "ft",
    "WMO": "DJ",
    "DS": " ",
    "Dial": "253",
    "FIFA": "DJI",
    "FIPS": "DJ",
    "GAUL": "70",
    "IOC": "DJI",
    "currency_alphabetic_code": "DJF",
    "currency_country_name": "DJIBOUTI",
    "currency_minor_unit": "0",
    "currency_name": "Djibouti Franc",
    "currency_numeric_code": "262",
    "is_independent": "Yes"
  },
  {
    "name": "Dominica",
    "name_fr": "Dominique",
    "ISO3166-1-Alpha-2": "DM",
    "ISO3166-1-Alpha-3": "DMA",
    "ISO3166-1-numeric": "212",
    "ITU": "DMA",
    "MARC": "dq",
    "WMO": "DO",
    "DS": "WD",
    "Dial": "1-767",
    "FIFA": "DMA",
    "FIPS": "DO",
    "GAUL": "71",
    "IOC": "DMA",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "DOMINICA",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Dominican Republic",
    "name_fr": "Dominicaine, République",
    "ISO3166-1-Alpha-2": "DO",
    "ISO3166-1-Alpha-3": "DOM",
    "ISO3166-1-numeric": "214",
    "ITU": "DOM",
    "MARC": "dr",
    "WMO": "DR",
    "DS": "DOM",
    "Dial": "1-809,1-829,1-849",
    "FIFA": "DOM",
    "FIPS": "DR",
    "GAUL": "72",
    "IOC": "DOM",
    "currency_alphabetic_code": "DOP",
    "currency_country_name": "DOMINICAN REPUBLIC",
    "currency_minor_unit": "2",
    "currency_name": "Dominican Peso",
    "currency_numeric_code": "214",
    "is_independent": "Yes"
  },
  {
    "name": "Ecuador",
    "name_fr": "Équateur",
    "ISO3166-1-Alpha-2": "EC",
    "ISO3166-1-Alpha-3": "ECU",
    "ISO3166-1-numeric": "218",
    "ITU": "EQA",
    "MARC": "ec",
    "WMO": "EQ",
    "DS": "EC",
    "Dial": "593",
    "FIFA": "ECU",
    "FIPS": "EC",
    "GAUL": "73",
    "IOC": "ECU",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "ECUADOR",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Egypt",
    "name_fr": "Égypte",
    "ISO3166-1-Alpha-2": "EG",
    "ISO3166-1-Alpha-3": "EGY",
    "ISO3166-1-numeric": "818",
    "ITU": "EGY",
    "MARC": "ua",
    "WMO": "EG",
    "DS": "ET",
    "Dial": "20",
    "FIFA": "EGY",
    "FIPS": "EG",
    "GAUL": "40765",
    "IOC": "EGY",
    "currency_alphabetic_code": "EGP",
    "currency_country_name": "EGYPT",
    "currency_minor_unit": "2",
    "currency_name": "Egyptian Pound",
    "currency_numeric_code": "818",
    "is_independent": "Yes"
  },
  {
    "name": "El Salvador",
    "name_fr": "El Salvador",
    "ISO3166-1-Alpha-2": "SV",
    "ISO3166-1-Alpha-3": "SLV",
    "ISO3166-1-numeric": "222",
    "ITU": "SLV",
    "MARC": "es",
    "WMO": "ES",
    "DS": "ES",
    "Dial": "503",
    "FIFA": "SLV",
    "FIPS": "ES",
    "GAUL": "75",
    "IOC": "ESA",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "EL SALVADOR",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Equatorial Guinea",
    "name_fr": "Guinée Équatoriale",
    "ISO3166-1-Alpha-2": "GQ",
    "ISO3166-1-Alpha-3": "GNQ",
    "ISO3166-1-numeric": "226",
    "ITU": "GNE",
    "MARC": "eg",
    "WMO": "GQ",
    "DS": " ",
    "Dial": "240",
    "FIFA": "EQG",
    "FIPS": "EK",
    "GAUL": "76",
    "IOC": "GEQ",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "EQUATORIAL GUINEA",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Eritrea",
    "name_fr": "Érythrée",
    "ISO3166-1-Alpha-2": "ER",
    "ISO3166-1-Alpha-3": "ERI",
    "ISO3166-1-numeric": "232",
    "ITU": "ERI",
    "MARC": "ea",
    "WMO": " ",
    "DS": " ",
    "Dial": "291",
    "FIFA": "ERI",
    "FIPS": "ER",
    "GAUL": "77",
    "IOC": "ERI",
    "currency_alphabetic_code": "ERN",
    "currency_country_name": "ERITREA",
    "currency_minor_unit": "2",
    "currency_name": "Nakfa",
    "currency_numeric_code": "232",
    "is_independent": "Yes"
  },
  {
    "name": "Estonia",
    "name_fr": "Estonie",
    "ISO3166-1-Alpha-2": "EE",
    "ISO3166-1-Alpha-3": "EST",
    "ISO3166-1-numeric": "233",
    "ITU": "EST",
    "MARC": "er",
    "WMO": "EO",
    "DS": "EST",
    "Dial": "372",
    "FIFA": "EST",
    "FIPS": "EN",
    "GAUL": "78",
    "IOC": "EST",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "ESTONIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Ethiopia",
    "name_fr": "Éthiopie",
    "ISO3166-1-Alpha-2": "ET",
    "ISO3166-1-Alpha-3": "ETH",
    "ISO3166-1-numeric": "231",
    "ITU": "ETH",
    "MARC": "et",
    "WMO": "ET",
    "DS": "ETH",
    "Dial": "251",
    "FIFA": "ETH",
    "FIPS": "ET",
    "GAUL": "79",
    "IOC": "ETH",
    "currency_alphabetic_code": "ETB",
    "currency_country_name": "ETHIOPIA",
    "currency_minor_unit": "2",
    "currency_name": "Ethiopian Birr",
    "currency_numeric_code": "230",
    "is_independent": "Yes"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "name_fr": "Falkland, Îles (Malvinas)",
    "ISO3166-1-Alpha-2": "FK",
    "ISO3166-1-Alpha-3": "FLK",
    "ISO3166-1-numeric": "238",
    "ITU": "FLK",
    "MARC": "fk",
    "WMO": "FK",
    "DS": " ",
    "Dial": "500",
    "FIFA": "FLK",
    "FIPS": "FK",
    "GAUL": "81",
    "IOC": "FLK",
    "currency_alphabetic_code": "FKP",
    "currency_country_name": "FALKLAND ISLANDS (MALVINAS)",
    "currency_minor_unit": "2",
    "currency_name": "Falkland Islands Pound",
    "currency_numeric_code": "238",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Faroe Islands",
    "name_fr": "Féroé, Îles",
    "ISO3166-1-Alpha-2": "FO",
    "ISO3166-1-Alpha-3": "FRO",
    "ISO3166-1-numeric": "234",
    "ITU": "FRO",
    "MARC": "fa",
    "WMO": "FA",
    "DS": "FO",
    "Dial": "298",
    "FIFA": "FRO",
    "FIPS": "FO",
    "GAUL": "82",
    "IOC": "FAR",
    "currency_alphabetic_code": "DKK",
    "currency_country_name": "FAROE ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Danish Krone",
    "currency_numeric_code": "208",
    "is_independent": "Part of DK"
  },
  {
    "name": "Fiji",
    "name_fr": "Fidji",
    "ISO3166-1-Alpha-2": "FJ",
    "ISO3166-1-Alpha-3": "FJI",
    "ISO3166-1-numeric": "242",
    "ITU": "FJI",
    "MARC": "fj",
    "WMO": "FJ",
    "DS": "FJI",
    "Dial": "679",
    "FIFA": "FIJ",
    "FIPS": "FJ",
    "GAUL": "83",
    "IOC": "FIJ",
    "currency_alphabetic_code": "FJD",
    "currency_country_name": "FIJI",
    "currency_minor_unit": "2",
    "currency_name": "Fiji Dollar",
    "currency_numeric_code": "242",
    "is_independent": "Yes"
  },
  {
    "name": "Finland",
    "name_fr": "Finlande",
    "ISO3166-1-Alpha-2": "FI",
    "ISO3166-1-Alpha-3": "FIN",
    "ISO3166-1-numeric": "246",
    "ITU": "FIN",
    "MARC": "fi",
    "WMO": "FI",
    "DS": "FIN",
    "Dial": "358",
    "FIFA": "FIN",
    "FIPS": "FI",
    "GAUL": "84",
    "IOC": "FIN",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "FINLAND",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "France",
    "name_fr": "France",
    "ISO3166-1-Alpha-2": "FR",
    "ISO3166-1-Alpha-3": "FRA",
    "ISO3166-1-numeric": "250",
    "ITU": "F",
    "MARC": "fr",
    "WMO": "FR",
    "DS": "F",
    "Dial": "33",
    "FIFA": "FRA",
    "FIPS": "FR",
    "GAUL": "85",
    "IOC": "FRA",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "FRANCE",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "French Guiana",
    "name_fr": "Guyane Française",
    "ISO3166-1-Alpha-2": "GF",
    "ISO3166-1-Alpha-3": "GUF",
    "ISO3166-1-numeric": "254",
    "ITU": "GUF",
    "MARC": "fg",
    "WMO": "FG",
    "DS": "F",
    "Dial": "594",
    "FIFA": "GUF",
    "FIPS": "FG",
    "GAUL": "86",
    "IOC": "FGU",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "FRENCH GUIANA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "French Polynesia",
    "name_fr": "Polynésie Française",
    "ISO3166-1-Alpha-2": "PF",
    "ISO3166-1-Alpha-3": "PYF",
    "ISO3166-1-numeric": "258",
    "ITU": "OCE",
    "MARC": "fp",
    "WMO": "PF",
    "DS": "F",
    "Dial": "689",
    "FIFA": "TAH",
    "FIPS": "FP",
    "GAUL": "87",
    "IOC": "FPO",
    "currency_alphabetic_code": "XPF",
    "currency_country_name": "FRENCH POLYNESIA",
    "currency_minor_unit": "0",
    "currency_name": "CFP Franc",
    "currency_numeric_code": "953",
    "is_independent": "Territory of FR"
  },
  {
    "name": "French Southern Territories",
    "name_fr": "Terres Australes Françaises",
    "ISO3166-1-Alpha-2": "TF",
    "ISO3166-1-Alpha-3": "ATF",
    "ISO3166-1-numeric": "260",
    "ITU": " ",
    "MARC": "fs",
    "WMO": " ",
    "DS": "F",
    "Dial": "262",
    "FIFA": "",
    "FIPS": "FS",
    "GAUL": "88",
    "IOC": " ",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "FRENCH SOUTHERN TERRITORIES",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Territory of FR"
  },
  {
    "name": "Gabon",
    "name_fr": "Gabon",
    "ISO3166-1-Alpha-2": "GA",
    "ISO3166-1-Alpha-3": "GAB",
    "ISO3166-1-numeric": "266",
    "ITU": "GAB",
    "MARC": "go",
    "WMO": "GO",
    "DS": "G",
    "Dial": "241",
    "FIFA": "GAB",
    "FIPS": "GB",
    "GAUL": "89",
    "IOC": "GAB",
    "currency_alphabetic_code": "XAF",
    "currency_country_name": "GABON",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BEAC",
    "currency_numeric_code": "950",
    "is_independent": "Yes"
  },
  {
    "name": "Gambia",
    "name_fr": "Gambie",
    "ISO3166-1-Alpha-2": "GM",
    "ISO3166-1-Alpha-3": "GMB",
    "ISO3166-1-numeric": "270",
    "ITU": "GMB",
    "MARC": "gm",
    "WMO": "GB",
    "DS": "WAG",
    "Dial": "220",
    "FIFA": "GAM",
    "FIPS": "GA",
    "GAUL": "90",
    "IOC": "GAM",
    "currency_alphabetic_code": "GMD",
    "currency_country_name": "GAMBIA",
    "currency_minor_unit": "2",
    "currency_name": "Dalasi",
    "currency_numeric_code": "270",
    "is_independent": "Yes"
  },
  {
    "name": "Georgia",
    "name_fr": "Géorgie",
    "ISO3166-1-Alpha-2": "GE",
    "ISO3166-1-Alpha-3": "GEO",
    "ISO3166-1-numeric": "268",
    "ITU": "GEO",
    "MARC": "gs",
    "WMO": "GG",
    "DS": "GE",
    "Dial": "995",
    "FIFA": "GEO",
    "FIPS": "GG",
    "GAUL": "92",
    "IOC": "GEO",
    "currency_alphabetic_code": "GEL",
    "currency_country_name": "GEORGIA",
    "currency_minor_unit": "2",
    "currency_name": "Lari",
    "currency_numeric_code": "981",
    "is_independent": "Yes"
  },
  {
    "name": "Germany",
    "name_fr": "Allemagne",
    "ISO3166-1-Alpha-2": "DE",
    "ISO3166-1-Alpha-3": "DEU",
    "ISO3166-1-numeric": "276",
    "ITU": "D",
    "MARC": "gw",
    "WMO": "DL",
    "DS": "D",
    "Dial": "49",
    "FIFA": "GER",
    "FIPS": "GM",
    "GAUL": "93",
    "IOC": "GER",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "GERMANY",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Ghana",
    "name_fr": "Ghana",
    "ISO3166-1-Alpha-2": "GH",
    "ISO3166-1-Alpha-3": "GHA",
    "ISO3166-1-numeric": "288",
    "ITU": "GHA",
    "MARC": "gh",
    "WMO": "GH",
    "DS": "GH",
    "Dial": "233",
    "FIFA": "GHA",
    "FIPS": "GH",
    "GAUL": "94",
    "IOC": "GHA",
    "currency_alphabetic_code": "GHS",
    "currency_country_name": "GHANA",
    "currency_minor_unit": "2",
    "currency_name": "Ghana Cedi",
    "currency_numeric_code": "936",
    "is_independent": "Yes"
  },
  {
    "name": "Gibraltar",
    "name_fr": "Gibraltar",
    "ISO3166-1-Alpha-2": "GI",
    "ISO3166-1-Alpha-3": "GIB",
    "ISO3166-1-numeric": "292",
    "ITU": "GIB",
    "MARC": "gi",
    "WMO": "GI",
    "DS": "GBZ",
    "Dial": "350",
    "FIFA": "GBZ",
    "FIPS": "GI",
    "GAUL": "95",
    "IOC": "GIB",
    "currency_alphabetic_code": "GIP",
    "currency_country_name": "GIBRALTAR",
    "currency_minor_unit": "2",
    "currency_name": "Gibraltar Pound",
    "currency_numeric_code": "292",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Greece",
    "name_fr": "Grèce",
    "ISO3166-1-Alpha-2": "GR",
    "ISO3166-1-Alpha-3": "GRC",
    "ISO3166-1-numeric": "300",
    "ITU": "GRC",
    "MARC": "gr",
    "WMO": "GR",
    "DS": "GR",
    "Dial": "30",
    "FIFA": "GRE",
    "FIPS": "GR",
    "GAUL": "97",
    "IOC": "GRE",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "GREECE",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Greenland",
    "name_fr": "Groenland",
    "ISO3166-1-Alpha-2": "GL",
    "ISO3166-1-Alpha-3": "GRL",
    "ISO3166-1-numeric": "304",
    "ITU": "GRL",
    "MARC": "gl",
    "WMO": "GL",
    "DS": "DK",
    "Dial": "299",
    "FIFA": "GRL",
    "FIPS": "GL",
    "GAUL": "98",
    "IOC": "GRL",
    "currency_alphabetic_code": "DKK",
    "currency_country_name": "GREENLAND",
    "currency_minor_unit": "2",
    "currency_name": "Danish Krone",
    "currency_numeric_code": "208",
    "is_independent": "Part of DK"
  },
  {
    "name": "Grenada",
    "name_fr": "Grenade",
    "ISO3166-1-Alpha-2": "GD",
    "ISO3166-1-Alpha-3": "GRD",
    "ISO3166-1-numeric": "308",
    "ITU": "GRD",
    "MARC": "gd",
    "WMO": "GD",
    "DS": "WG",
    "Dial": "1-473",
    "FIFA": "GRN",
    "FIPS": "GJ",
    "GAUL": "99",
    "IOC": "GRN",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "GRENADA",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Guadeloupe",
    "name_fr": "Guadeloupe",
    "ISO3166-1-Alpha-2": "GP",
    "ISO3166-1-Alpha-3": "GLP",
    "ISO3166-1-numeric": "312",
    "ITU": "GDL",
    "MARC": "gp",
    "WMO": "MF",
    "DS": "F",
    "Dial": "590",
    "FIFA": "GLP",
    "FIPS": "GP",
    "GAUL": "100",
    "IOC": "GUD",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "GUADELOUPE",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Guam",
    "name_fr": "Guam",
    "ISO3166-1-Alpha-2": "GU",
    "ISO3166-1-Alpha-3": "GUM",
    "ISO3166-1-numeric": "316",
    "ITU": "GUM",
    "MARC": "gu",
    "WMO": "GM",
    "DS": "USA",
    "Dial": "1-671",
    "FIFA": "GUM",
    "FIPS": "GQ",
    "GAUL": "101",
    "IOC": "GUM",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "GUAM",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of US"
  },
  {
    "name": "Guatemala",
    "name_fr": "Guatemala",
    "ISO3166-1-Alpha-2": "GT",
    "ISO3166-1-Alpha-3": "GTM",
    "ISO3166-1-numeric": "320",
    "ITU": "GTM",
    "MARC": "gt",
    "WMO": "GU",
    "DS": "GCA",
    "Dial": "502",
    "FIFA": "GUA",
    "FIPS": "GT",
    "GAUL": "103",
    "IOC": "GUA",
    "currency_alphabetic_code": "GTQ",
    "currency_country_name": "GUATEMALA",
    "currency_minor_unit": "2",
    "currency_name": "Quetzal",
    "currency_numeric_code": "320",
    "is_independent": "Yes"
  },
  {
    "name": "Guernsey",
    "name_fr": "Guernesey",
    "ISO3166-1-Alpha-2": "GG",
    "ISO3166-1-Alpha-3": "GGY",
    "ISO3166-1-numeric": "831",
    "ITU": " ",
    "MARC": "uik",
    "WMO": " ",
    "DS": "GBG",
    "Dial": "44",
    "FIFA": "GBG",
    "FIPS": "GK",
    "GAUL": "104",
    "IOC": " ",
    "currency_alphabetic_code": "GBP",
    "currency_country_name": "GUERNSEY",
    "currency_minor_unit": "2",
    "currency_name": "Pound Sterling",
    "currency_numeric_code": "826",
    "is_independent": "Crown dependency of GB"
  },
  {
    "name": "Guinea",
    "name_fr": "Guinée",
    "ISO3166-1-Alpha-2": "GN",
    "ISO3166-1-Alpha-3": "GIN",
    "ISO3166-1-numeric": "324",
    "ITU": "GUI",
    "MARC": "gv",
    "WMO": "GN",
    "DS": "RG",
    "Dial": "224",
    "FIFA": "GUI",
    "FIPS": "GV",
    "GAUL": "106",
    "IOC": "GUI",
    "currency_alphabetic_code": "GNF",
    "currency_country_name": "GUINEA",
    "currency_minor_unit": "0",
    "currency_name": "Guinea Franc",
    "currency_numeric_code": "324",
    "is_independent": "Yes"
  },
  {
    "name": "Guinea-Bissau",
    "name_fr": "Guinée-Bissau",
    "ISO3166-1-Alpha-2": "GW",
    "ISO3166-1-Alpha-3": "GNB",
    "ISO3166-1-numeric": "624",
    "ITU": "GNB",
    "MARC": "pg",
    "WMO": "GW",
    "DS": " ",
    "Dial": "245",
    "FIFA": "GNB",
    "FIPS": "PU",
    "GAUL": "105",
    "IOC": "GBS",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "GUINEA-BISSAU",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Guyana",
    "name_fr": "Guyana",
    "ISO3166-1-Alpha-2": "GY",
    "ISO3166-1-Alpha-3": "GUY",
    "ISO3166-1-numeric": "328",
    "ITU": "GUY",
    "MARC": "gy",
    "WMO": "GY",
    "DS": "GUY",
    "Dial": "592",
    "FIFA": "GUY",
    "FIPS": "GY",
    "GAUL": "107",
    "IOC": "GUY",
    "currency_alphabetic_code": "GYD",
    "currency_country_name": "GUYANA",
    "currency_minor_unit": "2",
    "currency_name": "Guyana Dollar",
    "currency_numeric_code": "328",
    "is_independent": "Yes"
  },
  {
    "name": "Haiti",
    "name_fr": "Haïti",
    "ISO3166-1-Alpha-2": "HT",
    "ISO3166-1-Alpha-3": "HTI",
    "ISO3166-1-numeric": "332",
    "ITU": "HTI",
    "MARC": "ht",
    "WMO": "HA",
    "DS": "RH",
    "Dial": "509",
    "FIFA": "HAI",
    "FIPS": "HA",
    "GAUL": "108",
    "IOC": "HAI",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "HAITI",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Heard Island and McDonald Islands",
    "name_fr": "Heard-Et-Îles Macdonald, Île",
    "ISO3166-1-Alpha-2": "HM",
    "ISO3166-1-Alpha-3": "HMD",
    "ISO3166-1-numeric": "334",
    "ITU": " ",
    "MARC": "hm",
    "WMO": " ",
    "DS": "AUS",
    "Dial": "672",
    "FIFA": "",
    "FIPS": "HM",
    "GAUL": "109",
    "IOC": " ",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "HEARD ISLAND AND McDONALD ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Territory of AU"
  },
  {
    "name": "Holy See (Vatican City State)",
    "name_fr": "Saint-Siège (État de la Cité du Vatican)",
    "ISO3166-1-Alpha-2": "VA",
    "ISO3166-1-Alpha-3": "VAT",
    "ISO3166-1-numeric": "336",
    "ITU": "CVA",
    "MARC": "vc",
    "WMO": " ",
    "DS": "V",
    "Dial": "39-06",
    "FIFA": "VAT",
    "FIPS": "VT",
    "GAUL": "110",
    "IOC": " ",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "HOLY SEE (VATICAN CITY STATE)",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Honduras",
    "name_fr": "Honduras",
    "ISO3166-1-Alpha-2": "HN",
    "ISO3166-1-Alpha-3": "HND",
    "ISO3166-1-numeric": "340",
    "ITU": "HND",
    "MARC": "ho",
    "WMO": "HO",
    "DS": " ",
    "Dial": "504",
    "FIFA": "HON",
    "FIPS": "HO",
    "GAUL": "111",
    "IOC": "HON",
    "currency_alphabetic_code": "HNL",
    "currency_country_name": "HONDURAS",
    "currency_minor_unit": "2",
    "currency_name": "Lempira",
    "currency_numeric_code": "340",
    "is_independent": "Yes"
  },
  {
    "name": "Hong Kong",
    "name_fr": "Hong Kong",
    "ISO3166-1-Alpha-2": "HK",
    "ISO3166-1-Alpha-3": "HKG",
    "ISO3166-1-numeric": "344",
    "ITU": "HKG",
    "MARC": " ",
    "WMO": "HK",
    "DS": "HK",
    "Dial": "852",
    "FIFA": "HKG",
    "FIPS": "HK",
    "GAUL": "33364",
    "IOC": "HKG",
    "currency_alphabetic_code": "HKD",
    "currency_country_name": "HONG KONG",
    "currency_minor_unit": "2",
    "currency_name": "Hong Kong Dollar",
    "currency_numeric_code": "344",
    "is_independent": "Part of CN"
  },
  {
    "name": "Hungary",
    "name_fr": "Hongrie",
    "ISO3166-1-Alpha-2": "HU",
    "ISO3166-1-Alpha-3": "HUN",
    "ISO3166-1-numeric": "348",
    "ITU": "HNG",
    "MARC": "hu",
    "WMO": "HU",
    "DS": "H",
    "Dial": "36",
    "FIFA": "HUN",
    "FIPS": "HU",
    "GAUL": "113",
    "IOC": "HUN",
    "currency_alphabetic_code": "HUF",
    "currency_country_name": "HUNGARY",
    "currency_minor_unit": "2",
    "currency_name": "Forint",
    "currency_numeric_code": "348",
    "is_independent": "Yes"
  },
  {
    "name": "Iceland",
    "name_fr": "Islande",
    "ISO3166-1-Alpha-2": "IS",
    "ISO3166-1-Alpha-3": "ISL",
    "ISO3166-1-numeric": "352",
    "ITU": "ISL",
    "MARC": "ic",
    "WMO": "IL",
    "DS": "IS",
    "Dial": "354",
    "FIFA": "ISL",
    "FIPS": "IC",
    "GAUL": "114",
    "IOC": "ISL",
    "currency_alphabetic_code": "ISK",
    "currency_country_name": "ICELAND",
    "currency_minor_unit": "0",
    "currency_name": "Iceland Krona",
    "currency_numeric_code": "352",
    "is_independent": "Yes"
  },
  {
    "name": "India",
    "name_fr": "Inde",
    "ISO3166-1-Alpha-2": "IN",
    "ISO3166-1-Alpha-3": "IND",
    "ISO3166-1-numeric": "356",
    "ITU": "IND",
    "MARC": "ii",
    "WMO": "IN",
    "DS": "IND",
    "Dial": "91",
    "FIFA": "IND",
    "FIPS": "IN",
    "GAUL": "115",
    "IOC": "IND",
    "currency_alphabetic_code": "INR",
    "currency_country_name": "INDIA",
    "currency_minor_unit": "2",
    "currency_name": "Indian Rupee",
    "currency_numeric_code": "356",
    "is_independent": "Yes"
  },
  {
    "name": "Indonesia",
    "name_fr": "Indonésie",
    "ISO3166-1-Alpha-2": "ID",
    "ISO3166-1-Alpha-3": "IDN",
    "ISO3166-1-numeric": "360",
    "ITU": "INS",
    "MARC": "io",
    "WMO": "ID",
    "DS": "RI",
    "Dial": "62",
    "FIFA": "IDN",
    "FIPS": "ID",
    "GAUL": "116",
    "IOC": "INA",
    "currency_alphabetic_code": "IDR",
    "currency_country_name": "INDONESIA",
    "currency_minor_unit": "2",
    "currency_name": "Rupiah",
    "currency_numeric_code": "360",
    "is_independent": "Yes"
  },
  {
    "name": "Iran, Islamic Republic of",
    "name_fr": "Iran, République Islamique d'",
    "ISO3166-1-Alpha-2": "IR",
    "ISO3166-1-Alpha-3": "IRN",
    "ISO3166-1-numeric": "364",
    "ITU": "IRN",
    "MARC": "ir",
    "WMO": "IR",
    "DS": "IR",
    "Dial": "98",
    "FIFA": "IRN",
    "FIPS": "IR",
    "GAUL": "117",
    "IOC": "IRI",
    "currency_alphabetic_code": "IRR",
    "currency_country_name": "IRAN, ISLAMIC REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "Iranian Rial",
    "currency_numeric_code": "364",
    "is_independent": "Yes"
  },
  {
    "name": "Iraq",
    "name_fr": "Iraq",
    "ISO3166-1-Alpha-2": "IQ",
    "ISO3166-1-Alpha-3": "IRQ",
    "ISO3166-1-numeric": "368",
    "ITU": "IRQ",
    "MARC": "iq",
    "WMO": "IQ",
    "DS": "IRQ",
    "Dial": "964",
    "FIFA": "IRQ",
    "FIPS": "IZ",
    "GAUL": "118",
    "IOC": "IRQ",
    "currency_alphabetic_code": "IQD",
    "currency_country_name": "IRAQ",
    "currency_minor_unit": "3",
    "currency_name": "Iraqi Dinar",
    "currency_numeric_code": "368",
    "is_independent": "Yes"
  },
  {
    "name": "Ireland",
    "name_fr": "Irlande",
    "ISO3166-1-Alpha-2": "IE",
    "ISO3166-1-Alpha-3": "IRL",
    "ISO3166-1-numeric": "372",
    "ITU": "IRL",
    "MARC": "ie",
    "WMO": "IE",
    "DS": "IRL",
    "Dial": "353",
    "FIFA": "IRL",
    "FIPS": "EI",
    "GAUL": "119",
    "IOC": "IRL",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "IRELAND",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Isle of Man",
    "name_fr": "Île de Man",
    "ISO3166-1-Alpha-2": "IM",
    "ISO3166-1-Alpha-3": "IMN",
    "ISO3166-1-numeric": "833",
    "ITU": " ",
    "MARC": "uik",
    "WMO": " ",
    "DS": "GBM",
    "Dial": "44",
    "FIFA": "GBM",
    "FIPS": "IM",
    "GAUL": "120",
    "IOC": " ",
    "currency_alphabetic_code": "GBP",
    "currency_country_name": "ISLE OF MAN",
    "currency_minor_unit": "2",
    "currency_name": "Pound Sterling",
    "currency_numeric_code": "826",
    "is_independent": "Crown dependency of GB"
  },
  {
    "name": "Israel",
    "name_fr": "Israël",
    "ISO3166-1-Alpha-2": "IL",
    "ISO3166-1-Alpha-3": "ISR",
    "ISO3166-1-numeric": "376",
    "ITU": "ISR",
    "MARC": "is",
    "WMO": "IS",
    "DS": "IL",
    "Dial": "972",
    "FIFA": "ISR",
    "FIPS": "IS",
    "GAUL": "121",
    "IOC": "ISR",
    "currency_alphabetic_code": "ILS",
    "currency_country_name": "ISRAEL",
    "currency_minor_unit": "2",
    "currency_name": "New Israeli Sheqel",
    "currency_numeric_code": "376",
    "is_independent": "Yes"
  },
  {
    "name": "Italy",
    "name_fr": "Italie",
    "ISO3166-1-Alpha-2": "IT",
    "ISO3166-1-Alpha-3": "ITA",
    "ISO3166-1-numeric": "380",
    "ITU": "I",
    "MARC": "it",
    "WMO": "IY",
    "DS": "I",
    "Dial": "39",
    "FIFA": "ITA",
    "FIPS": "IT",
    "GAUL": "122",
    "IOC": "ITA",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "ITALY",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Jamaica",
    "name_fr": "Jamaïque",
    "ISO3166-1-Alpha-2": "JM",
    "ISO3166-1-Alpha-3": "JAM",
    "ISO3166-1-numeric": "388",
    "ITU": "JMC",
    "MARC": "jm",
    "WMO": "JM",
    "DS": "JA",
    "Dial": "1-876",
    "FIFA": "JAM",
    "FIPS": "JM",
    "GAUL": "123",
    "IOC": "JAM",
    "currency_alphabetic_code": "JMD",
    "currency_country_name": "JAMAICA",
    "currency_minor_unit": "2",
    "currency_name": "Jamaican Dollar",
    "currency_numeric_code": "388",
    "is_independent": "Yes"
  },
  {
    "name": "Japan",
    "name_fr": "Japon",
    "ISO3166-1-Alpha-2": "JP",
    "ISO3166-1-Alpha-3": "JPN",
    "ISO3166-1-numeric": "392",
    "ITU": "J",
    "MARC": "ja",
    "WMO": "JP",
    "DS": "J",
    "Dial": "81",
    "FIFA": "JPN",
    "FIPS": "JA",
    "GAUL": "126",
    "IOC": "JPN",
    "currency_alphabetic_code": "JPY",
    "currency_country_name": "JAPAN",
    "currency_minor_unit": "0",
    "currency_name": "Yen",
    "currency_numeric_code": "392",
    "is_independent": "Yes"
  },
  {
    "name": "Jersey",
    "name_fr": "Jersey",
    "ISO3166-1-Alpha-2": "JE",
    "ISO3166-1-Alpha-3": "JEY",
    "ISO3166-1-numeric": "832",
    "ITU": " ",
    "MARC": "uik",
    "WMO": " ",
    "DS": "GBJ",
    "Dial": "44",
    "FIFA": "GBJ",
    "FIPS": "JE",
    "GAUL": "128",
    "IOC": " ",
    "currency_alphabetic_code": "GBP",
    "currency_country_name": "JERSEY",
    "currency_minor_unit": "2",
    "currency_name": "Pound Sterling",
    "currency_numeric_code": "826",
    "is_independent": "Crown dependency of GB"
  },
  {
    "name": "Jordan",
    "name_fr": "Jordanie",
    "ISO3166-1-Alpha-2": "JO",
    "ISO3166-1-Alpha-3": "JOR",
    "ISO3166-1-numeric": "400",
    "ITU": "JOR",
    "MARC": "jo",
    "WMO": "JD",
    "DS": "HKJ",
    "Dial": "962",
    "FIFA": "JOR",
    "FIPS": "JO",
    "GAUL": "130",
    "IOC": "JOR",
    "currency_alphabetic_code": "JOD",
    "currency_country_name": "JORDAN",
    "currency_minor_unit": "3",
    "currency_name": "Jordanian Dinar",
    "currency_numeric_code": "400",
    "is_independent": "Yes"
  },
  {
    "name": "Kazakhstan",
    "name_fr": "Kazakhstan",
    "ISO3166-1-Alpha-2": "KZ",
    "ISO3166-1-Alpha-3": "KAZ",
    "ISO3166-1-numeric": "398",
    "ITU": "KAZ",
    "MARC": "kz",
    "WMO": "KZ",
    "DS": "KZ",
    "Dial": "7",
    "FIFA": "KAZ",
    "FIPS": "KZ",
    "GAUL": "132",
    "IOC": "KAZ",
    "currency_alphabetic_code": "KZT",
    "currency_country_name": "KAZAKHSTAN",
    "currency_minor_unit": "2",
    "currency_name": "Tenge",
    "currency_numeric_code": "398",
    "is_independent": "Yes"
  },
  {
    "name": "Kenya",
    "name_fr": "Kenya",
    "ISO3166-1-Alpha-2": "KE",
    "ISO3166-1-Alpha-3": "KEN",
    "ISO3166-1-numeric": "404",
    "ITU": "KEN",
    "MARC": "ke",
    "WMO": "KN",
    "DS": "EAK",
    "Dial": "254",
    "FIFA": "KEN",
    "FIPS": "KE",
    "GAUL": "133",
    "IOC": "KEN",
    "currency_alphabetic_code": "KES",
    "currency_country_name": "KENYA",
    "currency_minor_unit": "2",
    "currency_name": "Kenyan Shilling",
    "currency_numeric_code": "404",
    "is_independent": "Yes"
  },
  {
    "name": "Kosovo",
    "name_fr": "Kosovo",
    "ISO3166-1-Alpha-2": "KE",
    "ISO3166-1-Alpha-3": "XKX",
    "ITU": "XKX",
    "Dial": "383",
    "FIFA": "XKX",
    "FIPS": "XK",
    "IOC": "XKX",
    "currency_country_name": "KOSOVO",
    "currency_minor_unit": "2",
    "is_independent": "Yes"
  },
  {
    "name": "Kiribati",
    "name_fr": "Kiribati",
    "ISO3166-1-Alpha-2": "KI",
    "ISO3166-1-Alpha-3": "KIR",
    "ISO3166-1-numeric": "296",
    "ITU": "KIR",
    "MARC": "gb",
    "WMO": "KB",
    "DS": " ",
    "Dial": "686",
    "FIFA": "KIR",
    "FIPS": "KR",
    "GAUL": "135",
    "IOC": "KIR",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "KIRIBATI",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Yes"
  },
  
  {
    "name": "Korea, Democratic People's Republic of",
    "name_fr": "Corée, République Populaire Démocratique de",
    "ISO3166-1-Alpha-2": "KP",
    "ISO3166-1-Alpha-3": "PRK",
    "ISO3166-1-numeric": "408",
    "ITU": "KRE",
    "MARC": "kn",
    "WMO": "KR",
    "DS": " ",
    "Dial": "850",
    "FIFA": "PRK",
    "FIPS": "KN",
    "GAUL": "67",
    "IOC": "PRK",
    "currency_alphabetic_code": "KPW",
    "currency_country_name": "KOREA, DEMOCRATIC PEOPLE’S REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "North Korean Won",
    "currency_numeric_code": "408",
    "is_independent": "Yes"
  },
  {
    "name": "Korea, Republic of",
    "name_fr": "Corée, République de",
    "ISO3166-1-Alpha-2": "KR",
    "ISO3166-1-Alpha-3": "KOR",
    "ISO3166-1-numeric": "410",
    "ITU": "KOR",
    "MARC": "ko",
    "WMO": "KO",
    "DS": "ROK",
    "Dial": "82",
    "FIFA": "KOR",
    "FIPS": "KS",
    "GAUL": "202",
    "IOC": "KOR",
    "currency_alphabetic_code": "KRW",
    "currency_country_name": "KOREA, REPUBLIC OF",
    "currency_minor_unit": "0",
    "currency_name": "Won",
    "currency_numeric_code": "410",
    "is_independent": "Yes"
  },
  {
    "name": "Kuwait",
    "name_fr": "Koweït",
    "ISO3166-1-Alpha-2": "KW",
    "ISO3166-1-Alpha-3": "KWT",
    "ISO3166-1-numeric": "414",
    "ITU": "KWT",
    "MARC": "ku",
    "WMO": "KW",
    "DS": "KWT",
    "Dial": "965",
    "FIFA": "KUW",
    "FIPS": "KU",
    "GAUL": "137",
    "IOC": "KUW",
    "currency_alphabetic_code": "KWD",
    "currency_country_name": "KUWAIT",
    "currency_minor_unit": "3",
    "currency_name": "Kuwaiti Dinar",
    "currency_numeric_code": "414",
    "is_independent": "Yes"
  },
  {
    "name": "Kyrgyzstan",
    "name_fr": "Kirghizistan",
    "ISO3166-1-Alpha-2": "KG",
    "ISO3166-1-Alpha-3": "KGZ",
    "ISO3166-1-numeric": "417",
    "ITU": "KGZ",
    "MARC": "kg",
    "WMO": "KG",
    "DS": "KS",
    "Dial": "996",
    "FIFA": "KGZ",
    "FIPS": "KG",
    "GAUL": "138",
    "IOC": "KGZ",
    "currency_alphabetic_code": "KGS",
    "currency_country_name": "KYRGYZSTAN",
    "currency_minor_unit": "2",
    "currency_name": "Som",
    "currency_numeric_code": "417",
    "is_independent": "Yes"
  },
  {
    "name": "Lao People's Democratic Republic",
    "name_fr": "Lao, République Démocratique Populaire",
    "ISO3166-1-Alpha-2": "LA",
    "ISO3166-1-Alpha-3": "LAO",
    "ISO3166-1-numeric": "418",
    "ITU": "LAO",
    "MARC": "ls",
    "WMO": "LA",
    "DS": "LAO",
    "Dial": "856",
    "FIFA": "LAO",
    "FIPS": "LA",
    "GAUL": "139",
    "IOC": "LAO",
    "currency_alphabetic_code": "LAK",
    "currency_country_name": "LAO PEOPLE’S DEMOCRATIC REPUBLIC",
    "currency_minor_unit": "2",
    "currency_name": "Kip",
    "currency_numeric_code": "418",
    "is_independent": "Yes"
  },
  {
    "name": "Latvia",
    "name_fr": "Lettonie",
    "ISO3166-1-Alpha-2": "LV",
    "ISO3166-1-Alpha-3": "LVA",
    "ISO3166-1-numeric": "428",
    "ITU": "LVA",
    "MARC": "lv",
    "WMO": "LV",
    "DS": "LV",
    "Dial": "371",
    "FIFA": "LVA",
    "FIPS": "LG",
    "GAUL": "140",
    "IOC": "LAT",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "LATVIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Lebanon",
    "name_fr": "Liban",
    "ISO3166-1-Alpha-2": "LB",
    "ISO3166-1-Alpha-3": "LBN",
    "ISO3166-1-numeric": "422",
    "ITU": "LBN",
    "MARC": "le",
    "WMO": "LB",
    "DS": "RL",
    "Dial": "961",
    "FIFA": "LIB",
    "FIPS": "LE",
    "GAUL": "141",
    "IOC": "LIB",
    "currency_alphabetic_code": "LBP",
    "currency_country_name": "LEBANON",
    "currency_minor_unit": "2",
    "currency_name": "Lebanese Pound",
    "currency_numeric_code": "422",
    "is_independent": "Yes"
  },
  {
    "name": "Lesotho",
    "name_fr": "Lesotho",
    "ISO3166-1-Alpha-2": "LS",
    "ISO3166-1-Alpha-3": "LSO",
    "ISO3166-1-numeric": "426",
    "ITU": "LSO",
    "MARC": "lo",
    "WMO": "LS",
    "DS": "LS",
    "Dial": "266",
    "FIFA": "LES",
    "FIPS": "LT",
    "GAUL": "142",
    "IOC": "LES",
    "currency_alphabetic_code": "ZAR",
    "currency_country_name": "LESOTHO",
    "currency_minor_unit": "2",
    "currency_name": "Rand",
    "currency_numeric_code": "710",
    "is_independent": "Yes"
  },
  {
    "name": "Liberia",
    "name_fr": "Libéria",
    "ISO3166-1-Alpha-2": "LR",
    "ISO3166-1-Alpha-3": "LBR",
    "ISO3166-1-numeric": "430",
    "ITU": "LBR",
    "MARC": "lb",
    "WMO": "LI",
    "DS": "LB",
    "Dial": "231",
    "FIFA": "LBR",
    "FIPS": "LI",
    "GAUL": "144",
    "IOC": "LBR",
    "currency_alphabetic_code": "LRD",
    "currency_country_name": "LIBERIA",
    "currency_minor_unit": "2",
    "currency_name": "Liberian Dollar",
    "currency_numeric_code": "430",
    "is_independent": "Yes"
  },
  {
    "name": "Libya",
    "name_fr": "Libye",
    "ISO3166-1-Alpha-2": "LY",
    "ISO3166-1-Alpha-3": "LBY",
    "ISO3166-1-numeric": "434",
    "ITU": "LBY",
    "MARC": "ly",
    "WMO": "LY",
    "DS": "LAR",
    "Dial": "218",
    "FIFA": "LBY",
    "FIPS": "LY",
    "GAUL": "145",
    "IOC": "LBA",
    "currency_alphabetic_code": "LYD",
    "currency_country_name": "LIBYA",
    "currency_minor_unit": "3",
    "currency_name": "Libyan Dinar",
    "currency_numeric_code": "434",
    "is_independent": "Yes"
  },
  {
    "name": "Liechtenstein",
    "name_fr": "Liechtenstein",
    "ISO3166-1-Alpha-2": "LI",
    "ISO3166-1-Alpha-3": "LIE",
    "ISO3166-1-numeric": "438",
    "ITU": "LIE",
    "MARC": "lh",
    "WMO": " ",
    "DS": "FL",
    "Dial": "423",
    "FIFA": "LIE",
    "FIPS": "LS",
    "GAUL": "146",
    "IOC": "LIE",
    "currency_alphabetic_code": "CHF",
    "currency_country_name": "LIECHTENSTEIN",
    "currency_minor_unit": "2",
    "currency_name": "Swiss Franc",
    "currency_numeric_code": "756",
    "is_independent": "Yes"
  },
  {
    "name": "Lithuania",
    "name_fr": "Lituanie",
    "ISO3166-1-Alpha-2": "LT",
    "ISO3166-1-Alpha-3": "LTU",
    "ISO3166-1-numeric": "440",
    "ITU": "LTU",
    "MARC": "li",
    "WMO": "LT",
    "DS": "LT",
    "Dial": "370",
    "FIFA": "LTU",
    "FIPS": "LH",
    "GAUL": "147",
    "IOC": "LTU",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "LITHUANIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Luxembourg",
    "name_fr": "Luxembourg",
    "ISO3166-1-Alpha-2": "LU",
    "ISO3166-1-Alpha-3": "LUX",
    "ISO3166-1-numeric": "442",
    "ITU": "LUX",
    "MARC": "lu",
    "WMO": "BX",
    "DS": "L",
    "Dial": "352",
    "FIFA": "LUX",
    "FIPS": "LU",
    "GAUL": "148",
    "IOC": "LUX",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "LUXEMBOURG",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Macao",
    "name_fr": "Macao",
    "ISO3166-1-Alpha-2": "MO",
    "ISO3166-1-Alpha-3": "MAC",
    "ISO3166-1-numeric": "446",
    "ITU": "MAC",
    "MARC": " ",
    "WMO": "MU",
    "DS": " ",
    "Dial": "853",
    "FIFA": "MAC",
    "FIPS": "MC",
    "GAUL": "149",
    "IOC": "MAC",
    "currency_alphabetic_code": "MOP",
    "currency_country_name": "MACAO",
    "currency_minor_unit": "2",
    "currency_name": "Pataca",
    "currency_numeric_code": "446",
    "is_independent": "Part of CN"
  },
  {
    "name": "Macedonia, the Former Yugoslav Republic of",
    "name_fr": "Macédoine, l'Ex-république Yougoslave de",
    "ISO3166-1-Alpha-2": "MK",
    "ISO3166-1-Alpha-3": "MKD",
    "ISO3166-1-numeric": "807",
    "ITU": "MKD",
    "MARC": "xn",
    "WMO": "MJ",
    "DS": "MK",
    "Dial": "389",
    "FIFA": "MKD",
    "FIPS": "MK",
    "GAUL": "241",
    "IOC": "MKD",
    "currency_alphabetic_code": "MKD",
    "currency_country_name": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "Denar",
    "currency_numeric_code": "807",
    "is_independent": "Yes"
  },
  {
    "name": "Madagascar",
    "name_fr": "Madagascar",
    "ISO3166-1-Alpha-2": "MG",
    "ISO3166-1-Alpha-3": "MDG",
    "ISO3166-1-numeric": "450",
    "ITU": "MDG",
    "MARC": "mg",
    "WMO": "MG",
    "DS": "RM",
    "Dial": "261",
    "FIFA": "MAD",
    "FIPS": "MA",
    "GAUL": "150",
    "IOC": "MAD",
    "currency_alphabetic_code": "MGA",
    "currency_country_name": "MADAGASCAR",
    "currency_minor_unit": "2",
    "currency_name": "Malagasy Ariary",
    "currency_numeric_code": "969",
    "is_independent": "Yes"
  },
  {
    "name": "Malawi",
    "name_fr": "Malawi",
    "ISO3166-1-Alpha-2": "MW",
    "ISO3166-1-Alpha-3": "MWI",
    "ISO3166-1-numeric": "454",
    "ITU": "MWI",
    "MARC": "mw",
    "WMO": "MW",
    "DS": "MW",
    "Dial": "265",
    "FIFA": "MWI",
    "FIPS": "MI",
    "GAUL": "152",
    "IOC": "MAW",
    "currency_alphabetic_code": "MWK",
    "currency_country_name": "MALAWI",
    "currency_minor_unit": "2",
    "currency_name": "Kwacha",
    "currency_numeric_code": "454",
    "is_independent": "Yes"
  },
  {
    "name": "Malaysia",
    "name_fr": "Malaisie",
    "ISO3166-1-Alpha-2": "MY",
    "ISO3166-1-Alpha-3": "MYS",
    "ISO3166-1-numeric": "458",
    "ITU": "MLA",
    "MARC": "my",
    "WMO": "MS",
    "DS": "MAL",
    "Dial": "60",
    "FIFA": "MAS",
    "FIPS": "MY",
    "GAUL": "153",
    "IOC": "MAS",
    "currency_alphabetic_code": "MYR",
    "currency_country_name": "MALAYSIA",
    "currency_minor_unit": "2",
    "currency_name": "Malaysian Ringgit",
    "currency_numeric_code": "458",
    "is_independent": "Yes"
  },
  {
    "name": "Maldives",
    "name_fr": "Maldives",
    "ISO3166-1-Alpha-2": "MV",
    "ISO3166-1-Alpha-3": "MDV",
    "ISO3166-1-numeric": "462",
    "ITU": "MLD",
    "MARC": "xc",
    "WMO": "MV",
    "DS": " ",
    "Dial": "960",
    "FIFA": "MDV",
    "FIPS": "MV",
    "GAUL": "154",
    "IOC": "MDV",
    "currency_alphabetic_code": "MVR",
    "currency_country_name": "MALDIVES",
    "currency_minor_unit": "2",
    "currency_name": "Rufiyaa",
    "currency_numeric_code": "462",
    "is_independent": "Yes"
  },
  {
    "name": "Mali",
    "name_fr": "Mali",
    "ISO3166-1-Alpha-2": "ML",
    "ISO3166-1-Alpha-3": "MLI",
    "ISO3166-1-numeric": "466",
    "ITU": "MLI",
    "MARC": "ml",
    "WMO": "MI",
    "DS": "RMM",
    "Dial": "223",
    "FIFA": "MLI",
    "FIPS": "ML",
    "GAUL": "155",
    "IOC": "MLI",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "MALI",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Malta",
    "name_fr": "Malte",
    "ISO3166-1-Alpha-2": "MT",
    "ISO3166-1-Alpha-3": "MLT",
    "ISO3166-1-numeric": "470",
    "ITU": "MLT",
    "MARC": "mm",
    "WMO": "ML",
    "DS": "M",
    "Dial": "356",
    "FIFA": "MLT",
    "FIPS": "MT",
    "GAUL": "156",
    "IOC": "MLT",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "MALTA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Marshall Islands",
    "name_fr": "Marshall, Îles",
    "ISO3166-1-Alpha-2": "MH",
    "ISO3166-1-Alpha-3": "MHL",
    "ISO3166-1-numeric": "584",
    "ITU": "MHL",
    "MARC": "xe",
    "WMO": "MH",
    "DS": " ",
    "Dial": "692",
    "FIFA": "MHL",
    "FIPS": "RM",
    "GAUL": "157",
    "IOC": "MSH",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "MARSHALL ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Martinique",
    "name_fr": "Martinique",
    "ISO3166-1-Alpha-2": "MQ",
    "ISO3166-1-Alpha-3": "MTQ",
    "ISO3166-1-numeric": "474",
    "ITU": "MRT",
    "MARC": "mq",
    "WMO": "MR",
    "DS": "F",
    "Dial": "596",
    "FIFA": "MTQ",
    "FIPS": "MB",
    "GAUL": "158",
    "IOC": "MRT",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "MARTINIQUE",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Mauritania",
    "name_fr": "Mauritanie",
    "ISO3166-1-Alpha-2": "MR",
    "ISO3166-1-Alpha-3": "MRT",
    "ISO3166-1-numeric": "478",
    "ITU": "MTN",
    "MARC": "mu",
    "WMO": "MT",
    "DS": "RIM",
    "Dial": "222",
    "FIFA": "MTN",
    "FIPS": "MR",
    "GAUL": "159",
    "IOC": "MTN",
    "currency_alphabetic_code": "MRO",
    "currency_country_name": "MAURITANIA",
    "currency_minor_unit": "2",
    "currency_name": "Ouguiya",
    "currency_numeric_code": "478",
    "is_independent": "Yes"
  },
  {
    "name": "Mauritius",
    "name_fr": "Maurice",
    "ISO3166-1-Alpha-2": "MU",
    "ISO3166-1-Alpha-3": "MUS",
    "ISO3166-1-numeric": "480",
    "ITU": "MAU",
    "MARC": "mf",
    "WMO": "MA",
    "DS": "MS",
    "Dial": "230",
    "FIFA": "MRI",
    "FIPS": "MP",
    "GAUL": "160",
    "IOC": "MRI",
    "currency_alphabetic_code": "MUR",
    "currency_country_name": "MAURITIUS",
    "currency_minor_unit": "2",
    "currency_name": "Mauritius Rupee",
    "currency_numeric_code": "480",
    "is_independent": "Yes"
  },
  {
    "name": "Mayotte",
    "name_fr": "Mayotte",
    "ISO3166-1-Alpha-2": "YT",
    "ISO3166-1-Alpha-3": "MYT",
    "ISO3166-1-numeric": "175",
    "ITU": "MYT",
    "MARC": "ot",
    "WMO": " ",
    "DS": " ",
    "Dial": "262",
    "FIFA": "MYT",
    "FIPS": "MF",
    "GAUL": "161",
    "IOC": "MAY",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "MAYOTTE",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Mexico",
    "name_fr": "Mexique",
    "ISO3166-1-Alpha-2": "MX",
    "ISO3166-1-Alpha-3": "MEX",
    "ISO3166-1-numeric": "484",
    "ITU": "MEX",
    "MARC": "mx",
    "WMO": "MX",
    "DS": "MEX",
    "Dial": "52",
    "FIFA": "MEX",
    "FIPS": "MX",
    "GAUL": "162",
    "IOC": "MEX",
    "currency_alphabetic_code": "MXN",
    "currency_country_name": "MEXICO",
    "currency_minor_unit": "2",
    "currency_name": "Mexican Peso",
    "currency_numeric_code": "484",
    "is_independent": "Yes"
  },
  {
    "name": "Micronesia, Federated States of",
    "name_fr": "Micronésie, États Fédérés de",
    "ISO3166-1-Alpha-2": "FM",
    "ISO3166-1-Alpha-3": "FSM",
    "ISO3166-1-numeric": "583",
    "ITU": "FSM",
    "MARC": "fm",
    "WMO": " ",
    "DS": " ",
    "Dial": "691",
    "FIFA": "FSM",
    "FIPS": "FM",
    "GAUL": "163",
    "IOC": "FSM",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "MICRONESIA, FEDERATED STATES OF",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Moldova, Republic of",
    "name_fr": "Moldova, République de",
    "ISO3166-1-Alpha-2": "MD",
    "ISO3166-1-Alpha-3": "MDA",
    "ISO3166-1-numeric": "498",
    "ITU": "MDA",
    "MARC": "mv",
    "WMO": "RM",
    "DS": "MD",
    "Dial": "373",
    "FIFA": "MDA",
    "FIPS": "MD",
    "GAUL": "165",
    "IOC": "MDA",
    "currency_alphabetic_code": "MDL",
    "currency_country_name": "MOLDOVA, REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "Moldovan Leu",
    "currency_numeric_code": "498",
    "is_independent": "Yes"
  },
  {
    "name": "Monaco",
    "name_fr": "Monaco",
    "ISO3166-1-Alpha-2": "MC",
    "ISO3166-1-Alpha-3": "MCO",
    "ISO3166-1-numeric": "492",
    "ITU": "MCO",
    "MARC": "mc",
    "WMO": " ",
    "DS": "MC",
    "Dial": "377",
    "FIFA": "MON",
    "FIPS": "MN",
    "GAUL": "166",
    "IOC": "MON",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "MONACO",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Mongolia",
    "name_fr": "Mongolie",
    "ISO3166-1-Alpha-2": "MN",
    "ISO3166-1-Alpha-3": "MNG",
    "ISO3166-1-numeric": "496",
    "ITU": "MNG",
    "MARC": "mp",
    "WMO": "MO",
    "DS": "MGL",
    "Dial": "976",
    "FIFA": "MNG",
    "FIPS": "MG",
    "GAUL": "167",
    "IOC": "MGL",
    "currency_alphabetic_code": "MNT",
    "currency_country_name": "MONGOLIA",
    "currency_minor_unit": "2",
    "currency_name": "Tugrik",
    "currency_numeric_code": "496",
    "is_independent": "Yes"
  },
  {
    "name": "Montenegro",
    "name_fr": "Monténégro",
    "ISO3166-1-Alpha-2": "ME",
    "ISO3166-1-Alpha-3": "MNE",
    "ISO3166-1-numeric": "499",
    "ITU": "MNE",
    "MARC": "mo",
    "WMO": " ",
    "DS": "MNE",
    "Dial": "382",
    "FIFA": "MNE",
    "FIPS": "MJ",
    "GAUL": "2647",
    "IOC": "MGO",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "MONTENEGRO",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Montserrat",
    "name_fr": "Montserrat",
    "ISO3166-1-Alpha-2": "MS",
    "ISO3166-1-Alpha-3": "MSR",
    "ISO3166-1-numeric": "500",
    "ITU": "MSR",
    "MARC": "mj",
    "WMO": " ",
    "DS": " ",
    "Dial": "1-664",
    "FIFA": "MSR",
    "FIPS": "MH",
    "GAUL": "168",
    "IOC": "MNT",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "MONTSERRAT",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Morocco",
    "name_fr": "Maroc",
    "ISO3166-1-Alpha-2": "MA",
    "ISO3166-1-Alpha-3": "MAR",
    "ISO3166-1-numeric": "504",
    "ITU": "MRC",
    "MARC": "mr",
    "WMO": "MC",
    "DS": "MA",
    "Dial": "212",
    "FIFA": "MAR",
    "FIPS": "MO",
    "GAUL": "169",
    "IOC": "MAR",
    "currency_alphabetic_code": "MAD",
    "currency_country_name": "MOROCCO",
    "currency_minor_unit": "2",
    "currency_name": "Moroccan Dirham",
    "currency_numeric_code": "504",
    "is_independent": "Yes"
  },
  {
    "name": "Mozambique",
    "name_fr": "Mozambique",
    "ISO3166-1-Alpha-2": "MZ",
    "ISO3166-1-Alpha-3": "MOZ",
    "ISO3166-1-numeric": "508",
    "ITU": "MOZ",
    "MARC": "mz",
    "WMO": "MZ",
    "DS": "MOC",
    "Dial": "258",
    "FIFA": "MOZ",
    "FIPS": "MZ",
    "GAUL": "170",
    "IOC": "MOZ",
    "currency_alphabetic_code": "MZN",
    "currency_country_name": "MOZAMBIQUE",
    "currency_minor_unit": "2",
    "currency_name": "Mozambique Metical",
    "currency_numeric_code": "943",
    "is_independent": "Yes"
  },
  {
    "name": "Myanmar",
    "name_fr": "Myanmar",
    "ISO3166-1-Alpha-2": "MM",
    "ISO3166-1-Alpha-3": "MMR",
    "ISO3166-1-numeric": "104",
    "ITU": "MYA",
    "MARC": "br",
    "WMO": "BM",
    "DS": "BUR",
    "Dial": "95",
    "FIFA": "MYA",
    "FIPS": "BM",
    "GAUL": "171",
    "IOC": "MYA",
    "currency_alphabetic_code": "MMK",
    "currency_country_name": "MYANMAR",
    "currency_minor_unit": "2",
    "currency_name": "Kyat",
    "currency_numeric_code": "104",
    "is_independent": "Yes"
  },
  {
    "name": "Namibia",
    "name_fr": "Namibie",
    "ISO3166-1-Alpha-2": "NA",
    "ISO3166-1-Alpha-3": "NAM",
    "ISO3166-1-numeric": "516",
    "ITU": "NMB",
    "MARC": "sx",
    "WMO": "NM",
    "DS": "NAM",
    "Dial": "264",
    "FIFA": "NAM",
    "FIPS": "WA",
    "GAUL": "172",
    "IOC": "NAM",
    "currency_alphabetic_code": "ZAR",
    "currency_country_name": "NAMIBIA",
    "currency_minor_unit": "2",
    "currency_name": "Rand",
    "currency_numeric_code": "710",
    "is_independent": "Yes"
  },
  {
    "name": "Nauru",
    "name_fr": "Nauru",
    "ISO3166-1-Alpha-2": "NR",
    "ISO3166-1-Alpha-3": "NRU",
    "ISO3166-1-numeric": "520",
    "ITU": "NRU",
    "MARC": "nu",
    "WMO": "NW",
    "DS": "NAU",
    "Dial": "674",
    "FIFA": "NRU",
    "FIPS": "NR",
    "GAUL": "173",
    "IOC": "NRU",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "NAURU",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Yes"
  },
  {
    "name": "Nepal",
    "name_fr": "Népal",
    "ISO3166-1-Alpha-2": "NP",
    "ISO3166-1-Alpha-3": "NPL",
    "ISO3166-1-numeric": "524",
    "ITU": "NPL",
    "MARC": "np",
    "WMO": "NP",
    "DS": "NEP",
    "Dial": "977",
    "FIFA": "NEP",
    "FIPS": "NP",
    "GAUL": "175",
    "IOC": "NEP",
    "currency_alphabetic_code": "NPR",
    "currency_country_name": "NEPAL",
    "currency_minor_unit": "2",
    "currency_name": "Nepalese Rupee",
    "currency_numeric_code": "524",
    "is_independent": "Yes"
  },
  {
    "name": "Netherlands",
    "name_fr": "Pays-Bas",
    "ISO3166-1-Alpha-2": "NL",
    "ISO3166-1-Alpha-3": "NLD",
    "ISO3166-1-numeric": "528",
    "ITU": "HOL",
    "MARC": "ne",
    "WMO": "NL",
    "DS": "NL",
    "Dial": "31",
    "FIFA": "NED",
    "FIPS": "NL",
    "GAUL": "177",
    "IOC": "NED",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "NETHERLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "New Caledonia",
    "name_fr": "Nouvelle-Calédonie",
    "ISO3166-1-Alpha-2": "NC",
    "ISO3166-1-Alpha-3": "NCL",
    "ISO3166-1-numeric": "540",
    "ITU": "NCL",
    "MARC": "nl",
    "WMO": "NC",
    "DS": "F",
    "Dial": "687",
    "FIFA": "NCL",
    "FIPS": "NC",
    "GAUL": "178",
    "IOC": "NCD",
    "currency_alphabetic_code": "XPF",
    "currency_country_name": "NEW CALEDONIA",
    "currency_minor_unit": "0",
    "currency_name": "CFP Franc",
    "currency_numeric_code": "953",
    "is_independent": "Territory of FR"
  },
  {
    "name": "New Zealand",
    "name_fr": "Nouvelle-Zélande",
    "ISO3166-1-Alpha-2": "NZ",
    "ISO3166-1-Alpha-3": "NZL",
    "ISO3166-1-numeric": "554",
    "ITU": "NZL",
    "MARC": "nz",
    "WMO": "NZ",
    "DS": "NZ",
    "Dial": "64",
    "FIFA": "NZL",
    "FIPS": "NZ",
    "GAUL": "179",
    "IOC": "NZL",
    "currency_alphabetic_code": "NZD",
    "currency_country_name": "NEW ZEALAND",
    "currency_minor_unit": "2",
    "currency_name": "New Zealand Dollar",
    "currency_numeric_code": "554",
    "is_independent": "Yes"
  },
  {
    "name": "Nicaragua",
    "name_fr": "Nicaragua",
    "ISO3166-1-Alpha-2": "NI",
    "ISO3166-1-Alpha-3": "NIC",
    "ISO3166-1-numeric": "558",
    "ITU": "NCG",
    "MARC": "nq",
    "WMO": "NK",
    "DS": "NIC",
    "Dial": "505",
    "FIFA": "NCA",
    "FIPS": "NU",
    "GAUL": "180",
    "IOC": "NCA",
    "currency_alphabetic_code": "NIO",
    "currency_country_name": "NICARAGUA",
    "currency_minor_unit": "2",
    "currency_name": "Cordoba Oro",
    "currency_numeric_code": "558",
    "is_independent": "Yes"
  },
  {
    "name": "Niger",
    "name_fr": "Niger",
    "ISO3166-1-Alpha-2": "NE",
    "ISO3166-1-Alpha-3": "NER",
    "ISO3166-1-numeric": "562",
    "ITU": "NGR",
    "MARC": "ng",
    "WMO": "NR",
    "DS": "RN",
    "Dial": "227",
    "FIFA": "NIG",
    "FIPS": "NG",
    "GAUL": "181",
    "IOC": "NIG",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "NIGER",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Nigeria",
    "name_fr": "Nigéria",
    "ISO3166-1-Alpha-2": "NG",
    "ISO3166-1-Alpha-3": "NGA",
    "ISO3166-1-numeric": "566",
    "ITU": "NIG",
    "MARC": "nr",
    "WMO": "NI",
    "DS": "NGR",
    "Dial": "234",
    "FIFA": "NGA",
    "FIPS": "NI",
    "GAUL": "182",
    "IOC": "NGR",
    "currency_alphabetic_code": "NGN",
    "currency_country_name": "NIGERIA",
    "currency_minor_unit": "2",
    "currency_name": "Naira",
    "currency_numeric_code": "566",
    "is_independent": "Yes"
  },
  {
    "name": "Niue",
    "name_fr": "Niué",
    "ISO3166-1-Alpha-2": "NU",
    "ISO3166-1-Alpha-3": "NIU",
    "ISO3166-1-numeric": "570",
    "ITU": "NIU",
    "MARC": "xh",
    "WMO": " ",
    "DS": "NZ",
    "Dial": "683",
    "FIFA": "NIU",
    "FIPS": "NE",
    "GAUL": "183",
    "IOC": "NIU",
    "currency_alphabetic_code": "NZD",
    "currency_country_name": "NIUE",
    "currency_minor_unit": "2",
    "currency_name": "New Zealand Dollar",
    "currency_numeric_code": "554",
    "is_independent": "Associated with NZ"
  },
  {
    "name": "Norfolk Island",
    "name_fr": "Norfolk, Île",
    "ISO3166-1-Alpha-2": "NF",
    "ISO3166-1-Alpha-3": "NFK",
    "ISO3166-1-numeric": "574",
    "ITU": "NFK",
    "MARC": "nx",
    "WMO": "NF",
    "DS": "AUS",
    "Dial": "672",
    "FIFA": "NFK",
    "FIPS": "NF",
    "GAUL": "184",
    "IOC": "NFI",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "NORFOLK ISLAND",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Territory of AU"
  },
  {
    "name": "Northern Mariana Islands",
    "name_fr": "Mariannes du Nord, Îles",
    "ISO3166-1-Alpha-2": "MP",
    "ISO3166-1-Alpha-3": "MNP",
    "ISO3166-1-numeric": "580",
    "ITU": "MRA",
    "MARC": "nw",
    "WMO": "MY",
    "DS": "USA",
    "Dial": "1-670",
    "FIFA": "NMI",
    "FIPS": "CQ",
    "GAUL": "185",
    "IOC": "NMA",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "NORTHERN MARIANA ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Commonwealth of US"
  },
  {
    "name": "Norway",
    "name_fr": "Norvège",
    "ISO3166-1-Alpha-2": "NO",
    "ISO3166-1-Alpha-3": "NOR",
    "ISO3166-1-numeric": "578",
    "ITU": "NOR",
    "MARC": "no",
    "WMO": "NO",
    "DS": "N",
    "Dial": "47",
    "FIFA": "NOR",
    "FIPS": "NO",
    "GAUL": "186",
    "IOC": "NOR",
    "currency_alphabetic_code": "NOK",
    "currency_country_name": "NORWAY",
    "currency_minor_unit": "2",
    "currency_name": "Norwegian Krone",
    "currency_numeric_code": "578",
    "is_independent": "Yes"
  },
  {
    "name": "Oman",
    "name_fr": "Oman",
    "ISO3166-1-Alpha-2": "OM",
    "ISO3166-1-Alpha-3": "OMN",
    "ISO3166-1-numeric": "512",
    "ITU": "OMA",
    "MARC": "mk",
    "WMO": "OM",
    "DS": " ",
    "Dial": "968",
    "FIFA": "OMA",
    "FIPS": "MU",
    "GAUL": "187",
    "IOC": "OMA",
    "currency_alphabetic_code": "OMR",
    "currency_country_name": "OMAN",
    "currency_minor_unit": "3",
    "currency_name": "Rial Omani",
    "currency_numeric_code": "512",
    "is_independent": "Yes"
  },
  {
    "name": "Pakistan",
    "name_fr": "Pakistan",
    "ISO3166-1-Alpha-2": "PK",
    "ISO3166-1-Alpha-3": "PAK",
    "ISO3166-1-numeric": "586",
    "ITU": "PAK",
    "MARC": "pk",
    "WMO": "PK",
    "DS": "PK",
    "Dial": "92",
    "FIFA": "PAK",
    "FIPS": "PK",
    "GAUL": "188",
    "IOC": "PAK",
    "currency_alphabetic_code": "PKR",
    "currency_country_name": "PAKISTAN",
    "currency_minor_unit": "2",
    "currency_name": "Pakistan Rupee",
    "currency_numeric_code": "586",
    "is_independent": "Yes"
  },
  {
    "name": "Palau",
    "name_fr": "Palaos",
    "ISO3166-1-Alpha-2": "PW",
    "ISO3166-1-Alpha-3": "PLW",
    "ISO3166-1-numeric": "585",
    "ITU": "PLW",
    "MARC": "pw",
    "WMO": " ",
    "DS": " ",
    "Dial": "680",
    "FIFA": "PLW",
    "FIPS": "PS",
    "GAUL": "189",
    "IOC": "PLW",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "PALAU",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Palestine, State of",
    "name_fr": "Palestine, État de",
    "ISO3166-1-Alpha-2": "PS",
    "ISO3166-1-Alpha-3": "PSE",
    "ISO3166-1-numeric": "275",
    "ITU": " ",
    "MARC": "gz,wj",
    "WMO": " ",
    "DS": " ",
    "Dial": "970",
    "FIFA": "PLE",
    "FIPS": "GZ,WE",
    "GAUL": "91,267",
    "IOC": "PLE",
    "currency_alphabetic_code": "",
    "currency_country_name": "PALESTINE, STATE OF",
    "currency_minor_unit": "",
    "currency_name": "No universal currency",
    "currency_numeric_code": "",
    "is_independent": "In contention"
  },
  {
    "name": "Panama",
    "name_fr": "Panama",
    "ISO3166-1-Alpha-2": "PA",
    "ISO3166-1-Alpha-3": "PAN",
    "ISO3166-1-numeric": "591",
    "ITU": "PNR",
    "MARC": "pn",
    "WMO": "PM",
    "DS": "PA",
    "Dial": "507",
    "FIFA": "PAN",
    "FIPS": "PM",
    "GAUL": "191",
    "IOC": "PAN",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "PANAMA",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Papua New Guinea",
    "name_fr": "Papouasie-Nouvelle-Guinée",
    "ISO3166-1-Alpha-2": "PG",
    "ISO3166-1-Alpha-3": "PNG",
    "ISO3166-1-numeric": "598",
    "ITU": "PNG",
    "MARC": "pp",
    "WMO": "NG",
    "DS": "PNG",
    "Dial": "675",
    "FIFA": "PNG",
    "FIPS": "PP",
    "GAUL": "192",
    "IOC": "PNG",
    "currency_alphabetic_code": "PGK",
    "currency_country_name": "PAPUA NEW GUINEA",
    "currency_minor_unit": "2",
    "currency_name": "Kina",
    "currency_numeric_code": "598",
    "is_independent": "Yes"
  },
  {
    "name": "Paraguay",
    "name_fr": "Paraguay",
    "ISO3166-1-Alpha-2": "PY",
    "ISO3166-1-Alpha-3": "PRY",
    "ISO3166-1-numeric": "600",
    "ITU": "PRG",
    "MARC": "py",
    "WMO": "PY",
    "DS": "PY",
    "Dial": "595",
    "FIFA": "PAR",
    "FIPS": "PA",
    "GAUL": "194",
    "IOC": "PAR",
    "currency_alphabetic_code": "PYG",
    "currency_country_name": "PARAGUAY",
    "currency_minor_unit": "0",
    "currency_name": "Guarani",
    "currency_numeric_code": "600",
    "is_independent": "Yes"
  },
  {
    "name": "Peru",
    "name_fr": "Pérou",
    "ISO3166-1-Alpha-2": "PE",
    "ISO3166-1-Alpha-3": "PER",
    "ISO3166-1-numeric": "604",
    "ITU": "PRU",
    "MARC": "pe",
    "WMO": "PR",
    "DS": "PE",
    "Dial": "51",
    "FIFA": "PER",
    "FIPS": "PE",
    "GAUL": "195",
    "IOC": "PER",
    "currency_alphabetic_code": "PEN",
    "currency_country_name": "PERU",
    "currency_minor_unit": "2",
    "currency_name": "Nuevo Sol",
    "currency_numeric_code": "604",
    "is_independent": "Yes"
  },
  {
    "name": "Philippines",
    "name_fr": "Philippines",
    "ISO3166-1-Alpha-2": "PH",
    "ISO3166-1-Alpha-3": "PHL",
    "ISO3166-1-numeric": "608",
    "ITU": "PHL",
    "MARC": "ph",
    "WMO": "PH",
    "DS": "RP",
    "Dial": "63",
    "FIFA": "PHI",
    "FIPS": "RP",
    "GAUL": "196",
    "IOC": "PHI",
    "currency_alphabetic_code": "PHP",
    "currency_country_name": "PHILIPPINES",
    "currency_minor_unit": "2",
    "currency_name": "Philippine Peso",
    "currency_numeric_code": "608",
    "is_independent": "Yes"
  },
  {
    "name": "Pitcairn",
    "name_fr": "Pitcairn",
    "ISO3166-1-Alpha-2": "PN",
    "ISO3166-1-Alpha-3": "PCN",
    "ISO3166-1-numeric": "612",
    "ITU": "PTC",
    "MARC": "pc",
    "WMO": "PT",
    "DS": " ",
    "Dial": "870",
    "FIFA": "PCN",
    "FIPS": "PC",
    "GAUL": "197",
    "IOC": " ",
    "currency_alphabetic_code": "NZD",
    "currency_country_name": "PITCAIRN",
    "currency_minor_unit": "2",
    "currency_name": "New Zealand Dollar",
    "currency_numeric_code": "554",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Poland",
    "name_fr": "Pologne",
    "ISO3166-1-Alpha-2": "PL",
    "ISO3166-1-Alpha-3": "POL",
    "ISO3166-1-numeric": "616",
    "ITU": "POL",
    "MARC": "pl",
    "WMO": "PL",
    "DS": "PL",
    "Dial": "48",
    "FIFA": "POL",
    "FIPS": "PL",
    "GAUL": "198",
    "IOC": "POL",
    "currency_alphabetic_code": "PLN",
    "currency_country_name": "POLAND",
    "currency_minor_unit": "2",
    "currency_name": "Zloty",
    "currency_numeric_code": "985",
    "is_independent": "Yes"
  },
  {
    "name": "Portugal",
    "name_fr": "Portugal",
    "ISO3166-1-Alpha-2": "PT",
    "ISO3166-1-Alpha-3": "PRT",
    "ISO3166-1-numeric": "620",
    "ITU": "POR",
    "MARC": "po",
    "WMO": "PO",
    "DS": "P",
    "Dial": "351",
    "FIFA": "POR",
    "FIPS": "PO",
    "GAUL": "199",
    "IOC": "POR",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "PORTUGAL",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Puerto Rico",
    "name_fr": "Porto Rico",
    "ISO3166-1-Alpha-2": "PR",
    "ISO3166-1-Alpha-3": "PRI",
    "ISO3166-1-numeric": "630",
    "ITU": "PTR",
    "MARC": "pr",
    "WMO": "PU",
    "DS": "USA",
    "Dial": "1",
    "FIFA": "PUR",
    "FIPS": "RQ",
    "GAUL": "200",
    "IOC": "PUR",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "PUERTO RICO",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Commonwealth of US"
  },
  {
    "name": "Qatar",
    "name_fr": "Qatar",
    "ISO3166-1-Alpha-2": "QA",
    "ISO3166-1-Alpha-3": "QAT",
    "ISO3166-1-numeric": "634",
    "ITU": "QAT",
    "MARC": "qa",
    "WMO": "QT",
    "DS": "Q",
    "Dial": "974",
    "FIFA": "QAT",
    "FIPS": "QA",
    "GAUL": "201",
    "IOC": "QAT",
    "currency_alphabetic_code": "QAR",
    "currency_country_name": "QATAR",
    "currency_minor_unit": "2",
    "currency_name": "Qatari Rial",
    "currency_numeric_code": "634",
    "is_independent": "Yes"
  },
  {
    "name": "Romania",
    "name_fr": "Roumanie",
    "ISO3166-1-Alpha-2": "RO",
    "ISO3166-1-Alpha-3": "ROU",
    "ISO3166-1-numeric": "642",
    "ITU": "ROU",
    "MARC": "rm",
    "WMO": "RO",
    "DS": "RO",
    "Dial": "40",
    "FIFA": "ROU",
    "FIPS": "RO",
    "GAUL": "203",
    "IOC": "ROU",
    "currency_alphabetic_code": "RON",
    "currency_country_name": "ROMANIA",
    "currency_minor_unit": "2",
    "currency_name": "New Romanian Leu",
    "currency_numeric_code": "946",
    "is_independent": "Yes"
  },
  {
    "name": "Russian Federation",
    "name_fr": "Russie, Fédération de",
    "ISO3166-1-Alpha-2": "RU",
    "ISO3166-1-Alpha-3": "RUS",
    "ISO3166-1-numeric": "643",
    "ITU": "RUS",
    "MARC": "ru",
    "WMO": "RS",
    "DS": "RUS",
    "Dial": "7",
    "FIFA": "RUS",
    "FIPS": "RS",
    "GAUL": "204",
    "IOC": "RUS",
    "currency_alphabetic_code": "RUB",
    "currency_country_name": "RUSSIAN FEDERATION",
    "currency_minor_unit": "2",
    "currency_name": "Russian Ruble",
    "currency_numeric_code": "643",
    "is_independent": "Yes"
  },
  {
    "name": "Rwanda",
    "name_fr": "Rwanda",
    "ISO3166-1-Alpha-2": "RW",
    "ISO3166-1-Alpha-3": "RWA",
    "ISO3166-1-numeric": "646",
    "ITU": "RRW",
    "MARC": "rw",
    "WMO": "RW",
    "DS": "RWA",
    "Dial": "250",
    "FIFA": "RWA",
    "FIPS": "RW",
    "GAUL": "205",
    "IOC": "RWA",
    "currency_alphabetic_code": "RWF",
    "currency_country_name": "RWANDA",
    "currency_minor_unit": "0",
    "currency_name": "Rwanda Franc",
    "currency_numeric_code": "646",
    "is_independent": "Yes"
  },
  {
    "name": "Réunion",
    "name_fr": "Réunion",
    "ISO3166-1-Alpha-2": "RE",
    "ISO3166-1-Alpha-3": "REU",
    "ISO3166-1-numeric": "638",
    "ITU": "REU",
    "MARC": "re",
    "WMO": "RE",
    "DS": "F",
    "Dial": "262",
    "FIFA": "REU",
    "FIPS": "RE",
    "GAUL": "206",
    "IOC": "REU",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "RÉUNION",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Saint Barthélemy",
    "name_fr": "Saint-Barthélemy",
    "ISO3166-1-Alpha-2": "BL",
    "ISO3166-1-Alpha-3": "BLM",
    "ISO3166-1-numeric": "652",
    "ITU": " ",
    "MARC": "sc",
    "WMO": " ",
    "DS": " ",
    "Dial": "590",
    "FIFA": " ",
    "FIPS": "TB",
    "GAUL": "",
    "IOC": " ",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SAINT BARTHÉLEMY",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "name_fr": "Sainte-Hélène, Ascension et Tristan da Cunha",
    "ISO3166-1-Alpha-2": "SH",
    "ISO3166-1-Alpha-3": "SHN",
    "ISO3166-1-numeric": "654",
    "ITU": "SHN",
    "MARC": "xj",
    "WMO": "HE",
    "DS": " ",
    "Dial": "290 n",
    "FIFA": "SHN",
    "FIPS": "SH",
    "GAUL": "207",
    "IOC": "HEL",
    "currency_alphabetic_code": "SHP",
    "currency_country_name": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    "currency_minor_unit": "2",
    "currency_name": "Saint Helena Pound",
    "currency_numeric_code": "654",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Saint Kitts and Nevis",
    "name_fr": "Saint-Kitts-Et-Nevis",
    "ISO3166-1-Alpha-2": "KN",
    "ISO3166-1-Alpha-3": "KNA",
    "ISO3166-1-numeric": "659",
    "ITU": "KNA",
    "MARC": "xd",
    "WMO": "AT",
    "DS": " ",
    "Dial": "1-869",
    "FIFA": "SKN",
    "FIPS": "SC",
    "GAUL": "208",
    "IOC": "SKN",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "SAINT KITTS AND NEVIS",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Saint Lucia",
    "name_fr": "Sainte-Lucie",
    "ISO3166-1-Alpha-2": "LC",
    "ISO3166-1-Alpha-3": "LCA",
    "ISO3166-1-numeric": "662",
    "ITU": "LCA",
    "MARC": "xk",
    "WMO": "LC",
    "DS": "WL",
    "Dial": "1-758",
    "FIFA": "LCA",
    "FIPS": "ST",
    "GAUL": "209",
    "IOC": "LCA",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "SAINT LUCIA",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Saint Martin (French part)",
    "name_fr": "Saint-Martin(partie Française)",
    "ISO3166-1-Alpha-2": "MF",
    "ISO3166-1-Alpha-3": "MAF",
    "ISO3166-1-numeric": "663",
    "ITU": " ",
    "MARC": "st",
    "WMO": " ",
    "DS": " ",
    "Dial": "590",
    "FIFA": " ",
    "FIPS": "RN",
    "GAUL": "",
    "IOC": " ",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SAINT MARTIN (FRENCH PART)",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "name_fr": "Saint-Pierre-Et-Miquelon",
    "ISO3166-1-Alpha-2": "PM",
    "ISO3166-1-Alpha-3": "SPM",
    "ISO3166-1-numeric": "666",
    "ITU": "SPM",
    "MARC": "xl",
    "WMO": "FP",
    "DS": "F",
    "Dial": "508",
    "FIFA": "SPM",
    "FIPS": "SB",
    "GAUL": "210",
    "IOC": "SPM",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SAINT PIERRE AND MIQUELON",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FR"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "name_fr": "Saint-Vincent-Et-Les Grenadines",
    "ISO3166-1-Alpha-2": "VC",
    "ISO3166-1-Alpha-3": "VCT",
    "ISO3166-1-numeric": "670",
    "ITU": "VCT",
    "MARC": "xm",
    "WMO": "VG",
    "DS": "WV",
    "Dial": "1-784",
    "FIFA": "VIN",
    "FIPS": "VC",
    "GAUL": "211",
    "IOC": "VIN",
    "currency_alphabetic_code": "XCD",
    "currency_country_name": "SAINT VINCENT AND THE GRENADINES",
    "currency_minor_unit": "2",
    "currency_name": "East Caribbean Dollar",
    "currency_numeric_code": "951",
    "is_independent": "Yes"
  },
  {
    "name": "Samoa",
    "name_fr": "Samoa",
    "ISO3166-1-Alpha-2": "WS",
    "ISO3166-1-Alpha-3": "WSM",
    "ISO3166-1-numeric": "882",
    "ITU": "SMO",
    "MARC": "ws",
    "WMO": "ZM",
    "DS": "WS",
    "Dial": "685",
    "FIFA": "SAM",
    "FIPS": "WS",
    "GAUL": "212",
    "IOC": "SAM",
    "currency_alphabetic_code": "WST",
    "currency_country_name": "SAMOA",
    "currency_minor_unit": "2",
    "currency_name": "Tala",
    "currency_numeric_code": "882",
    "is_independent": "Yes"
  },
  {
    "name": "San Marino",
    "name_fr": "Saint-Marin",
    "ISO3166-1-Alpha-2": "SM",
    "ISO3166-1-Alpha-3": "SMR",
    "ISO3166-1-numeric": "674",
    "ITU": "SMR",
    "MARC": "sm",
    "WMO": " ",
    "DS": "RSM",
    "Dial": "378",
    "FIFA": "SMR",
    "FIPS": "SM",
    "GAUL": "213",
    "IOC": "SMR",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SAN MARINO",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Sao Tome and Principe",
    "name_fr": "Sao Tomé-Et-Principe",
    "ISO3166-1-Alpha-2": "ST",
    "ISO3166-1-Alpha-3": "STP",
    "ISO3166-1-numeric": "678",
    "ITU": "STP",
    "MARC": "sf",
    "WMO": "TP",
    "DS": " ",
    "Dial": "239",
    "FIFA": "STP",
    "FIPS": "TP",
    "GAUL": "214",
    "IOC": "STP",
    "currency_alphabetic_code": "STD",
    "currency_country_name": "SAO TOME AND PRINCIPE",
    "currency_minor_unit": "2",
    "currency_name": "Dobra",
    "currency_numeric_code": "678",
    "is_independent": "Yes"
  },
  {
    "name": "Saudi Arabia",
    "name_fr": "Arabie Saoudite",
    "ISO3166-1-Alpha-2": "SA",
    "ISO3166-1-Alpha-3": "SAU",
    "ISO3166-1-numeric": "682",
    "ITU": "ARS",
    "MARC": "su",
    "WMO": "SD",
    "DS": "SA",
    "Dial": "966",
    "FIFA": "KSA",
    "FIPS": "SA",
    "GAUL": "215",
    "IOC": "KSA",
    "currency_alphabetic_code": "SAR",
    "currency_country_name": "SAUDI ARABIA",
    "currency_minor_unit": "2",
    "currency_name": "Saudi Riyal",
    "currency_numeric_code": "682",
    "is_independent": "Yes"
  },
  {
    "name": "Senegal",
    "name_fr": "Sénégal",
    "ISO3166-1-Alpha-2": "SN",
    "ISO3166-1-Alpha-3": "SEN",
    "ISO3166-1-numeric": "686",
    "ITU": "SEN",
    "MARC": "sg",
    "WMO": "SG",
    "DS": "SN",
    "Dial": "221",
    "FIFA": "SEN",
    "FIPS": "SG",
    "GAUL": "217",
    "IOC": "SEN",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "SENEGAL",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Serbia",
    "name_fr": "Serbie",
    "ISO3166-1-Alpha-2": "RS",
    "ISO3166-1-Alpha-3": "SRB",
    "ISO3166-1-numeric": "688",
    "ITU": "SRB",
    "MARC": "rb",
    "WMO": "YG",
    "DS": "SRB",
    "Dial": "381 p",
    "FIFA": "SRB",
    "FIPS": "RI,KV",
    "GAUL": "2648",
    "IOC": "SRB",
    "currency_alphabetic_code": "RSD",
    "currency_country_name": "SERBIA",
    "currency_minor_unit": "2",
    "currency_name": "Serbian Dinar",
    "currency_numeric_code": "941",
    "is_independent": "Yes"
  },
  {
    "name": "Seychelles",
    "name_fr": "Seychelles",
    "ISO3166-1-Alpha-2": "SC",
    "ISO3166-1-Alpha-3": "SYC",
    "ISO3166-1-numeric": "690",
    "ITU": "SEY",
    "MARC": "se",
    "WMO": "SC",
    "DS": "SY",
    "Dial": "248",
    "FIFA": "SEY",
    "FIPS": "SE",
    "GAUL": "220",
    "IOC": "SEY",
    "currency_alphabetic_code": "SCR",
    "currency_country_name": "SEYCHELLES",
    "currency_minor_unit": "2",
    "currency_name": "Seychelles Rupee",
    "currency_numeric_code": "690",
    "is_independent": "Yes"
  },
  {
    "name": "Sierra Leone",
    "name_fr": "Sierra Leone",
    "ISO3166-1-Alpha-2": "SL",
    "ISO3166-1-Alpha-3": "SLE",
    "ISO3166-1-numeric": "694",
    "ITU": "SRL",
    "MARC": "sl",
    "WMO": "SL",
    "DS": "WAL",
    "Dial": "232",
    "FIFA": "SLE",
    "FIPS": "SL",
    "GAUL": "221",
    "IOC": "SLE",
    "currency_alphabetic_code": "SLL",
    "currency_country_name": "SIERRA LEONE",
    "currency_minor_unit": "2",
    "currency_name": "Leone",
    "currency_numeric_code": "694",
    "is_independent": "Yes"
  },
  {
    "name": "Singapore",
    "name_fr": "Singapour",
    "ISO3166-1-Alpha-2": "SG",
    "ISO3166-1-Alpha-3": "SGP",
    "ISO3166-1-numeric": "702",
    "ITU": "SNG",
    "MARC": "si",
    "WMO": "SR",
    "DS": "SGP",
    "Dial": "65",
    "FIFA": "SIN",
    "FIPS": "SN",
    "GAUL": "222",
    "IOC": "SIN",
    "currency_alphabetic_code": "SGD",
    "currency_country_name": "SINGAPORE",
    "currency_minor_unit": "2",
    "currency_name": "Singapore Dollar",
    "currency_numeric_code": "702",
    "is_independent": "Yes"
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "name_fr": "Saint-Martin (Partie Néerlandaise)",
    "ISO3166-1-Alpha-2": "SX",
    "ISO3166-1-Alpha-3": "SXM",
    "ISO3166-1-numeric": "534",
    "ITU": "",
    "MARC": "sn",
    "WMO": "",
    "DS": "",
    "Dial": "1-721",
    "FIFA": "",
    "FIPS": "NN",
    "GAUL": "",
    "IOC": "",
    "currency_alphabetic_code": "ANG",
    "currency_country_name": "SINT MAARTEN (DUTCH PART)",
    "currency_minor_unit": "2",
    "currency_name": "Netherlands Antillean Guilder",
    "currency_numeric_code": "532",
    "is_independent": "Part of NL"
  },
  {
    "name": "Slovakia",
    "name_fr": "Slovaquie",
    "ISO3166-1-Alpha-2": "SK",
    "ISO3166-1-Alpha-3": "SVK",
    "ISO3166-1-numeric": "703",
    "ITU": "SVK",
    "MARC": "xo",
    "WMO": "SQ",
    "DS": "SK",
    "Dial": "421",
    "FIFA": "SVK",
    "FIPS": "LO",
    "GAUL": "223",
    "IOC": "SVK",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SLOVAKIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Slovenia",
    "name_fr": "Slovénie",
    "ISO3166-1-Alpha-2": "SI",
    "ISO3166-1-Alpha-3": "SVN",
    "ISO3166-1-numeric": "705",
    "ITU": "SVN",
    "MARC": "xv",
    "WMO": "LJ",
    "DS": "SLO",
    "Dial": "386",
    "FIFA": "SVN",
    "FIPS": "SI",
    "GAUL": "224",
    "IOC": "SLO",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SLOVENIA",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Solomon Islands",
    "name_fr": "Salomon, Îles",
    "ISO3166-1-Alpha-2": "SB",
    "ISO3166-1-Alpha-3": "SLB",
    "ISO3166-1-numeric": "090",
    "ITU": "SLM",
    "MARC": "bp",
    "WMO": "SO",
    "DS": " ",
    "Dial": "677",
    "FIFA": "SOL",
    "FIPS": "BP",
    "GAUL": "225",
    "IOC": "SOL",
    "currency_alphabetic_code": "SBD",
    "currency_country_name": "SOLOMON ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Solomon Islands Dollar",
    "currency_numeric_code": "090",
    "is_independent": "Yes"
  },
  {
    "name": "Somalia",
    "name_fr": "Somalie",
    "ISO3166-1-Alpha-2": "SO",
    "ISO3166-1-Alpha-3": "SOM",
    "ISO3166-1-numeric": "706",
    "ITU": "SOM",
    "MARC": "so",
    "WMO": "SI",
    "DS": "SO",
    "Dial": "252",
    "FIFA": "SOM",
    "FIPS": "SO",
    "GAUL": "226",
    "IOC": "SOM",
    "currency_alphabetic_code": "SOS",
    "currency_country_name": "SOMALIA",
    "currency_minor_unit": "2",
    "currency_name": "Somali Shilling",
    "currency_numeric_code": "706",
    "is_independent": "Yes"
  },
  {
    "name": "South Africa",
    "name_fr": "Afrique du Sud",
    "ISO3166-1-Alpha-2": "ZA",
    "ISO3166-1-Alpha-3": "ZAF",
    "ISO3166-1-numeric": "710",
    "ITU": "AFS",
    "MARC": "sa",
    "WMO": "ZA",
    "DS": "ZA",
    "Dial": "27",
    "FIFA": "RSA",
    "FIPS": "SF",
    "GAUL": "227",
    "IOC": "RSA",
    "currency_alphabetic_code": "ZAR",
    "currency_country_name": "SOUTH AFRICA",
    "currency_minor_unit": "2",
    "currency_name": "Rand",
    "currency_numeric_code": "710",
    "is_independent": "Yes"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "name_fr": "Géorgie du Sud-Et-Les Îles Sandwich du Sud",
    "ISO3166-1-Alpha-2": "GS",
    "ISO3166-1-Alpha-3": "SGS",
    "ISO3166-1-numeric": "239",
    "ITU": " ",
    "MARC": "xs",
    "WMO": " ",
    "DS": " ",
    "Dial": "500",
    "FIFA": "",
    "FIPS": "SX",
    "GAUL": "228",
    "IOC": " ",
    "currency_alphabetic_code": "",
    "currency_country_name": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    "currency_minor_unit": "",
    "currency_name": "No universal currency",
    "currency_numeric_code": "",
    "is_independent": "Territory of GB"
  },
  {
    "name": "South Sudan",
    "name_fr": "Soudan du Sud",
    "ISO3166-1-Alpha-2": "SS",
    "ISO3166-1-Alpha-3": "SSD",
    "ISO3166-1-numeric": "728",
    "ITU": "SSD",
    "MARC": "sd",
    "WMO": "",
    "DS": "",
    "Dial": "211",
    "FIFA": "",
    "FIPS": "OD",
    "GAUL": "",
    "IOC": "",
    "currency_alphabetic_code": "SSP",
    "currency_country_name": "SOUTH SUDAN",
    "currency_minor_unit": "2",
    "currency_name": "South Sudanese Pound",
    "currency_numeric_code": "728",
    "is_independent": "Yes"
  },
  {
    "name": "Spain",
    "name_fr": "Espagne",
    "ISO3166-1-Alpha-2": "ES",
    "ISO3166-1-Alpha-3": "ESP",
    "ISO3166-1-numeric": "724",
    "ITU": "E",
    "MARC": "sp",
    "WMO": "SP",
    "DS": "E",
    "Dial": "34",
    "FIFA": "ESP",
    "FIPS": "SP",
    "GAUL": "229",
    "IOC": "ESP",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "SPAIN",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Yes"
  },
  {
    "name": "Sri Lanka",
    "name_fr": "Sri Lanka",
    "ISO3166-1-Alpha-2": "LK",
    "ISO3166-1-Alpha-3": "LKA",
    "ISO3166-1-numeric": "144",
    "ITU": "CLN",
    "MARC": "ce",
    "WMO": "SB",
    "DS": "CL",
    "Dial": "94",
    "FIFA": "SRI",
    "FIPS": "CE",
    "GAUL": "231",
    "IOC": "SRI",
    "currency_alphabetic_code": "LKR",
    "currency_country_name": "SRI LANKA",
    "currency_minor_unit": "2",
    "currency_name": "Sri Lanka Rupee",
    "currency_numeric_code": "144",
    "is_independent": "Yes"
  },
  {
    "name": "Sudan",
    "name_fr": "Soudan",
    "ISO3166-1-Alpha-2": "SD",
    "ISO3166-1-Alpha-3": "SDN",
    "ISO3166-1-numeric": "729",
    "ITU": "SDN",
    "MARC": "sj",
    "WMO": "SU",
    "DS": "SUD",
    "Dial": "249",
    "FIFA": "SUD",
    "FIPS": "SU",
    "GAUL": "40764",
    "IOC": "SUD",
    "currency_alphabetic_code": "SDG",
    "currency_country_name": "SUDAN",
    "currency_minor_unit": "2",
    "currency_name": "Sudanese Pound",
    "currency_numeric_code": "938",
    "is_independent": "Yes"
  },
  {
    "name": "Suriname",
    "name_fr": "Suriname",
    "ISO3166-1-Alpha-2": "SR",
    "ISO3166-1-Alpha-3": "SUR",
    "ISO3166-1-numeric": "740",
    "ITU": "SUR",
    "MARC": "sr",
    "WMO": "SM",
    "DS": "SME",
    "Dial": "597",
    "FIFA": "SUR",
    "FIPS": "NS",
    "GAUL": "233",
    "IOC": "SUR",
    "currency_alphabetic_code": "SRD",
    "currency_country_name": "SURINAME",
    "currency_minor_unit": "2",
    "currency_name": "Surinam Dollar",
    "currency_numeric_code": "968",
    "is_independent": "Yes"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "name_fr": "Svalbard et Île Jan Mayen",
    "ISO3166-1-Alpha-2": "SJ",
    "ISO3166-1-Alpha-3": "SJM",
    "ISO3166-1-numeric": "744",
    "ITU": "NOR",
    "MARC": " ",
    "WMO": "SZ",
    "DS": " ",
    "Dial": "47",
    "FIFA": "",
    "FIPS": "SV,JN",
    "GAUL": "234",
    "IOC": " ",
    "currency_alphabetic_code": "NOK",
    "currency_country_name": "SVALBARD AND JAN MAYEN",
    "currency_minor_unit": "2",
    "currency_name": "Norwegian Krone",
    "currency_numeric_code": "578",
    "is_independent": "Territory of NO"
  },
  {
    "name": "Swaziland",
    "name_fr": "Swaziland",
    "ISO3166-1-Alpha-2": "SZ",
    "ISO3166-1-Alpha-3": "SWZ",
    "ISO3166-1-numeric": "748",
    "ITU": "SWZ",
    "MARC": "sq",
    "WMO": "SV",
    "DS": "SD",
    "Dial": "268",
    "FIFA": "SWZ",
    "FIPS": "WZ",
    "GAUL": "235",
    "IOC": "SWZ",
    "currency_alphabetic_code": "SZL",
    "currency_country_name": "SWAZILAND",
    "currency_minor_unit": "2",
    "currency_name": "Lilangeni",
    "currency_numeric_code": "748",
    "is_independent": "Yes"
  },
  {
    "name": "Sweden",
    "name_fr": "Suède",
    "ISO3166-1-Alpha-2": "SE",
    "ISO3166-1-Alpha-3": "SWE",
    "ISO3166-1-numeric": "752",
    "ITU": "S",
    "MARC": "sw",
    "WMO": "SN",
    "DS": "S",
    "Dial": "46",
    "FIFA": "SWE",
    "FIPS": "SW",
    "GAUL": "236",
    "IOC": "SWE",
    "currency_alphabetic_code": "SEK",
    "currency_country_name": "SWEDEN",
    "currency_minor_unit": "2",
    "currency_name": "Swedish Krona",
    "currency_numeric_code": "752",
    "is_independent": "Yes"
  },
  {
    "name": "Switzerland",
    "name_fr": "Suisse",
    "ISO3166-1-Alpha-2": "CH",
    "ISO3166-1-Alpha-3": "CHE",
    "ISO3166-1-numeric": "756",
    "ITU": "SUI",
    "MARC": "sz",
    "WMO": "SW",
    "DS": "CH",
    "Dial": "41",
    "FIFA": "SUI",
    "FIPS": "SZ",
    "GAUL": "237",
    "IOC": "SUI",
    "currency_alphabetic_code": "CHF",
    "currency_country_name": "SWITZERLAND",
    "currency_minor_unit": "2",
    "currency_name": "Swiss Franc",
    "currency_numeric_code": "756",
    "is_independent": "Yes"
  },
  {
    "name": "Syrian Arab Republic",
    "name_fr": "Syrienne, République Arabe",
    "ISO3166-1-Alpha-2": "SY",
    "ISO3166-1-Alpha-3": "SYR",
    "ISO3166-1-numeric": "760",
    "ITU": "SYR",
    "MARC": "sy",
    "WMO": "SY",
    "DS": "SYR",
    "Dial": "963",
    "FIFA": "SYR",
    "FIPS": "SY",
    "GAUL": "238",
    "IOC": "SYR",
    "currency_alphabetic_code": "SYP",
    "currency_country_name": "SYRIAN ARAB REPUBLIC",
    "currency_minor_unit": "2",
    "currency_name": "Syrian Pound",
    "currency_numeric_code": "760",
    "is_independent": "Yes"
  },
  {
    "name": "Taiwan, Province of China",
    "name_fr": "Taïwan, Province de Chine",
    "ISO3166-1-Alpha-2": "TW",
    "ISO3166-1-Alpha-3": "TWN",
    "ISO3166-1-numeric": "158",
    "ITU": " ",
    "MARC": "ch",
    "WMO": " ",
    "DS": "RC",
    "Dial": "886",
    "FIFA": "TPE",
    "FIPS": "TW",
    "GAUL": "925",
    "IOC": "TPE",
    "currency_alphabetic_code": "TWD",
    "currency_country_name": "TAIWAN, PROVINCE OF CHINA",
    "currency_minor_unit": "2",
    "currency_name": "New Taiwan Dollar",
    "currency_numeric_code": "901",
    "is_independent": "Yes"
  },
  {
    "name": "Tajikistan",
    "name_fr": "Tadjikistan",
    "ISO3166-1-Alpha-2": "TJ",
    "ISO3166-1-Alpha-3": "TJK",
    "ISO3166-1-numeric": "762",
    "ITU": "TJK",
    "MARC": "ta",
    "WMO": "TA",
    "DS": "TJ",
    "Dial": "992",
    "FIFA": "TJK",
    "FIPS": "TI",
    "GAUL": "239",
    "IOC": "TJK",
    "currency_alphabetic_code": "TJS",
    "currency_country_name": "TAJIKISTAN",
    "currency_minor_unit": "2",
    "currency_name": "Somoni",
    "currency_numeric_code": "972",
    "is_independent": "Yes"
  },
  {
    "name": "Tanzania, United Republic of",
    "name_fr": "Tanzanie, République-Unie de",
    "ISO3166-1-Alpha-2": "TZ",
    "ISO3166-1-Alpha-3": "TZA",
    "ISO3166-1-numeric": "834",
    "ITU": "TZA",
    "MARC": "tz",
    "WMO": "TN",
    "DS": "EAT",
    "Dial": "255",
    "FIFA": "TAN",
    "FIPS": "TZ",
    "GAUL": "257",
    "IOC": "TAN",
    "currency_alphabetic_code": "TZS",
    "currency_country_name": "TANZANIA, UNITED REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "Tanzanian Shilling",
    "currency_numeric_code": "834",
    "is_independent": "Yes"
  },
  {
    "name": "Thailand",
    "name_fr": "Thaïlande",
    "ISO3166-1-Alpha-2": "TH",
    "ISO3166-1-Alpha-3": "THA",
    "ISO3166-1-numeric": "764",
    "ITU": "THA",
    "MARC": "th",
    "WMO": "TH",
    "DS": "T",
    "Dial": "66",
    "FIFA": "THA",
    "FIPS": "TH",
    "GAUL": "240",
    "IOC": "THA",
    "currency_alphabetic_code": "THB",
    "currency_country_name": "THAILAND",
    "currency_minor_unit": "2",
    "currency_name": "Baht",
    "currency_numeric_code": "764",
    "is_independent": "Yes"
  },
  {
    "name": "Timor-Leste",
    "name_fr": "Timor-Leste",
    "ISO3166-1-Alpha-2": "TL",
    "ISO3166-1-Alpha-3": "TLS",
    "ISO3166-1-numeric": "626",
    "ITU": "TLS",
    "MARC": "em",
    "WMO": "TM",
    "DS": " ",
    "Dial": "670",
    "FIFA": "TLS",
    "FIPS": "TT",
    "GAUL": "242",
    "IOC": "TLS",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "TIMOR-LESTE",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "Togo",
    "name_fr": "Togo",
    "ISO3166-1-Alpha-2": "TG",
    "ISO3166-1-Alpha-3": "TGO",
    "ISO3166-1-numeric": "768",
    "ITU": "TGO",
    "MARC": "tg",
    "WMO": "TG",
    "DS": "TG",
    "Dial": "228",
    "FIFA": "TOG",
    "FIPS": "TO",
    "GAUL": "243",
    "IOC": "TOG",
    "currency_alphabetic_code": "XOF",
    "currency_country_name": "TOGO",
    "currency_minor_unit": "0",
    "currency_name": "CFA Franc BCEAO",
    "currency_numeric_code": "952",
    "is_independent": "Yes"
  },
  {
    "name": "Tokelau",
    "name_fr": "Tokelau",
    "ISO3166-1-Alpha-2": "TK",
    "ISO3166-1-Alpha-3": "TKL",
    "ISO3166-1-numeric": "772",
    "ITU": "TKL",
    "MARC": "tl",
    "WMO": "TK",
    "DS": "NZ",
    "Dial": "690",
    "FIFA": "TKL",
    "FIPS": "TL",
    "GAUL": "244",
    "IOC": " ",
    "currency_alphabetic_code": "NZD",
    "currency_country_name": "TOKELAU",
    "currency_minor_unit": "2",
    "currency_name": "New Zealand Dollar",
    "currency_numeric_code": "554",
    "is_independent": "Territory of NZ"
  },
  {
    "name": "Tonga",
    "name_fr": "Tonga",
    "ISO3166-1-Alpha-2": "TO",
    "ISO3166-1-Alpha-3": "TON",
    "ISO3166-1-numeric": "776",
    "ITU": "TON",
    "MARC": "to",
    "WMO": "TO",
    "DS": " ",
    "Dial": "676",
    "FIFA": "TGA",
    "FIPS": "TN",
    "GAUL": "245",
    "IOC": "TGA",
    "currency_alphabetic_code": "TOP",
    "currency_country_name": "TONGA",
    "currency_minor_unit": "2",
    "currency_name": "Pa’anga",
    "currency_numeric_code": "776",
    "is_independent": "Yes"
  },
  {
    "name": "Trinidad and Tobago",
    "name_fr": "Trinité-Et-Tobago",
    "ISO3166-1-Alpha-2": "TT",
    "ISO3166-1-Alpha-3": "TTO",
    "ISO3166-1-numeric": "780",
    "ITU": "TRD",
    "MARC": "tr",
    "WMO": "TD",
    "DS": "TT",
    "Dial": "1-868",
    "FIFA": "TRI",
    "FIPS": "TD",
    "GAUL": "246",
    "IOC": "TTO",
    "currency_alphabetic_code": "TTD",
    "currency_country_name": "TRINIDAD AND TOBAGO",
    "currency_minor_unit": "2",
    "currency_name": "Trinidad and Tobago Dollar",
    "currency_numeric_code": "780",
    "is_independent": "Yes"
  },
  {
    "name": "Tunisia",
    "name_fr": "Tunisie",
    "ISO3166-1-Alpha-2": "TN",
    "ISO3166-1-Alpha-3": "TUN",
    "ISO3166-1-numeric": "788",
    "ITU": "TUN",
    "MARC": "ti",
    "WMO": "TS",
    "DS": "TN",
    "Dial": "216",
    "FIFA": "TUN",
    "FIPS": "TS",
    "GAUL": "248",
    "IOC": "TUN",
    "currency_alphabetic_code": "TND",
    "currency_country_name": "TUNISIA",
    "currency_minor_unit": "3",
    "currency_name": "Tunisian Dinar",
    "currency_numeric_code": "788",
    "is_independent": "Yes"
  },
  {
    "name": "Turkey",
    "name_fr": "Turquie",
    "ISO3166-1-Alpha-2": "TR",
    "ISO3166-1-Alpha-3": "TUR",
    "ISO3166-1-numeric": "792",
    "ITU": "TUR",
    "MARC": "tu",
    "WMO": "TU",
    "DS": "TR",
    "Dial": "90",
    "FIFA": "TUR",
    "FIPS": "TU",
    "GAUL": "249",
    "IOC": "TUR",
    "currency_alphabetic_code": "TRY",
    "currency_country_name": "TURKEY",
    "currency_minor_unit": "2",
    "currency_name": "Turkish Lira",
    "currency_numeric_code": "949",
    "is_independent": "Yes"
  },
  {
    "name": "Turkmenistan",
    "name_fr": "Turkménistan",
    "ISO3166-1-Alpha-2": "TM",
    "ISO3166-1-Alpha-3": "TKM",
    "ISO3166-1-numeric": "795",
    "ITU": "TKM",
    "MARC": "tk",
    "WMO": "TR",
    "DS": "TM",
    "Dial": "993",
    "FIFA": "TKM",
    "FIPS": "TX",
    "GAUL": "250",
    "IOC": "TKM",
    "currency_alphabetic_code": "TMT",
    "currency_country_name": "TURKMENISTAN",
    "currency_minor_unit": "2",
    "currency_name": "Turkmenistan New Manat",
    "currency_numeric_code": "934",
    "is_independent": "Yes"
  },
  {
    "name": "Turks and Caicos Islands",
    "name_fr": "Turks-Et-Caïcos, Îles",
    "ISO3166-1-Alpha-2": "TC",
    "ISO3166-1-Alpha-3": "TCA",
    "ISO3166-1-numeric": "796",
    "ITU": "TCA",
    "MARC": "tc",
    "WMO": "TI",
    "DS": " ",
    "Dial": "1-649",
    "FIFA": "TCA",
    "FIPS": "TK",
    "GAUL": "251",
    "IOC": "TKS",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "TURKS AND CAICOS ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Tuvalu",
    "name_fr": "Tuvalu",
    "ISO3166-1-Alpha-2": "TV",
    "ISO3166-1-Alpha-3": "TUV",
    "ISO3166-1-numeric": "798",
    "ITU": "TUV",
    "MARC": "tv",
    "WMO": "TV",
    "DS": " ",
    "Dial": "688",
    "FIFA": "TUV",
    "FIPS": "TV",
    "GAUL": "252",
    "IOC": "TUV",
    "currency_alphabetic_code": "AUD",
    "currency_country_name": "TUVALU",
    "currency_minor_unit": "2",
    "currency_name": "Australian Dollar",
    "currency_numeric_code": "036",
    "is_independent": "Yes"
  },
  {
    "name": "Uganda",
    "name_fr": "Ouganda",
    "ISO3166-1-Alpha-2": "UG",
    "ISO3166-1-Alpha-3": "UGA",
    "ISO3166-1-numeric": "800",
    "ITU": "UGA",
    "MARC": "ug",
    "WMO": "UG",
    "DS": "EAU",
    "Dial": "256",
    "FIFA": "UGA",
    "FIPS": "UG",
    "GAUL": "253",
    "IOC": "UGA",
    "currency_alphabetic_code": "UGX",
    "currency_country_name": "UGANDA",
    "currency_minor_unit": "0",
    "currency_name": "Uganda Shilling",
    "currency_numeric_code": "800",
    "is_independent": "Yes"
  },
  {
    "name": "Ukraine",
    "name_fr": "Ukraine",
    "ISO3166-1-Alpha-2": "UA",
    "ISO3166-1-Alpha-3": "UKR",
    "ISO3166-1-numeric": "804",
    "ITU": "UKR",
    "MARC": "un",
    "WMO": "UR",
    "DS": "UA",
    "Dial": "380",
    "FIFA": "UKR",
    "FIPS": "UP",
    "GAUL": "254",
    "IOC": "UKR",
    "currency_alphabetic_code": "UAH",
    "currency_country_name": "UKRAINE",
    "currency_minor_unit": "2",
    "currency_name": "Hryvnia",
    "currency_numeric_code": "980",
    "is_independent": "Yes"
  },
  {
    "name": "United Arab Emirates",
    "name_fr": "Émirats Arabes Unis",
    "ISO3166-1-Alpha-2": "AE",
    "ISO3166-1-Alpha-3": "ARE",
    "ISO3166-1-numeric": "784",
    "ITU": "UAE",
    "MARC": "ts",
    "WMO": "ER",
    "DS": " ",
    "Dial": "971",
    "FIFA": "UAE",
    "FIPS": "AE",
    "GAUL": "255",
    "IOC": "UAE",
    "currency_alphabetic_code": "AED",
    "currency_country_name": "UNITED ARAB EMIRATES",
    "currency_minor_unit": "2",
    "currency_name": "UAE Dirham",
    "currency_numeric_code": "784",
    "is_independent": "Yes"
  },
  {
    "name": "United Kingdom",
    "name_fr": "Royaume-Uni",
    "ISO3166-1-Alpha-2": "GB",
    "ISO3166-1-Alpha-3": "GBR",
    "ISO3166-1-numeric": "826",
    "ITU": "G",
    "MARC": "xxk",
    "WMO": "UK",
    "DS": "GB",
    "Dial": "44",
    "FIFA": "ENG,NIR,SCO,WAL",
    "FIPS": "UK",
    "GAUL": "256",
    "IOC": "GBR",
    "currency_alphabetic_code": "GBP",
    "currency_country_name": "UNITED KINGDOM",
    "currency_minor_unit": "2",
    "currency_name": "Pound Sterling",
    "currency_numeric_code": "826",
    "is_independent": "Yes"
  },
  {
    "name": "United States",
    "name_fr": "États-Unis",
    "ISO3166-1-Alpha-2": "US",
    "ISO3166-1-Alpha-3": "USA",
    "ISO3166-1-numeric": "840",
    "ITU": "USA",
    "MARC": "xxu",
    "WMO": "US",
    "DS": "USA",
    "Dial": "1",
    "FIFA": "USA",
    "FIPS": "US",
    "GAUL": "259",
    "IOC": "USA",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "UNITED STATES",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Yes"
  },
  {
    "name": "United States Minor Outlying Islands",
    "name_fr": "Îles Mineures Éloignées des États-Unis",
    "ISO3166-1-Alpha-2": "UM",
    "ISO3166-1-Alpha-3": "UMI",
    "ISO3166-1-numeric": "581",
    "ITU": " ",
    "MARC": "ji,xf,wk,uc,up",
    "WMO": " ",
    "DS": "USA",
    "Dial": " ",
    "FIFA": "",
    "FIPS": "FQ,HQ,DQ,JQ,KQ,MQ,BQ,LQ,WQ",
    "GAUL": "",
    "IOC": " ",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "UNITED STATES MINOR OUTLYING ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territories of US"
  },
  {
    "name": "Uruguay",
    "name_fr": "Uruguay",
    "ISO3166-1-Alpha-2": "UY",
    "ISO3166-1-Alpha-3": "URY",
    "ISO3166-1-numeric": "858",
    "ITU": "URG",
    "MARC": "uy",
    "WMO": "UY",
    "DS": "ROU",
    "Dial": "598",
    "FIFA": "URU",
    "FIPS": "UY",
    "GAUL": "260",
    "IOC": "URU",
    "currency_alphabetic_code": "UYU",
    "currency_country_name": "URUGUAY",
    "currency_minor_unit": "2",
    "currency_name": "Peso Uruguayo",
    "currency_numeric_code": "858",
    "is_independent": "Yes"
  },
  {
    "name": "Uzbekistan",
    "name_fr": "Ouzbékistan",
    "ISO3166-1-Alpha-2": "UZ",
    "ISO3166-1-Alpha-3": "UZB",
    "ISO3166-1-numeric": "860",
    "ITU": "UZB",
    "MARC": "uz",
    "WMO": "UZ",
    "DS": "UZ",
    "Dial": "998",
    "FIFA": "UZB",
    "FIPS": "UZ",
    "GAUL": "261",
    "IOC": "UZB",
    "currency_alphabetic_code": "UZS",
    "currency_country_name": "UZBEKISTAN",
    "currency_minor_unit": "2",
    "currency_name": "Uzbekistan Sum",
    "currency_numeric_code": "860",
    "is_independent": "Yes"
  },
  {
    "name": "Vanuatu",
    "name_fr": "Vanuatu",
    "ISO3166-1-Alpha-2": "VU",
    "ISO3166-1-Alpha-3": "VUT",
    "ISO3166-1-numeric": "548",
    "ITU": "VUT",
    "MARC": "nn",
    "WMO": "NV",
    "DS": " ",
    "Dial": "678",
    "FIFA": "VAN",
    "FIPS": "NH",
    "GAUL": "262",
    "IOC": "VAN",
    "currency_alphabetic_code": "VUV",
    "currency_country_name": "VANUATU",
    "currency_minor_unit": "0",
    "currency_name": "Vatu",
    "currency_numeric_code": "548",
    "is_independent": "Yes"
  },
  {
    "name": "Venezuela, Bolivarian Republic of",
    "name_fr": "Venezuela, République Bolivarienne du",
    "ISO3166-1-Alpha-2": "VE",
    "ISO3166-1-Alpha-3": "VEN",
    "ISO3166-1-numeric": "862",
    "ITU": "VEN",
    "MARC": "ve",
    "WMO": "VN",
    "DS": "YV",
    "Dial": "58",
    "FIFA": "VEN",
    "FIPS": "VE",
    "GAUL": "263",
    "IOC": "VEN",
    "currency_alphabetic_code": "VEF",
    "currency_country_name": "VENEZUELA, BOLIVARIAN REPUBLIC OF",
    "currency_minor_unit": "2",
    "currency_name": "Bolivar",
    "currency_numeric_code": "937",
    "is_independent": "Yes"
  },
  {
    "name": "Viet Nam",
    "name_fr": "Viet Nam",
    "ISO3166-1-Alpha-2": "VN",
    "ISO3166-1-Alpha-3": "VNM",
    "ISO3166-1-numeric": "704",
    "ITU": "VTN",
    "MARC": "vm",
    "WMO": "VS",
    "DS": "VN",
    "Dial": "84",
    "FIFA": "VIE",
    "FIPS": "VM",
    "GAUL": "264",
    "IOC": "VIE",
    "currency_alphabetic_code": "VND",
    "currency_country_name": "VIET NAM",
    "currency_minor_unit": "0",
    "currency_name": "Dong",
    "currency_numeric_code": "704",
    "is_independent": "Yes"
  },
  {
    "name": "Virgin Islands, British",
    "name_fr": "Îles Vierges Britanniques",
    "ISO3166-1-Alpha-2": "VG",
    "ISO3166-1-Alpha-3": "VGB",
    "ISO3166-1-numeric": "092",
    "ITU": "VRG",
    "MARC": "vb",
    "WMO": "VI",
    "DS": "BVI",
    "Dial": "1-284",
    "FIFA": "VGB",
    "FIPS": "VI",
    "GAUL": "39",
    "IOC": "IVB",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "VIRGIN ISLANDS (BRITISH)",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of GB"
  },
  {
    "name": "Virgin Islands, U.S.",
    "name_fr": "Îles Vierges des États-Unis",
    "ISO3166-1-Alpha-2": "VI",
    "ISO3166-1-Alpha-3": "VIR",
    "ISO3166-1-numeric": "850",
    "ITU": "VIR",
    "MARC": "vi",
    "WMO": "VI",
    "DS": "USA",
    "Dial": "1-340",
    "FIFA": "VIR",
    "FIPS": "VQ",
    "GAUL": "258",
    "IOC": "ISV",
    "currency_alphabetic_code": "USD",
    "currency_country_name": "VIRGIN ISLANDS (U.S.)",
    "currency_minor_unit": "2",
    "currency_name": "US Dollar",
    "currency_numeric_code": "840",
    "is_independent": "Territory of US"
  },
  {
    "name": "Wallis and Futuna",
    "name_fr": "Wallis et Futuna",
    "ISO3166-1-Alpha-2": "WF",
    "ISO3166-1-Alpha-3": "WLF",
    "ISO3166-1-numeric": "876",
    "ITU": "WAL",
    "MARC": "wf",
    "WMO": "FW",
    "DS": "F",
    "Dial": "681",
    "FIFA": "WLF",
    "FIPS": "WF",
    "GAUL": "266",
    "IOC": "WAF",
    "currency_alphabetic_code": "XPF",
    "currency_country_name": "WALLIS AND FUTUNA",
    "currency_minor_unit": "0",
    "currency_name": "CFP Franc",
    "currency_numeric_code": "953",
    "is_independent": "Territory of FR"
  },
  {
    "name": "Western Sahara",
    "name_fr": "Sahara Occidental",
    "ISO3166-1-Alpha-2": "EH",
    "ISO3166-1-Alpha-3": "ESH",
    "ISO3166-1-numeric": "732",
    "ITU": "AOE",
    "MARC": "ss",
    "WMO": " ",
    "DS": " ",
    "Dial": "212",
    "FIFA": "SAH",
    "FIPS": "WI",
    "GAUL": "268",
    "IOC": " ",
    "currency_alphabetic_code": "MAD",
    "currency_country_name": "WESTERN SAHARA",
    "currency_minor_unit": "2",
    "currency_name": "Moroccan Dirham",
    "currency_numeric_code": "504",
    "is_independent": "In contention"
  },
  {
    "name": "Yemen",
    "name_fr": "Yémen",
    "ISO3166-1-Alpha-2": "YE",
    "ISO3166-1-Alpha-3": "YEM",
    "ISO3166-1-numeric": "887",
    "ITU": "YEM",
    "MARC": "ye",
    "WMO": "YE",
    "DS": "YAR",
    "Dial": "967",
    "FIFA": "YEM",
    "FIPS": "YM",
    "GAUL": "269",
    "IOC": "YEM",
    "currency_alphabetic_code": "YER",
    "currency_country_name": "YEMEN",
    "currency_minor_unit": "2",
    "currency_name": "Yemeni Rial",
    "currency_numeric_code": "886",
    "is_independent": "Yes"
  },
  {
    "name": "Zambia",
    "name_fr": "Zambie",
    "ISO3166-1-Alpha-2": "ZM",
    "ISO3166-1-Alpha-3": "ZMB",
    "ISO3166-1-numeric": "894",
    "ITU": "ZMB",
    "MARC": "za",
    "WMO": "ZB",
    "DS": "Z",
    "Dial": "260",
    "FIFA": "ZAM",
    "FIPS": "ZA",
    "GAUL": "270",
    "IOC": "ZAM",
    "currency_alphabetic_code": "ZMW",
    "currency_country_name": "ZAMBIA",
    "currency_minor_unit": "2",
    "currency_name": "Zambian Kwacha",
    "currency_numeric_code": "967",
    "is_independent": "Yes"
  },
  {
    "name": "Zimbabwe",
    "name_fr": "Zimbabwe",
    "ISO3166-1-Alpha-2": "ZW",
    "ISO3166-1-Alpha-3": "ZWE",
    "ISO3166-1-numeric": "716",
    "ITU": "ZWE",
    "MARC": "rh",
    "WMO": "ZW",
    "DS": "ZW",
    "Dial": "263",
    "FIFA": "ZIM",
    "FIPS": "ZI",
    "GAUL": "271",
    "IOC": "ZIM",
    "currency_alphabetic_code": "ZWL",
    "currency_country_name": "ZIMBABWE",
    "currency_minor_unit": "2",
    "currency_name": "Zimbabwe Dollar",
    "currency_numeric_code": "932",
    "is_independent": "Yes"
  },
  {
    "name": "Åland Islands",
    "name_fr": "Åland, Îles",
    "ISO3166-1-Alpha-2": "AX",
    "ISO3166-1-Alpha-3": "ALA",
    "ISO3166-1-numeric": "248",
    "ITU": " ",
    "MARC": " ",
    "WMO": " ",
    "DS": "FIN",
    "Dial": "358",
    "FIFA": "ALD",
    "FIPS": " ",
    "GAUL": "1242",
    "IOC": " ",
    "currency_alphabetic_code": "EUR",
    "currency_country_name": "ÅLAND ISLANDS",
    "currency_minor_unit": "2",
    "currency_name": "Euro",
    "currency_numeric_code": "978",
    "is_independent": "Part of FI"
  }
]