import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import moment from 'moment';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from 'src/app/components/common/dropdown-menu/dropdown-menu.component';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';
@Component({
  selector: 'app-discount-details',
  templateUrl: './discount-details.component.html',
  styleUrls: ['../../voucher.component.sass']
})
export class DiscountDetailsComponent implements OnInit, OnChanges {

  @Input() sendVoucher:any;
  @Input() loading:boolean = false;
  weekdays:any = [
    {'label':'Monday', 'value':'MONDAY', 'checked':true},
    {'label':'Tuesday', 'value':'TUESDAY', 'checked':true},
    {'label':'Wednesday', 'value':'WEDNESDAY', 'checked':true},
    {'label':'Thursday', 'value':'THURSDAY', 'checked':true},
    {'label':'Friday', 'value':'FRIDAY', 'checked':true},
    {'label':'Saturday', 'value':'SATURDAY', 'checked':true},
    {'label':'Sunday', 'value':'SUNDAY', 'checked':true},
  ];
  selectedWeekdays:any = "All";

  hotels:any[] = [];
  selectedHotels:any = "All";

  allotmentList:MenuType[] = [];
  selectedAllotment?:MenuType;

  allotmentSearchFound:boolean = true;

  //Details
  detailType = "FIXED_PRICE";
  detailGeneral:any = {
    'detailType': null,
    'fixedPrice': [],
    'percentage': null,
    'breakfast': false,
    'eci': false,
    'lco': false,
    'refund': true,
    'hotelsArray': null,
    'hotels': null,
    'weekdaysArray': null,
    'weekdays': [],
    'minNights': null,
    'maxNights': null,
    'description': null,
    'allotment': null,
  };
  orderFixedPrice:any = {
    firstPrice:NaN,
    secondPrice:NaN,
    thirdPrice:NaN,
    fourthPrice:NaN,
    percentage:NaN
  };

  nightsInput:any = {
    minNights:NaN,
    maxNights:NaN
  };

  //Status
  newDetailShow:boolean = false;
  newDetail100Show:boolean = false;
  viewDetail:boolean = false;
  editDetail:boolean = false;

  userInfo:any;
  token:any = window.localStorage.getItem('jwt');
  hasAllotRole:boolean = false;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  
  // Category
  categoryList:MenuType[] = [];
  selectedCategory?:MenuType;
  categoryError:boolean = false;

  // Group segment
  segmentList:MenuType[] = [];
  selectedSegment?:MenuType;
  
  constructor(
    private voucherService: VoucherService,
    public utils: UTILS,
    private route: ActivatedRoute,
    private storeService: StoreService,
  ) { }

  @Output() sendParent = new EventEmitter<any>();

  ngOnInit(): void {
    this.checkRoles();
    this.getSearchAllotments(() => {
      this.getHotels();
    });
    this.getAllCategory();
    this.getAllSegment();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.sendVoucher) {
      if(this.sendVoucher.allotmentId) {
        const findAllotment = this.allotmentList.find(item => item.value === this.sendVoucher.allotmentId.toString());
        if(findAllotment) {
          this.selectAllotment(findAllotment)
        }
      }
      if(this.sendVoucher.segmentId) {
        const findSegment = this.segmentList.find(item => item.value === this.sendVoucher.segmentId.toString());
        if(findSegment) {
          this.selectSegment(findSegment)
        }
      }
    }
  }

  checkRoles() {
    if(GlobalUserSession) {
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE", "REVENUE_MANAGER"].includes(GlobalUserSession.role)) {
        this.hasAllotRole = true;
      }
    }
  }

  getAllCategory() {
    this.voucherService.getAllCategory().subscribe(data => {
      let tmpCategoryList:MenuType[] = [];
      data.forEach((item:any) => {
        tmpCategoryList.push({
          label: item.name,
          value: item.id.toString(),
          disabled: false
        })
      })
      this.categoryList = tmpCategoryList;
    });
  }
  selectCategory(item:MenuType) {
    this.selectedCategory = item;
    this.categoryError = false;
  }

  getAllSegment() {
    this.voucherService.getAllSegment().subscribe(data => {
      let tmpSegmentList:MenuType[] = [
        {
          label: "--",
          value: "none",
          disabled: false
        }
      ];
      data.forEach((item:any) => {
        tmpSegmentList.push({
          label: item.name,
          value: item.id.toString(),
          disabled: false
        })
      })
      this.segmentList = tmpSegmentList;
      this.selectDefaultSegment();
    });
  }
  selectSegment(item:MenuType) {
    this.selectedSegment = item;
  }

  getHotels() {
    this.voucherService.getHotels().subscribe(data => {
      this.hotels = data;
      this.checkAllotmentParam();
    });
  }

  checkAllotmentParam() {
    setTimeout(() => {
      this.route.queryParamMap.subscribe(params => {
        let paramKeys = params.keys;
        let checkAllotParams = ['isRedirectFromHA', 'allotmentId'].every((key:any) => paramKeys.includes(key));
        if(checkAllotParams && params.get('isRedirectFromHA') == 'true') {
          let allotmentId = Number(params.get('allotmentId'));
          this.voucherService.getAllotments(allotmentId).subscribe(data => {
            let rooms:number = 0;
            data.hotelAllotments.forEach((allot:any) => {
              rooms += allot.reservedRoomLabels.length;
            });
            let amount = moment(data.hotelAllotments[0].endDate).diff(moment(data.hotelAllotments[0].startDate), 'day') * rooms;
            let allotHotels = _.map(data.hotelAllotments, (hotel) => {return hotel.hotelId} );
  
            document.querySelector<HTMLInputElement>(`.discount-table input[name=voucherKey]`)!.value = data.name;
            document.querySelector<HTMLInputElement>(`.discount-table input[name=voucherAmount]`)!.value = amount.toString();
            if(data.description) {
              document.querySelector<HTMLInputElement>(`.discount-table textarea`)!.value = data.description;
            }

            this.hotels.forEach((hotel:any) => {
              if(allotHotels.includes(hotel.hotelId)) {
                hotel.checked = true;
              } else {
                hotel.checked = false;
              }
            });
            this.showSelectedHotels();

            this.selectAllotmentFromValue(data.id.toString())
          })
        } else {
          this.hotels = this.hotels.map(v => ({...v, checked: true}));
        }
      })
    }, 300);
  }

  getSearchAllotments(callback:any = null) {
    this.voucherService.getSearchAllotments().subscribe(data => {
      let tmpAllotmentList:MenuType[] = [{
        label: "--",
        value: "none",
        disabled: false
      }];
      data.forEach((item:any) => {
        tmpAllotmentList.push({
          label: item.name,
          value: item.id.toString(),
          disabled: false
        })
      })
      this.allotmentList = tmpAllotmentList;
      this.selectDefaultAllotment();

      if(callback) {
        callback();
      }
    })
  }

  selectAllotment(item:MenuType) {
    this.selectedAllotment = item;
  }
  selectDefaultAllotment() {
    this.selectedAllotment = this.allotmentList[0]
  }
  selectDefaultSegment() {
    this.selectSegment(this.segmentList[0])
  }
  selectAllotmentFromValue(value:string) {
    const findAllotment = this.allotmentList.find(item => item.value === value);
    if(findAllotment) {
      this.selectAllotment(findAllotment)
    }
  }

  changeDetailCheckbox(type:string,e:any) {
    this.detailGeneral[type] = e.target.checked;
  }

  toggleDetailType(type:string) {
    this.detailType = type;
    this.detailGeneral['breakfast'] = false;
  }
  
  //Hotel change
  hotelChange(e:any,index:number) {
    this.hotels[index].checked = e.target.checked;
    this.showSelectedHotels();
  }
  showSelectedHotels() {
    let getSelect = document.querySelector<HTMLInputElement>(`#selectHotels`);
    if(!this.checkUncheckedHotels()) {
      this.selectedHotels = "All";
      if(getSelect) getSelect.classList.remove('input-error');
    } else {
      this.selectedHotels = _.map(this.hotels,(item) => {
        if(item.checked) {
          return this.utils.hotelDisplayByHotel(this.hotelConfig, item);
        }
        else return 0;
      }).filter((item:any) => {return item != 0;});
      if(this.selectedHotels.length == 0) {
        if(getSelect) getSelect.classList.add('input-error');
      } else {
        if(getSelect) getSelect.classList.remove('input-error');
      }
    }
  }
  checkUncheckedHotels() {
    let check = false;
    this.hotels.forEach((item:any) => {
      if(!item.checked) {
        check = true;
      }
    });
    return check;
  }
  selectAllHotels() {
    this.hotels.forEach((item:any) => {
      item.checked = true;
    });
    this.selectedHotels = "All";
    this.showSelectedHotels();
  }
  getHotelObjId(hotelId:any): any {
    const hotelObj = this.hotels.find((obj:any) => {
      return obj.hotelId == hotelId;
    });
    return hotelObj;
  }

  //Weekdays change
  weekdayChange(e:any,index:number) {
    this.weekdays[index].checked = e.target.checked;
    this.showSelectedWeekday();
  }
 showSelectedWeekday() {
    let getSelect = document.querySelector<HTMLInputElement>(`#selectWeekdays`);
    if(!this.checkUncheckedWeekdays()) {
      this.selectedWeekdays = "All";
      if(getSelect) getSelect.classList.remove('input-error');
    } else {
      this.selectedWeekdays = _.map(this.weekdays,(item) => {if(item.checked) return item.label; else return 0;}).filter((item:any) => {return item != 0;});
      if(this.selectedWeekdays.length == 0) {
        if(getSelect) getSelect.classList.add('input-error');
      } else {
        if(getSelect) getSelect.classList.remove('input-error');
      }
    }
  }

  checkUncheckedWeekdays() {
    let check = false;
    this.weekdays.forEach((item:any) => {
      if(!item.checked) {
        check = true;
      }
    });
    return check;
  }

  selectAllWeekdays() {
    this.weekdays.forEach((item:any) => {
      item.checked = true;
    });
    this.selectedWeekdays = "All";
    this.showSelectedWeekday();
  }

  resetNewDetail() {
    this.weekdays.forEach((item:any) => {
      item.checked = true;
    });
    this.selectedWeekdays = "All";
    this.hotels.forEach((item:any) => {
      item.checked = true;
    });
    this.selectedHotels = "All";
    this.selectDefaultAllotment();
    this.selectDefaultSegment();
    this.detailType = "FIXED_PRICE";
    this.detailGeneral = {
      'detailType': null,
      'fixedPrice': [],
      'percentage': null,
      'breakfast': false,
      'eci': false,
      'lco': false,
      'refund': true,
      'hotelsArray': null,
      'hotels': null,
      'weekdaysArray': null,
      'weekdays': [],
      'minNights': null,
      'maxNights': null,
      'description': null,
      'allotment': null,
    };
    this.orderFixedPrice.firstPrice = "";
    this.orderFixedPrice.secondPrice = "";
    this.orderFixedPrice.thirdPrice = "";
    this.orderFixedPrice.fourthPrice = "";
    this.orderFixedPrice.percentage = "";
    this.nightsInput.minNights = "";
    this.nightsInput.maxNights = "";
    this.selectedCategory = undefined;
    const getDescField = document.querySelector<HTMLInputElement>(`.detail-table textarea`);
    if(getDescField) {
      getDescField.value = "";
    }
  }

  getInputValue(name:string, type?:string) {
    let getInput, tmpValue:any;
    if(name == 'description') {
      getInput = document.querySelector<HTMLInputElement>(`.detail-table textarea`);
    } else {
      getInput = document.querySelector<HTMLInputElement>(`.detail-table input[name=${name}]`);
    }
    if(getInput) tmpValue = getInput.value;
    if(type == 'number') {
      return tmpValue ? Number(tmpValue) : 0;
    } else {
      return tmpValue;
    }
  }

  checkPattern(name:string, value:any) {
    const percentagePattern = new RegExp(/^0*(?:[1-9][0-9]?|100)$/);
    const pricePattern = new RegExp(/^\d{1,4}(\,|\.)?(\d{1,2})?$/);
    const nightPattern = new RegExp(/^(?:[1-9]?[0-9]?[0-9]?)$/);
    switch (name) {
      case 'percentage':
        return percentagePattern.test(value);
      case 'firstPrice':
      case 'secondPrice':
      case 'thirdPrice':
      case 'fourthPrice':
        return pricePattern.test(value);
      case 'description':
        if(value) {
          return true;
        } else {
          return false;
        }
      case 'minNights':
      case 'maxNights':
        return nightPattern.test(value);
      default:
        return false;
    }
  }

  validateDetailInput(name:string, e:any) {
    let inputValue;
    if(typeof e == 'object') {
      inputValue = e.target.value;
    } else {
      inputValue = e;
    }
    let patternCheck = this.checkPattern(name, inputValue);
    let getInput;
    if(name == 'description') {
      getInput = document.querySelector<HTMLInputElement>(`.detail-table textarea`);
    } else {
      getInput = document.querySelector<HTMLInputElement>(`.detail-table input[name=${name}]`);
      // if(inputValue!==100) {
      //   this.detailGeneral['breakfast'] = false;
      // }
    }
    if(!patternCheck) {
      if(getInput) {
        getInput.classList.add('input-error');
        return false;
      } else {
        return false;
      }
    } else {
      if(getInput) {
        getInput.classList.remove('input-error');
        return true;
      } else {
        return false;
      }
    }
  }
  customHandleFixedPriceInput(type:string,name:string){
    if(type==='increment'){
      let newValue = 0;
      switch(name){
        case 'firstPrice':
          this.orderFixedPrice.firstPrice = this.orderFixedPrice.firstPrice ? this.orderFixedPrice.firstPrice + 1 : 1;
          newValue = this.orderFixedPrice.firstPrice;
          break;
        case 'secondPrice':
          this.orderFixedPrice.secondPrice = this.orderFixedPrice.secondPrice ? this.orderFixedPrice.secondPrice + 1 : 1;
          newValue = this.orderFixedPrice.secondPrice;
          break;
        case 'thirdPrice':
          this.orderFixedPrice.thirdPrice = this.orderFixedPrice.thirdPrice ? this.orderFixedPrice.thirdPrice + 1 : 1;
          newValue = this.orderFixedPrice.thirdPrice;
          break;
        case 'fourthPrice':
          this.orderFixedPrice.fourthPrice = this.orderFixedPrice.fourthPrice ? this.orderFixedPrice.fourthPrice + 1 : 1;
          newValue = this.orderFixedPrice.fourthPrice;
          break;
        case 'percentage':
          this.orderFixedPrice.percentage = this.orderFixedPrice.percentage ? this.orderFixedPrice.percentage + 1 : 1;
          newValue = this.orderFixedPrice.percentage;
          break;
      }
      this.validateDetailInput(name,newValue)
      return
    }
    let newValue = 0;
      switch(name){
        case 'firstPrice':
          this.orderFixedPrice.firstPrice = this.orderFixedPrice.firstPrice ? this.orderFixedPrice.firstPrice - 1 : 0;
          newValue = this.orderFixedPrice.firstPrice;
          break;
        case 'secondPrice':
          this.orderFixedPrice.secondPrice = this.orderFixedPrice.secondPrice ? this.orderFixedPrice.secondPrice - 1 : 0;
          newValue = this.orderFixedPrice.secondPrice;
          break;
        case 'thirdPrice':
          this.orderFixedPrice.thirdPrice = this.orderFixedPrice.thirdPrice ? this.orderFixedPrice.thirdPrice - 1 : 0;
          newValue = this.orderFixedPrice.thirdPrice;
          break;
        case 'fourthPrice':
          this.orderFixedPrice.fourthPrice = this.orderFixedPrice.fourthPrice ? this.orderFixedPrice.fourthPrice - 1 : 0;
          newValue = this.orderFixedPrice.fourthPrice;
          break;
        case 'percentage':
          this.orderFixedPrice.percentage = this.orderFixedPrice.percentage ? this.orderFixedPrice.percentage - 1 : 0;
          newValue = this.orderFixedPrice.percentage;
          break;
      }
    this.validateDetailInput(name,newValue)
  }

  customHandleNightValue(type:string, name:string){
    if(type==='increment'){
      let newValue = 0;
      switch(name){
        case 'minNights':
          this.nightsInput.minNights = this.nightsInput.minNights ? this.nightsInput.minNights + 1 : 1;
          newValue = this.nightsInput.minNights;
          break;
        case 'maxNights':
          this.nightsInput.maxNights = this.nightsInput.maxNights ? this.nightsInput.maxNights + 1 : 1;
          newValue = this.nightsInput.maxNights;
          break;
      }
      this.nightInput(name,newValue)
      return
    }
    let newValue = 0;
    switch(name){
      case 'minNights':
        this.nightsInput.minNights = this.nightsInput.minNights ? this.nightsInput.minNights - 1 : 0;
        newValue = this.nightsInput.minNights;
        break;
      case 'maxNights':
        this.nightsInput.maxNights = this.nightsInput.maxNights ? this.nightsInput.maxNights - 1 : 0;
        newValue = this.nightsInput.maxNights;
        break;
    }
    this.nightInput(name,newValue)
  }
  nightInput(type:string, e:any) {
    let inputValue;
    if(typeof e == 'object') {
      inputValue = e.target.value;
    } else {
      inputValue = e;
    }
    switch(type) {
      case 'minNights':
        let maxNightsValue = this.getInputValue('maxNights', 'number');
        if(!this.nightsInput.maxNights){
          this.validateDetailInput('minNights',inputValue);
          break;
        }
        if(!Number.isNaN(maxNightsValue) && inputValue > maxNightsValue && this.validateDetailInput('minNights',inputValue)) {
          let getInput = document.querySelector<HTMLInputElement>(`.detail-table input[name=maxNights]`);
          if(getInput) {
            getInput.value = inputValue;
            this.nightsInput.maxNights = inputValue;
          }
        }
        break;
      case 'maxNights':
        let minNightsValue = this.getInputValue('minNights', 'number');
        if(!Number.isNaN(minNightsValue) && inputValue != '' && inputValue < minNightsValue && this.validateDetailInput('maxNights',inputValue)) {
          let getInput:any = document.querySelector<HTMLInputElement>(`.detail-table input[name=maxNights]`);
          if(getInput) {
            getInput.value = minNightsValue;
            this.nightsInput.maxNights = minNightsValue;
          }
        }
    }
  }

  getVoucherType(fixed:any) {
    if(fixed.length > 0) {
      return 'Fixed price'
    } else {
      return 'Percentage'
    }
  }

  getRestriction(restriction:any, type:string) {
    switch(type) {
      case 'hotel':
        let hotelIndex = restriction.findIndex((item:any) => {return item.type === 'HotelRestriction'});
        if(hotelIndex === -1) {
          return 'All';
        } else {
          let hotelList = _.map(restriction[hotelIndex]['hotelIds'],(item) => {
            return this.utils.hotelDisplayByHotel(this.hotelConfig, this.getHotelObjId(item));
          });
          return hotelList.join(', ');
        }
      case 'weekday':
        let weekdayIndex = restriction.findIndex((item:any) => {return item.type === 'InvoiceDayOfWeekRestriction'});
        if(weekdayIndex === -1) {
          return 'All';
        } else {
          let weekdayList = _.map(restriction[weekdayIndex]['daysOfWeek'],(item) => {
            return _.upperFirst(_.lowerCase(item));
          });
          return weekdayList.join(', ');
        }
      case 'minNights':
        let minNightIndex = restriction.findIndex((item:any) => {return item.type === 'NightCountRestriction'});
        if(minNightIndex === -1 || restriction[minNightIndex]['minNights'] == undefined) {
          return '';
        } else {
          return restriction[minNightIndex]['minNights'];
        }
      case 'maxNights':
        let maxNightIndex = restriction.findIndex((item:any) => {return item.type === 'NightCountRestriction'});
        if(maxNightIndex === -1 || restriction[maxNightIndex]['maxNights'] == undefined) {
          return '';
        } else {
          return restriction[maxNightIndex]['maxNights'];
        }
      default:
        return 0;
    }
  }
  getCategory(categoryId:number){
    const findCategory = this.categoryList.find(item => item.value === categoryId.toString());
    if(findCategory) {
      return findCategory.label;
    } else {
      return "Other"
    }
  }
  getSegment(segmentId?:number){
    if(segmentId) {
      const findSegment = this.segmentList.find(item => item.value === segmentId.toString());
      if(findSegment) {
        return findSegment.label;
      } else {
        return ""
      }
    } else {
      return ""
    }
  }
  getAllotment(value?:number) {
    if(value) {
      const findAllotment = this.allotmentList.find(item => item.value === value.toString());
      if(findAllotment) {
        return findAllotment.label
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  addVoucher() {
    this.detailGeneral['detailType'] = this.detailType;
    this.detailGeneral['description'] = this.getInputValue('description');
    this.detailGeneral['allotment'] = this.selectedAllotment ? (this.selectedAllotment.value !== "none" ? Number(this.selectedAllotment.value) : null) : null;
    this.detailGeneral['minNights'] = this.getInputValue('minNights','number');
    this.detailGeneral['maxNights'] = this.getInputValue('maxNights','number');
    this.detailGeneral['hotels'] = this.selectedHotels;
    this.detailGeneral['hotelsArray'] = this.hotels;
    this.detailGeneral['weekdays'] = this.selectedWeekdays;
    this.detailGeneral['weekdaysArray'] = this.weekdays;
    this.detailGeneral['category'] = this.selectedCategory ? Number(this.selectedCategory.value) : undefined;
    this.detailGeneral['segmentId'] = this.selectedSegment && this.selectedSegment.value !== "none" ? Number(this.selectedSegment.value) : undefined;
    switch(this.detailType) {
      case 'FIXED_PRICE':
        this.detailGeneral['fixedPrice'] = [this.getInputValue('firstPrice','number'),this.getInputValue('secondPrice','number'),this.getInputValue('thirdPrice','number'),this.getInputValue('fourthPrice','number')];
        break;
      case 'PERCENTAGE':
        this.detailGeneral['percentage'] = this.getInputValue('percentage');
        break;
    }
    this.sendParent.emit(this.detailGeneral);
  }

  updateVoucher() {
    this.sendVoucher.comment = this.getInputValue('description');
    this.sendVoucher.allotmentId = this.selectedAllotment ? (this.selectedAllotment.value !== "none" ? Number(this.selectedAllotment.value) : null) : null;
    this.sendVoucher.segmentId = this.selectedSegment && this.selectedSegment.value !== "none" ? Number(this.selectedSegment.value) : null;
    this.sendParent.emit(this.sendVoucher);
  }
}
