<app-header-user-management activePage="dashboard"></app-header-user-management>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Dashboard</div>
          <div class="view-desc">{{determineTime()}}, {{ firstName }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Loading icon -->
  @if (loadingContainer) {
    <div class="center-item">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  }
  @if (!loadingContainer) {
    <div class="wrapper-outer-table">
      <div class="outer-table">
        <div class="container-fluid">
          <div class="container-title">Choose a client</div>
          @if (tenants.length > 0) {
            <table class="custom-table dashboard-table">
              <thead>
                <th class="client-name">
                  <div class="padding">Client name</div>
                </th>
                @if (isAdminRole || isClientAdminRole) {
                  <th class="column-format">
                    <div class="flex-container padding">
                      <div class="table-title">Active users</div>
                    </div>
                  </th>
                }
                @if (isAdminRole || isClientAdminRole) {
                  <th class="column-format">
                    <div class="flex-container padding">
                      <div class="table-title">Issues</div>
                    </div>
                  </th>
                }
                <!-- <th class="switch-column" *ngIf="isAdminRole">
                <div class="flex-container padding">
                </div>
              </th> -->
            </thead>
            <tbody>
              <ng-container>
                @for (environment of environments; track environment) {
                  <tr>
                    <ng-container>
                      <td (click)="chooseEnvironment(environment)">
                        <b class="client-name-color">{{ environment.name }}</b>
                      </td>
                      @if (isAdminRole || isClientAdminRole) {
                        <td>
                          <div>{{ environment.activeUsers }}</div>
                        </td>
                      }
                      @if (isAdminRole || isClientAdminRole) {
                        <td>
                          <div class="{{ environment.issue > 1 ? 'warn-text' : ''}}">
                            {{ environment.issues }}
                          </div>
                        </td>
                      }
                    </ng-container>
                  </tr>
                }
              </ng-container>
            </tbody>
          </table>
        }
      </div>
    </div>
  </div>
}
</div>