import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const API = '/api/management/secure/allotment';
const API_CREATE_ALLOTMENT = '/api/management/secure/allotment/create';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AllotmentsService {

  constructor(private http: HttpClient) { }

  searchAllotments(request: any): Observable<any> {
    let url = API + '/search-allotment?includeCancelledAllotment=true';
    if (request.hotelIds) {
      url += '&hotelIds=' + request.hotelIds
    }
    if (request.name) {
      url += '&name=' + request.name;
    }
    if (request.description) {
      url += '&description=' + request.description;
    }
    if (request.startDate) {
      url += '&start=' + request.startDate;
    }
    if (request.endDate) {
      url += '&end=' + request.endDate;
    }
    return this.http.get(url);
  }
  allotmentInfo(allotmentId: number): Observable<any> {
    let url = API + '/hotel-allotment-info/' + allotmentId;
    return this.http.get(url);
  }
  getVoucherByAllotmentId(allotmentId: number): Observable<any> {
    let url = '/api/management/secure/voucher/discount/by_allotment_id/' + allotmentId;
    return this.http.get(url);
  }
  getAvailabilities(hotelId: any, request: any): Observable<any> {
    let url = '/api/management/secure/allotment/' + hotelId + '/availabilities';
    return this.http.get(url, { params: request });
  }
  getAvailableRooms(hotelId: any, request: any): Observable<any> {
    let url = '/api/management/secure/allotment/' + hotelId + '/available-rooms';
    return this.http.get(url, { params: request });
  }
  save(allotment: any): Observable<any> {
    return this.http.post(API_CREATE_ALLOTMENT, allotment, httpOptions);
  }
  checkOverlapWithVoucher(allotment: any): Observable<any> {
    let url = '/api/management/secure/voucher/discount/checkValidities/';
    return this.http.post(url, allotment, httpOptions);
  }
  getAllotment(allotmentId: number): Observable<any> {
    let url = '/api/management/secure/allotment/' + allotmentId;
    return this.http.get(url);
  }
  cancelAllotment(allotmentId: number): Observable<any> {
    let url = '/api/management/secure/allotment/cancel/' + allotmentId;
    return this.http.put(url, allotmentId, httpOptions);
  }

}