import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type BeonxHotelNotifExportType = {
  reservationCode?: string,
  type: string,
  hotelLabel?: string,
  allotmentId?: string
}

@Injectable({
  providedIn: 'root'
})
export class InternalToolsService {

  constructor(
    private http: HttpClient
  ) { }

  public retrieveOTAreservation(channel:string, externalNumber:string, hotelLabel:string) {
    return this.http.post<any>(`/api/management/secure/retrieve-external-reservation?channel=${channel}&bookingChannelReservationId=${externalNumber}&hotelLabel=${hotelLabel}`, {});
  }

  public updateReservationLanguage(reservationCode:string, lang:string) {
    return this.http.put<any>(`/api/management/secure/reservation/update-lang?reservationCode=${reservationCode}&lang=${lang}`, {});
  }

  public downloadXmlPost(url: string, request:BeonxHotelNotifExportType) {
    let requestParam = new HttpParams();
    Object.keys(request).forEach(key => {
      const typedKey = key as keyof typeof request;
      const keyValue = request[typedKey];
      if(keyValue) {
        requestParam = requestParam.append(key, keyValue)
      }
    })
    return this.http.post(url, null, { responseType: 'blob', params: requestParam })
  }
}
