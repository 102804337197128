<app-header activePage="voucher"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Vouchers</div>
        </div>
      </div>

      <form [formGroup]="voucherSearch">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>Search for</label>
            <input class="form-control custom-input searchQuery" formControlName="searchQuery">

            <label>Optional filters</label>
            <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                <div class="menu-selected">
                  @for (filter of getThreeFilter(); track filter; let i = $index) {
                    @if (filter.checked) {
                      <div class="filter-content">
                        <div class="filter-item">
                          <div class="filter-label">{{filter.label}}</div>
                          <div (click)="removeFilter(filter, $event)">
                            <fa-icon type="solid" icon="xmark"></fa-icon>
                          </div>
                        </div>
                      </div>
                    }
                  }
                  @if (getAllFilter().length > 2) {
                    <div class="filter-content">
                      <div class="filter-item">
                        <div class="filter-label"> + {{getAllFilter().length - 2}}</div>
                      </div>
                    </div>
                  }
                </div>
                <div class="menu-arrow">
                  <fa-icon type="solid" icon="chevron-down"></fa-icon>
                </div>
              </div>
              <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                (click)="utils.preventClose($event)">
                <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                @for (filter of optionalFilter; track filter) {
                  <div class="normal-checkbox" [ngClass]="{disabled: !filter.enabled, child: filter.voucherType != 'main'}">
                    <input type="checkbox" (click)="filterChange($event,filter.value)" [checked]="filter.checked">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    <div class="checkbox-label">{{filter.label}}</div>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (hotels.length > 1) {
            <div class="col-12 col-md-2">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <div class="col-6 col-md-2">
            <label>Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                [(bsValue)]="startDate" #startDateInput="bsDatepicker" (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>

          </div>
          <div class="col-6 col-md-2">
            <label>End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control" appEditDate [(bsValue)]="endDate"
                bsDatepicker #endDateInput="bsDatepicker" (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>

            <label>Date selection</label>
            <div class="box-selector">
              <div class="custom-radio">
                <input type="radio" name="date-selection" (click)="dateMode = 'OVERLAP'" [checked]="dateMode == 'OVERLAP'">
                <div class="radio-box">Overlap</div>
              </div>
              <div class="custom-radio">
                <input type="radio" name="date-selection" (click)="dateMode = 'EXACT'" [checked]="dateMode == 'EXACT'">
                <div class="radio-box">Exact</div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="bottom-filter">
        <div class="bottom-filter-left"></div>
        <div class="bottom-filter-right">
          <button class="btn btn-none-outline-green large-btn" (click)="clearSearch()"><fa-icon type="solid" icon="trash-can"></fa-icon>Clear search</button>
          <button class="btn btn-green large-btn" (click)="onSearch()"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
        </div>
      </div>

    </div>
  </div>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <app-discount-table [discountResult]="discountResult" [discountCount]="discountCount" (showMore)="showMore('discount')"></app-discount-table>

        <app-monetary-table [monetaryResult]="monetaryResult" [monetaryCount]="monetaryCount" (showMore)="showMore('monetary')"></app-monetary-table>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>