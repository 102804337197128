import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CONSTANT } from 'src/app/helpers/constants';
import { CustomModalComponent } from '../../common/custom-modal/custom-modal.component';
import { LoadingAlertType, ResultAlertType } from '../../common/dropdown-menu/dropdown-menu.component';
import { EInvoiceOperatorType, HotelService } from 'src/app/services/hotel.service';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { FormBuilder, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { CustomToastService } from 'src/app/services/custom-toast.service';

@Component({
  selector: 'integrated-eivoice-ops',
  templateUrl: './integrated-eivoice-ops.component.html',
  styleUrls: ['../hotels.component.sass']
})
export class IntegratedEivoiceOpsComponent implements OnInit {
  @ViewChild('modalBody') modalBody!: ElementRef;

  constructor(
    private hotelService: HotelService,
    private formBuilder: NonNullableFormBuilder,
    private toast: CustomToastService
  ) { }

  addOperatorForm = this.formBuilder.group({
    name: ["", Validators.required],
    swiftCode: ["", Validators.required],
    active: [false],
  });
  get opName() {return this.addOperatorForm.get("name");}
  get opSwiftCode() {return this.addOperatorForm.get("swiftCode");}
  get opActive() {return this.addOperatorForm.get("active");}

  unsavedChange:boolean = true;

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];
  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: ''
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }
  scrollModal(location:string) {
    setTimeout(() => {
      switch (location) {
        case "top":
          this.modalBody.nativeElement.scrollTop = 0;
          break;
        case "bottom":
          this.modalBody.nativeElement.scrollTop = this.modalBody.nativeElement.scrollHeight;
          break;
        default:
          break;
      }
    }, 0);
  }

  checkUnsavedChange() {
    this.scrollModal("top");
    if(this.unsavedChange) {
      this.openCustomModal("unsavedChangeAlert");
    }
  }
  actionUnsavedChange(discard:boolean) {
    this.closeCustomModal("unsavedChangeAlert");
    if(discard) {
      this.unsavedChange = false;
    } else {
      this.openCustomModal("eInvoiceListModal");
    }
  }
  
  eInvoiceList:EInvoiceOperatorType[] = [];
  eInvoiceListFiltered:EInvoiceOperatorType[] = [];

  eInvoiceAddList:EInvoiceOperatorType[] = [];
  operatorSearchKeyword:string = "";

  ngOnInit(): void {
    
  }

  convertDate(date?:string | null) {
    if(date) {
      return format(toZonedTime(new Date(date), "Europe/Helsinki"), "dd.LL.yyyy HH:mm");
    } else {
      return "";
    }
  }

  async copyText(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.log(err)
      this.toast.error("An error occurred while copying payment link, please try again", 'Error!');
    }
  }

  openEInvoiceOpsModal() {
    this.openLoadingAlert("Loading manage operators...");
    this.eInvoiceAddList = [];
    this.unsavedChange = false;
    this.operatorSearchKeyword = "";
    this.resetAddOperatorForm();
    this.hotelService.getEInvoiceOperators().subscribe({
      next: (data) => {
        this.closeLoadingAlert();
        let tmpEInvoiceList:EInvoiceOperatorType[] = [];
        data.forEach((operator:any) => {
          const tmpOperator:EInvoiceOperatorType = {
            ...operator,
            checked: operator.active
          }
          tmpEInvoiceList.push(tmpOperator);
        });
        this.eInvoiceList = tmpEInvoiceList;
        this.eInvoiceListFiltered = tmpEInvoiceList;
        this.openCustomModal('eInvoiceListModal');
      },
      error: () => {
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred, please try again later!");
      }
    })
  }

  resetAddOperatorForm() {
    this.addOperatorForm.reset();
    this.addOperatorForm.markAsPristine();
    this.addOperatorForm.markAsUntouched();
  }
  addOperatorToList() {
    this.addOperatorForm.markAllAsTouched();
    const getForm = this.addOperatorForm.controls;
    if(this.addOperatorForm.valid) {
      const tmpName = getForm["name"].value;
      const tmpSwiftCode = getForm["swiftCode"].value;
      const findExistingOp = this.eInvoiceList.find(op => {
        return op.name === tmpName && op.swiftCode === tmpSwiftCode
      });
      if(findExistingOp) {
        this.toast.error('An operator with the same name and SWIFT code already existed!','Error!', 6000);
      } else {
        this.eInvoiceAddList.push({
          name: tmpName,
          swiftCode: tmpSwiftCode,
          active: getForm["active"].value
        });
        this.resetAddOperatorForm();
        this.scrollModal("bottom");
        this.unsavedChange = true;
      }
    }
  }
  editOperatorFromAddList(index:number, action:string, event?:any) {
    switch (action) {
      case "remove":
        this.eInvoiceAddList.splice(index, 1);
        break;
      case "toggleActive":
        this.eInvoiceAddList[index].active = event.target.checked;
        break;
    
      default:
        break;
    }
  }

  toggleCurrentActive(id?:number, event?:any) {
    if(id !== undefined) {
      const indexFullOp = this.eInvoiceList.findIndex(op => op.id === id);
      const indexFilteredOp = this.eInvoiceListFiltered.findIndex(op => op.id === id);
      if(indexFilteredOp > -1 && indexFullOp > -1) {
        this.eInvoiceList[indexFullOp].checked = event.target.checked;
        this.eInvoiceListFiltered[indexFilteredOp].checked = event.target.checked;
        this.unsavedChange = true;
      }
    }
  }
  searchCurrentOperators() {
    if(this.operatorSearchKeyword === "") {
      this.eInvoiceListFiltered = this.eInvoiceList;
    } else {
      this.eInvoiceListFiltered = this.eInvoiceList.filter(op => op.name.toLowerCase().includes(this.operatorSearchKeyword.toLowerCase()) || op.swiftCode.toLowerCase().includes(this.operatorSearchKeyword.toLowerCase()));
    }
  }
  saveOperators() {
    this.unsavedChange = false;
    let saveRequest:EInvoiceOperatorType[] = [];
    this.eInvoiceList.forEach(op => {
      if(op.checked !== op.active) {
        let request = JSON.parse(JSON.stringify(op));
        request.active = request.checked;
        delete request.checked;
        saveRequest.push(request);
      }
    })
    saveRequest = saveRequest.concat(this.eInvoiceAddList);
    this.closeCustomModal("eInvoiceListModal");
    this.openLoadingAlert("Saving changes...");
    this.hotelService.updateEInvoiceOperator(saveRequest).subscribe({
      next: () => {
        this.closeLoadingAlert();
        if(this.eInvoiceAddList.length) {
          this.openCustomModal("addOperatorsModal");
        } else {
          this.openResultAlert("success", "Success", "E-invoice operator changes have been saved!");
        }
      },
      error: () => {
        this.openCustomModal("eInvoiceListModal");
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred, please try again later!");
      }
    })
  }
}
