import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastType } from '../custom-toast.component';

@Component({
  selector: 'custom-toast-item',
  templateUrl: './custom-toast-item.component.html',
  styleUrl: './custom-toast-item.component.sass'
})
export class CustomToastItemComponent implements AfterContentInit {
  @ViewChild('toastItem', { static: false }) toastItem!: ElementRef;
  @Input() toastRequest?:ToastType;
  @Output() toastRemoveId = new EventEmitter<number>();
  showToast:boolean = false;
  toastHeight:number = 0;

  ngAfterContentInit(): void {
    if(this.toastRequest) {
      setTimeout(() => {
        const toast = this.toastItem.nativeElement.getBoundingClientRect();
        this.showToast = true;
        this.toastHeight = toast.height;
        setTimeout(() => {
          this.closeToast();
        }, this.toastRequest?.timeOut);
      }, 0);
    }
  }
  closeToast() {
    if(this.toastRequest) {
      this.showToast = false;
      this.toastHeight = 0;
      setTimeout(() => {
        this.toastRemoveId.emit(this.toastRequest?.id)
      }, 550);
    }
  }
}
