import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const hotelAPI = '/api/hotel-chains/1/hotels';
const categoryAPI = '/api/management/secure/maintenance/category';
const roomAPI = '/api/management/secure/maintenance/rooms';
const maintenanceAPI = '/api/management/secure/maintenance';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private http: HttpClient) { }

  public getHotels(): Observable<any> {
    return this.http.get(hotelAPI);
  }

  public getCategories(): Observable<any> {
    return this.http.get(categoryAPI);
  }

  public getMaintenance(request: any): Observable<any> {
    return this.http.get<any>(maintenanceAPI, {params: request});
  }

  public createMaintenance(request: any) {
    return this.http.post<any>(maintenanceAPI, request, {});
  }

  public getRooms(request: any): Observable<any> {
    return this.http.get<any>(roomAPI, {params: request});
  }

  public getMaintenanceInfo(id:any): Observable<any> {
    return this.http.get(maintenanceAPI + '/' + id);
  }

  public cancelMaintenance(id:any): Observable<any> {
    return this.http.delete(maintenanceAPI + '/' + id);
  }
}
