<div class="login-page">
  <div class="login-box">
    <img class="brand-login-logo" src="assets/images/nelson-bg-logo.png">
    <form [formGroup]="forgotForm" (ngSubmit)="submitForgotForm()">
      <div class="password-criteria" style="text-align: center;">
        <div class="container-header-title" style="margin-bottom: 15px;">Forgot password</div>
        Input your email address to start the reset password process
      </div>
      <div class="custom-float-input">
        <input type="text" min="1" formControlName="email" id="email">
        <label>Email address</label>
      </div>
      <button type="submit" [disabled]="loading" class="btn btn-green full-width">Send verification code</button>
      <div class="forgot-btn" style="margin-top: 15px;">
        <a routerLink="/login">Return to login</a>
      </div>
    </form>
  </div>
</div>

<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
  </div>
</custom-modal>
<!-- Reset success -->
<custom-modal
  modalName="resetSuccessAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  alertType="success"
  >
  <div class="custom-modal-body">
    <div class="alert-title">Success</div>
    <div class="alert-desc">Password has been changed. You will be automatically redirected back to login page in 5 seconds.</div>
  </div>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{loadingElements.title}}</div>
    @if (loadingElements.desc) {
      <div class="alert-desc">{{loadingElements.desc}}</div>
    }
  </div>
</custom-modal>

<custom-modal
  modalName="resetPasswordModal"
  modalTitle="Reset password"
  modalOrder="0"
  >
  @if (requestedUsername) {
    <div class="custom-modal-body">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <div class="password-criteria">
          <p>An email with verification code has been sent to <b>{{requestedUsername}}</b>. Please check your spam folder if you didn't receive the code</p>
        </div>
        <div class="custom-float-input">
          <input type="tel" formControlName="code" id="code" autocomplete="off">
          <label>Verification code</label>
        </div>
        <div class="custom-float-input">
          <input type="password" min="1" formControlName="newPassword" id="newPassword">
          <label>New password</label>
        </div>
        <div class="custom-float-input">
          <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword">
          <label>Confirm new password</label>
        </div>
        <div class="password-criteria">
          <p>Password must contain at least:</p>
          <ul>
            <li>At least 8 characters—the more characters, the better</li>
            <li>A mixture of both uppercase and lowercase letters</li>
            <li>Contains at least one number and one special character</li>
          </ul>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"(click)="resendVerificationCode()">Resend code</button>
      <button type="button" [disabled]="loading" class="btn btn-green" (click)="submitResetPasswordForm()">Reset password</button>
    </div>
  }
</custom-modal>