import { AfterViewInit, Component, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import {UTILS} from 'src/app/helpers/utils';
import {CONSTANT} from 'src/app/helpers/constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { SaveETRequestType, UsageService } from 'src/app/services/usage.service';
import { formatDate, Location } from '@angular/common';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import sign from 'jwt-encode';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig, ReservationLabelType } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { ChangeDetectorRef } from '@angular/core';
import { LoadingAlertType, MenuType, ResultAlertType } from '../common/dropdown-menu/dropdown-menu.component';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';

type ViewRoomInfoType = {
  room: any,
  roomIndex: number
}

type ViewRoomCleanType = {
  room: any,
  roomIndex: number
}

type RequestHighlightType = {
  roomIndex: number,
  usageIndex: number
}

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.sass','../../../styles.sass'],
  host: {
    '(window:resize)': 'onResize()',
  }
})

export class UsageComponent implements OnInit, AfterViewInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  datepickerCustom: DatepickerDateCustomClasses[];
  datepickerCustomModal: DatepickerDateCustomClasses[];
  datepickerCustomModalSet: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  @HostListener('document:click', ['$event']) documentClickEvent($event: MouseEvent) {
    this.isShowHotel = false;
    this.isShowHotelPopup = false;
    this.isShowRoomType = false;
    this.isShowHotelECIPopup = false;
    this.isShowCapacity = false;
  }
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public utils: UTILS,
    private modalService: BsModalService,
    private toast: CustomToastService,
    private usageService: UsageService,
    private storeService: StoreService,
    private changeDectector: ChangeDetectorRef
  ) {
    this.datepickerCustom = [];
    this.datepickerCustomModal = [];
    this.datepickerCustomModalSet = [];
  }

  requestedParams:any;
  hotels:any;
  
  capacityList:MenuType[] = [];
  selectedCapacity?:MenuType;

  roomTypeList:MenuType[] = [];
  selectedRoomType?:MenuType;
  hotelRoomTypes:any;

  hotelList:MenuType[] = [];
  selectedHotelItem?:MenuType;
  selectedHotelInfo:any;
  appliedHotel:any;

  products:any;
  lastItemWidth:number = 0;
  pastWidth:number = 0;
  tableWidth:any = 'auto';
  startDatePicker:any;
  endDatePicker:any;
  startDate:any;
  endDate:any;
  allotmentRows:boolean = false;
  showLegend:boolean = false;
  fitTable:boolean = false;
  currentTimezone:any;
  tableLoading:boolean = true;
  isShowHotel:boolean = false;
  isShowHotelPopup:boolean = false;
  isShowRoomType:boolean = false;
  isShowHotelECIPopup:boolean = false;
  isShowCapacity:boolean = false;

  currency: string = "EUR";
  percentLeftShortcut:number = 0;
  percentShortcutLength:number = 100;

  isSearching:boolean = false;
  showResult:boolean = false;
  isFormValid:boolean = false;

  usageData:any;
  availabilityData:any;
  allotmentData:any;
  allDates:any = [];
  selectedUsage:any;

  roomInfo:any;
  roomInfoStatus:any;

  cleanEventData:any = [];
  cleanEvent:any;
  currentRoomUsageStatus: any;
  roomUsageSelected: any;
  roomIndexSelected: any;

  openedModal:any;
  modalDate:any;
  modalData:any;
  modalSelectedType:any;
  modalLoading:boolean = true;
  modalStartDate:any;
  modalEndDate:any;
  modalSetBuffer:number = 1;
  modalSetStartDate:any;
  modalSetEndDate:any;
  eciAction:any;
  failedReAllocationData:any;

  swapRoomRequest:any;
  swapRoomAlert:any = {
    roomFrom: null,
    balanceRoomFrom: 0,
    roomTo: null,
    balanceRoomTo: 0
  };
  changeRoomAlert:any = {
    balanceRoom: 0
  };
  isAllowChangeRoomAcrossRoomTypes: boolean = false;
  isNotifySwapRoomAcrossRoomTypes: boolean = false;
  changeRoomRequest: any;

  userInfo:any;
  token:any = window.localStorage.getItem('jwt');
  isCleaner:boolean = false;
  hasEciLcoRole:boolean = false;
  hasEciLcoEditRole:boolean = false;
  hasAllotRole:boolean = false;
  hasChannelRole:boolean = false;
  hasRoomInfoRole:boolean = false;
  hasReservationRole:boolean = false;
  hasReservationEditRole:boolean = false;
  hasMaintenanceRole:boolean = false;

  dragScrollInterval:any = null;
  triggerHideAvailability: boolean = false;
  disabledBtnWhileCallingAPI: boolean = false;
  lastValidUsageLength:number = 84;
  dirtyRoomList:string = "";
  swapAccrossRoom:boolean = false;
  showAlreadyMarkedModal:boolean = false;

  filterReservationType:any = [
    {
      value: "NORMAL",
      label: "Normal",
      checked: false
    },
    {
      value: "GROUP",
      label: "Group",
      checked: false
    },
  ]
  filterRoomType:any = [];
  filterCapacity:any = [
    {
      value: 1,
      label: "1 guest",
      checked: false
    },
    {
      value: 2,
      label: "2 guests",
      checked: false
    },
    {
      value: 3,
      label: "3 guests",
      checked: false
    },
    {
      value: 4,
      label: "4 guests",
      checked: false
    },
  ]
  filterAvail:any = [
    {
      value: "available",
      label: "Available",
      checked: false
    },
    {
      value: "unavailable",
      label: "Unavailable",
      checked: false
    },
    {
      value: "maintenance",
      label: "Maintenance",
      checked: false
    }
  ]
  filterPayment:any = [
    {
      value: true,
      label: "Paid",
      checked: false
    },
    {
      value: false,
      label: "Unpaid",
      checked: false
    },
  ]
  filterBookingChannel:any = [];
  usageHoverLeft:string = "0%";
  usageHoverTop:string = "-200px";
  showUsageHover:boolean = false;
  selectedHoverUsage:any = null;
  shortcutTopPosition:boolean = false;

  // Popover
  viewRoomInfo?:ViewRoomInfoType;
  viewRoomClean?:ViewRoomCleanType;

  // Edit channel modal
  editExternalThreshold:boolean = false;
  editedExternalThreshold:number = 0;
  editETError:string | null = null;
  
  // shortcut selectedRoom
  rangeDateSelected: any[] = [];
  indexDateSelected: any;
  shortcutRoomIndex?: number;
  shortcutRoomPosIndex?: number;
  shortcutmove: boolean = false;
  allFullDate: any = [];
  shortcutMDCoord: any;
  shortcutMMCoord: any;
  parentSquareItemWidth: any;
  squareItemWidth: any;
  itemResize: any;
  itMoving: boolean = false;
  roomSelected: any = [];
  startDateShortcut: any;
  endDateShortcut: any;
  shortcutErrorMess: any = '';
  disableBooknow: boolean = false;

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];
  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: ''
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }
  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  handleModalEvent(event:string) {
    if(event === "closeModal") {
      this.getUsageData(false);
    }
  }
  openResultAlert(type:string, title:string, desc:string) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  changeFilter(type:string, value:any) {
    switch (type) {
      case "reservationType":
        let filterReservationTypeIndex = this.filterReservationType.findIndex((filter:any) => filter.value === value);
        if(filterReservationTypeIndex > -1) {
          this.filterReservationType[filterReservationTypeIndex].checked = !this.filterReservationType[filterReservationTypeIndex].checked;
        }
        break;
      case "room":
        let filterRoomIndex = this.filterRoomType.findIndex((filter:any) => filter.value === value);
        if(filterRoomIndex > -1) {
          this.filterRoomType[filterRoomIndex].checked = !this.filterRoomType[filterRoomIndex].checked;
        }
        break;
      case "capacity":
        let filterCapacityIndex = this.filterCapacity.findIndex((filter:any) => filter.value === value);
        if(filterCapacityIndex > -1) {
          this.filterCapacity[filterCapacityIndex].checked = !this.filterCapacity[filterCapacityIndex].checked;
        }
        break;
      case "avail":
        let filterAvailIndex = this.filterAvail.findIndex((filter:any) => filter.value === value);
        if(filterAvailIndex > -1) {
          this.filterAvail[filterAvailIndex].checked = !this.filterAvail[filterAvailIndex].checked;
        }
        break;
      case "payment":
        let filterPaymentIndex = this.filterPayment.findIndex((filter:any) => filter.value === value);
        if(filterPaymentIndex > -1) {
          this.filterPayment[filterPaymentIndex].checked = !this.filterPayment[filterPaymentIndex].checked;
        }
        break;
      case "channel":
        let filterChannelIndex = this.filterBookingChannel.findIndex((filter:any) => filter.value === value);
        if(filterChannelIndex > -1) {
          this.filterBookingChannel[filterChannelIndex].checked = !this.filterBookingChannel[filterChannelIndex].checked;
        }
        break;
    
      default:
        break;
    }
  }

  checkFilterAll(type:string) {
    let allChecked = true;
    switch (type) {
      case "reservationType":
        this.filterReservationType.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
      case "room":
        this.filterRoomType.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
      case "capacity":
        this.filterCapacity.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
      case "avail":
        this.filterAvail.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
      case "payment":
        this.filterPayment.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
      case "channel":
        this.filterBookingChannel.every((filter:any) => {
          if(!filter.checked) {
            allChecked = false;
            return false;
          }
          return true;
        })
        break;
    
      default:
        break;
    }
    return allChecked;
  }

  changeFilterAll(type:string, event:any) {
    const checked = event.target.checked;
    switch (type) {
      case "reservationType":
        this.filterReservationType.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
      case "room":
        this.filterRoomType.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
      case "capacity":
        this.filterCapacity.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
      case "avail":
        this.filterAvail.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
      case "payment":
        this.filterPayment.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
      case "channel":
        this.filterBookingChannel.forEach((filter:any) => {
          filter.checked = checked;
        })
        break;
    
      default:
        break;
    }
  }

  clearFilter() {
    this.filterReservationType.forEach((filter:any) => {
      filter.checked = false;
    })
    this.filterRoomType.forEach((filter:any) => {
      filter.checked = false;
    })
    this.filterCapacity.forEach((filter:any) => {
      filter.checked = false;
    })
    this.filterAvail.forEach((filter:any) => {
      filter.checked = false;
    })
    this.filterPayment.forEach((filter:any) => {
      filter.checked = false;
    })
    this.filterBookingChannel.forEach((filter:any) => {
      filter.checked = false;
    })
  }

  updateDatepicker(type:string) {
    switch(type) {
      case 'main':
        if(this.startDatePicker && this.endDatePicker) {
          let custom = [
            {date: this.startDatePicker, classes: ['selectedDate', 'start']},
          ]
          let tmpTime = this.startDatePicker.getTime() + 24*3600*1000;
          while(tmpTime < this.endDatePicker.getTime()) {
            custom.push({
              date: new Date(tmpTime), classes: ['dateRange']
            });
            tmpTime += 24*3600*1000;
          }
          custom.push({date: this.endDatePicker, classes: ['selectedDate', 'end']});
          this.datepickerCustom = custom;
        }
        break;
      case 'modal':
        let customModal = [
          {date: this.modalStartDate, classes: ['selectedDate', 'start']},
        ]
        let tmpTimeModal = this.modalStartDate.getTime() + 24*3600*1000;
        while(tmpTimeModal < this.modalEndDate.getTime()) {
          customModal.push({
            date: new Date(tmpTimeModal), classes: ['dateRange']
          });
          tmpTimeModal += 24*3600*1000;
        }
        customModal.push({date: this.modalEndDate, classes: ['selectedDate', 'end']});
        this.datepickerCustomModal = customModal;
        break;
      case 'modalSet':
        let customModalSet = [
          {date: this.modalSetStartDate, classes: ['selectedDate', 'start']},
        ]
        let tmpTimeModalSet = this.modalSetStartDate.getTime() + 24*3600*1000;
        while(tmpTimeModalSet < this.modalSetEndDate.getTime()) {
          customModalSet.push({
            date: new Date(tmpTimeModalSet), classes: ['dateRange']
          });
          tmpTimeModalSet += 24*3600*1000;
        }
        customModalSet.push({date: this.modalSetEndDate, classes: ['selectedDate', 'end']});
        this.datepickerCustomModalSet = customModalSet;
        break;
    }
  }

  switchTriggerHideAvailability(){
    this.triggerHideAvailability = !this.triggerHideAvailability;
  }

  checkRoles() {
    if(GlobalUserSession) {
      const userRoles = GlobalUserSession.role;
      if(["CLEANER"].includes(userRoles)) {
        this.isCleaner = true;
      }
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "CLEANER", "CUSTOMER_SERVICE"].includes(userRoles)) {
        this.hasMaintenanceRole = true;
      }
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "REVENUE_MANAGER", "CUSTOMER_SERVICE"].includes(userRoles)) {
        this.hasAllotRole = true;
        this.hasEciLcoRole = true;
      }
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "REVENUE_MANAGER"].includes(userRoles)) {
        this.hasChannelRole = true;
        this.hasEciLcoEditRole = true;
      }
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE"].includes(userRoles)) {
        this.hasRoomInfoRole = true;
        this.hasReservationEditRole = true;
      }
      if(["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE", "REVENUE_MANAGER"].includes(userRoles)) {
        this.hasReservationRole = true;
      }
    }
  }

  openEditChannel() {
    this.openLoadingAlert("Loading channel config")
    this.openedModal = 'channel';
    this.editExternalThreshold = false;
    this.modalLoading = true;
    this.modalSetBuffer = 1;
    this.modalStartDate = this.startDatePicker;
    this.modalEndDate = this.endDatePicker;
    this.modalSetStartDate = this.startDatePicker;
    this.modalSetEndDate = this.endDatePicker;

    const findDefaultRoomType = this.roomTypeList.find(roomType => roomType.value === this.hotelRoomTypes[this.selectedHotelInfo.hotelId][0]);
    if(findDefaultRoomType) {
      this.selectRoomType(findDefaultRoomType, () => {
        this.closeLoadingAlert();
        this.openCustomModal("editChannelsModal");
      });
    }
    this.setupModalDate();
  }
  openEditEciLco() {
    this.openLoadingAlert("Loading ECI/LCO...");
    this.openedModal = 'eci';
    this.eciAction = {
      type: 'eci',
      set: 'value',
      setValue: 1,
      weekdays: {
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: true,
        Sun: true,
      }
    }
    this.modalLoading = true;
    this.modalSetBuffer = 1;
    this.modalStartDate = this.startDatePicker;
    this.modalEndDate = this.endDatePicker;
    this.modalSetStartDate = this.startDatePicker;
    this.modalSetEndDate = this.endDatePicker;
    this.setupModalDate();
    this.getEciLco(() => {
      setTimeout(() => {
        this.closeLoadingAlert();
        this.openCustomModal("eciLcoModal");
      }, 500);
    })
  }
  openSwapRoomModal() {
    this.openCustomModal("swapRoomAlert");
  }
  openChangeRoomModal() {
    this.openCustomModal("changeRoomAlert")
  }
  openDirtyChangeRoomModal() {
    this.openCustomModal("dirtyChangeRoomAlert")
  }
  openDirtySwapRoomModal() {
    this.openCustomModal("dirtySwapRoomAlert")
  }
  openFailedReAllocationModal() {
    this.openCustomModal("failedReAllocationAlert")
  }
  openShortcutModal() {
    this.openCustomModal("shortcutAlert")
  }

  toggleEditExternalThreshold() {
    this.editExternalThreshold = !this.editExternalThreshold;
  }
  validateET() {
    const totalRooms = this.modalData.totalRooms;
    const threshold = Number(this.editedExternalThreshold);
    if(!this.editedExternalThreshold) {
      this.editETError = "This field is required!"
    } else if(isNaN(threshold)) {
      this.editETError = "Please input valid number!"
    } else if(threshold < 0) {
      this.editETError = "Threshold cannot be negative!"
    } else if(threshold > totalRooms) {
      this.editETError = `Threshold cannot be greater than total rooms in hotel (${totalRooms})`
    } else {
      this.editETError = null;
    }
  }
  saveExternalThreshold() {
    if(!this.editETError && this.selectedHotelItem) {
      this.closeCustomModal("editChannelsModal");
      this.openLoadingAlert("Saving threshold...");
      const request:SaveETRequestType = {
        hotelId: Number(this.selectedHotelItem.value),
        threshold: Number(this.editedExternalThreshold),
        roomType: this.modalSelectedType
      }
      this.usageService.saveExternalThreshold(request).subscribe(
        data => {
          this.getAvailability(false, () => {
            this.closeLoadingAlert();
            this.openCustomModal("editChannelsModal");
            this.openResultAlert("success", "Success", "The external channel threshold has been updated successfully. The availability data is being updated. Please check the updated availability after a short while.")
          });
        }, err => {
          this.displayAPIerror(err);
          this.closeLoadingAlert();
          this.openCustomModal("editChannelsModal");
        }
      )
    }
  }

  toggleEciWeekday(day:string) {
    this.eciAction.weekdays[day] = !this.eciAction.weekdays[day]
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error.description) errorMessage = err.error.description;
    if(err.error.message) errorMessage = err.error.message;
    this.openResultAlert("error", "Error", errorMessage);
  }

  setupModalDate() {
    this.modalDate = [];
    let tmpDate: any = this.modalStartDate.setHours(0,0,0,0);
    let endDate = this.modalEndDate.setHours(0,0,0,0);
    let dsTime = false
    while(tmpDate <= endDate) {
      let newDate = new Date(tmpDate);
      if(!moment(newDate).tz(this.selectedHotelInfo.zone).isDST()  && dsTime == false){ 
        dsTime = true;
        tmpDate += 3600*1000;
        endDate += 3600*1000;
        newDate = new Date(tmpDate);
      } else if (moment(newDate).tz(this.selectedHotelInfo.zone).isDST() && dsTime == true) {
        dsTime = false;
        tmpDate -= 3600*1000;
        newDate = new Date(tmpDate);
      }
      this.modalDate.push({
            fullDate: formatDate(newDate, 'dd.MM EEE', 'en-US'),
            day: formatDate(newDate, 'EEE', 'en-US')
          });
      tmpDate += 24*3600*1000;
    }
  }

  selectRoomType(item:MenuType, callback: any = null) {
    this.selectedRoomType = item;
    this.modalSelectedType = item.value;
    this.getAvailability(false, () => {
      if (callback) callback();
    });
  }
  getAvailability(hotelChange:boolean = false, callback: any = null) {
    this.modalLoading = true;
    this.editExternalThreshold = false;
    if(hotelChange) {
      const findDefaultRoomType = this.roomTypeList.find(roomType => roomType.value === this.hotelRoomTypes[this.selectedHotelInfo.hotelId][0]);
      if(findDefaultRoomType) {
        this.selectRoomType(findDefaultRoomType, false);
      }
    }
    let usageRequest = {
      'startDate': formatDate(this.modalStartDate, "yyyy-MM-dd", "en-US"),
      'roomType': this.modalSelectedType,
      'endDate': formatDate(this.modalEndDate, "yyyy-MM-dd", "en-US"),
    }
    this.usageService.getAvailabilityChannel(this.selectedHotelInfo.hotelId, usageRequest).subscribe(data => {
      this.modalData = data;
      this.editedExternalThreshold = this.modalData.thresholdMap[this.modalSelectedType];
      this.setupAvailability(this.modalData, true);
      this.modalLoading = false;
      if (callback) callback();
    })
  }

  getEciLco(callback: any = null) {
    this.modalLoading = true;
    let usageRequest = {
      'startDate': formatDate(this.modalStartDate, "yyyy-MM-dd", "en-US"),
      'endDate': formatDate(this.modalEndDate, "yyyy-MM-dd", "en-US"),
    }
    this.usageService.getEciLco(this.selectedHotelInfo.hotelId, usageRequest).subscribe(data => {
      this.modalData = data;
      this.modalLoading = false;
      if (callback) callback();
    })
  }

  checkWeekend(date:any) {
    let newDate = new Date(date);
    let getDay = formatDate(newDate, 'EEE', 'en-US');
    if(getDay == 'Sat' || getDay =='Sun') {
      return true;
    } else {
      return false;
    }
  }

  checkExternalSoldOut(buffer:any, bufferIndex:any) {
    return buffer === this.modalData.channels.BOOKINGCOM[bufferIndex].used + this.modalData.channels.EXPEDIA[bufferIndex].used;
  }
  calculateTable(type:string, buffer:any, bufferIndex:any) {
    switch(type) {
      case 'externalAvailable':
        return buffer - this.modalData.channels.BOOKINGCOM[bufferIndex].used - this.modalData.channels.EXPEDIA[bufferIndex].used;
      case 'nelsonTotal':
        return this.modalData.totalRooms - this.modalData.maintenance.dates[bufferIndex].used - buffer
      case 'nelsonAvailable':
        return this.modalData.totalRooms - this.modalData.maintenance.dates[bufferIndex].used - buffer - this.modalData.channels.NELSON[bufferIndex].used;
      default:
        return 0;
    }
  }

  channelEdit(e:any, bufferIndex:number, date:string) {
    this.validateChannelEdit(e.target.value, bufferIndex, date)
  }
  customChannelEdit(type:string, bufferIndex:number, date:string){
    if(type==='increment'){
      let newValue  = this.modalData.channelAvailabilityBuffers[bufferIndex].buffer + 1;
      this.validateChannelEdit(newValue, bufferIndex, date);
      return;
    }
    let newValue  = this.modalData.channelAvailabilityBuffers[bufferIndex].buffer - 1;
    this.validateChannelEdit(newValue, bufferIndex, date);
  }
  validateChannelEdit(value:number, bufferIndex:number, date:string) {
    const buffer = Number(value);
    const threshold = this.modalData.thresholdMap[this.modalSelectedType];
    const tmpMaxBuffer = this.modalData.totalRooms - threshold;
    const maxBuffer = tmpMaxBuffer >= 0 ? tmpMaxBuffer : 0;
    const minTotal = this.modalData.channels.BOOKINGCOM[bufferIndex].used + this.modalData.channels.EXPEDIA[bufferIndex].used;
    if(buffer > maxBuffer || isNaN(buffer)) {
      this.modalData.channelAvailabilityBuffers[bufferIndex].buffer = maxBuffer;
    } else if(buffer < minTotal) {
      this.modalData.channelAvailabilityBuffers[bufferIndex].buffer = minTotal;
    } else {
      this.modalData.channelAvailabilityBuffers[bufferIndex].buffer = buffer;
    }
    // const allValueOfDate = document.querySelectorAll("#ex-"+date);
    // allValueOfDate.forEach((item:any) => {
    //   item.classList.add('trigger-change');
    // });
    // setTimeout(() => {
    //   allValueOfDate.forEach((item:any) => {
    //     item.classList.remove('trigger-change');
    //   });
    // }, 100);
  }

  eciEdit(e:any, infoIndex:number, date:string) {
    this.validateEciEdit(e.target.value, infoIndex, date);
  }
  customEciEdit(type:string, infoIndex:number, date:string){
    if(type==='increment'){
      let newValue = this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer + 1;
      this.validateEciEdit(newValue, infoIndex, date);
      return
    }
    let newValue = this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer - 1;
    this.validateEciEdit(newValue, infoIndex, date);
  }
  validateEciEdit(value:number, infoIndex:number, date:string) {
    let info = Number(value);
    let commonBuffer = this.modalData.commonBuffer;
    let lcoBuffer = this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer;
    if(info > commonBuffer || isNaN(info)) {
      this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer = commonBuffer;
      this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer = 0;
      this.flashChange("eci-"+date);
    } else if(info + lcoBuffer > commonBuffer) {
      this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer = commonBuffer - this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer;
      this.flashChange("lco-"+date);
    } else {
      this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer = info;
    }
    this.flashChange("ecilco-"+date);
    this.flashChange("eci-left-"+date);
  }

  lcoEdit(e:any, infoIndex:number, date:string) {
    this.validateLcoEdit(e.target.value, infoIndex, date);
  }
  customLcoEdit(type:string, infoIndex:number, date:string){
    if(type==='increment'){
      let newValue = this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer + 1;
      this.validateLcoEdit(newValue, infoIndex, date);
      return
    }
    let newValue = this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer - 1;
    this.validateLcoEdit(newValue, infoIndex, date);
  }
  validateLcoEdit(value:number, infoIndex:number, date:string) {
    let info = Number(value);
    let commonBuffer = this.modalData.commonBuffer;
    let eciBuffer = this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer;
    if(info > commonBuffer || isNaN(info)) {
      this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer = commonBuffer;
      this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer = 0;
    } else if(info + eciBuffer > commonBuffer) {
      this.modalData.infos[infoIndex].eciInfos.pm200.guaranteedBuffer = commonBuffer - this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer;
      this.flashChange("eci-"+date);
    } else {
      this.modalData.infos[infoIndex].lcoInfos.pm200.guaranteedBuffer = info;
    }
    this.flashChange("ecilco-"+date);
    this.flashChange("lco-left-"+date);
  }

  flashChange(className:string) {
    let element = document.querySelector('#' + className);
    element?.classList.add('trigger-change');
    setTimeout(() => {
      element?.classList.remove('trigger-change');
    }, 100);
  }
  convertToNumber(value:string) {
    const converted = Number(value);
    return isNaN(converted) ? 0 : converted;
  }

  modalDateChange(type:string, modal:string) {
    switch(type) {
      case 'startDate':
        if(this.modalStartDate) {
          this.modalSetStartDate = this.modalStartDate;
        }
        if(this.modalStartDate && this.modalEndDate && this.modalStartDate.getTime() > this.modalEndDate.getTime()) {
          this.modalEndDate = new Date(this.modalStartDate.getTime() + 24*3600*1000);
        }
        this.validateModalDate(modal);
        this.updateDatepicker('modal');
        break;
      case 'endDate':
        if(this.modalEndDate) {
          this.modalSetEndDate = this.modalEndDate;
        }
        if(this.modalStartDate && this.modalEndDate && this.modalStartDate.getTime() > this.modalEndDate.getTime()) {
          this.modalStartDate = new Date(this.modalEndDate.getTime() - 24*3600*1000);
        }
        this.validateModalDate(modal);
        this.updateDatepicker('modal');
        break;
      case 'setStartDate':
        if(this.modalSetStartDate && this.modalSetEndDate && this.modalSetStartDate.getTime() > this.modalSetEndDate.getTime()) {
          this.modalSetEndDate = new Date(this.modalSetStartDate.getTime() + 24*3600*1000);
        }
        this.updateDatepicker('modalSet')
        break;
      case 'setEndDate':
        if(this.modalSetStartDate && this.modalSetEndDate && this.modalSetStartDate.getTime() > this.modalSetEndDate.getTime()) {
          this.modalSetStartDate = new Date(this.modalSetEndDate.getTime() - 24*3600*1000);
        }
        this.updateDatepicker('modalSet')
        break;
    }
  }
  validateModalDate(modal:string) {
    if(this.modalStartDate && this.modalEndDate && this.modalStartDate != "Invalid Date" && this.modalEndDate != "Invalid Date") {
      switch (modal) {
        case "channel":
          this.getAvailability(false, () => {
            this.setupModalDate();
          })
          break;
        case "eciLco":
          this.getEciLco(() => {
            this.setupModalDate();
          })
          break;
        default:
          break;
      }
    }
  }

  modalTableScroll(type:string) {
    let table1:any = document.querySelector<HTMLInputElement>('#table1');
    let table2:any = document.querySelector<HTMLInputElement>('#table2');
    let table3:any = document.querySelector<HTMLInputElement>('#table3');
    switch(type) {
      case 'table1':
        let table1Scrolled = table1?.scrollLeft;
        if(table2) table2.scrollLeft = table1Scrolled;
        if(table3) table3.scrollLeft = table1Scrolled;
        break;
      case 'table2':
        let table2Scrolled = table2?.scrollLeft;
        if(table1) table1.scrollLeft = table2Scrolled;
        if(table3) table3.scrollLeft = table2Scrolled;
        break;
      case 'table3':
        let table3Scrolled = table3?.scrollLeft;
        if(table2) table2.scrollLeft = table3Scrolled;
        if(table1) table1.scrollLeft = table3Scrolled;
        break;
      default:
        console.error('Invalid request');
    }
  }

  setMultipleBuffer() {
    let dateRange:any = [];
    let tmpDate:any = this.modalSetStartDate.setHours(0,0,0,0);
    while(tmpDate <= this.modalSetEndDate.setHours(0,0,0,0)) {
      let newDate = new Date(tmpDate);
      dateRange.push(formatDate(newDate, 'yyyy-MM-dd', 'en-US'));
      tmpDate += 24*3600*1000;
    }
    this.modalData.channelAvailabilityBuffers.forEach((buffer:any, bufferIndex:number) => {
      if(dateRange.includes(buffer.date)) {
        this.validateChannelEdit(this.modalSetBuffer, bufferIndex, buffer.date);
      }
    });
  }
  customSetBuffer(type:string){
    if(type==='increment'){
      this.modalSetBuffer +=1;
      return;
    }
    this.modalSetBuffer -=1;
  }

  customSetBufferEciLco(type:string){
    if(type==='increment'){
      this.eciAction.setValue +=1;
      return;
    }
    this.eciAction.setValue -=1;
  }

  saveBufferChange(close:boolean = false) {
    this.closeCustomModal("editChannelsModal");
    this.openLoadingAlert("Saving changes...");
    this.disabledBtnWhileCallingAPI = true;
    let dtos:any = [];
    this.modalData.channelAvailabilityBuffers.forEach((buffer:any) => {
      dtos.push({
        buffer: buffer.buffer,
        date: buffer.date,
        hotelId: this.selectedHotelInfo.hotelId
      })
    });
    let request = {
      dtos: dtos,
      roomType: this.modalSelectedType
    }
    this.usageService.saveBufferChange(request).subscribe(
      data => {
        this.closeLoadingAlert();
        this.toast.success('Channels information saved!', 'Success!');
        if(close) {
          this.openResultAlert("success", "Success", "Channels information has been saved")
        } else {
          this.openCustomModal("editChannelsModal");
        }
        this.disabledBtnWhileCallingAPI = false;
        this.getAvailability();
      },
      err => {
        this.closeLoadingAlert();
        this.displayAPIerror(err);
        this.openCustomModal("editChannelsModal");
        this.disabledBtnWhileCallingAPI = false;
      }
    )
  }

  getMaxNumber(info:any) {
    return Math.max(info.pm200.guaranteedBuffer - info.pm200.used, 0)
  }

  applyChange() {
    let weekdayRange = Object.keys(this.eciAction.weekdays).filter(w => this.eciAction.weekdays[w]);
    let dateRange:any = [];
    let tmpDate:any = this.modalSetStartDate.setHours(0,0,0,0);
    while(tmpDate <= this.modalSetEndDate.setHours(0,0,0,0)) {
      let newDate = new Date(tmpDate);
      dateRange.push(formatDate(newDate, 'yyyy-MM-dd', 'en-US'));
      tmpDate += 24*3600*1000;
    }
    let infoType:any;
    if(this.eciAction.type == 'eci') {
      infoType = 'eciInfos';
    } else {
      infoType = 'lcoInfos';
    }
    this.modalData.infos.forEach((info:any, infoIndex:number) => {
      if(dateRange.includes(info.date) && weekdayRange.includes(formatDate(info.date, 'EEE', 'en-US'))) {
        switch(this.eciAction.set) {
          case 'value':
            if(this.eciAction.type == 'eci') {
              this.validateEciEdit(this.eciAction.setValue, infoIndex, info.date);
            } else {
              this.validateLcoEdit(this.eciAction.setValue, infoIndex, info.date);
            }
            break;
          case 'close':
            info[infoType].pm200.enabled = false;
            this.flashChange(this.eciAction.type + '-enable-' + info.date);
            break;
          case 'open':
            info[infoType].pm200.enabled = true;
            this.flashChange(this.eciAction.type + '-enable-' + info.date);
            break;
        }
      }
    });
  }
  saveEciChange(close:boolean = false) {
    this.closeCustomModal("eciLcoModal");
    this.openLoadingAlert("Saving changes...");
    this.disabledBtnWhileCallingAPI = true;
    let request:any = [];
    this.modalData.infos.forEach((info:any) => {
      request.push({
        date: info.date,
        hotelId: this.selectedHotelInfo.hotelId,
        eciEnabled: info.eciInfos.pm200.enabled,
        eciGuaranteedBuffer: info.eciInfos.pm200.guaranteedBuffer,
        lcoEnabled: info.lcoInfos.pm200.enabled,
        lcoGuaranteedBuffer: info.lcoInfos.pm200.guaranteedBuffer,
      })
    });
    this.usageService.saveEciLcoChange(request).subscribe(
      data => {
        this.closeLoadingAlert();
        if(close) {
          this.closeCustomModal("eciLcoModal");
          this.openResultAlert("success", "Success", "Early check-in/Late check-out has been updated");
        } else {
          this.toast.success("Early check-in/Late check-out has been updated", "Success")
          this.openCustomModal("eciLcoModal");
        }
        this.disabledBtnWhileCallingAPI = false;
      },
      err => {
        this.closeLoadingAlert();
        if(close) {
          this.openCustomModal("eciLcoModal");
        }
        this.displayAPIerror(err);
        this.disabledBtnWhileCallingAPI = false;
      }
    )
  }

  ngOnInit(): void {
    this.checkRoles();
    this.getHotels();
    this.checkChangeRoomAcrossRoomTypes();
  }

  checkChangeRoomAcrossRoomTypes(): void {
    this.usageService.checkChangeRoomAcrossRoomTypes().subscribe(data => {
      this.isAllowChangeRoomAcrossRoomTypes = data;
    });
  }

  selectCapacity(item:MenuType) {
    this.roomInfoStatus.capacity = Number(item.value);
    this.selectedCapacity = item;
  }

  checkParams() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkBasicParams = ['hotelId', 'startDate', 'endDate'].every((key:any) => paramKeys.includes(key))
      if(checkBasicParams) {
        this.startDatePicker = new Date(params.get('startDate')!);
        this.endDatePicker = new Date(params.get('endDate')!);
        const findHotel = this.hotels.find((hotel:any) => {
          return hotel.hotelId == params.get('hotelId');
        });
        if(findHotel) {
          const findHotelMenu = this.hotelList.find(hotel => hotel.value === findHotel.hotelId.toString());
          if(findHotelMenu) {
            this.selectHotel(findHotelMenu)
          }
          this.appliedHotel = findHotel;
          this.updateShortcutOffset();
        } else {
          window.open("/usage","_self")
        }
        this.requestedParams = {
          reservationUuid: params.get('reservationUuid'),
          roomRequestId: params.get('roomRequestId'),
          maintenanceReservationId: params.get('maintenanceReservationId'),
          allotmentId: params.get('allotmentId')
        }
      } else {
        this.selectHotel(this.hotelList[0])
        this.appliedHotel = this.hotels[0];
        this.startDatePicker = new Date(new Date().getTime() - 24*3600*1000);
        this.endDatePicker = new Date(new Date().getTime() + 14*24*3600*1000);
      }
      this.getUsageData();
    });
  }
  hoverUsage(roomIndex:number, usageIndex:number) {
    let selectedUsage = _.cloneDeep(this.usageData[roomIndex].usages[usageIndex]);
    if(selectedUsage.comments && selectedUsage.comments.length) {
      selectedUsage.comments.sort((a:any, b:any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    } else {
      selectedUsage.comments = null;
    }
    this.selectedHoverUsage = selectedUsage;
    const selectUsage:any = document.querySelector(`.usage-item .item-row[roomindex="${roomIndex}"]`);
    const selectHover:any = document.querySelector(`.custom-usage-hover`);
    this.showUsageHover = true;
    setTimeout(() => {
      if(selectUsage && selectHover) {
        this.usageHoverTop = `calc(${selectUsage.offsetTop - selectHover.offsetHeight}px + 7px + 301px)`;
        this.usageHoverLeft = `calc(${( Number(selectedUsage.usageDisplay.usageLength.left.split("%")[0]) + ((Number(selectedUsage.usageDisplay.usageLength.width.split("%")[0]) / 2)) )}% - ${selectHover.offsetWidth / 2}px)`;
      }
    }, 50);
  }
  hoverUsageLeave() {
    this.showUsageHover = false;
  }
  hoverUsageDirect() {
    this.showUsageHover = true;
  }
  highlightUsage(roomIndex:number, usageIndex:number) {
    let selectedUsage = this.usageData[roomIndex].usages[usageIndex];
    let type = selectedUsage.usageDisplay.usageColorClass;
    let hotelId = this.appliedHotel.hotelId;
    let stringStartDate = formatDate(this.startDatePicker, "yyyy-MM-dd", "en-US");
    let stringEndDate = formatDate(this.endDatePicker, "yyyy-MM-dd", "en-US");
    let usageParams:any = {
      hotelId: hotelId,
      startDate: stringStartDate,
      endDate:stringEndDate,
    };
    if(type == 'maintenance') {
      this.selectedUsage = type + selectedUsage.maintenanceReservationId;
      usageParams.maintenanceReservationId = selectedUsage.maintenanceReservationId;
    } else if(type == 'allotment') {
      this.selectedUsage = type + selectedUsage.allotmentId;
      usageParams.allotmentId = selectedUsage.allotmentId;
    } else {
      this.selectedUsage = selectedUsage.reservationUuid;
      usageParams.reservationUuid = selectedUsage.reservationUuid;
      usageParams.roomRequestId = selectedUsage.roomRequestId;
    }
    let url = this.router.createUrlTree(['/usage'], {queryParams: usageParams}).toString();
    this.location.go(url);
  }

  onResize() {
    this.setTableWidth();
  }

  toggleAllotment() {
    this.allotmentRows = !this.allotmentRows;
  }

  toggleLegend() {
    this.showLegend = !this.showLegend;
  }

  getHotels(): void {
    this.usageService.getProducts().subscribe(data => {
      this.products = data;
    })
    this.usageService.getHotels().subscribe(data => {
      this.usageService.getHotelRoomTypes().subscribe(response => {
        this.hotels = data;

        let tmpHotelList:MenuType[] = [];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
            value: hotel.hotelId.toString(),
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;

        let tmpCapacityList:MenuType[] = [];
        CONSTANT.CAPACITIES.forEach(item => {
          tmpCapacityList.push({
            label: item.toString(),
            value: item.toString(),
            disabled: false
          })
        })
        this.capacityList = tmpCapacityList;
        
        this.hotelRoomTypes = response;
        this.checkParams();
      })
    });
  }
  convertDate(date:any) {
    return moment(date).tz(this.appliedHotel.zone).format('DD.MM.YYYY HH:mm');
  }

  getSelectedHotelDataFromMenu(item:MenuType) {
    const findHotel = this.hotels.find((hotel:any) => hotel.hotelId === Number(item.value));
    if(findHotel) {
      return findHotel;
    } else {
      return null
    }
  }
  selectHotel(item:MenuType, channel:boolean = false) {
    this.selectedHotelItem = item;
    const getHotelInfo = this.getSelectedHotelDataFromMenu(item);
    if(getHotelInfo) {
      this.selectedHotelInfo = getHotelInfo;
      let tmpRoomTypeList:MenuType[] = [];
      this.hotelRoomTypes[getHotelInfo.hotelId].forEach((roomType:any) => {
        tmpRoomTypeList.push({
          label: this.utils.showRoomType(roomType),
          value: roomType,
          disabled: false
        })
      })
      this.roomTypeList = tmpRoomTypeList;
    }
    if(channel) {
      switch(this.openedModal) {
        case 'channel':
          this.getAvailability(true);
          break;
        case 'eci':
          this.getEciLco();
          break;
      }
    }
  }
  updateShortcutOffset() {
    let checkInHour = Number(this.appliedHotel.defaultCheckInTime.split(":")[0]);
    let checkInMinute = Number(this.appliedHotel.defaultCheckInTime.split(":")[1]);
    let checkOutHour = Number(this.appliedHotel.defaultCheckOutTime.split(":")[0]);
    let checkOutMinute = Number(this.appliedHotel.defaultCheckOutTime.split(":")[1]);
    this.percentLeftShortcut = (((checkInHour * 60) + checkInMinute) * 60) / 86400 * 100;
    this.percentShortcutLength = (86400 - (((checkInHour * 60) + checkInMinute) * 60) + (((checkOutHour * 60) + checkOutMinute) * 60)) / 86400 * 100;
  }
  getUsageWithFilter(showLoading:boolean = true) {
    let filterRequest:any = {
      roomType: [],
      reservationType: [],
      capacity: [],
      bookingChannel: [],
      available: undefined,
      maintenance: undefined,
      paid: undefined,
    };
    this.filterRoomType.forEach((filter:any) => {
      if(filter.checked) {
        filterRequest.roomType.push(filter.value);
      }
    })
    this.filterReservationType.forEach((filter:any) => {
      if(filter.checked) {
        filterRequest.reservationType.push(filter.value);
      }
    })
    this.filterCapacity.forEach((filter:any) => {
      if(filter.checked) {
        filterRequest.capacity.push(filter.value);
      }
    })
    if(!this.checkFilterAll("avail")) {
      let available = false;
      let unavailable = false;
      let maintenance = undefined;
      this.filterAvail.forEach((filter:any) => {
        if(filter.checked) {
          if(filter.value === "available") {
            available = true;
          }
          if(filter.value === "unavailable") {
            unavailable = true;
          }
          if(filter.value === "maintenance") {
            maintenance = true;
          }
        }
      });
      filterRequest.available = (!available && !unavailable) || (available && unavailable) ? undefined : (available ? true : (unavailable ? false : false));
      filterRequest.maintenance = maintenance;
    }
    if(!this.checkFilterAll("payment")) {
      let selectedFilter = undefined;
      this.filterPayment.forEach((filter:any) => {
        if(filter.checked) {
          selectedFilter = filter.value;
        }
      });
      filterRequest.paid = selectedFilter;
    }
    this.filterBookingChannel.forEach((filter:any) => {
      if(filter.checked) {
        filterRequest.bookingChannel.push(filter.value);
      }
    })
    this.getUsageData(showLoading, filterRequest)
  }
  getUsageData(applied:boolean = false, filter?:any) {
    this.updateShortcutOffset();
    this.disabledBtnWhileCallingAPI = true;
    if(applied) {
      this.tableLoading = true;
      this.availabilityData = null;
    }
    if(!filter) {
      this.appliedHotel = this.selectedHotelInfo;
      this.currency = this.selectedHotelInfo.currency
      moment.tz.setDefault(this.selectedHotelInfo.zone);
      let tmpRoomTypeFilter:any = [];
      this.hotelRoomTypes[this.appliedHotel.hotelId].forEach((roomType:any) => {
        tmpRoomTypeFilter.push({
          value: roomType,
          label: roomType,
          checked: false
        })
      })
      this.filterRoomType = tmpRoomTypeFilter;

      let tmpChannelFilter:any = [
        {
          value: "NELSON",
          label: "Web",
          checked: false
        },
        {
          value: "MOBILEAPP",
          label: "MobileApp",
          checked: false
        }
      ]
      CONSTANT.EXTERNAL_CHANNEL_BOOKING.forEach(channel => {
        if(this.hotelConfig.MUIfeature.OTAServices.includes(channel.value)) {
          tmpChannelFilter.push({
            value: channel.value,
            label: channel.label,
            checked: false
          });
        }
      })
      this.filterBookingChannel = tmpChannelFilter

      this.clearFilter();
    }
    let hotelId = this.appliedHotel.hotelId;
    let stringStartDate = formatDate(this.startDatePicker, "yyyy-MM-dd", "en-US");
    let stringEndDate = formatDate(this.endDatePicker, "yyyy-MM-dd", "en-US");
    let usageParams:any = {
      hotelId: hotelId,
      startDate: stringStartDate,
      endDate:stringEndDate,
    };
    if(this.requestedParams) {
      if(this.requestedParams.reservationUuid) {
        usageParams.reservationUuid = this.requestedParams.reservationUuid;
        if(this.requestedParams.roomRequestId) {
          usageParams.roomRequestId = this.requestedParams.roomRequestId;
        }
      }
      if(this.requestedParams.maintenanceReservationId) {
        usageParams.maintenanceReservationId = this.requestedParams.maintenanceReservationId;
      }
      if(this.requestedParams.allotmentId) {
        usageParams.allotmentId = this.requestedParams.allotmentId;
      }
    }
    let url = this.router.createUrlTree(['/usage'], {queryParams: usageParams}).toString();
    this.location.go(url);
    let usageMainRequest:any = {
      'startDate': stringStartDate,
      'endDate': stringEndDate,
    }
    let usageRequest = {
      'startDate': stringStartDate,
      'endDate': stringEndDate,
    }
    let allotmentRequest = {
      'hotelIds': hotelId,
      'start': stringStartDate,
      'end': stringEndDate,
    }
    if(filter) {
      if(filter.available !== undefined) {
        usageMainRequest.available = filter.available;
      }
      if(filter.maintenance !== undefined) {
        usageMainRequest.maintenance = filter.maintenance;
      }
      if(filter.paid !== undefined) {
        usageMainRequest.paid = filter.paid;
      }
      if(filter.roomType.length) {
        usageMainRequest.roomType = filter.roomType;
      }
      if(filter.capacity.length) {
        usageMainRequest.capacity = filter.capacity;
      }
      if(filter.bookingChannel.length) {
        usageMainRequest.bookingChannel = filter.bookingChannel;
      }
      if(filter.reservationType.length) {
        usageMainRequest.reservationType = filter.reservationType;
      }
    }
    this.startDate = this.startDatePicker;
    this.endDate = this.endDatePicker;
    this.setupDate();
    this.getPastLength();
    this.usageService.getUsage(hotelId, usageMainRequest).subscribe(
      (data) => {
        this.tableLoading = false;
        this.disabledBtnWhileCallingAPI = false;
        this.setupUsage(data);
        setTimeout(() => {
          this.setTableWidth();
          // if(this.modalRef) {
          //   this.modalRef.hide();
          // }
        }, 100);
      }
    )
    this.usageService.getAvailability(hotelId, usageRequest).subscribe(
      (data) => {
        this.availabilityData = data;
        this.setupAvailability(data);
      }
    )
    if(!this.isCleaner && this.hasAllotRole) {
      this.usageService.getAllotment(allotmentRequest).subscribe(
        (data) => {
          this.allotmentData = data;
          this.setupAllotment();
        }
      )
    }
  }

  setupDate() {
    this.allDates = [];
    this.allFullDate = [];
    let tmpDate:any = this.startDatePicker.setHours(0,0,0,0);
    let endDate = this.endDatePicker.setHours(0,0,0,0);
    let dsTime = false
    while(tmpDate <= endDate) {
      let newDate = new Date(tmpDate);
      if(!moment(newDate).tz(this.appliedHotel.zone).isDST()  && dsTime == false){ 
        dsTime = true;
        tmpDate += 3600*1000;
        endDate += 3600*1000;
        newDate = new Date(tmpDate);
      } else if (moment(newDate).tz(this.appliedHotel.zone).isDST() && dsTime == true) {
        dsTime = false;
        tmpDate -= 3600*1000;
        newDate = new Date(tmpDate);
      }
      this.allDates.push(formatDate(newDate, 'EEEEEE dd.MM', 'en-US'))
      this.allFullDate.push(formatDate(newDate, 'yyyy-MM-dd', 'en-US'))
      tmpDate += 24*3600*1000;
    }
  }
  setupAvailability(availabilityData:any, channel:boolean = false) {
    let maintenance:any = [];
    for(let property in availabilityData.maintenance) {
      if(availabilityData.maintenance.hasOwnProperty(property)) {
        maintenance.unshift({
          date: property,
          used: availabilityData.maintenance[property]
        })
      }
    }
    availabilityData.maintenance = {
      label: 'Maintenance',
      dates: this.sortAvailabilityByDate(maintenance),
    }
    availabilityData.channelAvailabilityBuffers = this.sortAvailabilityByDate(availabilityData.channelAvailabilityBuffers);
    availabilityData.reserved = this.sortAvailabilityByDate(availabilityData.reserved);
    availabilityData.channelAvailabilityBuffers.forEach((buffer:any, bufferIndex:number) => {
      buffer.currentBuffer = buffer.buffer;
      buffer.buffer = buffer.lastBufferSetting;
      buffer.modified = buffer.currentBuffer !== buffer.buffer;
      if(channel) {
        buffer.maxBuffer = Math.max(availabilityData.reserved[bufferIndex].totalAvailableRoom - availabilityData.thresholdMap[this.modalSelectedType], 0) + availabilityData.channels.BOOKINGCOM[bufferIndex].used + availabilityData.channels.EXPEDIA[bufferIndex].used;
      }
    });
  }
  sortAvailabilityByDate(avail:any) {
    let availability = avail.map((a:any) => {
      a.date = formatDate(a.date, "yyyy-MM-dd", "en-US");
      return a;
    });
    availability.sort((a1:any, a2:any) => {
      return new Date(a1.date).getTime() - new Date(a2.date).getTime();
    });
    return availability;
  }
  setupAllotment() {
    let startDate = new Date(this.startDate).setHours(0,0,0,0);
    let endDate = new Date(this.endDate).setHours(0,0,0,0);
    let currentDate = startDate;
    let dsTime = false;
    let allFullDates:any = [];
    while(currentDate <= endDate) {
      if(!moment(currentDate).tz(this.appliedHotel.zone).isDST()  && dsTime == false){ 
        dsTime = true;
        currentDate += 3600*1000;
        endDate += 3600*1000;
      } else if (moment(currentDate).tz(this.appliedHotel.zone).isDST() && dsTime == true) {
        dsTime = false;
        currentDate -= 3600*1000;
      }
      allFullDates.push(formatDate(currentDate, "yyyy-MM-dd", "en-US"));
      currentDate += 24*3600*1000;
    }
    this.allotmentData.forEach((data:any) => {
      let allDate = data.hotelAllotmentDates.map((allotDate:any) => {
        let dateTime = moment(allotDate.date).tz(this.appliedHotel.zone).format('YYYY-MM-DD');
        return dateTime;
      })
      let missingDate:any = allFullDates.filter((date:any) => {
        return !allDate.includes(date);
      })
      missingDate.forEach((date:any) => {
        data.hotelAllotmentDates.push({
          availability: null,
          date: date,
          used: null
        })
      });
      data.hotelAllotmentDates.sort((a1:any, a2:any) => {
        return new Date(a1.date).getTime() - new Date(a2.date).getTime();
      })
    });
  }

  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.startDatePicker != "Invalid Date" && this.endDatePicker != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDatePicker && this.endDatePicker && this.startDatePicker.getTime() > this.endDatePicker.getTime()) {
          this.endDatePicker = new Date(this.startDatePicker.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.startDatePicker && this.endDatePicker && this.startDatePicker.getTime() > this.endDatePicker.getTime()) {
          this.startDatePicker = new Date(this.endDatePicker.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker('main');
    this.validateDate();
  }

  setupUsage(data:any) {
    let tmpUsageData = data;
    let requestHighlight:RequestHighlightType | undefined;
    tmpUsageData.forEach((room:any, roomIndex:number) => {
      let product = _.find(this.products, (product:any) => {
        return room.productId == product.productId;
      });
      room.productName = product.name;
      room.usages.forEach((usage:any, usageIndex:number) => {
        usage.usageDisplay = this.getUsageInfo(usage, room);
        usage.labelDisplay = this.getUsageLabels(usage);
        let findUsage = false;
        if(this.requestedParams) {
          if(this.requestedParams.reservationUuid && usage.reservationUuid == this.requestedParams.reservationUuid) {
            findUsage = true;
          } else if (this.requestedParams.maintenanceReservationId && usage.maintenanceReservationId == this.requestedParams.maintenanceReservationId) {
            findUsage = true;
          } else if (this.requestedParams.allotmentId && usage.allotmentId == this.requestedParams.allotmentId) {
            findUsage = true;
          }
        }
        if(findUsage) {
          requestHighlight = {
            roomIndex: roomIndex,
            usageIndex: usageIndex
          }
        }
      });
    });
    this.usageData = tmpUsageData;
    if(requestHighlight) {
      this.highlightUsage(requestHighlight.roomIndex, requestHighlight.usageIndex);
    }
  }

  setLastItem() {
    this.lastItemWidth = 0;
    let dateWidth:any = document.querySelector<HTMLInputElement>('.usage-table .table-head .date-row')?.scrollWidth;
    let rowWidth:any = document.querySelector<HTMLInputElement>('.usage-table .table-head #normal-row')?.scrollWidth;
    this.lastItemWidth = dateWidth - rowWidth;
  }
  toggleTableSetting() {
    this.fitTable = !this.fitTable
    this.setTableWidth(true);
  }
  setTableWidth(bypass:boolean = false) {
    this.tableWidth = 'auto';
    this.lastItemWidth = 0;
    if(!bypass) {
      this.fitTable = false;
    }

    setTimeout(() => {
      let tableContainerWidth:any = document.querySelector<HTMLInputElement>('.usage-table')?.scrollWidth;
      let dateWidth:any = document.querySelector<HTMLInputElement>('.usage-table .table-head .date-row')?.scrollWidth;
      let rowWidth:any = document.querySelector<HTMLInputElement>('.usage-table .table-head #normal-row')?.scrollWidth;
      
      this.lastItemWidth = dateWidth - rowWidth;
      this.tableWidth = dateWidth + 'px';
      if(tableContainerWidth == rowWidth && bypass) {
        this.fitTable = true;
      }
      if(tableContainerWidth == rowWidth && !bypass) {
        this.fitTable = true;
      }
    }, 0);
  }
  tableScroll(type:string) {
    let tableHead:any = document.querySelector<HTMLInputElement>('.usage-table .table-head-container');
    let tableBody:any = document.querySelector<HTMLInputElement>('.usage-table .table-body-container');
    let dummyContent:any = document.querySelector<HTMLInputElement>('.usage-table .floating-scrollbar');
    let usageHover:any = document.querySelector<HTMLInputElement>('.usage-table .custom-usage-container');
    switch(type) {
      case 'head':
        let tableHeadScrolled = tableHead?.scrollLeft;
        if(dummyContent) dummyContent.scrollLeft = tableHeadScrolled;
        if(tableBody) tableBody.scrollLeft = tableHeadScrolled;
        if(usageHover) usageHover.scrollLeft = tableHeadScrolled;
        break;
      case 'body':
        let tableBodyScrolled = tableBody?.scrollLeft;
        if(tableHead) tableHead.scrollLeft = tableBodyScrolled;
        if(dummyContent) dummyContent.scrollLeft = tableBodyScrolled;
        if(usageHover) usageHover.scrollLeft = tableBodyScrolled;
        break;
      case 'dummy':
        let dummyScrolled = dummyContent?.scrollLeft;
        if(tableHead) tableHead.scrollLeft = dummyScrolled;
        if(tableBody) tableBody.scrollLeft = dummyScrolled;
        if(usageHover) usageHover.scrollLeft = dummyScrolled;
        break;
      case 'hover':
        let hoverScrolled = usageHover?.scrollLeft;
        if(tableHead) tableHead.scrollLeft = hoverScrolled;
        if(tableBody) tableBody.scrollLeft = hoverScrolled;
        if(dummyContent) dummyContent.scrollLeft = hoverScrolled;
        break;
      default:
        console.log("Invalid request")
    }
  }

  getPastLength() {
    let startDate = moment(formatDate(this.startDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD').unix();
    let endDate = moment(formatDate(this.endDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD').endOf('day').unix()
    let currentTime = moment().unix();
    if(currentTime <= startDate) {
      this.pastWidth = 0;
    } else if (currentTime >= endDate) {
      this.pastWidth = 100;
    } else {
      this.pastWidth = ((currentTime - startDate) * 100)/(endDate - startDate)
    }
  }

  isPendingUsage(usageState:any) {
    return (usageState === 'PENDING_CONFIRMATION' || usageState === 'BLOCKED');
  }
  isMismatchCapacity(numberOfGuests:any, roomCapacity:any) {
    return numberOfGuests > roomCapacity;
  }
  dateDiffInDays(start:string, end:string) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const startDate:Date = new Date(start);
    const endDate:Date = new Date(end);
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  getUsageColorClass(usage:any, room:any) {
    if(this.isMismatchCapacity(usage.numberOfGuests, room.capacity)) {
      return 'mismatch';
    } else if(this.isPendingUsage(usage.reservationState)) {
      return 'pending';
    } else if(_.isNumber(usage.roomRequestId)) {
      if(usage.permanent) {
        return 'permanent';
      }
      if(usage.reservationState === "INITIAL") {
        return 'initial';
      } else {
        const isLongTerm = this.dateDiffInDays(usage.range.start, usage.range.end) >= this.hotelConfig.MUIfeature.longTermReservationDays;
        return isLongTerm ? "long-term" : ""
      }
    } else if(_.isNumber(usage.maintenanceReservationId)) {
      return 'maintenance';
    } else if(_.isNumber(usage.allotmentId)) {
      return 'allotment';
    } else {
      return 'vacant';
    }
  }
  getUsageInfo(usage:any, room:any) {
    let range = usage.range;
    let startDateObject = moment(formatDate(this.startDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD');
    let endDateObject = moment(formatDate(this.endDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD').endOf('day');
    let usageStart = moment(range.start).unix();
    let usageEnd = moment(range.end).unix();
    let checkInHour = Number(this.appliedHotel.defaultCheckInTime.split(":")[0]);
    let checkInMinute = Number(this.appliedHotel.defaultCheckInTime.split(":")[1]);
    let checkOutHour = Number(this.appliedHotel.defaultCheckOutTime.split(":")[0]);
    let checkOutMinute = Number(this.appliedHotel.defaultCheckOutTime.split(":")[1]);

    let startDate = startDateObject.unix();
    let endDate = endDateObject.unix();

    if(usageStart < startDate) {
      usageStart = startDate;
    }
    if(usageEnd > endDate) {
      usageEnd = endDate;
    }
    if(usageStart > endDate) {
      return 0
    }
    let percentStart = ((usageStart - startDate) * 100)/(endDate - startDate);
    let percentEnd = ((usageEnd - startDate) * 100)/(endDate - startDate);
    let usageLength = percentEnd - percentStart;

    //ECI/LCO
    let eciWidth, lcoWidth;
    if (usage.allotmentId && (!usage.isWithEci && !usage.isWithLco) && moment(range.start).format('DD-MM-YYYY') === moment(range.end).format('DD-MM-YYYY')) {
      eciWidth = '8px';
    } else if (usage.isWithEci) {
      if(usageStart < startDate) {
        eciWidth = '4px';
      } else {
        let defaultCheckIn = moment(range.start).set({'hour': checkInHour, 'minute': checkInMinute, 'second': 0}).unix();
        let percentEci = ((defaultCheckIn - usageStart) * 100)/(usageEnd - usageStart);
        eciWidth = percentEci+'%';
      }
    } else {
      eciWidth = 0;
    }

    if (usage.isWithLco) {
      if(usageEnd > endDate) {
        lcoWidth = '4px';
      } else {
        let defaultCheckOut = moment(range.end).set({'hour': checkOutHour, 'minute': checkOutMinute, 'second': 0}).unix();
        let percentLcoFromStart = ((defaultCheckOut - usageStart) * 100)/(usageEnd - usageStart);
        lcoWidth = (100 - percentLcoFromStart)+'%';
      }
    } else {
      lcoWidth = 0;
    }
    const isDraggable = this.isCleaner || _.isNumber(usage.maintenanceReservationId) || (_.isNumber(usage.allotmentId) && !usage.reservationCode) ? false : true

    return {
      'usageLength': {
        'rawWidth': usageLength,
        'width': usageLength + '%',
        'left': percentStart + '%',
        'start': percentStart,
        'end': percentEnd
      },
      'eciLength': {
        'width': eciWidth,
      },
      'lcoLength': {
        'width': lcoWidth,
      },
      'labelLeftOffset': {
        'left': eciWidth
      },
      'usageColorClass': this.getUsageColorClass(usage, room),
      'draggable': isDraggable
    }
  }
  getUsageLabels(usage:any) {
    let tmpLabelList:ReservationLabelType[] = [];
    usage.labels.forEach((label:string) => {
      const findLabel = this.hotelConfig.MUIfeature.reservationLabelList.find(dLabel => dLabel.id === label);
      if(findLabel) {
        tmpLabelList.push(findLabel);
      } else {
        tmpLabelList.push({
          id: "UNKNOWN",
          name: label,
          iconType: "solid",
          iconName: ""
        })
      }
    })
    return tmpLabelList;
  }

  getLogDescription(operation:any, oldValue:any, newValue:any) {
    switch(operation) {
      case 'CHANGE_CAPACITY':
        return `Changed capacity from ${oldValue.capacity} to ${newValue.capacity}`;
      case 'CHANGE_SPECIFIC_INFO':
        return newValue.specific_info;
      case 'CHANGE_DE_PRIORITIZE':
        return `Changed de-prioritize from ${oldValue.de_prioritize} to ${newValue.de_prioritize}`;
      default:
        return '';
    }
  }

  editDescription(text:any) {
    this.roomInfoStatus.roomDescription = true;
    this.roomInfoStatus.descriptionInput = text;
  }
  cancelDescription() {
    this.roomInfoStatus.roomDescription = false;
  }
  editCapacity(capacity:any) {
    this.roomInfoStatus.roomCapacity = true;
    this.roomInfoStatus.capacity = capacity;
    const findCapacity = this.capacityList.find(item => item.value === capacity.toString());
    if(findCapacity) {
      this.selectCapacity(findCapacity);
    }
  }
  cancelCapacity() {
    this.roomInfoStatus.roomCapacity = false;
  }
  getRoomInfo(room:any, roomIndex:number, roomId:number) {
    this.viewRoomInfo = {
      room: room,
      roomIndex: roomIndex
    }
    this.roomInfoStatus = {
      loading: true,
      roomDescription: false,
      roomCapacity: false,
      checkDescriptionInput: false,
      descriptionInput: '',
      capacity: 0,
    }
    this.startGetRoomInfo(roomId);
  }
  startGetRoomInfo(roomId:number) {
    this.usageService.getRoomInfo(roomId).subscribe(data => {
      this.roomInfo = data;
      this.roomInfoStatus.loading = false;
    })
  }

  showHotelBaseOnConfig(hotelLabel:string) {
    if(!this.hotelConfig.MUIfeature.useHotelCode) {
      const findHotel = this.hotels.find((hotel:any) => hotel.label === hotelLabel);
      if(findHotel) {
        return findHotel.name;
      } else {
        return hotelLabel
      }
    } else {
      return hotelLabel
    }
  }

  checkDescription(desc:string) {
    if(!this.roomInfoStatus.descriptionInput || this.roomInfoStatus.descriptionInput == desc) {
      this.roomInfoStatus.checkDescriptionInput = false;
    } else {
      this.roomInfoStatus.checkDescriptionInput = true;
    }
  }

  saveDescription(roomId:number) {
    let request:any = {
      roomId: roomId,
      specificInfo: this.roomInfoStatus.descriptionInput
    }
    this.usageService.setDescription(request).subscribe(data => {
      this.roomInfo = data;
      this.cancelDescription();
    })
  }
  saveCapacity(roomId:number, roomIndex:number) {
    let request:any = {
      roomId: roomId,
      newCapacity: this.roomInfoStatus.capacity
    }
    this.usageService.setCapacity(request).subscribe(data => {
      this.roomInfo = data.roomDetails;      
      this.usageData[roomIndex].capacity = data.roomDetails.capacity;
      this.usageData[roomIndex].productName = data.roomDetails.productName;
      this.cancelCapacity();
      this.getUsageWithFilter(false);
      if(data.reAllocationResult.failedReAllocationSet.length > 0) {
        this.failedReAllocationData = data.reAllocationResult.failedReAllocationSet.length;
        this.openFailedReAllocationModal();
      }
    }, (errorResp: any) => {
      if (errorResp.status === 406 || errorResp.status === 412 || errorResp.status !== 500) {
        this.toast.error(errorResp.error.description, 'Error!');
      } else {
        this.toast.error("Can't set capacity for this room.", 'Error!');
      }
    });
  }

  markCleanStatus(clean:boolean, roomId:number, roomIndex:number, skipModal:boolean) {
    if(!skipModal && this.showAlreadyMarkedModal) {
      this.openCustomModal("roomAlreadyCleanAlert")
    } else {
      if(clean) {
        this.usageService.markClean(roomId).subscribe(data => {
          this.usageData[roomIndex].clean = data;
        })
      } else {
        this.usageService.markDirty(roomId).subscribe(data => {
          this.usageData[roomIndex].clean = data;
        })
      }
    }
  }
  getCleanEvent(room:any, roomIndex:number, roomId:number) {
    this.viewRoomClean = {
      room: room,
      roomIndex: roomIndex
    }
    this.cleanEventData = [];
    this.cleanEvent = {
      show: false,
      totalItem: 0,
      current: 1,
      finalPage: 0,
      hasDot: true,
      hasDotLeft: false,
      pageArray: []
    }
    let request = {
      limit: 2,
      offset: 0
    }
    this.startRequestCleanEvent(roomId, request);
  }
  startRequestCleanEvent(roomId:number, request:any) {
    this.usageService.getCleanEvent(roomId, request).subscribe(data => {
      this.cleanEventData = data.items;
      this.cleanEvent.totalItem = data.total;
      if(data.total % 2 == 0) {
        this.cleanEvent.finalPage = data.total / 2;
      } else {
        this.cleanEvent.finalPage = (data.total + 1) / 2;
      }
      this.tablePageChange(this.cleanEvent.current);
      // Marking room as clean that has already been marked clean (or simultaneous 'clean' marking)
      if (request.limit === 2 && request.offset === 0 && data.items.length !== 0) {
        this.currentRoomUsageStatus = data.items[0].status;
        this.roomUsageSelected = this.usageData.find((roomUsage: any, index: number) => { 
          this.roomIndexSelected = index;
          return roomUsage.roomId === roomId
        });
        let roomUsageStatusSelect = this.roomUsageSelected.clean ? "CLEAN" : "DIRTY";
        if (this.currentRoomUsageStatus !== roomUsageStatusSelect) {
          this.showAlreadyMarkedModal = true;
        } else {
          this.showAlreadyMarkedModal = false;
        }
      }
    });
  }
  changeRoomUsageStatus() {
    this.markCleanStatus(this.currentRoomUsageStatus === "CLEAN", this.roomUsageSelected.roomId, this.roomIndexSelected, true);
    this.closeCustomModal("roomAlreadyCleanAlert");
  }
  capitalizeFirstLetter(string: string) {
    if(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else {
      return ""
    }
  }
  tablePageChange(pageNumber:number) {
    let clean = this.cleanEvent;
    clean.current = pageNumber;
    let pageGroup:any = [];
    let selectedGroup:any = null;
    for (let indexMin = 1; indexMin < clean.finalPage; indexMin += 2) {
      let indexMax:number = indexMin + 3;
      if(indexMax > clean.finalPage) {
        indexMax = clean.finalPage;
      }
      pageGroup.push({
        min: indexMin,
        max: indexMax
      });
    }
    if(pageGroup.length > 0) {
      if(clean.current == 1 || clean.current == 2 || clean.current == clean.finalPage) {
        if(clean.current == 1 || clean.current == 2) {
          selectedGroup = pageGroup[0];
          clean.hasDotLeft = false;
        } else {
          clean.hasDotLeft = true;
        }
        if(clean.current == clean.finalPage) {
          selectedGroup = pageGroup[pageGroup.length - 1];
          clean.hasDot = false;
        } else {
          clean.hasDot = true;
        }
      } else {
        pageGroup.every((group:any, groupIndex:any) => {
          if(clean.current == group.min && clean.current != 1) {
            selectedGroup = pageGroup[groupIndex - 1];
          }
          if(clean.current == group.max && clean.current != clean.finalPage) {
            selectedGroup = pageGroup[groupIndex + 1];
          }
          if(selectedGroup) {
            clean.hasDotLeft = true;
            return false;
          } else {
            return true;
          }
        });
      }
      if(pageGroup.length == 1) {
        clean.hasDot = false;
      }
      
      let pageArray = [];
      for(let i = selectedGroup.min; i <= selectedGroup.max; i++) {
        pageArray.push(i);
      }
      clean.pageArray = pageArray;
    }
  }
  changePage(pageNumber:number, roomId:number) {
    let request = {
      limit: 2,
      offset: (pageNumber * 2) - 2
    }
    this.cleanEvent.current = pageNumber;
    this.startRequestCleanEvent(roomId, request);
  }
  singlePageChange(type:string, roomId:number) {
    let newPage = 0;
    switch(type) {
      case 'prev':
        newPage = this.cleanEvent.current - 1;
        break;
      case 'next':
        newPage = this.cleanEvent.current + 1;
        break;
      case 'first':
        newPage = 1;
        break;
      case 'last':
        newPage = this.cleanEvent.finalPage;
        break;
    }
    this.changePage(newPage, roomId);
  }

  checkDraggable(usage:any) {
    if(this.isCleaner) {
      return false;
    } else {
      if(usage.usageDisplay.usageColorClass == 'maintenance' || usage.usageDisplay.usageColorClass == 'allotment' || moment(usage.range.end).isBefore(moment())) {
        return false;
      } else {
        return true;
      }
    }
  }
  roomDragover:any = {
    roomId: null,
    roomIndex: null
  };
  roomDragstart:any = {
    roomId: null,
    roomIndex: null,
    usageIndex: null,
  };
  currentPage = document.querySelector<HTMLInputElement>('html');
  lastRoomIndexDragover: any = null;
  automaticDrag(e:any) {
    let tableHeadHeight:any = document.querySelector<HTMLInputElement>('.table-head-container')?.offsetHeight;
    clearInterval(this.dragScrollInterval);
    if(e.clientY < tableHeadHeight + 50) {
      this.dragScrollInterval = setInterval(() => {
        if (this.currentPage !== null) {
          this.currentPage.scrollTop = this.currentPage.scrollTop - 5;
        }
      }, 10)
    }
    if(e.clientY > window.innerHeight - 100) {
      this.dragScrollInterval = setInterval(() => {
        if (this.currentPage !== null) {
          this.currentPage.scrollTop = this.currentPage.scrollTop + 5.2;
        }
      }, 10)
    }
  }
  usageDragover(e: any) {
    e.preventDefault();
  }
  usageDragenter(e:any, roomIndex:number) {
    e.preventDefault();
    let allUsageRow = document.querySelectorAll(".item-row");
    allUsageRow.forEach((item:any) => {
      item.classList.remove('dragover');
    });
    let allUsageLeftRow = document.querySelectorAll(".room-item");
    allUsageLeftRow.forEach((item:any) => {
      item.classList.remove('dragover');
    });
    this.automaticDrag(e);
    if(roomIndex != this.roomDragstart.roomIndex) {
      document.querySelector(`[roomindex="${roomIndex}"]`)?.classList.add("dragover");
      document.querySelector(`[roomleftindex="${roomIndex}"]`)?.classList.add("dragover");
      this.roomDragover = {
        roomId: 'room' + roomIndex,
        roomIndex: roomIndex
      };
    } else {
      this.roomDragover = {
        roomId: null,
        roomIndex: null
      };
    }
  }
  usageDragstart(roomIndex:number, usageIndex:number, e:any) {
    this.showUsageHover = false;
    this.hoverUsageLeave();
    e.dataTransfer.setData("text/plain", e.target.id);
    e.stopPropagation();
    this.roomDragstart = {
      roomName: 'usage' + roomIndex + usageIndex,
      roomIndex: roomIndex,
      usageIndex: usageIndex
    };
    setTimeout(() => {
      this.changeDectector.detach();
    }, 0);
  }
  usageDragend() {
    clearInterval(this.dragScrollInterval);
    this.roomDragover = {
      roomId: null,
      roomIndex: null
    };
    this.roomDragstart = {
      roomId: null,
      roomIndex: null,
      usageIndex: null,
    };
  }
  usageDrop = async(e:any) => {
    let allUsageRow = document.querySelectorAll(".item-row");
    allUsageRow.forEach((item:any) => {
      item.classList.remove('dragover');
    });
    let allUsageLeftRow = document.querySelectorAll(".room-item");
    allUsageLeftRow.forEach((item:any) => {
      item.classList.remove('dragover');
    });
    this.changeDectector.reattach();
    if(this.roomDragover.roomIndex !== null) {
      let swap = false;
      let getUsage = this.usageData[this.roomDragstart.roomIndex].usages[this.roomDragstart.usageIndex];
      let usageDrag = {
        start: getUsage.usageDisplay.usageLength.start,
        end: getUsage.usageDisplay.usageLength.end
      }
      let swappingUsage:any;
      if(this.usageData[this.roomDragover.roomIndex].usages.length > 0) {
        this.usageData[this.roomDragover.roomIndex].usages.every((usage:any) => {
          let tmpUsage = {
            start: usage.usageDisplay.usageLength.start,
            end: usage.usageDisplay.usageLength.end
          }
          let swapCondition = (tmpUsage.start < usageDrag.start && usageDrag.start < tmpUsage.end && usageDrag.end > tmpUsage.end) || 
          (usageDrag.start > tmpUsage.start && usageDrag.start < tmpUsage.end && usageDrag.end > tmpUsage.start && usageDrag.end < tmpUsage.end) || 
          (usageDrag.start == tmpUsage.start && usageDrag.end == tmpUsage.end) || 
          (usageDrag.start < tmpUsage.start && usageDrag.end > tmpUsage.start && usageDrag.end < tmpUsage.end) || 
          (usageDrag.start < tmpUsage.start && usageDrag.end > tmpUsage.end ||
          ((usageDrag.start < tmpUsage.start || usageDrag.start > tmpUsage.start ) && usageDrag.end == tmpUsage.end) ||
          ((usageDrag.end > tmpUsage.end || usageDrag.end < tmpUsage.end) && usageDrag.start == tmpUsage.start))
          if(swapCondition) {
            if(usage.usageDisplay.usageColorClass == 'maintenance' || usage.usageDisplay.usageColorClass == 'allotment') {
              swap = false;
            } else {
              swap = true;
              swappingUsage = {
                roomRequest: usage.roomRequestId,
                usageId: usage.usageId
              }
            }
            return false;
          } else {
            return true;
          }
        });
      }
      if(swap) {
        this.swapRoomAlert = {
          roomFrom: this.usageData[this.roomDragstart.roomIndex].label,
          roomTo: this.usageData[this.roomDragover.roomIndex].label,
          balanceRoomFrom: 0,
          balanceRoomTo: 0
        }
        this.swapRoomRequest = {
          left: {
            roomId: this.usageData[this.roomDragstart.roomIndex].roomId,
            roomRequestId: getUsage.roomRequestId,
            roomUsageId: getUsage.usageId
          },
          right: {
            roomId: this.usageData[this.roomDragover.roomIndex].roomId,
            roomRequestId: swappingUsage.roomRequest,
            roomUsageId: swappingUsage.usageId
          }
        }
        if (this.isAllowChangeRoomAcrossRoomTypes) {
          this.checkSwapRoomAcrossRoomTypes();
        }
        this.openSwapRoomModal();
      } else {
        this.changeRoomRequest = {
          roomId: this.usageData[this.roomDragover.roomIndex].roomId,
          roomRequestId : getUsage.roomRequestId,
          roomUsageId : getUsage.usageId
        }
        if(this.hotelConfig.MUIfeature.allowMovingToDirtyRoom) {
          this.usageService.changeRoomState(this.appliedHotel.hotelId, this.changeRoomRequest).subscribe(
            data => {
              if(data.length) {
                this.dirtyRoomList = data.join(", ");
                this.openDirtyChangeRoomModal();
              } else {
                this.startMoveRoom("change")
              }
            },
            err => {
              this.displayAPIerror(err);
            }
          )
        } else {
          this.startMoveRoom("change")
        }
      }
    }
  }

  swapCheckDirty(swapAcross:boolean = false) {
    this.closeCustomModal("swapRoomAlert");
    this.swapAccrossRoom = swapAcross;
    if(this.hotelConfig.MUIfeature.allowMovingToDirtyRoom) {
      this.usageService.swapRoomState(this.appliedHotel.hotelId, this.swapRoomRequest).subscribe(
        data => {
          if(data.length) {
            this.dirtyRoomList = data.join(", ");
            setTimeout(() => {
              this.openDirtySwapRoomModal();
            }, 200);
          } else {
            this.startMoveRoom("swap")
          }
        },
        err => {
          this.displayAPIerror(err);
        }
      )
    } else {
      this.startMoveRoom("swap")
    }
  }

  startMoveRoom(type:string) {
    if(type === "change") {
      this.closeCustomModal("dirtyChangeRoomAlert")
      if (!this.isAllowChangeRoomAcrossRoomTypes) {
        this.changeRoom();
      } else {
        this.changeRoomAcrossRoomTypes();
      }
    }
    if(type === "swap") {
      this.closeCustomModal("dirtySwapRoomAlert")
      if(this.isNotifySwapRoomAcrossRoomTypes) {
        if(this.swapAccrossRoom) {
          this.swapRoomAcrossRoomTypes(true)
        } else {
          this.swapRoomAcrossRoomTypes(false)
        }
      } else {
        this.swapRoom();
      }
    }
  }

  changeRoom() {
    this.openLoadingAlert("Moving room...");
    this.usageService.changeRoom(this.appliedHotel.hotelId, this.changeRoomRequest).subscribe(
      data => {
        this.toast.success('Room changed successfully!', 'Success!');
        this.closeLoadingAlert();
        this.getUsageWithFilter(false);
      },
      err => {
        this.displayAPIerror(err);
        this.closeLoadingAlert();
      }
    )
  }

  changeRoomAcrossRoomTypes() {
    this.openLoadingAlert("Comparing prices...");
    this.usageService.changeRoomAcrossRoomTypes(this.appliedHotel.hotelId, this.changeRoomRequest).subscribe(
      data => {
        this.closeLoadingAlert();
        // If the room price don't any change then we call the API's change room
        if (data.balance === 0) {
          this.changeRoom();
        } else {
          this.changeRoomAlert.balanceRoom = data.balance.toFixed(2);
          this.openChangeRoomModal();
          this.changeRoomRequest.prices = data.prices;
        }
      },
      err => {
        this.closeLoadingAlert();
        this.displayAPIerror(err);
      }
    )
  }
  actionChangeRoomAcrossRoomTypes(isKeepAcrossRoomTypes: boolean) {
    this.closeCustomModal("changeRoomAlert");
    this.changeRoomRequest.isOverridePrice = isKeepAcrossRoomTypes;
    this.changeRoom();
  }

  swapRoom() {
    this.openLoadingAlert("Swapping rooms...");
    this.usageService.swapRoom(this.appliedHotel.hotelId, this.swapRoomRequest).subscribe(
      data => {
        this.closeLoadingAlert();
        this.toast.success('Room swapped successfully!', 'Success!');
        this.getUsageWithFilter(false);
      },
      err => {
        this.closeLoadingAlert();
        this.displayAPIerror(err);
      }
    )
  }

  swapRoomAcrossRoomTypes(isKeepAcrossRoomTypes: boolean) {
    this.openLoadingAlert("Swapping rooms...");
    this.swapRoomRequest.left.isOverridePrice = isKeepAcrossRoomTypes;
    this.swapRoomRequest.right.isOverridePrice = isKeepAcrossRoomTypes;
    this.usageService.swapRoom(this.appliedHotel.hotelId, this.swapRoomRequest).subscribe(
      data => {
        this.closeLoadingAlert();
        this.toast.success('Room swapped successfully!', 'Success!');
        this.getUsageWithFilter(false);
      },
      err => {
        this.closeLoadingAlert();
        this.displayAPIerror(err);
      }
    )
  }

  checkSwapRoomAcrossRoomTypes() {
    this.openLoadingAlert("Comparing prices...");
    this.usageService.swapRoomAcrossRoomTypes(this.appliedHotel.hotelId, this.swapRoomRequest).subscribe(
      data => {
        this.closeLoadingAlert();
        let balanceLeft = data.left.balance;
        let balanceRight = data.right.balance;
        if (balanceLeft !== 0 || balanceRight !== 0) {
          this.isNotifySwapRoomAcrossRoomTypes = true;
          this.swapRoomAlert.balanceRoomFrom = balanceLeft.toFixed(2);
          this.swapRoomAlert.balanceRoomTo = balanceRight.toFixed(2);
          // Update attribute to send request Swap room across room types
          this.swapRoomRequest.left.prices = data.left.prices;
          this.swapRoomRequest.right.prices = data.right.prices;
        }
      },
      err => {
        this.closeLoadingAlert();
        this.displayAPIerror(err);
      }
    )
  }

  createMaintenance(roomIndex:number) {
    let param:any = {
      hotelName: this.appliedHotel.hotelId,
      roomIndex: this.usageData[roomIndex].roomId
    };
    this.router.navigate(['/maintenance/create'], {queryParams: param});
  }

  getDateRange(data:any) {
    return `${moment(data.start).format("DD.MM.YYYY HH:mm")} - ${moment(data.end).format("DD.MM.YYYY HH:mm")}`;
  }

  truncateEmail(email: any) {
    if (!email)
      return;
    
    let length = email.length;
    let discountCodeResults;
    if (length > 20) {
      discountCodeResults = email.slice(0,21) + '...';
    } else {
      discountCodeResults = email;
    }
    return discountCodeResults;
  }
  selectedShortcut(roomIndex: any, shortcutIndex:number){
    this.shortcutRoomIndex = roomIndex;
    this.shortcutRoomPosIndex = shortcutIndex;
    const selectedDate = this.labelRangeDateSelected(true);
    if(selectedDate) {
      const convSelectedDate = moment(selectedDate[0] + " 23:59").tz(this.appliedHotel.zone).add(1, 'd');
      const convToday = moment().tz(this.appliedHotel.zone).format('yyyy-MM-DD HH:mm')
      const selectUsage:any = document.querySelector(`.usage-item .item-row[roomindex="${roomIndex}"]`);
      const usageBody:any = document.querySelector(`.table-body-container`);
      if(selectUsage.offsetTop > usageBody.offsetHeight - 50) {
        this.shortcutTopPosition = true;
      } else {
        this.shortcutTopPosition = false;
      }
      if(!convSelectedDate.isAfter(convToday)) {
        this.removeAllShortcut();
        this.shortcutTopPosition = false;
        this.toast.error("Selected usage is in the past, please select again!", "Cannot select")
      }
    }
  }
  shortcutCreateMaintenance(){
    if(this.shortcutRoomIndex !== undefined) {
      this.closeCustomModal("shortcutAlert");
      let allSelectedItem = document.querySelectorAll('.shortcut.selected');
      let getStartDate:string|null = null;
      let getEndDate:string|null = null;
      allSelectedItem.forEach((el, index) => {
        getStartDate = el.getAttribute('data-start');
        getEndDate = this.upOneDate(el.getAttribute('data-start'), el.getAttribute('data-end'))
      })
      let param:any = {
        hotelName: this.appliedHotel.hotelId,
        roomIndex: this.usageData[this.shortcutRoomIndex].roomId,
        start: getStartDate,
        end: getEndDate
      };
      setTimeout(() => {
        this.router.navigate(['/maintenance/create'], {queryParams: param});
      }, 500);
    }
  }
  mouseDownResizeShortcut(i: any, e: any){
    if(e.target.classList.contains("shortcut")) {
      e.preventDefault();
      this.shortcutmove = true;
      this.shortcutMDCoord = e.x;
      this.itemResize = e.target;
      this.squareItemWidth =this.itemResize.clientWidth;
      this.parentSquareItemWidth = this.itemResize.parentElement.clientWidth;
      this.indexDateSelected = i;
      let allShorcut = document.querySelectorAll('.shortcut');
      allShorcut.forEach(el => {
        if(el !== e.target){
          el.classList.add('not-hover');
          el.setAttribute('style', '');
        }
      })
    }
  }
  mouseMoveResizeShortcut(e: any) {
    e.preventDefault();
    if(this.shortcutmove && this.shortcutMDCoord){
      this.shortcutMMCoord = e.x;
      let move = this.shortcutMMCoord - this.shortcutMDCoord;
      let ratio = Math.ceil(this.squareItemWidth/this.parentSquareItemWidth);
      let tempW = ((move)*100)/ this.parentSquareItemWidth;
      if(tempW > 0){
        if(tempW < 5){
          return
        } else {
          this.itMoving = true;
        }
      } else {
        if(tempW >-5){
          return
        } else {
          this.itMoving = true;
        }
      }
      if(move >= 0){
        this.itemResize.setAttribute('style', `width:${Math.max((ratio)*100, (ratio)*100 + tempW)}%`);
      }
      else{
        this.itemResize.setAttribute('style', `width:${Math.min((ratio)*100, (ratio)*100 + tempW)}%`);
      }
    }
  }
  removeShortcut(e:any) {
    let allShorcut = document.querySelectorAll('.shortcut');
    allShorcut.forEach(el => {
      el.classList.remove('not-hover');
    })
    allShorcut.forEach(el => {
      if(el.classList.contains('selected') && el === e.target.parentNode.parentNode){
        el.classList.remove('selected');
        el.setAttribute('style', '');
      }
    })
  }
  removeAllShortcut() {
    let allShorcut = document.querySelectorAll('.shortcut');
    this.shortcutRoomIndex = undefined;
    this.shortcutRoomPosIndex = undefined;
    allShorcut.forEach(el => {
      el.classList.remove('not-hover');
    })
    allShorcut.forEach(el => {
      el.classList.remove('selected');
      el.setAttribute('style', '');
    })
  }
  mouseUpResizeShortcut(room: any, roomIndex: any, e: any) {
    let allShorcut = document.querySelectorAll('.shortcut');
    allShorcut.forEach(el => {
      el.classList.remove('not-hover');
    })
    if(!this.itMoving){
      let allItemResize = document.querySelectorAll('.shortcut.selected');
      let contains = false;
      allItemResize.forEach(el => {
        if(el === e.target || el === e.target.parentNode.parentNode){
          contains = true;
        }
      })
      if(contains){
        this.shortcutmove = false;
        return
      };
      this.roomSelected = [];
      this.roomSelected.push(room.label);
      allShorcut.forEach(el => {
        if(el.classList.contains('selected')){
          el.classList.remove('selected');
        }
      })
    }
    else {
      if(!this.roomSelected.includes(room.label)){
        this.roomSelected.push(room.label);
      }
    }
    // if(!e.ctrlKey){
      
    // }
    // else{
    //   if(!this.roomSelected.includes(room.label)){
    //     this.roomSelected.push(room.label);
    //   }
      
    // }
    if(this.itemResize && !this.itemResize.classList.contains('selected')){
      this.itemResize.classList.add('selected');
    }
    this.shortcutmove = false;
    this.itMoving = false;
    let tempW: number;
    let allowResize = false;
    if(this.shortcutMMCoord){
      let ratio = Math.ceil(this.squareItemWidth/this.parentSquareItemWidth);
      let xxCoord = this.shortcutMMCoord - this.shortcutMDCoord - 16;
      tempW = Math.ceil((xxCoord)/ this.parentSquareItemWidth);
      this.itemResize.setAttribute('style', `width:${(ratio + tempW - 1)*100 + this.percentShortcutLength}%`);
      // Check collision
      const overlapWithOtherUsage = this.checkResizeOverlap(roomIndex);
      if(overlapWithOtherUsage) {
        this.itemResize.setAttribute('style', `width:${this.lastValidUsageLength}%`);
        this.toast.error("Resized date range overlap with other usages, please resize again!", "Cannot resize");
      } else {
        allowResize = true;
        this.lastValidUsageLength = (ratio + tempW - 1)*100 + this.percentShortcutLength;
      }
    }
    if(!this.itemResize) return;
    this.rangeDateSelected = [];
    this.rangeDateSelected.push(this.allDates[this.indexDateSelected]);
    let distanceDate = Math.ceil((this.itemResize.clientWidth - this.parentSquareItemWidth + (this.parentSquareItemWidth * 68/100))/this.parentSquareItemWidth)
    this.rangeDateSelected.push(this.allDates[this.indexDateSelected + (distanceDate)]);
    this.shortcutMMCoord = undefined;
    this.shortcutMDCoord = undefined;
    this.itemResize.setAttribute('data-label', room.label);
    this.itemResize.setAttribute('data-productId', room.productId);
    this.itemResize.setAttribute('data-roomId', room.roomId);
    this.itemResize.setAttribute('data-hotelid', room.hotelId);
    this.itemResize.setAttribute('data-start', this.allFullDate[this.indexDateSelected]);
    this.itemResize.setAttribute('data-end', this.allFullDate[this.indexDateSelected + (distanceDate)]);
  }
  checkResizeOverlap(roomIndex:number) {
    const newDistanceDate = Math.ceil((this.itemResize.clientWidth - this.parentSquareItemWidth + (this.parentSquareItemWidth * 68/100))/this.parentSquareItemWidth);
    const usageStart = moment(this.allFullDate[this.indexDateSelected] + " 16:00").unix();
    const usageEnd = moment(this.allFullDate[this.indexDateSelected + newDistanceDate] + " 12:00").unix();
    const startDate = moment(formatDate(this.startDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD').unix();
    const endDate = moment(formatDate(this.endDate, 'yyyy-MM-dd', 'en-US'), 'YYYY-MM-DD').endOf('day').unix();
    const percentStart = ((usageStart - startDate) * 100)/(endDate - startDate);
    const percentEnd = ((usageEnd - startDate) * 100)/(endDate - startDate);
    if(this.usageData[roomIndex].usages.length) {
      let overlap = false;
      this.usageData[roomIndex].usages.every((usage:any) => {
        let tmpUsage = {
          start: usage.usageDisplay.usageLength.start,
          end: usage.usageDisplay.usageLength.end
        }
        let swapCondition = (tmpUsage.start < percentStart && percentStart < tmpUsage.end && percentEnd > tmpUsage.end) || 
        (percentStart > tmpUsage.start && percentStart < tmpUsage.end && percentEnd > tmpUsage.start && percentEnd < tmpUsage.end) || 
        (percentStart == tmpUsage.start && percentEnd == tmpUsage.end) || 
        (percentStart < tmpUsage.start && percentEnd > tmpUsage.start && percentEnd < tmpUsage.end) || 
        (percentStart < tmpUsage.start && percentEnd > tmpUsage.end ||
        ((percentStart < tmpUsage.start || percentStart > tmpUsage.start ) && percentEnd == tmpUsage.end) ||
        ((percentEnd > tmpUsage.end || percentEnd < tmpUsage.end) && percentStart == tmpUsage.start));
        if(swapCondition) {
          overlap = true;
          return false;
        } else {
          return true;
        }
      })
      return overlap
    } else {
      return false;
    }
    
  }
  labelRangeDateSelected(rawDate:boolean = false){
    let allItemResize = document.querySelectorAll('.shortcut.selected');
    let minD: any, maxD: any;
    allItemResize.forEach(el => {
      if(!minD){
        minD = moment(el.getAttribute('data-start') || '').format('yyyy-MM-DD');
      } else {
        if(new Date(minD) > new Date(el.getAttribute('data-start') || '')){
          minD = moment(el.getAttribute('data-start') || '').format('yyyy-MM-DD');
        }
      }
      if(!maxD){
        maxD = moment(el.getAttribute('data-end') || '').format('yyyy-MM-DD');
      } else {
        if(new Date(maxD) < new Date(el.getAttribute('data-end') || '')){
          maxD = moment(el.getAttribute('data-end') || '').format('yyyy-MM-DD');
        }
      }
    })
    if(minD && maxD) {
      this.startDateShortcut = formatDate(minD, 'yyyy-MM-dd', 'en-US');
      this.endDateShortcut = formatDate(maxD, 'yyyy-MM-dd', 'en-US');
      let newDate = moment(minD).add(1, 'd').format('yyyy-MM-DD');
      if(this.startDateShortcut == this.endDateShortcut ){
        if(rawDate) {
          return [formatDate(minD, 'yyyy-MM-dd', 'en-US'), moment(minD).add(1, 'd').format('yyyy-MM-DD')]
        } else {
          return `${formatDate(this.startDateShortcut, 'EEEEEE dd.MM', 'en-US')} - ${formatDate(newDate, 'EEEEEE dd.MM', 'en-US')}`;
        }
      }
      if(rawDate) {
        return [formatDate(minD, 'yyyy-MM-dd', 'en-US'), formatDate(maxD, 'yyyy-MM-dd', 'en-US')]
      } else {
        return `${formatDate(this.startDateShortcut, 'EEEEEE dd.MM', 'en-US')} - ${formatDate(this.endDateShortcut, 'EEEEEE dd.MM', 'en-US')}`;
      }
    }
  }
  upOneDate(startDate: any, endDate: any) {
    let newEndDate = moment(endDate).format('yyyy-MM-DD');
    if (startDate === endDate) {
      newEndDate = moment(endDate).add(1, 'd').format('yyyy-MM-DD');
    }
    return newEndDate;
  }
  async bookNow(){
    this.closeCustomModal("shortcutAlert");
    this.openLoadingAlert("Preparing data for booking...");
    let allSelectedItem = document.querySelectorAll('.shortcut.selected');
    let products: any[] = [];
    let dataRoom: any[] = [];
    this.disableBooknow = true;
    allSelectedItem.forEach((el, index) => {
      products.push({
        "childAges": [],
        "additionalProducts": [],
        "hotelId": el.getAttribute('data-hotelid'),
        "endDate": this.upOneDate(el.getAttribute('data-start'), el.getAttribute('data-end')),
        "startDate": el.getAttribute('data-start'),
        "totalAdults": 1,
        "totalChildren": 0,
        "roomAlias": (index + 1),
        "productId": el.getAttribute('data-productid'),
        "roomId": el.getAttribute('data-roomId'),
        "forDisabled": false,
        "isAppliedMemberPrice": false
      });
      dataRoom.push({
        "hotelId": el.getAttribute('data-hotelid'),
        "endDate": this.upOneDate(el.getAttribute('data-start'), el.getAttribute('data-end')),
        "startDate": el.getAttribute('data-start'),
        "productId": el.getAttribute('data-productid'),
        "roomId": el.getAttribute('data-roomId')
      });
    });
    let endDate = this.upOneDate(this.startDateShortcut, this.endDateShortcut);
    this.usageService.getHotelOffer({
      hotel: this.appliedHotel.hotelId,
      startDate: this.startDateShortcut,
      endDate: formatDate(endDate, 'yyyy-MM-dd', 'en-US'),
      lang: 'en'
    }).subscribe(data => {
      if(GlobalUserSession) {
        let priceCatalog = data[0].priceCatalog;
        this.usageService.reservationPreview({
          "async": false,
          "availabilityCollection": [],
          "errorMessage": "",
          "footerPanelVisible": true,
          "hotelId": this.appliedHotel.hotelId,
          "infoMessage": "",
          "isAllChildrenAgeSelected": false,
          "lang": "en",
          "priceCatalog": priceCatalog,
          "type": "NORMAL",
          "voucherKey": "",
          "products": products
        }).subscribe(
          (response) => {
            if(GlobalUserSession) {
              const secret = 'jwt_nelson';
              const dataJwt = sign({
                hotelId: this.appliedHotel.hotelId,
                products: products
              }, secret);
              if (this.utils.isLocalEnvironment()) {
                window.open(CONSTANT.CUSTOM_SELECT_ROOM_LOCALHOST + `/?token=${GlobalUserSession.token}&data=${dataJwt}`);
              } else {
                window.open(CONSTANT.SITEHOST + CONSTANT.CUSTOM_SELECT_ROOM_URL + `/?token=${GlobalUserSession.token}&data=${dataJwt}`);
              }
              this.disableBooknow = false;
              this.closeLoadingAlert();
              this.removeAllShortcut();
            }
          },
          (error) => {
            this.closeLoadingAlert();
            this.openCustomModal("shortcutAlert");
            this.openResultAlert("error", "Error", error.error.message)                    
            this.disableBooknow = false;
          }
        )
      }
    })
  }

  toggleDePrioritizeRoom(roomId: number, checkEvent: any) {
    this.usageService.postDePrioritize({roomId, isDePrioritized: checkEvent.target.checked}).subscribe(data => {
      this.toast.success('De-Prioritized updated!', 'Successfully!')
    }, err => {
      this.toast.error("Can't update De-Prioritized", 'Error!');
    })
  }

  getDate(date:string, type:string) {
    if(type === "date") {
      return date.split(" ")[0];
    }
    return date.split(" ")[1];
  }

  getMaintenanceName(name:string) {
    let finalName = name;
    switch(name) {
      case 'MAINTENANCE':
        finalName = "Maintenance";
        break;
      case 'TO_CLEAN':
        finalName = "To clean";
        break;
      case 'RENOVATION':
        finalName = "Renovation";
        break;
      case 'TRANSFER':
        finalName = "Transfer";
        break;
      case 'OUT_OF_USE':
        finalName = "Out of use";
        break;
    }
    return finalName;
  }

  openReservationWithPayment(uuid:string) {
    if(this.hasReservationRole) {
      const param = {
        openPayment: true
      }
      this.router.navigate(['/reservations/' + uuid], { queryParams: param });
    }
  }
}
