import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { GlobalUserSession } from '../helpers/globalAuth';
import { Auth2Service } from './auth2.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private toast: CustomToastService,
    private auth2Service: Auth2Service
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error instanceof ErrorEvent
        ) {
          console.error('A client-side or network error occurred', error.error);

          // else {
          //   console.log(`Backend returned code: ${error.status} ${error.statusText}`);
          //   switch(error.status){
          //     case 401:
          //       if (error.error.error === "Unauthorized") {
          //         this.tokenStorageService.logOut();
          //         console.error(error.error.message, 'Error!');
          //       } else if(error.error.error === "AUTHENTICATION_FAILED"){
          //         break;
          //       } else  {
          //         this.tokenStorageService.logOut();
          //         console.error("Unauthorized", 'Error!');
          //       }
          //       break;
          //   }
          // }
        }

        if(GlobalUserSession) {
          if (error.status === 401 && (!GlobalUserSession.token || !GlobalUserSession.refreshToken)) {
            this.auth2Service.logOutToAdminLogin(true);
            console.error('Unauthorized', 'Error!');
          }
          if (error.status === 401 && GlobalUserSession.refreshToken) {
            if(req.url === "/api/user/login") {
              this.auth2Service.logOutToAdminLogin(true);
            } else {
              return this.auth2Service.refreshSession(GlobalUserSession.cognitoUsername, GlobalUserSession.refreshToken).pipe(
                switchMap((data) => {
                  this.auth2Service.updateLoginSession(data.token);
                  let authReq = req;
                  authReq = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Bearer ' + data.token
                    ),
                  });
                  return next.handle(authReq).pipe(
                    catchError((errorRetry) => {
                      if (errorRetry.status !== 200) {
                        this.auth2Service.logOutToAdminLogin(true);
                      }
                      return throwError(() => error);
                    })
                  );
                }),
                catchError((errorRefreshTokenReq) => {
                  if (errorRefreshTokenReq.status !== 200) {
                    this.auth2Service.logOutToAdminLogin(true);
                  }
                  return throwError(() => error);
                })
              );
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
}
