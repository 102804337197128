import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { AllotmentsService } from 'src/app/services/allotments.service';
import { HotelService } from 'src/app/services/hotel.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-allotments',
  templateUrl: './allotments.component.html',
  styleUrls: ['./allotments.component.sass']
})
export class AllotmentsComponent implements OnInit {
  datepickerConfig: any = CONSTANT.datepickerConfig;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  isFormValid:boolean = false;
  isLoading: boolean = false;
  isHotelAllotment: boolean = false;
  
  bsStartDateValue: any = "";
  bsEndDateValue: any;
  hotels: any = [];

  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  allotments: any = <any>[];
  allotmentForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
  });

  datepickerCustom: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  constructor(private router: Router,
    private hotelService: HotelService,
    private toast: CustomToastService,
    private allotmentsService: AllotmentsService,
    private storeService: StoreService,
    public utils: UTILS) { 
      this.datepickerCustom = []
    }

  ngOnInit(): void {
    this.hotelService.getHotels().subscribe((data:any) => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectAllHotel();
    });
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }

  updateDatepicker() {
    if(this.bsStartDateValue && this.bsEndDateValue) {
      let custom = [
        {date: this.bsStartDateValue, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.bsStartDateValue.getTime() + 24*3600*1000;
      while(tmpTime < this.bsEndDateValue.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.bsEndDateValue, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }
  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.bsStartDateValue && this.bsEndDateValue && this.bsStartDateValue.getTime() > this.bsEndDateValue.getTime()) {
          this.bsEndDateValue = new Date(this.bsStartDateValue.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.bsStartDateValue && this.bsEndDateValue && this.bsStartDateValue.getTime() > this.bsEndDateValue.getTime()) {
          this.bsStartDateValue = new Date(this.bsEndDateValue.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  onSearch(): void {
    if(this.selectedHotel) {
      this.isLoading = true;
      let requestForm = <any>{
        includeCancelledAllotment: true,
        hotelIds: this.selectedHotel.value !== "all" ? this.selectedHotel.value : undefined,
        name: this.allotmentForm.value.name ? this.allotmentForm.value.name : undefined,
        description: this.allotmentForm.value.description ? this.allotmentForm.value.description : undefined,
        startDate: this.bsStartDateValue ? formatDate(this.bsStartDateValue, 'yyyy-MM-dd', 'en-US') : undefined,
        endDate: this.bsEndDateValue ? formatDate(this.bsEndDateValue, 'yyyy-MM-dd', 'en-US') : undefined,
      };
      this.allotments = [];
      this.allotmentsService.searchAllotments(requestForm).subscribe(data => {
        this.isLoading = false;
        if (data.length === 0) {
          this.toast.error('Allotments not found', 'Warning!');
        } else {
          for (let allotment of data) {
            let allotmentResponse = <any>{};
            allotmentResponse = allotment;
            allotmentResponse.isShowHotelAllotment = false;
            allotmentResponse.hotelAllotment = [];
            this.allotments.push(allotmentResponse);
          }
        }
      });
    }
  }

  goDetailHotelAllotment(allotment: any): void {
    allotment.isShowHotelAllotment = !allotment.isShowHotelAllotment;
    if (allotment.hotelAllotment.length == 0) {
      this.isHotelAllotment = true;
      this.allotmentsService.allotmentInfo(allotment.id).subscribe(data => {
        allotment.hotelAllotment = data;
        for (let hA of data) {
          for (let h of this.hotels) {
            if (h.hotelId == hA.hotelId) {
              hA.label = h.label;
              hA.dropdownHotelName = h.dropdownHotelName;
            }
          }
          hA.dayOfWeeks = hA.dayOfWeeks.map((d: any) => { return d.substring(0, 3) });
          this.isHotelAllotment = false;
        };
      });
      if (!allotment.vouchers) {
        this.allotmentsService.getVoucherByAllotmentId(allotment.id).subscribe(data => {
          allotment.vouchers = data;
        });
      }
    }
  }
  clearSearch(): void {
    this.allotmentForm.patchValue({
      name: "",
      description: "",
    });
    this.allotments = [];
    this.bsStartDateValue = "";
    this.bsEndDateValue = "";
    this.isValidStartDate = true;
    this.isValidEndDate = true;
    this.selectAllHotel();
  }
  redirectAllotment(): void {
    this.router.navigateByUrl("allotment");
  }
  redirectAllotmentById(allotmentId: number): void {
    this.router.navigateByUrl("/allotment/" + allotmentId);
  }
  goToUsage(allotment: any): void {
    let usageParams: any = {
      hotelId: allotment.hotelId,
      startDate: moment(allotment.startDate).format('YYYY-MM-DD'),
      endDate: moment(allotment.endDate).format('YYYY-MM-DD'),
      allotmentId: allotment.id,
    };
    this.router.navigate(['/usage'], { queryParams: usageParams });
  }

  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.bsStartDateValue != "Invalid Date" && this.bsEndDateValue != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }
}