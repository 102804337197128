import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileLoadService {
  private svgCache: { [url: string]: Observable<string> } = {};

  constructor(private http: HttpClient) {}

  getFile(url: string): Observable<string> {
    if (!this.svgCache[url]) {
      this.svgCache[url] = this.http.get(url, { responseType: 'text' }).pipe(
        shareReplay(1),
        catchError(() => of(''))
      );
    }
    return this.svgCache[url];
  }
}
