import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { DiscountTableComponent } from './discount/discount-table/discount-table.component';
import { MonetaryTableComponent } from './monetary/monetary-table/monetary-table.component';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.sass']
})
export class VoucherComponent implements OnInit {

  @ViewChild(MonetaryTableComponent)
  private monetaryTable!: MonetaryTableComponent;
  @ViewChild(DiscountTableComponent)
  private discountTable!: DiscountTableComponent;

  title = 'Vouchers';
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  startDate:any;
  endDate:any;
  dateMode:string = "OVERLAP";
  disabledBtnWhileCallingAPI: boolean = false;
  prevSearchAll:boolean = false;
  prevObject: any = {
    'discountOffset': 0,
    'monetaryOffset': 0,
    'prevQuery': null,
    'prevHotel': null,
    'prevStartDate': null,
    'prevEndDate': null,
    'prevFilter': null,
    'prevDateMode': null,
  };

  //Result
  discountResult:any[] = [];
  discountCount:number = 0;
  monetaryResult:any[] = [];
  monetaryCount:number = 0;

  selectedFilter:any = "[Default]";
  selectedFilterArray:any[] = [];
  optionalFilter:any[] = CONSTANT.OPTIONAL_FILTER_VOUCHER.filter(option => (option.voucherType === "MONETARY_VOUCHER" && this.hotelConfig.feature.giftCard) || (option.voucherType !== "MONETARY_VOUCHER"));

  datepickerCustom: DatepickerDateCustomClasses[];

  constructor(
    private voucherService: VoucherService,
    private route: ActivatedRoute,
    private router: Router,
    public utils: UTILS,
    private storeService: StoreService,
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
    this.selectedFilterArray = _.map(this.optionalFilter,this.getCheckedFilterValue).filter((item:any) => {return item != 0;});
  }

  getData(): void {
    this.voucherService.getHotels().subscribe(data => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectAllHotel();
      
      this.checkGiftcardParams();
    });
  }
  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }

  voucherSearch = new UntypedFormGroup({
    searchQuery: new UntypedFormControl('', Validators.required),
  });

  updateDatepicker() {
    if(this.startDate && this.endDate) {
      let custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.startDate.getTime() + 24*3600*1000;
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }

  changeDateMode(mode:string) {
    this.dateMode = mode;
  }

  getCurrentCheckedMain() {
    let check = false;
    this.optionalFilter.forEach(filter => {
      if(filter.voucherType == 'main' && filter.checked) {
        check = true;
      }
    });
    return check;
  };

  filterChange(e:any,value:any) {
    let selectedIndex = this.optionalFilter.findIndex((filter:any) => {
      return filter.value === value;
    });
    let checkedStatus =  e.target.checked;

    let currentCheckedMain = this.getCurrentCheckedMain();

    this.optionalFilter[selectedIndex].checked = checkedStatus;
    if(this.optionalFilter[selectedIndex].voucherType == 'main') {
      this.optionalFilter.forEach(filter => {
        if(filter.voucherType == value) {
          filter.enabled = checkedStatus;
          if(!checkedStatus && this.getCurrentCheckedMain()) filter.checked = false;
        }
      });
      
      if(!currentCheckedMain && checkedStatus == true) {
        this.optionalFilter.forEach(filter => {
          if(filter.voucherType != value && filter.voucherType != 'main') {
            filter.enabled = false;
            filter.checked = false;
          }
        });
      }
    }

    let getAfterCheckedMain = this.getCurrentCheckedMain();
    if(!getAfterCheckedMain) {
      this.optionalFilter.forEach(filter => {
        filter.enabled = true;
      });
    }

    let getUnchecked = this.optionalFilter.findIndex((filter:any) => {
      return filter.checked === false;
    });
    let getChecked = this.optionalFilter.findIndex((filter:any) => {
      return filter.checked === true;
    });
    this.selectedFilterArray = _.map(this.optionalFilter,this.getCheckedFilterValue).filter((item:any) => {return item != 0;});
    this.selectedFilter = _.map(this.optionalFilter,this.getCheckedFilter).filter((item:any) => {return item != 0;}).join(", ");
    if(getChecked == -1 || getUnchecked == -1) {
      this.selectedFilter = "[Default]";
    }
  }
  getCheckedFilter(item:any) {
    if(item.checked) return item.label; else return 0;
  }
  getCheckedFilterValue(item:any) {
    if(item.checked) return item.value; else return 0;
  }
  clearAllFilter() {
    this.optionalFilter.forEach(filter => {
      filter.enabled = true;
      filter.checked = false;
    });
    this.selectedFilter = "[Default]";
  }
  clearSearch() {
    this.clearAllFilter();
    this.discountTable.deselectVoucher();
    this.monetaryTable.deselectMonetary();
    this.discountResult = [];
    this.discountCount = 0;
    this.monetaryResult = [];
    this.monetaryCount = 0;
    this.startDate = null;
    this.endDate = null;
    this.dateMode = 'OVERLAP';
    this.voucherSearch.patchValue({
      searchQuery: ''
    })
    this.selectAllHotel();
  }
  //Datepicker
  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if((this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) || (this.startDate && !this.endDate)) {
          this.endDate = new Date(this.startDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if((this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) || (!this.startDate && this.endDate)) {
          this.startDate = new Date(this.endDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
  }

  //Search
  setTableLoading(type:boolean) {
    this.discountTable.isLoading = type;
    this.monetaryTable.isLoading = type;
  }
  onSearch():void {
    this.disabledBtnWhileCallingAPI = true;
    this.discountTable.deselectVoucher();
    this.monetaryTable.deselectMonetary();
    this.discountResult = [];
    this.discountCount = 0;
    this.monetaryResult = [];
    this.monetaryCount = 0;
    
    this.setTableLoading(true);
    let query = this.voucherSearch.controls.searchQuery.value;
    let dateMode = this.dateMode;

    let hotelId;
    if(this.selectedHotel && this.selectedHotel.value != "all") {
      hotelId = Number(this.selectedHotel.value);
    } else {
      hotelId = null;
    }

    let filter;
    if(this.selectedFilterArray.length == 0) {
      filter = null;
    } else {
      filter = this.selectedFilterArray;
    }

    let startDate;
    if(this.startDate) {
      startDate = this.utils.convertDate(this.startDate, 'YYYY-MM-dd');
    } else {
      startDate = null;
    }

    let endDate;
    if(this.endDate) {
      endDate = this.utils.convertDate(this.endDate, 'YYYY-MM-dd');
    } else {
      endDate = null;
    }

    this.prevObject.discountOffset = 0;
    this.prevObject.monetaryOffset = 0;
    this.prevObject.prevQuery = query;
    this.prevObject.prevHotel = hotelId;
    this.prevObject.prevStartDate = startDate;
    this.prevObject.prevEndDate = endDate;
    this.prevObject.prevFilter = filter;
    this.prevObject.prevDateMode = dateMode;

    this.getSearchResult(dateMode, startDate, endDate, hotelId, 0, query, filter, 'search');
  }

  checkGiftcardParams() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkGiftcardParams = ['isRedirectFromHA', 'giftcard'].every((key:any) => paramKeys.includes(key));
      if(checkGiftcardParams && params.get('isRedirectFromHA') == 'false') {
        let giftcard = params.get('giftcard');
        this.getSearchResult("OVERLAP", null, null, null, 0, giftcard, ["VOUCHER_CODE", "CUSTOMER_NAME", "EMAIL"], 'search', () => {
          this.monetaryTable.viewMonetary(this.monetaryResult[0]);
          this.monetaryTable.editMonetary(this.monetaryResult[0]);
        });
      }
    })
  }

  showMore(type:string) {
    let prev = this.prevObject;
    switch(type) {
      case 'discount':
        this.discountTable.isLoading = true;
        prev.discountOffset = prev.discountOffset + 10
        this.getSearchResult(prev.prevDateMode, prev.prevStartDate, prev.prevEndDate, prev.prevHotel, prev.discountOffset, prev.prevQuery, prev.prevFilter,'discount', () => {
          setTimeout(() => {
            let tableHeight = document.getElementById(type+'-table')!.offsetHeight;
            document.getElementById(type+'-details')!.style.height = tableHeight + 'px';
          }, 100);
        });
        break;
      case 'monetary':
        this.monetaryTable.isLoading = true;
        prev.monetaryOffset = prev.monetaryOffset + 10
        this.getSearchResult(prev.prevDateMode, prev.prevStartDate, prev.prevEndDate, prev.prevHotel, prev.monetaryOffset, prev.prevQuery, prev.prevFilter,'monetary', () => {
          setTimeout(() => {
            let tableHeight = document.getElementById(type+'-table')!.offsetHeight;
            document.getElementById(type+'-details')!.style.height = tableHeight + 'px';
          }, 100);
        });
        break;
    }
  }

  getSearchResult(dateMode:string, startDate:any, endDate:any, hotelId:any, offset:number, query:any, filter:any, type:string, callback:any = null): void {
    
    switch(type) {
      case 'discount':
        let discountRequest = {
          'dateMode': dateMode,
          'startDate': startDate,
          'endDate': endDate,
          'hotelId': hotelId,
          'limit': 10,
          'needToGetTotal': true,
          'offset': offset,
          'query': query,
          'optionalFilters': filter
        }
        if(this.prevSearchAll) {
          this.voucherService.getDiscountAll(discountRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.discountResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        } else {
          this.voucherService.getDiscount(discountRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.discountResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        }
        break;
      case 'monetary':
        let monetaryRequest = {
          'dateMode': dateMode,
          'startDate': startDate,
          'endDate': endDate,
          'hotelId': hotelId,
          'limit': 10,
          'needToGetTotal': true,
          'offset': offset,
          'query': query,
          'optionalFilters': filter
        }
        if(this.prevSearchAll) {
          this.voucherService.getMonetaryAll(monetaryRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.monetaryResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        } else {
          this.voucherService.getMonetary(monetaryRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.monetaryResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        }
        break;
      case 'search':
        if(!query && !startDate && !endDate && !hotelId && this.selectedFilter == "[Default]") {
          let searchRequest = {
            'dateMode': dateMode,
            'startDate': startDate,
            'endDate': endDate,
            'hotelId': hotelId,
            'limit': 10,
            'needToGetTotal': true,
            'offset': offset,
            'query': query,
            'optionalFilters': filter
          }
          combineLatest([this.voucherService.getDiscountAll(searchRequest), this.voucherService.getMonetaryAll(searchRequest)]).subscribe(data => {
            this.discountResult = data[0].vouchers;
            this.discountCount = data[0].totalCount;
            this.monetaryResult = data[1].vouchers;
            this.monetaryCount = data[1].totalCount;
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
          })
          // this.voucherService.getDiscountAll(searchRequest).subscribe(data => {
          //   this.discountResult = data.vouchers;
          //   this.discountCount = data.totalCount;
          // })
          // this.voucherService.getMonetaryAll(searchRequest).subscribe(data => {
          //   this.monetaryResult = data.vouchers;
          //   this.monetaryCount = data.totalCount;
          //   this.setTableLoading(false);
          //   this.disabledBtnWhileCallingAPI = false;
          // })
          this.prevSearchAll = true;
        } else {
          let filterDiscountArray = [];
          let filterMonetaryArray = [];
          let hasDiscountMain = false;
          let hasMonetaryMain = false;
          if(this.selectedFilterArray.length > 0) {
            this.selectedFilterArray.forEach(item => {
              let itemObject = _.find(this.optionalFilter, {'value': item, 'checked': true});
              if(itemObject) {
                if(itemObject.voucherType == 'main') {
                  if(item == 'DISCOUNT_VOUCHER') {
                    hasDiscountMain = true;
                    filterDiscountArray.push(item);
                  } else {
                    hasMonetaryMain = true;
                    filterMonetaryArray.push(item);
                  }
                } else {
                  if(itemObject.voucherType == 'DISCOUNT_VOUCHER') {
                    filterDiscountArray.push(item);
                  } else {
                    filterMonetaryArray.push(item);
                  }
                }
              }
            });
          } else {
            this.optionalFilter.forEach(item => {
              if(item.voucherType == 'main') {
                if(item == 'DISCOUNT_VOUCHER') {
                  hasDiscountMain = true;
                  filterDiscountArray.push(item.value);
                } else {
                  hasMonetaryMain = true;
                  filterMonetaryArray.push(item.value);
                }
              } else {
                if(item.voucherType == 'DISCOUNT_VOUCHER') {
                  filterDiscountArray.push(item.value);
                } else {
                  filterMonetaryArray.push(item.value);
                }
              }
            });
          }
          if(!hasDiscountMain && filterDiscountArray.length > 0) {
            filterDiscountArray.push('DISCOUNT_VOUCHER');
          }
          if(!hasMonetaryMain && filterMonetaryArray.length > 0) {
            filterMonetaryArray.push('MONETARY_VOUCHER');
          }
          if(filterDiscountArray.length > 0) {
            let discountRequest = {
              'dateMode': dateMode,
              'startDate': startDate,
              'endDate': endDate,
              'hotelId': hotelId,
              'limit': 10,
              'needToGetTotal': true,
              'offset': offset,
              'query': query,
              'optionalFilters': filterDiscountArray
            }
            this.voucherService.getDiscount(discountRequest).subscribe(data => {
              this.discountResult = data.vouchers;
              this.discountCount = data.totalCount;
              this.setTableLoading(false);
              this.disabledBtnWhileCallingAPI = false;
            })
          }
          if(filterMonetaryArray.length > 0) {
            let monetaryRequest = {
              'dateMode': dateMode,
              'startDate': startDate,
              'endDate': endDate,
              'hotelId': hotelId,
              'limit': 10,
              'needToGetTotal': true,
              'offset': offset,
              'query': query,
              'optionalFilters': filterMonetaryArray
            }
            this.voucherService.getMonetary(monetaryRequest).subscribe(data => {
              this.monetaryResult = data.vouchers;
              this.monetaryCount = data.totalCount;
              this.setTableLoading(false);
              this.disabledBtnWhileCallingAPI = false;
              if(callback) {
                callback();
              }
            })
          }
          this.prevSearchAll = false;
        }
        break;
    }
  }
  
  getThreeFilter(){
    return this.getAllFilter().slice(0, 2);
  }
  getAllFilter(){
    return this.optionalFilter.filter((f: any) => f.checked);
  }
  removeFilter(filter: any, e: any) {
    e.stopPropagation();
    this.optionalFilter.filter((f: any) => f.value == filter.value)[0].checked = false;
  }

  //Check table
  getRestriction(data:any, type:string) {
    switch(type) {
      case 'bookStart':
      case 'bookEnd':
        let getData = _.find(data,{'type':'InvoiceDataRestriction'});
        if(getData) {
          if(type == 'bookStart') {
            return this.utils.convertStringDate(getData.dateFrom);
          } else {
            return this.utils.convertStringDate(getData.dateUntil);
          }
        } else {
          return null;
        }
      default:
        return null;
    }
  }

}