@if (toastRequest) {
  <div
    class="custom-toast-item-container"
    [ngClass]="{'show': showToast}"
    [style]="{height: toastHeight + 'px'}"
    >
    <div class="custom-toast-item" #toastItem>
      <div class="custom-toast-content" [class]="toastRequest.type">
        <div class="item-icon">
          @if (toastRequest.type === 'success') {
            <fa-icon type="solid" icon="circle-check"></fa-icon>
          }
          @if (toastRequest.type === 'error') {
            <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
          }
        </div>
        <div class="item-message">
          <div class="item-title">
            <div class="title-text">{{toastRequest.title}}</div>
            <div class="close-btn">
              <button class="btn btn-none icon-only" (click)="closeToast()"><fa-icon type="solid" icon="xmark"></fa-icon></button>
            </div>
          </div>
          <div class="item-desc">{{toastRequest.desc}}</div>
        </div>
      </div>
    </div>
  </div>
}