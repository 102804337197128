import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const hotelAPI = '/api/hotel-chains/1/hotels';

const discount = '/api/management/secure/voucher/discount';
const discountAll = '/api/management/secure/voucher/discount/allNotCancelled';
const discountSave = '/api/management/secure/voucher/discount/save';
const discountCreate = '/api/management/secure/voucher/discount/multiple100/create';
const discountValidity = '/api/management/secure/voucher/discount/checkValidity';

const monetary = '/api/management/secure/voucher/monetary';
const monetaryAll = '/api/management/secure/voucher/monetary/all';
const monetaryUpdate = '/api/management/secure/voucher/monetary/update';

const searchAllotment = '/api/management/secure/allotment/search-allotment';
const getAllotment = '/api/management/secure/allotment/'
const getAllCategoryUrl = '/api/management/secure/voucher/getAllCategory';
const getAllSegmentUrl = '/api/management/secure/voucher/segments';

@Injectable({
  providedIn: 'root'
})

export class VoucherService {
  
  constructor(private http: HttpClient) { }

  public getHotels(): Observable<any> {
    return this.http.get(hotelAPI);
  }

  public getSearchAllotments(): Observable<any> {
    return this.http.get(searchAllotment);
  }
  public getAllotments(allotId:number): Observable<any> {
    return this.http.get(getAllotment + allotId);
  }

  public getDiscount(request: any): Observable<any> {
    return this.http.post<any>(discount, request);
  }
  public getDiscountAll(request: any): Observable<any> {
    return this.http.post<any>(discountAll, request);
  }
  public saveDiscount(request: any) {
    return this.http.post<any>(discountSave, request, {});
  }
  public createDiscount(request: any) {
    return this.http.post<any>(discountCreate, request, {});
  }
  public validityDiscount(request: any) {
    return this.http.post<any>(discountValidity, request, {});
  }
  public cancelDiscount(request: any) {
    return this.http.put<any>(`${discount}/${request}/cancel`, {}, {params: request});
  }

  public getMonetary(request: any): Observable<any> {
    return this.http.post<any>(monetary, request);
  }
  public getMonetaryAll(request: any): Observable<any> {
    return this.http.post<any>(monetaryAll, request);
  }
  public updateMonetary(request: any) {
    return this.http.put<any>(monetaryUpdate, request);
  }
  public sendEmailtMonetary(orderID:any, request: any): Observable<any> {
    return this.http.post<any>(`${monetary}/${orderID}/sendVoucherDetailsEmail`, request);
  }
  public getAllCategory(): Observable<any> {
    return this.http.get(getAllCategoryUrl);
  }
  public getAllSegment(): Observable<any> {
    return this.http.get(getAllSegmentUrl);
  }
}
