<app-header activePage="customers"></app-header>
<div class=" main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="row">
            <div class="col-md-12">
              <h1 class="view-title">Customer Data Export</h1>
            </div>
          </div>
        </div>

        <div class="row">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="col-md-3 ">
              <!-- Start datepicker -->
              <label for="start-date">From</label>
              <!-- <div class="input-group datepicker">
              <input type="text" class="form-control text-input datepicker-input nls-input" placement="bottom"
                formControlName="fromDate" (ngModelChange)="mySelectFromDate($event)">
              <span class="input-group-btn">
                <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [maxDate]="maxDate"
                  [(bsValue)]="bsFromDateValue" (bsValueChange)="onValueChangeFromDate($event)" [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                  <fa-icon type="regular" icon="calendar-days"></fa-icon>
                </button>
              </span>
            </div> -->
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                [(bsValue)]="fromDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>
          </div>
          <!-- End date picker -->
          <div class="col-md-3">
            <label for="end-date">To</label>
            <!-- <div class="input-group datepicker">
            <input type="text" class="form-control text-input datepicker-input nls-input" formControlName="toDate"
              (ngModelChange)="mySelectToDate($event)">
            <span class="input-group-btn">
              <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [maxDate]="maxDate"
                [(bsValue)]="bsToDateValue" (bsValueChange)="onValueChangeToDate($event)" [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </span>
          </div> -->
          <div class="datepicker-input">
            <input type="text" placeholder="End date" class="form-control" bsDatepicker appEditDate
              [(bsValue)]="toDate" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
              (bsValueChange)="dateChange('endDate')"
              [dateCustomClasses]="datepickerCustom"
              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
            <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
              <fa-icon type="regular" icon="calendar-days"></fa-icon>
            </button>
          </div>
        </div>


        <!-- Begin Compare to box -->
        <div class="col-md-6 ">
          <div class="btn-group dropdown " dropdown [insideClick]="true">
            <label for="fields">Fields</label>
            <button type="button" class="form-control custom-input dropbtn no-overflow-button" dropdownToggle
              aria-controls="dropdown-basic">{{selectedFieldLabels}}
              <div class="dropdown-chevron"><fa-icon type="solid" icon="chevron-down"></fa-icon></div>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              @if (!isAllFilters) {
                <li class="normal-checkbox" role="menuitem" (click)="selectAll()">
                  <div for="auto-notify" no-dirty>Select All</div>
                </li>
              }
              @if (isAllFilters) {
                <li class="normal-checkbox" role="menuitem" (click)="clearAll()">
                  <div for="auto-notify" no-dirty><b>Clear All</b></div>
                </li>
              }
              @for (field of fields; track field) {
                <li class="normal-checkbox" role="menuitem">
                  <div class="normal-checkbox" (click)="fieldChange(field)">
                    <input type="checkbox" [checked]="field.checked">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    <div class="checkbox-label">{{field.label}}</div>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
        <div class="col-md-12 text-right download-btn">
          <button type="submit" class="btn btn-green btn-big"
            [disabled]="!(hasFields && isValidFromDate && isValidToDate)">
            <fa-icon type="solid" icon="file-arrow-down"></fa-icon>Download
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>