import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import _ from 'lodash';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';

const TRANSLATION = {
  "orders.isOverrided": 'Price override',
  'lineItems.productId': 'Product',
  'roomRequest.productId': 'Product',
} as any;

const ACTIVITY_ACTION = {
  ADD_COMMENT: 'Added Comment',
  UPDATE_RESERVATION: 'Updated Booking',
  ADDED_EXTRA: 'Added Extra',
  MANUAL_REFUNDED: 'Manual Refunded',
  CANCEL_RESERVATION: 'Canceled Booking',
  SHOW_DOOR_CODE: 'Showed Door Code',
  RESET_DOOR_CODE: 'Reset Door Code',
  UPDATE_CONTACT_INFO: 'Updated Contact',
  MANUAL_PAYMENT: 'Manual Payment',
  REFUND_FOR_UNSATISFIED_SERVICE: 'Refunded For Unsatisfied Service',
  CHANGE_ROOM: 'Changed Room',
  CREATE_RESERVATION: 'Created Booking',
  CONFIRM_RESERVATION: 'Confirmed Reservation',
  INVOICE_MANUAL: 'Created Manual Invoice',
  CREDIT_INVOICE: 'Created Credit Invoice',
  UPDATE_GUEST_INFO: 'Updated Guest Info',
  INVITE_GUEST: "Invite guest",
  REMOVE_GUEST: "Remove guest",
  COMPLETE_OCI: "Completed Online check-in",
  ACCESS_OCI_PAGE: "Access Online check-in page",
  UPDATE_LANGUAGE: "Update Reservation Language",
  UPDATE_RESERVATION_LABEL: "Update special requests"
};

const NOT_RETURN_KEY_VALUE = ["guests.index", "guests.roomAlias", "lineItems.invoiceDate", "lineItems.serviceDate", "orders.id", "fennoaInvoiceDetails", "fennoaInvoiceId", "fennoaInvoiceNo"]

const NEW_LINE_KEY_VALUE = [] as [];

const NOT_RETURN_AND_NEW_LINE_KEY_VALUE = ["guests.id", "orders.roomId", "lineItems.id", "roomRequest.id"]

const CONVERT_VALUE_BY_KEY = [
  {
    id: 0,
    description: 'Datetime',
    keys: ['roomRequest.end', 'roomRequest.start', "changeInProgressSince", "checkIn", "checkOut"],
  },
  {
    id: 1,
    description: 'Currency',
    keys: ['price.amount', "price.beforeAppliedVoucherAmount", "price.oppositeVoucherAmount", "details.toBeRefundedAmount"],
  },
  {
    id: 2,
    description: 'To lowercase',
    keys: ['customer.purposeOfVisit', 'customer.nationality', "option", "bookingChanel", "bookingType", "invoiceStatus", ""],
  },
  {
    id: 3,
    description: 'Product id to product name',
    keys: ['lineItems.productId', 'roomRequest.productId'],
  },
  {
    id: 4,
    description: 'Activity type to string',
    keys: ['fennoaPaymentType', 'invoiceStatus'],
  },
];

const ACTIVITY_TYPE_GROUP = [
  {
    id: 0,
    description: 'Original type',
    actionType: [
      'CANCEL_RESERVATION',
      'SHOW_DOOR_CODE',
      'RESET_DOOR_CODE',
      'MANUAL_PAYMENT',
      'REFUND_FOR_UNSATISFIED_SERVICE',
      'CREATE_RESERVATION',
      'INVOICE_INTEGRATED'
    ],
  },
  {
    id: 1,
    description: 'Activity type',
    actionType: ['ADDED_EXTRA', 'MANUAL_REFUNDED', 'UPDATE_CONTACT_INFO', 'UPDATE_LANGUAGE'],
  },
  {
    id: 2,
    description: 'Comment type',
    actionType: ['ADD_COMMENT'],
  },
  {
    id: 3,
    description: 'Status type',
    actionType: ['CONFIRM_RESERVATION', 'UPDATE_RESERVATION'],
  },
    {
    id: 4,
    description: 'Send type',
    actionType: [
      'SEND_WELCOME_SMS',
      'SEND_CONFIRMATION_SMS',
      'SEND_WELCOME_EMAIL',
      'SEND_CONFIRMATION_EMAIL', 
      "SEND_THANKYOU_EMAIL", 
      "SEND_THANKYOU_SMS",
      "SEND_REMINDER_EMAIL", 
      "SEND_REMINDER_SMS", 
      "SEND_CANCELATION_EMAIL", 
      "SEND_GUEST_INVITATION_EMAIL", 
      "SEND_GUEST_UNINVITE_EMAIL", 
      "SEND_BLOCKED_OTA_CUSTOMER_EMAIL", 
      "SEND_RESERVATION_CHANGE_SMS"],
  },
  {
    id: 6,
    description: 'Change room type',
    actionType: ['CHANGE_ROOM'],
  },
  {
    id: 7,
    description: 'User tracking',
    actionType: ['USER_OPEN_LINK_ACCESS', 'USER_OPEN_EMAIL']
  },
  {
    id: 8,
    description: 'OCI events',
    actionType: ["INVITE_GUEST", "REMOVE_GUEST", "COMPLETE_OCI", "UPDATE_GUEST_INFO", "ACCESS_OCI_PAGE"]
  },
  {
    id: 9,
    description: 'Special requests events',
    actionType: ["UPDATE_RESERVATION_LABEL"]
  }
];

export type LogReservationType = {
  hotel: any,
  guests: any,
  bookingChanel: any,
  bookingType: any,
  createdByAdmin: any
}

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.sass'],
})
export class ActivityLogComponent implements OnInit {

  @Input() reservationCode:string = "";
  @Input() reservationUuid:string = "";
  @Input() reservationProducstById:any = null;
  @Input() reservationCreated:LogReservationType | null = null;
  @Input() activityLogResponse:any = null;

  constructor(
    public utils: UTILS,
    private storeService: StoreService,
  ) {}
  isExpandSort: boolean = false;
  activityLogData: any = [];
  activityLogDataFiltered: any = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  sortList: MenuType[] = [
    {
      value: "newest",
      label: "Newest first",
      disabled: false
    },
    {
      value: "oldest",
      label: "Oldest first",
      disabled: false
    }
  ];
  selectedSort: MenuType = this.sortList[0];

  @HostListener('document:click', ['$event']) documentClickEvent(
    $event: MouseEvent
  ) {
    this.isExpandSort = false;
  }

  ngOnInit(): void {

  }

  setupActivityLog() {
    this.selectedSort = this.sortList[0];
    if(this.activityLogResponse && this.reservationCode && this.reservationUuid && this.reservationProducstById && this.reservationCreated) {
      this.activityLogData = this.createDTOFromLogData(this.activityLogResponse);
      this.activityLogDataFiltered = _.cloneDeep(this.activityLogData);
    }
  }

  handleExpandSortBtn(t: boolean, e: any): void {
    e.stopPropagation();
    this.isExpandSort = t;
  }
  selectSort(item:MenuType) {
    this.selectedSort = item;
    const tmpNewData = _.cloneDeep(this.activityLogData);
    switch (item.value) {
      case "newest":
        this.activityLogDataFiltered = tmpNewData;
        break;
      case "oldest":
        this.activityLogDataFiltered = tmpNewData.reverse();
        break;
      default:
        break;
    }
  }

  convertActionNameCamelCase(action: string) {
    let result = [] as string[];
    let wordArr = action.toLocaleLowerCase().split("_");
    wordArr.forEach(word => {
      result.push(word.charAt(0).toUpperCase() + word.slice(1));
    })
    return result.join(" ");
  }

  convertActionName(action: string) {
    let actionTitle = Object.entries(ACTIVITY_ACTION).find(
      ([key, value]) => key === action
    );
    return actionTitle ? actionTitle[1] : this.convertActionNameCamelCase(action);
  }

  getSortAuthorName(author: string) {
    return author
      .split(' ')
      .map((word) => word[0])
      .slice(0, 2)
      .join('').toLocaleUpperCase();
  }

  getBgColorAvatarNumber(author: string) {
    let sortAuthorFirstName = this.getSortAuthorName(author)[0] || '';
    let colorNum = 'abcdefghijklmnopqrstuvwxyz'
      .split('')
      .findIndex(
        (letter) => sortAuthorFirstName.toLocaleLowerCase() === letter
      );
    if (colorNum < 12) {
      return 1;
    } else if (colorNum < 24) {
      return 2;
    }
    return 3;
  }

  getActivityItemTypeNumber(action: string) {
    let item = ACTIVITY_TYPE_GROUP.find((type) =>
      type.actionType.includes(action)
    );
    if (!item) return 3;
    return item.id;
  }

  convertPrice(price: string) {
    if (!price) return;
    return price.split(' ')[0] + " " + Number(price.split(' ')[1]).toFixed(2);
  }

  public getKeyValueObjectArray(objArr: {}, parentKey = '') {
    let result = [] as any[];
    if (!objArr) return result;
    Object.keys(objArr).forEach((item) => {
      if (this.checkIsObject((objArr as any)[item])) {
        let innerObjArr = [] as any[];
        let val = this.getKeyValueObjectArray((objArr as any)[item], item);
        val.forEach((i) => {
          innerObjArr.push(i);
        });
        result.push([parentKey.length ? parentKey + "." + item : item, innerObjArr]);
      } else if (
        Array.isArray((objArr as any)[item]) &&
        (objArr as any)[item].length
      ) {
        let itemValues = (objArr as any)[item] as [];
        let res = itemValues.map((att) => {
          let wrapperArr = this.getKeyValueObjectArray(att, item);
          if (wrapperArr.length < 2) {
            return wrapperArr[0];
          }
          return wrapperArr;
        });
        result.push([parentKey.length ? parentKey + "." + item : item, res]);
      } else {
        result.push([parentKey.length ? parentKey + "." + item : item, (objArr as any)[item]]);
      }
    });
    return result;
  }

  public convertAttributeNameCamelCase(attributeName: string) {
    let result = '';
    let upperCase =
      attributeName.charAt(0).toUpperCase() + attributeName.slice(1);
    let words = (upperCase.match(/[A-Z][a-z]+/g) || []) as [];
    words.forEach((word: string, index) => {
      if (index > 0) {
        result += ' ' + word.toLocaleLowerCase();
      } else {
        result += word;
      }
    });
    return result;
  }

  public createDTOFromLogData(logData: any[]) {
    let newLogData = [] as any[];
    //BE returned oldest first data, reverse to get newest data.
    const reverseLog = logData.reverse();
    reverseLog.forEach((dataItem, forEachIndex) => {
      if (
        !dataItem.data ||
        this.getActivityItemTypeNumber(dataItem.action) === 0 ||
        this.getActivityItemTypeNumber(dataItem.action) === 4 ||
        this.getActivityItemTypeNumber(dataItem.action) === 6 ||
        this.getActivityItemTypeNumber(dataItem.action) === 7 ||
        this.getActivityItemTypeNumber(dataItem.action) === 8
      ) {
        //Status type just returned, UI just get action, no changed
        if (dataItem.action === "CREATE_RESERVATION" && this.reservationCreated) {
          dataItem.data = this.reservationCreated;
        }
        newLogData.push(dataItem);
      } else {
        //Create log item data object {data: beforeChangeData, afterChangeData, ...} to showing on UI
        let logItemData = { ...dataItem, data: {} };
        let afterChangeData = { ...dataItem.data };
        let beforeChangeData = null;
        if(dataItem.action === "UPDATE_LANGUAGE") {
          const beforeLang = CONSTANT.SUPPORTED_LANGUAGES.find((lang:any) => lang.value === dataItem.data.oldValue);
          const afterLang = CONSTANT.SUPPORTED_LANGUAGES.find((lang:any) => lang.value === dataItem.data.newValue);
          if(beforeLang && afterLang) {
            beforeChangeData = beforeLang.label;
            afterChangeData = afterLang.label;
          }
        } else {
          let nextOlderLog = reverseLog.find(
            (findItem, findIndex) => {
              return findItem.action === dataItem.action && findIndex > forEachIndex;
            }
          );
          if (!nextOlderLog && dataItem.action === "UPDATE_RESERVATION") {
            nextOlderLog = reverseLog.find(findItem => findItem.action === "CREATE_RESERVATION");
          }
  
          if (nextOlderLog) {
            beforeChangeData = this.getDifferentKeyObjectCompare(
              nextOlderLog.data,
              afterChangeData
            );
          }
        }
        logItemData.data.afterChange = afterChangeData;
        logItemData.data.beforeChange = beforeChangeData;
        newLogData.push(logItemData);
      }
    });
    return newLogData;
  }

  public getDifferentKeyObjectCompare(oldData: {}, newData: {}): any {
    let matchedEntriesArr = Object.entries(oldData).filter(
      ([key, value]) =>
        Object.keys(newData).findIndex((attName) => attName === key && (oldData as any)[key] !== (newData as any)[attName]) !== -1
    );
    let matchedObjectArr = [];
    matchedObjectArr = matchedEntriesArr.map(([key, value]) => {
      //Object
      if (
        this.checkIsObject(value) &&
        this.checkIsObject((newData as any)[key])
      ) {
        let recursiveValue = this.getDifferentKeyObjectCompare(
          value as {},
          (newData as any)[key]
        );
        return [key, recursiveValue];
      }
      //Array
      if (
        Array.isArray(value) &&
        value.length &&
        Array.isArray((newData as any)[key]) &&
        (newData as any)[key].length
      ) {
        let newArrValues = (newData as any)[key] as any[];
        let matchedInnerArrValues = value.filter((item) => {
          if (item.id === null || item.id === undefined) return true;
          if (newArrValues.some((newItem) => newItem?.id === item.id)) {
            return true;
          }
          return false;
        }) as any[];
        let innerArrCompare = matchedInnerArrValues.map((item) => {
          if (item.id === null || item.id === undefined) return item;
          let sameObjId = newArrValues.find((att) => att.id === item.id);
          if (!sameObjId) return item;
          return this.getDifferentKeyObjectCompare(item, sameObjId);
        });
        return [key, innerArrCompare];
      }
      //Others
      return [key, value];
    });
    return this.customFromEntries(matchedObjectArr);
  }

  public customFromEntries(arr: any[]) {
    let resultObject = {} as any;
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      resultObject[element[0]] = element[1];
    }
    return resultObject;
  }

  public checkIsObject(params: any): boolean {
    if (
      typeof params === 'object' &&
      !Array.isArray(params) &&
      params !== null
    ) {
      return true;
    }
    return false;
  }

  public convertArrayEntriesToPreLogString(arr: any[], tabNum = -1) {
    if (!arr || arr.length === 0) return '';
    tabNum += 1;
    let stringResult = '';
    arr.forEach((arrItem) => {
      let arrTabNum = tabNum > 0 ? tabNum - 1 : tabNum;
      if (!Array.isArray(arrItem[0]) && !Array.isArray(arrItem[1])) {
        stringResult += this.handleKeyValueReturned(arrItem, tabNum);
      } else if (!Array.isArray(arrItem[0])) {
        stringResult += `${
          '\n' + '\t'.repeat(tabNum) + this.buildDisplayKey(arrItem[0])
        }: ${this.convertArrayEntriesToPreLogString(arrItem[1], tabNum)}`;
      } else {
        stringResult += `${this.convertArrayEntriesToPreLogString(
          arrItem,
          arrTabNum
        )}`;
      }
    });
    let result = stringResult;
    return result;
  }

  public renderLogUI(arr: any[]) {
    return this.convertArrayEntriesToPreLogString(
      this.getKeyValueObjectArray(arr)
    );
  }

  public handleKeyValueReturned(arrItem: any, tabNum: number): string {
    let result = `\n${'\t'.repeat(tabNum) +
    this.buildDisplayKey(arrItem[0])
    }: ${this.buildDisplayValue(arrItem[0], arrItem[1])}`

    let notShowedKeyValue = NOT_RETURN_KEY_VALUE.findIndex(key => key === arrItem[0]) !== -1;
    if (notShowedKeyValue) {
      return '';
    }

    let newLineKeyValue = NEW_LINE_KEY_VALUE.findIndex(key => key === arrItem[0]) !== -1;
    if (newLineKeyValue) {
      result = "\n" + result;
    }

    let notShowedAndNewLineKeyValue = NOT_RETURN_AND_NEW_LINE_KEY_VALUE.findIndex(key => key === arrItem[0]) !== -1;
    if (notShowedAndNewLineKeyValue) {
      return `\n${'\t'.repeat(tabNum)}-----------`;
    }

    return result;
  }

  public buildDisplayKey(key: string) {
    let newKey = TRANSLATION[key]
      ? TRANSLATION[key]
      : this.convertAttributeNameCamelCase(key.split(".")[1] ? key.split(".")[1] : key.split(".")[0]);
    return newKey;
  }

  public buildDisplayValue(key: string, value: any) {
    if (value === null) return "empty";
    if (value === true) return "Yes";
    if (value === false) return "No";
    let convertId = CONVERT_VALUE_BY_KEY.find((item) =>
      item.keys.includes(key)
    )?.id;
    switch (convertId) {
      case 0:
        return this.utils.convertISOFullDate(value);

      case 1:
        return this.convertPrice(value);

      case 2:
        return value.toLocaleLowerCase();

      case 3:
        return this.getValueByProductId(value);

      case 4:
        return this.convertActionNameCamelCase(value);

      default:
        break;
    }
    return value;
  }

  public getValueByProductId(value: any) {
    let result = value;
    let productDetail = this.reservationProducstById[value];
    if (productDetail) {
      result = productDetail.name;
    }
    return result;
  }

  public getStringType4(item: any) {
    let result = "Sent to: ";
    if (!item.data.destinations) return result;
    return result += item.data.destinations.join(", ");
  }

  renderLabels(labelList:string[]) {
    let tmpResult:string[] = [];
    labelList.forEach((label) => {
      const findLabel = this.hotelConfig.MUIfeature.reservationLabelList.find(dLabel => dLabel.id === label);
      if(findLabel) {
        tmpResult.push(findLabel.name);
      } else {
        tmpResult.push(label);
      }
    })
    return tmpResult;
  }
}
