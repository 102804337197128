import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from './token-storage.service';
import { CONSTANT } from '../helpers/constants';
import { UTILS } from '../helpers/utils';

const secureManagement = '/api/management/secure';
const domainSite = secureManagement + '/site-host';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService  {

  constructor(private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private utils: UTILS
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = route.queryParams.token || localStorage.getItem('jwt');
    const refreshToken = route.queryParams.refreshtoken;
    if (route.queryParams.token) {
      window.localStorage.clear();  //Reset the local storage when a token is passed from 
      if (refreshToken) this.tokenStorageService.saveRefreshTokenToken(refreshToken);
      this.tokenStorageService.saveToken(token);
      this.tokenStorageService.saveUser(this.tokenStorageService.decodeJwtToken(token).name);
      window.localStorage.removeItem("role");

      const currentUrl = window.location.hash.split("#")[1];
      let url = new URL("https://fjdkfjdkfjdkfj.djskdj" + currentUrl);
      let params = new URLSearchParams(url.search);
      params.delete("token");
      params.delete("refreshtoken");
      const urlPath = currentUrl.split("?")[0]
      const pathname = window.location.pathname ? `${window.location.pathname}#` : "/#";
      setTimeout(() => {
        window.location.replace(`http://${window.location.host}${pathname}${urlPath}${params.toString() ? `?${params.toString()}` : ""}`)
      }, 100);
      return true;
    }
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const getTokenRoles = this.tokenStorageService.decodeJwtToken(token).roles;
      let pageRequiredRoles = this.tokenStorageService.getRoles(route.url[0].path);
      let hasRoles = this.tokenStorageService.hasRoles(pageRequiredRoles);
      let menuItems = this.tokenStorageService.getPageNamesFromUserRoles(getTokenRoles);
      if (hasRoles) {
        // User has roles on the header, then redirect to these page
        return true;
      } else {
        // User has not role on the header, then redirect to the reservations page
        if (menuItems.length === 0) {
          const getLastUrl = this.utils.encodeLastUrl();
          // this.router.navigateByUrl(`/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`);
          this.router.navigateByUrl(`/login`);
          localStorage.clear();
          return false;
        }
        this.router.navigateByUrl(menuItems[0].pageName);
        return false;
      }
    }
    if (token && this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      // User doesn't log in
      const getLastUrl = this.utils.encodeLastUrl();
      // this.router.navigateByUrl(`/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`);
      this.router.navigateByUrl(`/login`);
      localStorage.clear();
      return false;
    }
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(CONSTANT.AUTH_API, {
      username,
      password,
      returnaccesstoken: false
    }, httpOptions);
  }

  loginWithAccess(username: string, refreshtoken: string): Observable<any> {
    return this.http.post(CONSTANT.AUTH_API, {
      username,
      refreshtoken,
      returnaccesstoken: true
    }, httpOptions);
  }

  confirmUser(username: string, newpassword: string, session: string): Observable<any> {
    return this.http.post(CONSTANT.CONFIRM_USER_API, {
      username,
      newpassword,
      session,
      challenegname: CONSTANT.NEW_PASSWORD_REQUIRED_CHALLENGE
    }, httpOptions);
  }

  public getDomainSiteHost(): Observable<any> {
    const { headers } = httpOptions
    return this.http.get(domainSite, { headers, responseType: 'text' });
  }
}
