import { Component, OnInit, HostListener, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'
import { cloneDeep } from "lodash";
import moment from 'moment';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { HotelService } from 'src/app/services/hotel.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { AnimationOptions } from 'ngx-lottie';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.sass','../../../styles.sass']
})
export class HotelsComponent implements OnInit, AfterViewInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }  
  hotels: any[] = [];
  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;

  isAllStartDateEndDateNull: boolean = false;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  startDate: any = new Date();
  endDate: any = new Date();
  datepickerConfig: any = CONSTANT.datepickerConfig;
  loading: boolean = true;

  isFormValid:boolean = false;

  datepickerCustom: DatepickerDateCustomClasses[];

  constructor(
    private storeService: StoreService,
    private toast: CustomToastService, 
    public utils: UTILS
  ) {
    this.datepickerCustom = [];
  }
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  ngOnInit(): void {

  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
}
