import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CurrencyFormatPipe } from './pipe/currency-format.pipe';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { CustomersComponent } from './components/customers/customers.component';
import { BreakfastComponent } from './components/breakfast/breakfast.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ReportsComponent } from './components/reports/reports.component';
import { UsageComponent } from './components/usage/usage.component';
import { AllotmentsComponent } from './components/allotments/allotments.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { HotelsComponent } from './components/hotels/hotels.component';

import { authInterceptorProviders } from './helpers/auth.interceptor';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/chronos';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MaintenanceDetailsComponent } from './components/maintenance/maintenance-details/maintenance-details.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';
import { CustomerDataExportComponent } from './components/customer-data-export/customer-data-export.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { DiscountDetailsComponent } from './components/voucher/discount/discount-details/discount-details.component';
import { DiscountTableComponent } from './components/voucher/discount/discount-table/discount-table.component';
import { MonetaryTableComponent } from './components/voucher/monetary/monetary-table/monetary-table.component';
import { MonetaryDetailsComponent } from './components/voucher/monetary/monetary-details/monetary-details.component';
import { PaymentProviderFilterPipe } from './pipe/payment-provider-filter.pipe';
import { ReservationDetailComponent } from './components/reservations/reservation-detail/reservation-detail.component';
import { EditPriceForReservationDirective } from './directives/edit-price-for-reservation.directive';
import { DayAsCheckboxDirective } from './directives/day-as-checkbox.directive';
import { AllotmentComponent } from './components/allotment/allotment.component';
import { RoomTypePipe } from './pipe/room-type.pipe';
import { EditDateDirective } from './directives/edit-date.directive';
import { CleanSourcePipe } from './pipe/clean-source.pipe';
import { AllotmentDetailComponent } from './components/allotment-detail/allotment-detail.component';
import { GlobalHttpInterceptorService } from './services/global-http-interceptor.service';
import { UserComponent } from './components/user/user.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderUserManagementComponent } from './components/header-user-management/header-user-management.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { LocksComponent } from './components/locks/locks.component';
import { LockDetailComponent } from './components/lock-detail/lock-detail.component';
import { LockUsageComponent } from './components/lock-usage/lock-usage.component';
import { EditPriceWithZeroDirective } from './directives/edit-price-with-zero';
import { EditPercentageDirective } from './directives/edit-percentage';
import { ForgotComponent } from './components/forgot/forgot.component';
import { InternalToolsComponent } from './components/internal-tools/internal-tools.component';
import { InternalToolsMuiComponent } from './components/internal-tools-mui/internal-tools-mui.component';
import { ManualExternalRetrieveComponent } from './components/internal-tools-mui/manual-external-retrieve/manual-external-retrieve.component';
import { UpdateReservationLangComponent } from './components/internal-tools-mui/update-reservation-lang/update-reservation-lang.component';
import { DropdownMenuComponent } from './components/common/dropdown-menu/dropdown-menu.component';
import { RouterOutlet } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomModalComponent } from './components/common/custom-modal/custom-modal.component';
import { InlineAlertComponent } from './components/common/inline-alert/inline-alert.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { BeonxHotelNotifExportComponent } from './components/internal-tools-mui/beonx-hotel-notif-export/beonx-hotel-notif-export.component';
import { CustomToastComponent } from './components/common/custom-toast/custom-toast.component';
import { CustomToastItemComponent } from './components/common/custom-toast/custom-toast-item/custom-toast-item.component';
import { FaIconComponent } from './components/common/fa-icon/fa-icon.component';
import { PageNotFoundComponent } from './components/error/page-not-found/page-not-found.component';
import { OtaNotificationEmailComponent } from './components/hotels/ota-notification-email/ota-notification-email.component';
import { IntegratedEivoiceOpsComponent } from './components/hotels/integrated-eivoice-ops/integrated-eivoice-ops.component';

@NgModule({
  declarations: [
      AppComponent,
      LoginComponent,
      FooterComponent,
      HeaderComponent,
      ReservationsComponent,
      ReservationDetailComponent,
      CustomersComponent,
      BreakfastComponent,
      MaintenanceComponent,
      PricingComponent,
      ReportsComponent,
      UsageComponent,
      AllotmentsComponent,
      VoucherComponent,
      HotelsComponent,
      MaintenanceDetailsComponent,
      CustomerDashboardComponent,
      CustomerDataExportComponent,
      CustomerCardComponent,
      CustomerComponent,
      CustomerDetailComponent,
      DiscountDetailsComponent,
      DiscountTableComponent,
      MonetaryTableComponent,
      MonetaryDetailsComponent,
      CurrencyFormatPipe,
      PaymentProviderFilterPipe,
      EditPriceForReservationDirective,
      EditPriceWithZeroDirective,
      EditPercentageDirective,
      DayAsCheckboxDirective,
      ScrollTrackerDirective,
      AllotmentComponent,
      RoomTypePipe,
      EditDateDirective,
      CleanSourcePipe,
      AllotmentDetailComponent,
      UserComponent,
      DashboardComponent,
      HeaderUserManagementComponent,
      ActivityLogComponent,
      LocksComponent,
      LockDetailComponent,
      LockUsageComponent,
      ForgotComponent,
      InternalToolsComponent,
      InternalToolsMuiComponent,
      ManualExternalRetrieveComponent,
      UpdateReservationLangComponent,
      DropdownMenuComponent,
      CustomModalComponent,
      InlineAlertComponent,
      BeonxHotelNotifExportComponent,
      CustomToastComponent,
      CustomToastItemComponent,
      FaIconComponent,
      PageNotFoundComponent,
      OtaNotificationEmailComponent,
      IntegratedEivoiceOpsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    LottieComponent,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule,
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    RouterOutlet,
    ReactiveFormsModule
  ], 
    providers: [
      authInterceptorProviders,
      BsDropdownConfig,
      provideLottieOptions({
          player: () => import('lottie-web'),
      }),
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
      provideHttpClient(withInterceptorsFromDi())
    ] 
})
export class AppModule { 
  constructor(localeService: BsLocaleService) {
    defineLocale('en-gb', enGbLocale);
    localeService.use('en-gb');
  }
}
