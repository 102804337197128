[
  {
    "code": "1",
    "name": "Nordea",
    "paymentType": "DEBIT"
  },
  {
    "code": "2",
    "name": "Osuuspankki",
    "paymentType": "DEBIT"
  },
  {
    "code": "3",
    "name": "Danske Bank",
    "paymentType": "DEBIT"
  },
  {
    "code": "5",
    "name": "Ålandsbanken",
    "paymentType": "DEBIT"
  },
  {
    "code": "6",
    "name": "Handelsbanken",
    "paymentType": "DEBIT"
  },
  {
    "code": "10",
    "name": "S-Pankki",
    "paymentType": "DEBIT"
  },
  {
    "code": "50",
    "name": "Aktia",
    "paymentType": "DEBIT"
  },
  {
    "code": "51",
    "name": "POP Pankki",
    "paymentType": "DEBIT"
  },
  {
    "code": "52",
    "name": "Säästöpankki",
    "paymentType": "DEBIT"
  },
  {
    "code": "53",
    "name": "Visa (Nets)",
    "paymentType": "CREDIT"
  },
  {
    "code": "54",
    "name": "MasterCard (Nets)",
    "paymentType": "CREDIT"
  },
  {
    "code": "58",
    "name": "MobilePay",
    "paymentType": "CREDIT"
  },
  {
    "code": "61",
    "name": "Oma Säästöpankki",
    "paymentType": "DEBIT"
  }
]