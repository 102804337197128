<app-header activePage="allotments"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">

      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Create Allotment</div>
        </div>
      </div>

      <form [formGroup]="addAllotmentForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="allotment-input-container">
              <div class="item-title">
                <label>Name</label>
              </div>
              <div class="item-content">
                <input type="text" class="form-control custom-input" id="alm-name" [ngClass]="{'error-glowing': !isValidName}" formControlName="name" no-special-character>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="allotment-input-container">
              <div class="item-title">
                <label>Type</label>
              </div>
              <div class="item-content">
                <dropdown-menu
                  [menuList]="allotmentTypeList"
                  [selectedItem]="selectedAllotmentType"
                  [fullWidth]="true"
                  (selectItem)="selectAllotmentType($event)"
                ></dropdown-menu>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="allotment-input-container top-align">
              <div class="item-title">
                <label>Description</label>
              </div>
              <div class="item-content">
                <textarea type="text" class="custom-input custom-textarea" id="alm-description" formControlName="description" rows="3"></textarea>
              </div>
            </div>
          </div>
          @if (hotelActived && hotelActived.allotmentType === 'HARD') {
            <div class="col-12">
              <div class="allotment-input-container">
                <div class="item-title">
                  <label>Group contact email</label>
                </div>
                <div class="item-content">
                  <input type="text" class="form-control custom-input nls-input text-input" id="alm-email"
                    [ngClass]="{'error-glowing': !isValidEmail}" formControlName="email" required>
                </div>
              </div>
            </div>
          }

        </div>

        @if (hotels.length > 1) {
          <div class="allotment-input-container top-align">
            <div class="item-title"><label for="alm-hotels" class="control-label">Hotels</label></div>
            <div class="item-content">
              <div class="row">
                @for (hotelChecked of hotels; track hotelChecked) {
                  <div class="col-6 col-sm-4">
                    <div class="normal-checkbox" style="margin-top: 10px;">
                      <input type="checkbox" [checked]="hotelChecked.selected" (click)="onHotelCheck(hotelChecked)">
                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                      <div class="checkbox-label">{{ utils.hotelDisplayByHotel(hotelConfig, hotelChecked) }}</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }

      </form>

    </div>
  </div>

  <div class="wrapper-outer-table">

    <div class="outer-table">
      <div class="container-fluid">
        @if (hotels.length > 1 && hasHotelSelected) {
          <!-- Allotment tab -->
          <div class="allotment-tab">
            @for (hotel of hotels; track hotel; let i = $index) {
              @if (hotel.selected) {
                <div class="item-tab" [ngClass]="{'active': hotel.active}" (click)="activeHotelTab(hotel)">
                  <ng-container>
                    {{ utils.hotelDisplayByHotel(hotelConfig, hotel) }}
                  </ng-container>
                </div>
              }
            }
          </div>
        }
        @if (hasHotelSelected) {
          <div>
            @if (hotelActived) {
              <div class="tab-content">
                <div class="container-title">Configuration for {{ utils.hotelDisplayByLabel(hotelConfig, hotels, hotelActived.label) }}</div>
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <div class="allotment-input-container">
                      <div class="item-title">
                        <label>Start date</label>
                      </div>
                      <div class="item-content">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                            [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                            (blur)="validateDate()" (bsValueChange)="dateChange('startDate')" [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                            <fa-icon type="regular" icon="calendar-days"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="allotment-input-container">
                      <div class="item-title">
                        <label>End date</label>
                      </div>
                      <div class="item-content">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select end date" class="form-control" appEditDate
                            [(bsValue)]="endDate" bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()"
                            (blur)="validateDate()" (bsValueChange)="dateChange('endDate')" [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                            <fa-icon type="regular" icon="calendar-days"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  @if (hotelActived.allotmentType === 'HARD') {
                    <div class="col-12 col-sm-7">
                      <div class="allotment-input-container">
                        <div class="item-title">
                          <label>Release date and time</label>
                        </div>
                        <div class="item-content">
                          <div class="row no-gutters">
                            <div class="col-7">
                              <div class="datepicker-input">
                                <input type="text" placeholder="Select expiry date" class="form-control" bsDatepicker
                                  appEditDate [ngClass]="{'error-glowing': isExpiryDate }" [(bsValue)]="expiryDate"
                                  #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                                  (bsValueChange)="updateExpiryDate()"
                                  [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                                  <fa-icon type="regular" icon="calendar-days"></fa-icon>
                                </button>
                              </div>
                            </div>
                            <div class="col-5">
                              <dropdown-menu
                                menuType="timepicker"
                                [initialDate]="expiryDate"
                                alignment="center"
                                [highlightError]="isExpiryTime"
                                [fullWidth]="true"
                                (selectDate)="setDateFromDropdown($event)"
                              ></dropdown-menu>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="allotment-input-container longer-title">
                        <div class="item-title">
                          <label>Set amount of rooms</label>
                        </div>
                        <div class="item-content">
                          <div class="set-amount-hard">
                            <div class="custom-input-number">
                              <input type="number" class="form-control custom-input text-input nls-input"
                                [ngClass]="{'error-glowing': !isValidateForAmountOfRooms }" [(ngModel)]="applyRangeAmountOfRoom" (change)="validateAmountOfRooms(applyRangeAmountOfRoom)" min="1">
                            </div>
                            <div class="set-button">
                              <button class="btn btn-green" (click)="applyAmountOfRoomForMultipleDay(true)">Apply</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                @if (hotelActived.allotmentType !== 'HARD') {
                  <div class="allotment-input-container">
                    <div class="item-title">
                      <label>Day</label>
                    </div>
                    <div class="item-content">
                      <div class="day-of-week-container">
                        @for (hotelChecked of hotelActived.weekDays; track $index) {
                          <div class="dow-item">
                            <div class="normal-checkbox" style="margin-top: 10px;" >
                              <input type="checkbox" [checked]="hotelChecked.selected" (click)="updateDayOfWeek(hotelChecked)">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{hotelChecked.code }}</div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                <!--  Amount of room -->
                <div class="allotment-input-container top-align">
                  <div class="item-title">
                    <label>Rooms</label>
                  </div>
                  <div class="item-content">
                    <div class="date-container">
                      @for (date of hotelActived.hotelAllotmentDates; track $index) {
                        @if(date.checked) {
                          <div class="alm-date" [ngClass]="{'weekend': date.day === 'Sat' || date.day === 'Sun'}">
                            <p class="date" ng-bind="date.day">{{ date.day }}</p>
                            <p class="date" ng-bind="date.time"><strong>{{ date.time }}</strong></p>
                            <div class="custom-input-number" style="margin-top: 16px">
                              <input type="number" value="{{ date.availability }}" min="1" (change)="updateAvailabilityForWeekdays($event, date)"
                                [disabled]="hotelActived.allotmentType === 'HARD'">
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </div>
                <!-- Soft allotment -->
                @if (hotelActived.allotmentType !== 'HARD') {
                  <div class="allotment-input-container">
                    <div class="item-title">
                      <label>Set amount of rooms</label>
                    </div>
                    <div class="item-content">
                      <div class="set-amount-container">
                        <div class="amount-input">
                          <div class="custom-input-number">
                            <input type="number" class="form-control custom-input" min="1"
                              [(ngModel)]="applyRangeAmountOfRoom">
                          </div>
                        </div>
                        <div class="amount-start-date">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                              [(bsValue)]="applyRangeStartDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                              (blur)="validateDate()" (bsValueChange)="rangeDateChange('startDate')" [dateCustomClasses]="datepickerCustomForApplyAmount"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                              <fa-icon type="regular" icon="calendar-days"></fa-icon>
                            </button>
                          </div>
                        </div>
                        <div class="amount-end-date">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Select end date" class="form-control" appEditDate
                              [(bsValue)]="applyRangeEndDate" bsDatepicker #endDateInput="bsDatepicker" [dateCustomClasses]="datepickerCustomForApplyAmount"
                              (input)="disableSearch()" (blur)="validateDate()" (bsValueChange)="rangeDateChange('endDate')"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                              <fa-icon type="regular" icon="calendar-days"></fa-icon>
                            </button>
                          </div>
                        </div>
                        <div class="apply-btn-wrapper">
                          <div>
                            <button class="btn btn-green btn-big"
                            (click)="applyAmountOfRoomForMultipleDay(false)">Apply</button>
                          </div>
                          <div>
                            @if (hotelActived.allotmentType !== 'HARD') {
                              <button class="btn btn-green"
                              (click)="applyToAllHotel()">Apply to all hotels</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                @if (hasHotelSelected) {
                  <div class="form-horizontal" style="margin-top: 30px;">
                    <div class="form-group text-right">
                      <button [disabled]="disabledBtnWhileCallingAPI" class="btn btn-green" (click)="createAllotment()"><fa-icon type="solid" icon="plus"></fa-icon>Create allotment</button>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>
