<div [hidden]="!hotelConfig.feature.giftCard">
  @if (viewDetail && sendVoucher) {
    <table class="custom-table detail-table monetary-table">
      <colgroup>
      <col style="width: 43%;">
      <col>
      </colgroup>
      <thead>
        <tr rowspan="2">
          <th colspan="2">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>Cusomter &#64;</b></td>
          <td class="number-input">
            @if (!editDetail) {
              {{sendVoucher.customerEmail}}
            }
            @if (editDetail) {
              <input type="text" name="customerEmail" value="{{customerEmail}}"
                (input)="updateInputEmail('customer', $event)">
            }
          </td>
        </tr>
        <tr>
          <td><b>Phone</b></td>
          <td class="number-input">
            @if (!editDetail) {
              {{sendVoucher.customerMobile}}
            }
            @if (editDetail) {
              <input class="phone-number" type="text" name="phone" (input)="validateDetailInput('phone',$event)" [value]="sendVoucher.customerMobile">
            }
          </td>
        </tr>
        <tr>
          <td><b>Recipient &#64;</b></td>
          <td class="number-input">
            @if (!editDetail) {
              {{sendVoucher.recipientEmail ? sendVoucher.recipientEmail : ''}}
            }
            @if (editDetail) {
              <input type="text" name="recipientEmail" value="{{recipientEmail}}"
                (input)="updateInputEmail('recipient', $event)">
            }
          </td>
        </tr>
        <tr>
          <td colspan="2" class="textarea-input">
            <b>Message to recipient</b>
            @if (showMessage) {
              <button class="hide-btn" (click)="toggleDetail('message')">Hide</button>
            }
            @if (!showMessage) {
              <button class="hide-btn" (click)="toggleDetail('message')">Show</button>
            }
            @if (showMessage) {
              <textarea rows="4" style="margin-top:10px" [disabled]="!editDetail" (input)="countMessage($event)" [(ngModel)]="sendVoucher.greetingText"></textarea>
              <div style="margin-bottom:20px">Maximum {{sendVoucher.greetingText && sendVoucher.greetingText.length ? sendVoucher.greetingText.length : 0}}/255 characters</div>
              <b>Notes (internal use only)</b>
              <textarea rows="4" style="margin-top:10px" disabled>{{sendVoucher.refundedReason}}</textarea>
            }
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <b>Payments</b>
            @if (showPayments) {
              <button class="hide-btn" (click)="toggleDetail('payments')">Hide</button>
            }
            @if (!showPayments) {
              <button class="hide-btn" (click)="toggleDetail('payments')">Show</button>
            }
          </td>
        </tr>
        @if (showPayments) {
          <tr>
            <td><b>Date</b></td>
            <td>
              {{sendVoucher.created ? utils.convertISODate(sendVoucher.created) : ''}}
            </td>
          </tr>
          <tr>
            <td><b>Amount</b></td>
            <td>
              {{sendVoucher.amount | number: '1.2-2'}} {{utils.getCurrency(sendVoucher.currencyCode)}}
            </td>
          </tr>
          <tr>
            <td><b>Payment provider</b></td>
            <td>
              {{ sendVoucher.payments[0] ? sendVoucher.payments[0].paymentProvider : '' }}
            </td>
          </tr>
          <tr>
            <td><b>Payment method</b></td>
            <td>
              {{ sendVoucher.payments[0] ? sendVoucher.payments[0].paymentTypeAndName : '' }}
            </td>
          </tr>
        }
      </tbody>
    </table>
  }

  @if (editDetail) {
    <div class="row" style="margin-bottom: 20px">
      <div class="col-md-6">
        <button class="btn btn-green large-btn full-width" [disabled]="loading" (click)="submitUpdate()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Update</button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-green large-btn full-width" (click)="openResendModal()">&#64; Resend</button>
      </div>
    </div>
  }
</div>

<!-- Restauran email modal -->
<custom-modal
  modalName="resendModal"
  modalTitle="Notification"
  modalOrder="0"
>
  <div class="custom-modal-body">
    <div class="custom-modal-voucher">
      <div class="row">
        <div class="col-md-4">
          <div class="normal-checkbox modal-fix">
            <input type="checkbox" [checked]="checkedCustomer" (click)="resendCheck('customer', $event)">
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
            <div class="checkbox-label">Email</div>
          </div>
        </div>
        <div class="col-md-8">
          <input type="text" name="resendCustomerEmail" placeholder="Customer email" value="{{customerEmail}}"
            (input)="validateResend()">
        </div>
      </div>
      <div class="row" style="margin-top: 20px">
        <div class="col-md-4">
          <div class="normal-checkbox modal-fix">
            <input type="checkbox" [checked]="checkedRecipient" (click)="resendCheck('recipient', $event)">
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
            <div class="checkbox-label">Recipient</div>
          </div>
        </div>
        <div class="col-md-8">
          <input type="text" name="resendRecipientEmail" placeholder="Recipient email" value="{{recipientEmail}}"
            (input)="validateResend()">
        </div>
      </div>
    </div>
  </div>
  <div class="custom-modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="closeCustomModal('resendModal')">Close</button>
    <button type="submit" class="btn btn-green" [disabled]="!resendValid || disabledBtnWhileCallingAPI" (click)="sendNotification()">Send</button>
  </div>
</custom-modal>