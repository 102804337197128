import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileLoadService } from 'src/app/services/file-load.service';

export type FaIconType = "brands" | "duotone" | "light" | "regular" | "sharp-duotone-solid" | "sharp-light" | "sharp-regular" | "sharp-solid" | "sharp-thin" | "solid" | "thin";

@Component({
  selector: 'fa-icon',
  templateUrl: './fa-icon.component.html',
  styleUrl: './fa-icon.component.sass'
})

export class FaIconComponent implements OnInit {
  @ViewChild('iconContainer', { static: true }) iconContainerRef!: ElementRef;
  @Input() type:FaIconType = "solid";
  @Input() icon:string = "";
  @Input() width:string = "20";
  svgContent:string = ""

  constructor(
    private fileLoadService: FileLoadService
  ) {}

  ngOnInit(): void {
    if(this.type && this.icon) {
      this.loadIcon();
    }
  }

  loadIcon(): void {
    this.fileLoadService.getFile(`/icon/${this.type}/${this.icon}.svg`).subscribe((data) => {
      const element = this.iconContainerRef.nativeElement;
      if (element) {
        element.innerHTML = data;
      }
    });
  }

}