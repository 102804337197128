<app-header activePage="hotels"></app-header>
<div class="main-container">
  <div class="page-header-container" style="margin-bottom: 30px;">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Settings</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mui-container">
    <integrated-eivoice-ops></integrated-eivoice-ops>
    <!-- <ota-notification-email></ota-notification-email> -->
  </div>
</div>  
