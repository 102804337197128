<app-header activePage="allotments"></app-header>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">

      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Allotments</div>
        </div>
        <div class="page-twb-button">
          <button type="button" class="btn btn-green" (click)="redirectAllotment()">
            <fa-icon type="solid" icon="plus"></fa-icon>Create
          </button>
        </div>
      </div>

      <form [formGroup]="allotmentForm" (ngSubmit)="onSearch()">
        <div class="row">
          <div class="col-12 col-sm-2">
            <label for="hotel">Hotel</label>
            <dropdown-menu
              [menuList]="hotelList"
              [selectedItem]="selectedHotel"
              [fullWidth]="true"
              (selectItem)="selectHotel($event)"
            ></dropdown-menu>
          </div>
          <div class="col-12 col-sm-3">
            <label for="name">Name</label>
            <input type="text" formControlName="name" class="form-control custom-input" />
          </div>
          <div class="col-12 col-sm-3">
            <label for="hotel">Description</label>
            <input type="text" formControlName="description" class="form-control custom-input" />
          </div>
          <div class="col-6 col-sm-2">
            <label for="hotel">Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker
                [(bsValue)]="bsStartDateValue" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-6 col-sm-2">
            <label for="hotel">End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control" bsDatepicker
                [(bsValue)]="bsEndDateValue" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red allotment-end-date', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <fa-icon type="regular" icon="calendar-days"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-filter">
          <div class="bottom-filter-left"></div>
          <div class="bottom-filter-right">
            <button type="button" class="btn btn-none-outline-green" (click)="clearSearch()"><fa-icon type="solid" icon="trash-can"></fa-icon>Clear Search
          </button>
          <button type="submit" [disabled]="!(isValidStartDate && isValidEndDate) || isLoading" class="btn btn-green btn-big">
            <fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search
          </button>
        </div>
      </div>
    </form>

  </div>
</div>

@if (allotments.length>0) {
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="container-title">History</div>
          </div>
        </div>
        @for (a of allotments; track a) {
          <div class="row " class="{{a.cancelled ? 'blur-more': ''}}">
            <!-- <div class="row row-bottom" *ngFor="let a of allotments | orderBy: 'name'" class="{{a.cancelled ? 'blur-more': ''}}"> -->
            <div class="col-sm-12 ">
              <div class="col-sm-12 allotment-info">
                <div class="col-sm-10 info-block" (click)="goDetailHotelAllotment(a)">
                  <div class="row">
                    <div class="col-sm-6">
                      <p class="name">{{a.name}}</p>
                    </div>
                    <div style="text-align: right;" class="col-sm-6">
                      <span class="created-date">Created date - {{a.created | date: 'dd.MM.yyyy'}}</span>
                    </div>
                  </div>
                  <p class="description">{{a.description || '&nbsp;'}}</p>
                </div>
                <div class="col-sm-1 sub-info-block">
                  <span class="type"
                  class="{{(a.allotmentType === 'SOFT') ? 'type-soft' : 'type-hard'}}">{{a.allotmentType}}</span>
                </div>
                <div class="col-sm-1 action-block">
                  <button class="btn btn-none-outline-green icon-only" (click)="redirectAllotmentById(a.id)">
                    <fa-icon type="solid" icon="pen-to-square"></fa-icon>
                  </button>
                </div>
              </div>
              @if (a.isShowHotelAllotment) {
                <div class="col-sm-12">
                  <!-- <loading *ngIf="isHotelAllotment"></loading> -->
                  @if (!isHotelAllotment) {
                    <div class="allotment-detail-info grey-border custom-table-container">
                      <table class="table custom-table">
                        <thead>
                          <tr>
                            <th>Hotel</th>
                            <th>Start date</th>
                            <th>End date</th>
                            <th>Day restriction</th>
                            <th>Usage</th>
                          </tr>
                        </thead>
                        <tbody>
                          @for (d of a.hotelAllotment; track d) {
                            <tr>
                              <td>{{d.label}}</td>
                              <td>{{d.startDate | date:'dd.MM.yyyy'}}</td>
                              <td>{{d.endDate | date:'dd.MM.yyyy'}}</td>
                              <td dayAsCheckbox [dayRestriction]="d.dayOfWeeks">
                              </td>
                              <td>
                                <a tooltip="See usage" placement="right">
                                  <fa-icon type="solid" icon="up-right-from-square"></fa-icon>
                                </a>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                      <div class="allotment-voucher-block">
                        <strong class="allotment-voucher-title">Vouchers: </strong>
                        @if (a.vouchers) {
                          <span>{{a.vouchers.join(', ')}}</span>
                        }
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}
</div>


<app-footer></app-footer>