import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import _, { cloneDeep, constant, forEach } from 'lodash';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { AllotmentsService } from 'src/app/services/allotments.service';
import { HotelService } from 'src/app/services/hotel.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-allotment',
  templateUrl: './allotment.component.html',
  styleUrls: ['./allotment.component.sass'],
})
export class AllotmentComponent implements OnInit {
  hotels: any = [];
  hotelActive: any = {};
  hotelActived: any = {};
  totalAvailableRooms = 0;
  hasHotelSelected = true;
  isValidName = true;
  isValidEmail = true;
  isExpiryDate = false;
  isExpiryTime = false;
  isValidateForAmountOfRooms = true;
  applyRangeAmountOfRoom = 1;
  applyRangeAmountOfRoomInit = 1;
  dates = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  today: any = new Date();
  startDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  endDate: any = new Date(
    new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
  );
  expiryDate: any = new Date(new Date().setHours(7, 0, 0, 0));
  minEnd: any = new Date(new Date().getTime() + 6 * 24 * 3600 * 1000);
  isFormValid: boolean = false;

  applyRangeStartDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  applyRangeEndDate: any = new Date(
    new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
  );
  minRangeEnd: any = new Date(
    new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
  );
  isRangeValid: boolean = false;

  addAllotmentForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [
      // Validators.required,
      Validators.pattern(/^[a-z0-9 ,.'-]+$/i),
    ]),
    description: new UntypedFormControl(''),
    email: new UntypedFormControl("", [
      Validators.required,
      // Validators.email,
      Validators.pattern(CONSTANT.REGEX_EMAIL),
    ]),
  });

  datepickerCustom: DatepickerDateCustomClasses[];
  datepickerCustomForApplyAmount: DatepickerDateCustomClasses[];

  allotmentTypeList:MenuType[] = [];
  selectedAllotmentType?:MenuType;

  disabledBtnWhileCallingAPI: boolean = false;

  constructor(
    private router: Router,
    private hotelService: HotelService,
    private allotmentsService: AllotmentsService,
    public utils: UTILS,
    private toast: CustomToastService,
    private storeService: StoreService,
  ) {
    this.datepickerCustom = [];
    this.datepickerCustomForApplyAmount = [];
  }

  get f() {
    return this.addAllotmentForm.controls;
  }

  ngOnInit(): void {
    this.hotelService.getHotels().subscribe((hotels: any) => {
      hotels.forEach((hotel: any, index: any) => {
        this.hotels.push(this.hotelAllotments(hotel, 'SOFT', false, index, hotels.length === 1));
      });
      this.hotelActived = this.hotels[0];
      this.hasHotelSelected = this.checkHasHotelSelected();

      let tmpAllotmentTypeList:MenuType[] = [];
      CONSTANT.ALLOTMENT_TYPES.forEach(type => {
        tmpAllotmentTypeList.push({
          label: type.name,
          value: type.value,
          disabled: false
        })
      })
      this.allotmentTypeList = tmpAllotmentTypeList;
      this.selectAllotmentType(this.allotmentTypeList[0])
    });
  }

  selectAllotmentType(item:MenuType) {
    this.selectedAllotmentType = item;
    const allotmentType = item.value;
    this.hotelActived.allotmentType = allotmentType;
    // Check temp hotels
    let hotelsTemp: any[] = [];
    this.hotels.forEach((hotel: any, index: any) => {
      hotelsTemp.push(this.hotelAllotments(hotel, allotmentType, false, index, this.hotels.length === 1));
    });
    this.hotels = hotelsTemp;
    if (allotmentType === 'SOFT' || this.hotels.length === 1) {
      this.hotels[0].active = true;
    }

    // Show/hidden create button and hotel tab when selecting least one hotel/don't select any hotel
    this.hasHotelSelected = this.checkHasHotelSelected();
    // Update hotel active when changing allotment type
    this.hotelActived = this.hotels[0];
    this.expiryDate = this.hotelActived.expiryDate;
    // Get availabilities for hotel with Hard Allotment when choosing allotment's type
    if (this.hotelActived.allotmentType === 'HARD') {
      if (JSON.stringify(this.hotelActived) !== '[]') {
        this.getAvailabilitiesForHotel(this.hotelActived);
      }
    }
  }

  updateDatepicker() {
    let custom = [{ date: this.startDate, classes: ['selectedDate', 'start'] }];
    let tmpTime = this.startDate.getTime() + 24 * 3600 * 1000;
    while (tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime),
        classes: ['dateRange'],
      });
      tmpTime += 24 * 3600 * 1000;
    }
    custom.push({ date: this.endDate, classes: ['selectedDate', 'end'] });
    this.datepickerCustom = custom;
  }

  updateDatepickerRangeDate() {
    let custom = [
      { date: this.applyRangeStartDate, classes: ['selectedDate', 'start'] },
    ];
    let tmpTime = this.applyRangeStartDate.getTime() + 24 * 3600 * 1000;
    while (tmpTime < this.applyRangeEndDate.getTime()) {
      custom.push({
        date: new Date(tmpTime),
        classes: ['dateRange'],
      });
      tmpTime += 24 * 3600 * 1000;
    }
    custom.push({
      date: this.applyRangeEndDate,
      classes: ['selectedDate', 'end'],
    });
    this.datepickerCustomForApplyAmount = custom;
  }

  hotelAllotments(
    hotel: any,
    allotmentType: string,
    isActive: boolean,
    index: number,
    isSingleProperty: boolean
  ): any {
    let timezone = hotel.zone ? hotel.zone : hotel.timezone;
    // moment.tz.setDefault(timezone);
    let hotelTemp: any = {};
    hotelTemp.hotelId = hotel.hotelId;
    hotelTemp.label = hotel.label;
    hotelTemp.name = hotel.name;
    hotelTemp.timezone = timezone;
    hotelTemp.active = allotmentType === 'HARD' ? false : index === 0 ? true : isActive;
    hotelTemp.defaultCheckInTime = hotel.defaultCheckInTime;
    hotelTemp.allotmentType = allotmentType;
    hotelTemp.weekDays = _.cloneDeep(CONSTANT.WEEKDAYS);
    hotelTemp.startDate = new Date(new Date().setHours(0, 0, 0, 0));
    hotelTemp.endDate = new Date(
      new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
    );
    hotelTemp.expiryTime = '15:00';
    hotelTemp.expiryDate = new Date(
      new Date().setHours(hotelTemp.expiryTime.slice(0, 2), 0, 0, 0)
    );
    if (hotelTemp.allotmentType === 'HARD' && !isSingleProperty) {
      hotelTemp.selected = false;
    } else {
      hotelTemp.selected = true;
    }
    hotelTemp.hotelAllotmentDates = this.updateDates();
    return hotelTemp;
  }

  checkValidateEmail(): any {
    // Group contact email is optional input when creating HA
    if (this.addAllotmentForm.controls.email.value.trim() === '') {
      this.isValidEmail = true;
      return true;
    }
    if (this.addAllotmentForm.controls.email.status === 'INVALID') {
      this.isValidEmail = false;
      return false;
    } else {
      this.isValidEmail = true;
      return true;
    }
  }

  onSubmit(): void {}

  checkHasHotelSelected(): any {
    let hotelsSelected = this.hotels.find((hotel: any) => {
      return hotel.selected == true;
    });
    return typeof hotelsSelected === 'undefined' ? false : true;
  }
  updateDayOfWeek(day: any): any {
    let hotelActived = cloneDeep(this.hotelActived);
    // Update weekDays
    hotelActived.weekDays.forEach((d: any) => {
      if (d.code.toUpperCase() === day.code.toUpperCase()) {
        if (d.selected) {
          d.selected = false;
        } else {
          d.selected = true;
        }
      }
    });
    // Update rooms when choose days
    hotelActived.hotelAllotmentDates.forEach((d: any) => {
      if (d.day.toUpperCase() === day.code.toUpperCase()) {
        if (d.checked) {
          d.checked = false;
        } else {
          d.checked = true;
        }
      }
    });
    this.updateHotelActivedForHotels(hotelActived);
  }
  updateHotelActivedForHotels(hotelActived: any) {
    // Update for the actived hotel to hotels
    this.hotelActived = hotelActived;
    let foundIndex = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndex] = this.hotelActived;
  }

  onHotelCheck(hotel: any): void {
    hotel.selected = !hotel.selected;
    if (hotel.selected) {
      let allInactive = true;
      for (let i = 0; i < this.hotels.length; i++) {
        if (this.hotels[i].active) {
          allInactive = false;
        }
      }
      if (allInactive) {
        hotel.active = true;
      }
      if (hotel.active) {
        // Update availabilite rooms for hotel selected and actived
        this.getAvailabilitiesForHotel(hotel);
      }
    }
    // Un-check for hotel
    if (hotel.active) {
      hotel.active = false;
      for (let j = 0; j < this.hotels.length; j++) {
        if (this.hotels[j].selected && !this.hotels[j].active) {
          this.hotels[j].active = true;
          this.activeHotelTab(this.hotels[j]);
          // Update availabilite rooms for when hotel un-selected. The next hotel is actived and selected automaitically
          this.getAvailabilitiesForHotel(this.hotels[j]);
          break;
        }
      }
    }

    this.hasHotelSelected = this.checkHasHotelSelected();
    // Update hotel is selected
    this.hotelActived = this.hotels.find((hotel: any) => {
      return hotel.active == true;
    });
  }
  getAvailabilitiesForHotel(hotel: any): any {
    let request = {
      startDate: formatDate(hotel.startDate, 'YYYY-MM-dd', CONSTANT.EN_US),
      endDate: formatDate(hotel.endDate, 'YYYY-MM-dd', CONSTANT.EN_US),
    };
    this.allotmentsService.getAvailabilities(hotel.label, request).subscribe(
      (response: any) => {
        let availableRooms = 0;
        response.availabilities.reduce((availabilitie: any, val: any) => {
          availableRooms = availableRooms + val.availableRooms;
        }, 0);
        this.totalAvailableRooms = availableRooms;
      },
      (err) => {
        console.log('err: ', err);
      }
    );
  }
  checkCheckedDay = (dayIndex:number, selectedDay:any) => {
    let days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const findDay = selectedDay.find((day:any) => day.code === days[dayIndex] && day.selected);
    if(findDay) {
      return true;
    } else {
      return false;
    }
  }
  updateDates(): any {
    let date = moment(this.startDate, 'DD.MM.YYYY');
    let dayRange = [];
    let endDate;
    let hotelSelected = this.hotels.find((hotel: any) => {
      return hotel.active == true;
    });
    if(hotelSelected) {
      if (this.hotelActived.allotmentType === 'HARD') {
        let endDateFormat = formatDate(
          this.endDate - 1 * CONSTANT.ONE_DAY_MS,
          'dd.MM.YYY',
          'en-us'
        );
        endDate = moment(endDateFormat, 'DD.MM.YYYY');
      } else {
        endDate = moment(this.endDate, 'DD.MM.YYYY');
      }
      const selectedDay:any = hotelSelected.weekDays;
      while (date.isSameOrBefore(endDate, 'day')) {
        const softCheckDay = this.checkCheckedDay(date.toDate().getDay(), selectedDay);
        let existingDate: any;
        if (hotelSelected) {
          existingDate = hotelSelected.hotelAllotmentDates.find((d: any) => {
            return moment(d.date).isSame(date, 'day');
          });
        }
        if (existingDate) {
          if(softCheckDay) {
            let tmpDateRange = this.hotelAllotmentDate(date.toDate(), existingDate.availability);
            tmpDateRange.checked = softCheckDay;
            dayRange.push(tmpDateRange);
          }
        } else {
          if (this.hotelActived.allotmentType === 'HARD') {
            if (hotelSelected) {
              dayRange.push(
                this.hotelAllotmentDate(
                  date.toDate(),
                  JSON.stringify(hotelSelected.hotelAllotmentDates) !== '[]'
                    ? hotelSelected.hotelAllotmentDates[0].availability
                    : 1
                )
              );
              this.getAvailabilitiesForHotel(this.hotelActived);
            } else {
              dayRange.push(this.hotelAllotmentDate(date.toDate(), 1));
            }
          } else {
            let tmpDateRange = this.hotelAllotmentDate(date.toDate(), 1);
            tmpDateRange.checked = softCheckDay;
            dayRange.push(tmpDateRange);
          }
        }
        date.add(1, 'd');
      }
    
      this.hotelActived.hotelAllotmentDates = dayRange.slice();
      return dayRange.slice();
    }
    return [];
  }
  hotelAllotmentDate(date: any, availability: any): any {
    let hotelAllotmentDate: any = {};
    hotelAllotmentDate.date = new Date(date.getTime());
    hotelAllotmentDate.availability = availability;
    hotelAllotmentDate.year = hotelAllotmentDate.date.getFullYear();
    hotelAllotmentDate.time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + (date.getFullYear());
    hotelAllotmentDate.day = toWeekDayName(date.getDay());
    hotelAllotmentDate.checked = true;

    function toWeekDayName(date: any) {
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return days[date];
    }
    return hotelAllotmentDate;
  }
  activeHotelTab(hotelActive: any): void {
    this.isExpiryDate = false;
    this.hotels.forEach((ht: any) => {
      ht.active = ht.label === hotelActive.label;
      // Get availabilities for hotel with Hard Allotment when choosing hotel tab
      if (ht.active) {
        if (ht.allotmentType === 'HARD') {
          // Auto update apply range start date & end date when activing hotel tab
          this.applyRangeStartDate = ht.startDate;
          this.applyRangeEndDate = ht.endDate;
          this.expiryDate = ht.expiryDate;
          // Get availablities for hotel
          this.getAvailabilitiesForHotel(ht);
        }
        this.hotelActived = ht;
      } else {
        this.applyRangeStartDate = ht.startDate;
        this.applyRangeEndDate = ht.endDate;
      }
    });
    this.transferDateForDatepicker();
  }
  transferDateForDatepicker(): any {
    // Update start date end date for datepicker
    this.hotelActived = this.hotels.filter((hotel: any) => {
      return hotel.active;
    })[0];
    this.startDate = this.hotelActived.startDate;
    this.endDate = this.hotelActived.endDate;
  }
  transferDateForHotelActived(): any {
    this.hotelActived.startDate = this.startDate;
    this.hotelActived.endDate = this.endDate;
    // Update date for hotelAllotmentDates
    this.updateDates();
  }
  dateChange(type: string) {
    switch (type) {
      case 'startDate':
        if (this.startDate.getTime() >= this.endDate.getTime()) {
          if (this.hotelActived.allotmentType === 'HARD') {
            this.endDate = new Date(
              this.startDate.getTime() + 24 * 3600 * 1000
            );
            this.minEnd = new Date(this.startDate.getTime() + 24 * 3600 * 1000);
          } else {
            this.endDate = this.startDate;
            this.minEnd = this.startDate;
          }
        }
        break;
      case 'endDate':
        if (this.startDate.getTime() >= this.endDate.getTime()) {
          if (this.hotelActived.allotmentType === 'HARD') {
            this.startDate = new Date(
              this.endDate.getTime() - 24 * 3600 * 1000
            );
          } else {
            this.startDate = this.endDate;
          }
        }
        break;
    }
    this.validateDate();
    this.transferDateForHotelActived();
    this.updateDatepicker();
  }
  setDateFromDropdown(value:Date) {
    this.expiryDate = value;
  }
  validateDate() {
    if (this.startDate != 'Invalid Date' && this.endDate != 'Invalid Date') {
      this.isFormValid = true;
      document
        .querySelector<HTMLInputElement>('input')
        ?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }
  rangeDateChange(type: string) {
    switch (type) {
      case 'startDate':
        if (
          this.applyRangeStartDate.getTime() > this.applyRangeEndDate.getTime()
        ) {
          this.applyRangeEndDate = this.applyRangeStartDate;
          this.minRangeEnd = this.applyRangeStartDate;
        }
        break;
      case 'endDate':
        if (
          this.applyRangeStartDate.getTime() > this.applyRangeEndDate.getTime()
        ) {
          this.applyRangeStartDate = this.applyRangeEndDate;
        }
        break;
    }
    this.validateRangeDate();
    this.updateDatepickerRangeDate();
  }
  validateRangeDate() {
    if (
      this.applyRangeStartDate != 'Invalid Date' &&
      this.applyRangeEndDate != 'Invalid Date'
    ) {
      this.isRangeValid = true;
      document
        .querySelector<HTMLInputElement>('input')
        ?.classList.remove('invalid');
    } else {
      this.isRangeValid = false;
    }
  }
  updateExpiryDate() {
    // Update for the actived hotel to hotels
    this.hotelActived.expiryDate = this.expiryDate;
    this.hotelActived.expiryTime = formatDate(
      this.expiryDate,
      'HH:mm',
      CONSTANT.EN_US
    );
    let foundIndex = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndex] = this.hotelActived;
  }
  disableSearch() {
    this.isFormValid = false;
    this.isRangeValid = false;
  }
  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  applyToAllHotel(): void {
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    this.hotels.forEach((hotel: any) => {
      hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
        if (
          moment(hotelAllotmentDate.date).isSameOrAfter(startDate, 'day') &&
          moment(hotelAllotmentDate.date).isSameOrBefore(endDate, 'day')
        ) {
          hotelAllotmentDate.availability = amount;
        }
      });
    });
  }

  getInfoFromAllHotel(): any {
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    this.hotels.forEach((hotel: any) => {
      hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
        if (
          moment(startDate).isSameOrAfter(hotel.startDate, 'day') &&
          moment(endDate).isSameOrBefore(hotel.endDate, 'day')
        ) {
          hotelAllotmentDate.availability = amount;
        }
      });
    });
  }

  applyAmountOfRoomForMultipleDay(isHardAllotment: any): void {
    if (isHardAllotment) {
      this.applyRangeStartDate = this.startDate;
      this.applyRangeEndDate = this.endDate;
    }
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    // Check validate for amount of rooms
    if (this.validateForAmountOfRooms(this.applyRangeAmountOfRoom)) {
      // If an amount of rooms not change
      this.applyAmountOfRoom(startDate, endDate, amount);
    }
  }
  showNotificationWhenSelectMoreRoom(
    startDate: any,
    endDate: any,
    amount: any
  ): any {
    this.applyAmountOfRoom(startDate, endDate, amount);
  }
  applyAmountOfRoom(startDate: any, endDate: any, amount: any): any {
    // Keep the amount when choosing another hotel
    this.hotels.forEach((hotel: any) => {
      if (hotel.active) {
        hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
          if (
            moment(hotelAllotmentDate.date).isSameOrAfter(startDate, 'day') &&
            moment(hotelAllotmentDate.date).isSameOrBefore(endDate, 'day')
          ) {
            hotelAllotmentDate.availability = amount;
          }
        });
        this.hotelActived = hotel;
      }
    });
  }
  autoActiveHotelTab(currentHotel: any): any {
    // When choosing multi hotels then show error message should be active hotel tab automacally
    this.hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        hotel.active = false;
      }
      if (hotel.hotelId === currentHotel.hotelId) {
        hotel.active = true;
      }
    });
  }
  checkValidStartDate(): Boolean {
    let hasError: boolean = false;
    this.hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let today = moment(new Date()).format('YYYY-MM-DD');
        if (moment(hotel.startDate).isBefore(moment(today))) {
          this.toast.error(
            'Precondition failed due to the start date allotment started before ' +
              moment(new Date()).format('DD.MM.YYYY') +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        }
      }
      return hasError;
    });
    return hasError;
  }
  checkValidExpiryDateTime(): any {
    let hasError = false;
    let numberOfTimesWithoutError = 0;
    let hotels = cloneDeep(this.hotels);
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let startDate = moment(hotel.startDate).format('YYYY-MM-DD');
        let expiryDate = moment(hotel.expiryDate).format('YYYY-MM-DD');
        let today = moment(new Date()).format('YYYY-MM-DD');
        if (startDate === expiryDate) {
          if (
            Number(hotel.expiryTime.slice(0, 2)) >=
            Number(hotel.defaultCheckInTime.slice(0, 2))
          ) {
            this.isExpiryTime = true;
            this.toast.error(
              'Precondition failed due to the expiry time allotment started after ' +
                hotel.defaultCheckInTime.slice(0, 5) +
                ' in ' +
                hotel.label,
              'Error!'
            );
            hasError = true;
            this.autoActiveHotelTab(hotel);
            return hasError;
          }
        } else if (moment(hotel.expiryDate).isAfter(moment(hotel.startDate))) {
          this.isExpiryDate = true;
          this.toast.error(
            'Precondition failed due to the expiry date allotment started after ' +
              moment(hotel.startDate).format('DD.MM.YYYY') +
              ' ' +
              hotel.defaultCheckInTime.slice(0, 5) +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        } else if (moment(hotel.expiryDate).isBefore(moment(new Date()))) {
          if (today === expiryDate) return hasError;

          this.isExpiryDate = true;
          this.toast.error(
            'Precondition failed due to the expiry date allotment started before ' +
              moment(new Date()).format('DD.MM.YYYY') +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        } else {
          numberOfTimesWithoutError = numberOfTimesWithoutError + 1;
        }
      }
      return hasError;
    });
    let length = hotels.filter((hotel: any) => {
      return hotel.selected == true;
    }).length;
    if (numberOfTimesWithoutError === length) {
      hasError = false;
    }
    return hasError;
  }
  validateAmountOfRooms(amountOfRooms: any): any {
    if (this.hotelActived.allotmentType === 'HARD') {
      this.applyRangeAmountOfRoom = amountOfRooms;
      this.isValidateForAmountOfRooms = true;
    }
  }
  customValidateAmountOfRooms(type:string){
    if (this.hotelActived.allotmentType === 'HARD') {
      if (type==='increment'){
        this.applyRangeAmountOfRoom += 1;
        this.isValidateForAmountOfRooms = true;
        return
      }
      if(this.applyRangeAmountOfRoom===1) return;
      this.applyRangeAmountOfRoom -= 1;
      this.isValidateForAmountOfRooms = true;
    }
  }
  validateForAmountOfRooms(amountOfRooms: any): any {
    if (this.hotelActived.allotmentType === 'HARD') {
      this.applyRangeAmountOfRoom = amountOfRooms;
      if (
        amountOfRooms <= this.totalAvailableRooms &&
        typeof amountOfRooms !== 'undefined'
      ) {
        this.isValidateForAmountOfRooms = true;
        return true;
      } else {
        this.toast.error(
          'Maximum total availability rooms for multiple days: ' +
            this.totalAvailableRooms,
          'Warning'
        );
        this.isValidateForAmountOfRooms = false;
        return false;
      }
    } else {
      return true;
    }
  }
  updateAvailabilityForWeekdays(e: any, date: any) {
    this.hotelActived.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
      if (hotelAllotmentDate.time === date.time) {
        hotelAllotmentDate.availability = Number(e.target.value);
      }
    });
    let foundIndexHotel = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndexHotel] = this.hotelActived;
  }
  customChangeAvailabilityForWeekdays(type:string, date:any){
    
    this.hotelActived.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
      if (hotelAllotmentDate.time === date.time && type==='increment') {
        hotelAllotmentDate.availability += 1;
      }
      else if(hotelAllotmentDate.time === date.time && type==='decrement'){
        if(hotelAllotmentDate.availability===1) return;
        hotelAllotmentDate.availability -= 1;
      }
    });
    let foundIndexHotel = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndexHotel] = this.hotelActived;
  }
  customChangeRangeAmountOfRoom(type:string){
    if(this.applyRangeAmountOfRoom<1) return this.applyRangeAmountOfRoom = 1;
    if(type==='increment'){
      this.applyRangeAmountOfRoom += 1;
      return;
    }
    if(this.applyRangeAmountOfRoom===1) return;
    this.applyRangeAmountOfRoom -= 1;
  }
  goToTopBrowser(): any {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  isInputValid(): any {
    let hasError = false;
    let hotels: any = [];
    let nameAfterTrimed = this.addAllotmentForm.value.name.trim();
    if (nameAfterTrimed <= 0) {
      this.toast.error("Allotment name can't be empty.", 'Missing input');
      this.isValidName = false;
      hasError = true;
      this.goToTopBrowser();
      return;
    } else {
      this.isValidName = true;
    }
    if (this.hotelActived.allotmentType === 'HARD') {
      if (!this.checkValidateEmail()) {
        this.toast.error(
          'The contact person email field is in the wrong format.',
          'Error!'
        );
        hasError = true;
        this.goToTopBrowser();
        return;
      }
      // Check valid start date can not before today
      if (this.checkValidStartDate()) {
        hasError = true;
        return;
      }

      // Check valid for expiry date and time: allotment must be started before expiry time
      if (this.checkValidExpiryDateTime()) {
        hasError = true;
        return;
      }
      // Check validate for amount of rooms
      hasError = !this.validateForAmountOfRooms(this.applyRangeAmountOfRoom);
    }

    // Convert weekDays object to pure strings ["Mon", "Tue" ...]
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let days: any[] = [];
        hotel.weekDays.each((day: any) => {
          if (day.selected) {
            days.push(day.code);
          }
        });
        hotel.weekDays = days;
      }
    });
    hotels.forEach((hotel: any) => {
      if (hotel.weekDays.length <= 0) {
        this.toast.error(
          'No weekdays is not allow. (' + hotel.label + ')',
          'Invalid input'
        );
        hasError = true;
      }
    });
    if (hasError) {
      this.goToTopBrowser();
    }
    return !hasError;
  }
  createAllotment(): any {
    this.disabledBtnWhileCallingAPI = true;
    if (!this.isInputValid()) {
      this.disabledBtnWhileCallingAPI = false;
      return;
    }

    let allotment: any = {};
    let hotels = cloneDeep(this.hotels);
    allotment.allotmentType = this.hotelActived.allotmentType;
    allotment.name = this.addAllotmentForm.controls.name.value.trim();
    allotment.description = this.addAllotmentForm.controls.description.value;
    allotment.email = this.addAllotmentForm.controls.email.value.trim();
    allotment.hotelAllotments = [];

    // Convert weekDays object to pure strings ["Mon", "Tue" ...]
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        this.hotelActive = hotel;
        let days: any[] = [];
        hotel.weekDays.forEach((day: any) => {
          if (day.selected) {
            days.push(day.code);
          }
        });
        hotel.weekDays = days;
      }
    });

    // Optimize hotelAllotments and convert day to full day name
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let tempHotel: any = {
          allotmentId: hotel.allotmentId,
          hotelId: hotel.hotelId,
          dayOfWeeks: this.convertArrayToFullDayName(hotel.weekDays),
          startDate: moment(hotel.startDate).format('YYYY-MM-DD'),
          endDate: moment(hotel.endDate).format('YYYY-MM-DD'),
          expiryDateTime:
            moment(hotel.expiryDate).format('YYYY-MM-DD') +
            'T' +
            hotel.expiryTime +
            ':00.000Z',
          hotelAllotmentDates: [],
        };
        hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
          let dateIsValid = hotel.weekDays.find((weekDay: any) => {
            return (
              weekDay.toUpperCase() === hotelAllotmentDate.day.toUpperCase()
            );
          });
          if (dateIsValid) {
            tempHotel.hotelAllotmentDates.push({
              date: moment(hotelAllotmentDate.date).format('YYYY-MM-DD'),
              availability: hotelAllotmentDate.availability,
            });
          }
        });

        if (allotment.allotmentType === 'HARD') {
          delete tempHotel.dayOfWeeks;
          delete tempHotel.allotmentId;
        }
        allotment.hotelAllotments.push(tempHotel);
      }
    });
  

    this.allotmentsService.save(allotment).subscribe(
      (response: any) => {
        this.isExpiryDate = false;
        this.toast.success('Created an allotment.', 'Successfully!');
        this.router.navigateByUrl('/allotment/' + response.id);
        this.disabledBtnWhileCallingAPI = false;
      },
      (errorResp: any) => {
        if (errorResp.status === 406) {
          this.toast.error(errorResp.error.description, 'Error!');
        } else if (errorResp.status === 412) {
          this.toast.error(errorResp.error.description, 'Error!');
        } else {
          this.toast.error("Can't create allotment.", 'Error!');
        }
        this.disabledBtnWhileCallingAPI = false;
      }
    );
  }
  convertArrayToFullDayName(days: any): any {
    return days.map((day: any) => {
      return this.convertToFullDayName(day);
    });
  }
  convertToFullDayName(day: any): any {
    let dayOfWeek: any = {
      MON: 'MONDAY',
      TUE: 'TUESDAY',
      WED: 'WEDNESDAY',
      THU: 'THURSDAY',
      FRI: 'FRIDAY',
      SAT: 'SATURDAY',
      SUN: 'SUNDAY',
    };
    return dayOfWeek[day.toUpperCase()];
  }
}
