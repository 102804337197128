<app-header activePage="locks"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Door events</h1>
        </div>
      </div>

      @if (!hotelOnly) {
        <h3 class="view-code" style="margin-top: -10px; margin-bottom: 20px;">Code usage: {{currentDoorCode}}</h3>
      }
      <div class="row">
        @if (hotelOnly) {
          <div class="col-12 col-md-3">
            <div class="row">
              @if (hotels.length > 1) {
                <div class="col-12 col-md-5" style="margin-bottom: 10px">
                  <label>Hotel</label>
                  <dropdown-menu
                    [menuList]="hotelList"
                    [selectedItem]="selectedHotel"
                    [fullWidth]="true"
                    (selectItem)="selectHotel($event)"
                  ></dropdown-menu>
                </div>
              }
              <div class="col-12 col-md-7" style="margin-bottom: 10px">
                <label>Select doors</label>
                <div class="select-menu door-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true" [ngClass]="{disabled: !doorsList.length || doorListLoading}" (onHidden)="loadEventType()">
                  <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedDoor() && doorsList.length}" dropdownToggle aria-controls="optionalFilter">
                    @if (!doorListLoading) {
                      <div class="menu-selected">
                        @if (selectAllDoors) {
                          <span>
                            <span class="filter-label">All doors</span>
                          </span>
                        }
                        @if (!selectAllDoors && getAllFilter().length > 0) {
                          <span class="filter-label">{{getAllFilter().length}} {{getAllFilter().length <= 1 ? "door" : "doors"}}</span>
                        }
                      </div>
                    }
                    @if (doorListLoading) {
                      <div class="menu-selected">
                        <div class="menu-loading">
                          <ng-lottie
                            [options]="options"
                            width="35px"
                            height="35px"
                          ></ng-lottie>
                        </div>
                      </div>
                    }
                    <div class="menu-arrow">
                      <fa-icon type="solid" icon="chevron-down"></fa-icon>
                    </div>
                  </div>
                  <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                    @if (getAllFilter().length) {
                      <div class="menu-tooltip-list">
                        <div class="menu-title">Selected doors</div>
                        @for (selectedDoor of getAllFilter(); track selectedDoor) {
                          <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                            {{selectedDoor.label}}
                          </div>
                        }
                      </div>
                    }
                    <div class="scroll-menu">
                      <div class="normal-checkbox" (click)="setAllDoors($event)">
                        <input type="checkbox" [checked]="selectAllDoors">
                        <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        <div class="checkbox-label">All</div>
                      </div>
                      @for (door of doorsList; track door; let i = $index) {
                        @if (!['floor', 'room', 'elevator'].includes(door.type)) {
                          <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                            <input type="checkbox" [checked]="door.checked">
                            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                            <div class="checkbox-label">{{door.label}}</div>
                          </div>
                        }
                      }
                      @for (floor of allFloors; track floor) {
                        @for (door of doorsList; track door; let i = $index) {
                          <!-- Elevator -->
                          @if (door.type === 'elevator' && door.floor === floor) {
                            <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                              <input type="checkbox" [checked]="door.checked">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{door.label}}</div>
                            </div>
                          }
                          <!-- Rooms with entrances -->
                          @if (door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)) {
                            <div class="accordion">
                              <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                              <div class="normal-checkbox">
                                <span (click)="changeDoor(i,$event)">
                                  <input type="checkbox" [checked]="door.checked">
                                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                  <div class="checkbox-label">{{door.label}}</div>
                                </span>
                                <label class="accordion-arrow" for="floor{{door.floor}}">
                                  <fa-icon type="solid" icon="chevron-right"></fa-icon>
                                </label>
                              </div>
                              <div class="accordion-content">
                                @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                  @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                    <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                      <input type="checkbox" [checked]="roomDoor.checked">
                                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                      <div class="checkbox-label">{{roomDoor.label}}</div>
                                    </div>
                                  }
                                }
                              </div>
                            </div>
                          }
                        }
                        <!-- Rooms without entrances -->
                        @if (missingEntranceFloors.includes(floor)) {
                          <div class="accordion">
                            <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                            <div class="normal-checkbox">
                              <span>
                                <input type="checkbox" disabled>
                                <div class="checkbox-box disabled"><fa-icon type="solid" icon="check"></fa-icon></div>
                                <div class="checkbox-label">
                                  Floor {{floor}}
                                  <div class="checkbox-desc">(No entrance)</div>
                                </div>
                              </span>
                              <label class="accordion-arrow" style="top: -1px" for="floor{{floor}}">
                                <fa-icon type="solid" icon="chevron-right"></fa-icon>
                              </label>
                            </div>
                            <div class="accordion-content">
                              @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                  <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                    <input type="checkbox" [checked]="roomDoor.checked">
                                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                    <div class="checkbox-label">{{roomDoor.label}}</div>
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div class="col-12" [ngClass]="{'col-md-9': hotelOnly, 'col-md-12': !hotelOnly}">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="row">
                <div class="col-sm-6" style="margin-bottom: 10px">
                  <label>Start date & time</label>
                  <div class="row no-gutters">
                    <div class="col-7">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                          [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('startDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                          <fa-icon type="regular" icon="calendar-days"></fa-icon>
                        </button>
                      </div>
                    </div>
                    <div class="col-5">
                      <dropdown-menu
                        menuType="timepicker"
                        [initialDate]="startDate"
                        alignment="center"
                        [fullWidth]="true"
                        (selectDate)="setDateFromDropdown($event, 'startDate')"
                      ></dropdown-menu>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" style="margin-bottom: 10px">
                  <label>End date & time</label>
                  <div class="row no-gutters">
                    <div class="col-7">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                          bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('endDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                          <fa-icon type="regular" icon="calendar-days"></fa-icon>
                        </button>
                      </div>
                    </div>
                    <div class="col-5">
                      <dropdown-menu
                        menuType="timepicker"
                        [initialDate]="endDate"
                        alignment="center"
                        [fullWidth]="true"
                        (selectDate)="setDateFromDropdown($event, 'endDate')"
                      ></dropdown-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3" style="margin-bottom: 10px">
              <label>Event list</label>
              <div class="select-menu event-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true" [ngClass]="{disabled: !eventList.length || eventListLoading}">
                <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedEvent() && eventList.length}" dropdownToggle aria-controls="optionalFilter">
                  @if (!eventListLoading) {
                    <div class="menu-selected">
                      @if (selectAllEvents) {
                        <span>
                          <span class="filter-label">All events</span>
                        </span>
                      }
                      @if (!selectAllEvents && getAllEvent().length > 0) {
                        <span class="filter-label">{{getAllEvent().length}} {{getAllEvent().length <= 1 ? "event" : "events"}}</span>
                      }
                    </div>
                  }
                  @if (eventListLoading) {
                    <div class="menu-selected">
                      <div class="menu-loading">
                        <ng-lottie
                          [options]="options"
                          width="35px"
                          height="35px"
                        ></ng-lottie>
                      </div>
                    </div>
                  }
                  <div class="menu-arrow">
                    <fa-icon type="solid" icon="chevron-down"></fa-icon>
                  </div>
                </div>
                <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                  <div class="normal-checkbox" (click)="setAllEvent($event)">
                    <input type="checkbox" [checked]="selectAllEvents">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    <div class="checkbox-label">All</div>
                  </div>
                  @for (ev of eventList; track ev; let i = $index) {
                    <div class="normal-checkbox" (click)="changeEvent(i,$event)">
                      <input type="checkbox" [checked]="ev.checked">
                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                      <div class="checkbox-label">{{ev.value}}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="right-btns">
            <button class="btn btn-green input-height" [disabled]="(hotelOnly && !checkSelectedDoor()) || !checkSelectedEvent()" (click)="showEvent('new')">Show events</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    @if (searchResult.length > 0) {
      <div class="custom-table-wrapper">
        <table class="custom-table">
          <thead>
            <tr>
              <th style="width:200px">Door</th>
              <th>Code</th>
              <th>Time</th>
              <th>Event</th>
              <th style="width:200px">Name</th>
              <th>Reservation</th>
            </tr>
          </thead>
          <tbody>
            @for (result of searchResult; track result) {
              <tr>
                <td>{{result.door}}</td>
                <td>{{result.doorCode}}</td>
                <td>{{result.time}}</td>
                <td>{{result.doorEvent}}</td>
                <td>{{result.doorName}}</td>
                <td>{{result.doorReservation}}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="show-more">
        <button type="button" class="btn btn-green large-btn show-more-btn icon-only" [ngClass]="{disabled: currentCount <= 20}" (click)="showEvent('prev')" style="margin-right: 15px"><fa-icon type="solid" icon="chevron-left"></fa-icon></button>
        <label style="display: inline-block">Showing {{currentCount}}/{{totalCount}}</label>
        <button type="button" class="btn btn-green large-btn show-more-btn icon-only"[ngClass]="{disabled: currentCount >= totalCount}" (click)="showEvent('next')" style="margin-left: 15px"><fa-icon type="solid" icon="chevron-right"></fa-icon></button>
      </div>
      <div class="right-btns">
        <button type="button" class="btn btn-green large-btn show-more-btn" (click)="exportCsv()"><fa-icon type="solid" icon="file-arrow-down"></fa-icon>Export as CSV</button>
      </div>
    }
  </div>
  @if (isSearching) {
    <div class="center-item">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  }
</div>