import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const API = '/api/management/secure/crm/search';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  searchCustomers(request: any): Observable<any> {
    return this.http.post(API, request, httpOptions);
  }
  applyCustomerDashboard(request: any): Observable<any> {
    let api = '/api/management/secure/crm/customer-dashboard';
    return this.http.post(api, request, httpOptions);
  }
  customerDataExport(request: any): Observable<any> {
    let api = '/api/management/secure/crm/customer-export';
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(api, request, { headers, responseType: 'blob' });
  }
  customerCard(email: any,mobile: any): Observable<any> {
    let url = '/api/management/secure/crm/customer-card';
    let request = {
      email: email,
      mobile: '+'+mobile,
      bookingOffset: 0,
      bookingLimit: 3,
      commentOffset: 0,
      commentLimit: 3
    }
    return this.http.post(url, request, httpOptions);
  }
  showStatusStatistics(email: any): Observable<any> {
    let url = '/api/management/secure/crm/customer-card/status-statistics';
    return this.http.post(url, email, httpOptions);
  }
  showMoreBookings(request: any): Observable<any> {
    let url = '/api/management/secure/crm/customer-card/bookings';
    return this.http.post(url, request, httpOptions);
  }
  showMoreComments(request: any): Observable<any> {
    let url = '/api/management/secure/crm/customer-card/comments';
    return this.http.post(url, request, httpOptions);
  }
  getCustomer(id: number): Observable<any> {
    let url = '/api/management/secure/blocking/customer/' + id;
    return this.http.get(url);

  }
  saveCustomer(request: any): Observable<any> {
    let url = '/api/management/secure/blocking/customer/save';
    return this.http.post(url, request, httpOptions);
  }
  getCountries(): Observable<any> {
    let url = 'assets/resources/country-codes.json';
    return this.http.get(url);
  }
  addHistory(request: any): Observable<any> {
    let url = '/api/management/secure/blocking/customer/' + request.id + '/add-history';
    let requestFrom = {
      reason: request.reason,
      state: request.state
    }
    return this.http.post(url, requestFrom, httpOptions);
  }
}