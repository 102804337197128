import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { LockService } from 'src/app/services/lock.service';
import { StoreService } from 'src/app/services/store.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { LOCKDUMMY } from 'src/app/helpers/dummylock';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-lock-detail',
  templateUrl: './lock-detail.component.html',
  styleUrls: ['./lock-detail.component.sass']
})
export class LockDetailComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;
  
  minEnd:any = new Date();
  startDate:any = new Date(new Date().setHours(16,0,0,0));
  endDate:any = new Date(new Date().setHours(22,0,0,0) + 24*3600*1000);
  prevStartDate:any = null;
  prevEndDate:any = null;
  lastStartDate:any;
  lastEndDate:any;
  isFormValid:boolean = false;
  selectAllDoors:boolean = false;
  deactivate:boolean = false;
  currentToken:string = "";
  datepickerCustom: DatepickerDateCustomClasses[];
  doorsList:any[] = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  isCreateNewCode:boolean = true;
  allFloors:number[] = [];
  missingEntranceFloors:number[] = [];
  title = "Axis Lock UI";
  firstLoad:boolean = false;

  lockDetail = new UntypedFormGroup({
    reservation: new UntypedFormControl(''),
    name: new UntypedFormControl('', [Validators.required]),
    doorCode: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{5}$/)])
  })
  get doorCodeName() { return this.lockDetail.get('name')!; }
  get doorCode() { return this.lockDetail.get('doorCode')!; }

  constructor(
    private router: Router,
    public utils: UTILS,
    public lockService: LockService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private toast: CustomToastService,
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const processData = (data:any) => {
      if(data && data.length) {
        this.hotels = data;
        let tmpHotelList:MenuType[] = [];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
            value: hotel.hotelId.toString(),
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;
        setTimeout(() => {
          this.checkParam();
        }, 100);
      }
    }
    if(this.hotelConfig.MUIfeature.dummyLock) {
      processData(LOCKDUMMY.DUMMY_LOCK_HOTEL);
    } else {
      this.lockService.getHotels().subscribe(data => {
        if(data && data.length) {
          processData(data)
        }
      })
    }
  }

  selectHotel(item:MenuType, getNewDoorList:boolean = true) {
    this.selectedHotel = item;
    this.validateForm(getNewDoorList);
  }
  selectHotelByValue(value:string, getNewDoorList:boolean = true) {
    const findHotel = this.hotelList.find(item => item.value === value);
    if(findHotel) {
      this.selectHotel(findHotel, getNewDoorList)
    }
  }

  checkParam() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      const hotelId = params.get("hotelId");
      const userToken = params.get("userToken");
      let checkUserParams = ["hotelId", "userToken"].every((key:any) => paramKeys.includes(key));
      let checkHotelParams = ["hotelId"].every((key:any) => paramKeys.includes(key));
      if(checkUserParams) {
        this.isCreateNewCode = false;
      } else {
        this.isCreateNewCode = true;
      }
      if(checkHotelParams && hotelId) {
        this.selectHotelByValue(hotelId, false)
      }
      if(!checkUserParams && !checkHotelParams) {
        this.selectHotelByValue(this.hotels[0].hotelId, false)
      }
      if(this.selectedHotel) {
        this.getDoorList(checkUserParams, userToken)
      }
      if(!userToken) {
        this.firstLoad = true;
      }
    })
  }

  getDoorList(checkUserParams?:any, userToken?:any) {
    if(this.selectedHotel) {
      const findRequestedHotel = this.getHotelObj(this.selectedHotel.value);
      const processData = (data:any) => {
        if(data && data.length) {
          let tmpDoorList:any[] = [];
          const tmpOtherDoors = data.filter((door:any) => !["floor", "room", "elevator"].includes(door.type));
          const otherSortOrder = ["gate", "main", "other"];
          tmpOtherDoors.sort((a:any,b:any) => {
            return otherSortOrder.indexOf(a.type) - otherSortOrder.indexOf(b.type);
          })
          tmpOtherDoors.forEach((door:any) => {
            tmpDoorList.push({
              label: door.accessProfileName,
              value: door.token,
              floor: door.floor,
              type: door.type,
              checked: false
            })
          })
          // Group and sort floor doors
          let tmpFloorDoors:any[] = [];
          let allFloors:number[] = [];
          data.forEach((door:any) => {
            if(!allFloors.includes(door.floor) && door.floor !== -1) {
              allFloors.push(door.floor);
            }
          })
          this.allFloors = allFloors;
          let missingEntranceFloors:number[] = [];
          const allEntrance = data.filter((door:any) => door.type === "floor");
          allFloors.forEach(floor => {
            const floorSortOrder = ["elevator", "floor", "room"];
            const filteredFloorDoors:any[] = data.filter((door:any) => door.floor === floor);
            filteredFloorDoors.sort((a,b) => {
              return floorSortOrder.indexOf(a.type) - floorSortOrder.indexOf(b.type);
            });
            tmpFloorDoors = tmpFloorDoors.concat(filteredFloorDoors);
            const findEntrance = allEntrance.filter((entrance:any) => entrance.floor === floor);
            if(!findEntrance.length) {
              missingEntranceFloors.push(floor);
            }
          })
          this.missingEntranceFloors = missingEntranceFloors;
          tmpFloorDoors.forEach(door => {
            tmpDoorList.push({
              label: door.accessProfileName,
              value: door.token,
              floor: door.floor,
              type: door.type,
              checked: false
            })
          })
          if(checkUserParams && userToken) {
            this.lockDetail.controls.name.disable();
            this.lockDetail.controls.doorCode.disable();
            this.lockDetail.controls.reservation.disable();
            const processUserDoorData = (data:any) => {
              this.currentToken = data.token;
              const tmpStartDate = new Date(new Date(`${data.startDate} ${data.startTime}`));
              const tmpEndDate = new Date(`${data.endDate} ${data.endTime}`);
              this.startDate = tmpStartDate;
              this.endDate = tmpEndDate;
              this.lastStartDate = tmpStartDate;
              this.lastEndDate = tmpEndDate;
              this.lockDetail.patchValue({
                doorCode: data.doorCode,
                reservation: data.reservationCode,
                name: data. username
              })
              tmpDoorList.map(door => data.doorAccessProfileTokenList.includes(door.value) ? door.checked = true : door.checked = false);
              this.deactivate = !data.active;
              this.doorsList = tmpDoorList;
              this.checkAllDoors();
            }
            if(this.hotelConfig.MUIfeature.dummyLock) {
              processUserDoorData(LOCKDUMMY.DUMMY_LOCK_USER_DATA)
            } else {
              this.lockService.getUserDoor(findRequestedHotel.label, userToken).subscribe((data:any) => {
                processUserDoorData(data)
              }, error => {
                this.toast.error(
                  error.message,
                  'Error!'
                );
              })
            }
            setTimeout(() => {
              this.firstLoad = true;
            }, 100);
          } else {
            this.lockDetail.controls.name.enable();
            this.lockDetail.controls.doorCode.enable();
            this.reRollDoorCode();
            this.doorsList = tmpDoorList;
          }
        }
      }
      if(this.hotelConfig.MUIfeature.dummyLock) {
        processData(LOCKDUMMY.DUMMY_LOCK_DOOR_LIST);
      } else {
        this.lockService.getAccessDoor(findRequestedHotel.label).subscribe((data:any[]) => {
          processData(data)
        })
      }
    }
  }

  reRollDoorCode() {
    this.lockDetail.patchValue({
      doorCode: Math.floor(Math.random() * 90000) + 10000
    });
  }

  disableSearch() {
    this.isFormValid = false;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  validateForm(getNewDoorList:boolean = true):void {
    this.validateDate();
    if(getNewDoorList) {
      this.getDoorList();
    }
  }

  validateDate() {
    if(this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    if(this.firstLoad) {
      switch(type) {
        case 'startDate':
          if(!this.prevStartDate) {
            this.prevStartDate = this.startDate;
          } else {
            const prevStartMonth = this.prevStartDate.getMonth();
            const newStartMonth = this.startDate.getMonth();
            if(prevStartMonth !== newStartMonth) {
              this.startDate = new Date(this.startDate.setHours(this.prevStartDate.getHours(), this.prevStartDate.getMinutes(), 0, 0));
              this.prevStartDate = this.startDate;
            } else {
              this.prevStartDate = this.startDate;
            }
          }
          if(this.startDate.getTime() >= this.endDate.getTime()) {
            let tmpStartDate = new Date(this.startDate.getTime())
            this.endDate = new Date(tmpStartDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0) + 24*3600*1000);
            this.minEnd = this.startDate;
          }
          break;
        case 'endDate':
          if(!this.prevEndDate) {
            this.prevEndDate = this.endDate;
          } else {
            const prevEndMonth = this.prevEndDate.getMonth();
            const newEndMonth = this.endDate.getMonth();
            if(prevEndMonth !== newEndMonth) {
              this.endDate = new Date(this.endDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0));
              this.prevEndDate = this.endDate;
            } else {
              this.prevEndDate = this.endDate;
            }
          }
          break;
      }
      this.updateDatepicker();
      this.validateDate();
    }
  }
  setDateFromDropdown(value:Date, type:string) {
    switch (type) {
      case "startDate":
        this.startDate = value;
        break;
      case "endDate":
        this.endDate = value;
        break;
      default:
        break;
    }
  }

  updateDatepicker() {
    let custom = [
      {date: this.startDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.startDate.getTime() + 24*3600*1000;
    while(tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }

  setDeactivate(event:any) {
    this.deactivate = event.target.checked;
    if(event.target.checked) {
      if(this.lastStartDate.getTime() > new Date().getTime()) {
        this.startDate = new Date();
      }
      setTimeout(() => {
        this.endDate = new Date();
      }, 100);
    } else {
      this.startDate = this.lastStartDate;
      this.endDate = this.lastEndDate;
    }
  }
  changeDoor(index:number, event:any) {
    this.doorsList[index].checked = event.target.checked;
    this.getCheckedFilterLabel();
    this.checkAllDoors();
  }
  setAllDoors(event:any) {
    this.selectAllDoors = event.target.checked;
    if(event.target.checked) {
      this.doorsList.map(door => door.checked = true);
    } else {
      this.doorsList.map(door => door.checked = false);
    }
  }
  checkAllDoors() {
    const checkUncheckedDoors = this.doorsList.filter(door => !door.checked);
    if(checkUncheckedDoors.length) {
      this.selectAllDoors = false;
    } else {
      this.selectAllDoors = true;
    }
  }
  checkSelectedDoor() {
    const checkCheckedDoors = this.doorsList.filter(door => door.checked);
    if(checkCheckedDoors.length) {
      return true;
    } else {
      return false;
    }
  }
  getCheckedFilterLabel() {
    let defaultFilters:any = [];
    for (let i = 0; i < 6; i++) {
      defaultFilters.push(this.doorsList[i]);
    }
    let selectedFilters:any = [];
    this.doorsList.forEach((item:any) => {
      if(item.checked) {
        selectedFilters.push(item);
      }
    });
  }
  clearAllFilter() {
    this.doorsList.forEach((item:any) => {
      item.checked = false;
    });
  }
  getMenuFilter(){
    return this.getAllFilter().slice(0, 1);
  }
  getAllFilter(){
    return this.doorsList.filter((f: any) => f.checked);
  }
  removeFilter(filter: any, e: any) {
    e.stopPropagation();
    this.doorsList.filter((f: any) => f.value == filter.value)[0].checked = false;
  }

  getCheckedFilters(item:any) {
    if(item.checked) return item.value; else return 0;
  }

  getSelectedDoorTokenList() {
    let tokenList:any[] = [];
    const selectedDoors = this.getAllFilter();
    selectedDoors.forEach(door => {
      tokenList.push(door.value)
    })
    return tokenList;
  }

  getHotelObj(hotelId?:string): any {
    let hotelObj:any = {};
    if(hotelId) {
      hotelObj = this.hotels.find((obj:any) => {
        return obj.hotelId == hotelId;
      });
    } else if (this.selectedHotel) {
      let hotelId = Number(this.selectedHotel.value);
      hotelObj = this.hotels.find((obj:any) => {
        return obj.hotelId == hotelId;
      });
    }
    return hotelObj;
  }

  getHotelObjByLabel(label:string): any {
    let hotelObj = this.hotels.find((obj:any) => {
      return obj.label == label;
    });
    return hotelObj;
  }

  saveDoorCode() {
    if(this.selectedHotel) {
      if(this.isCreateNewCode) {
        let saveRequest = {
          hotelLabel: this.getHotelObj(this.selectedHotel.value).label,
          username: this.lockDetail.controls.name.value,
          startDate: formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
          startTime: formatDate(this.startDate, "HH:mm", "en-US"),
          endDate: formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
          endTime: formatDate(this.endDate, "HH:mm", "en-US"),
          doorAccessProfileTokenList: this.getSelectedDoorTokenList(),
          doorCode: this.lockDetail.controls.doorCode.value,
          active: true
        }
        this.lockService.createDoorCode(saveRequest).subscribe(
          data => {
            this.toast.success(
              'Door code created',
              'Success!'
            );
            this.router.navigate(['/locks'])
          },
          error => {
            let errorText = error.error.description;
            if (errorText === "DoorCode is in-use") {
              errorText = "Door code is in use"
            }
            this.toast.error(
              errorText,
              'Error!'
            );
          }
        )
      } else {
        let saveRequest = {
          hotelLabel: this.getHotelObj(this.selectedHotel.value).label,
          username: this.lockDetail.controls.name.value,
          startDate: formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
          startTime: formatDate(this.startDate, "HH:mm", "en-US"),
          endDate: formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
          endTime: formatDate(this.endDate, "HH:mm", "en-US"),
          doorAccessProfileTokenList: this.getSelectedDoorTokenList(),
          doorCode: this.lockDetail.controls.doorCode.value,
          token: this.currentToken,
          active: !this.deactivate
        }
        this.lockService.updateDoorCode(saveRequest).subscribe(
          data => {
            if(this.deactivate) {
              this.backToLock();
            }
            this.lastStartDate = this.startDate;
            this.lastEndDate = this.endDate;
            this.toast.success(
              'Door code updated',
              'Success!'
            );
          },
          error => {
            let errorText = error.error.description;
            if (errorText === "DoorCode is in-use") {
              errorText = "Door code is in use"
            }
            this.toast.error(
              errorText,
              'Error!'
            );
          }
        )
      }
    }
  }

  openEventLog() {
    if(this.selectedHotel) {
      const params = {
        doorCode: this.lockDetail.controls.doorCode.value,
        hotelId: this.selectedHotel.value,
        userToken: this.currentToken,
        startDate: formatDate(this.lastStartDate, "yyyy-MM-dd", "en-US"),
        startTime: formatDate(this.lastStartDate, "HH:mm", "en-US"),
        endDate: formatDate(this.lastEndDate, "yyyy-MM-dd", "en-US"),
        endTime: formatDate(this.lastEndDate, "HH:mm", "en-US"),
      }
      this.router.navigate(['/locks/code-usage'], {queryParams: params});
    }
  }

  backToTopPage(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  backToLock() {
    this.router.navigate(['/locks'], {queryParams: {back: true}});
  }

}
