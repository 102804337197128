import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastRequestType } from '../components/common/custom-toast/custom-toast.component';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
  private triggerSubject = new Subject<ToastRequestType>();

  trigger$ = this.triggerSubject.asObservable();
  constructor() { }

  success(desc:string, title:string, timeOut?:number) {
    this.request({
      type: "success",
      title: title,
      desc: desc,
      timeOut: timeOut ? timeOut : 5000
    })
  }
  error(desc:string, title:string, timeOut?:number) {
    this.request({
      type: "error",
      title: title,
      desc: desc,
      timeOut: timeOut ? timeOut : 5000
    })
  }
  request(toastRequest:ToastRequestType) {
    this.triggerSubject.next(toastRequest); // Emit an event to trigger the function
  }
}
