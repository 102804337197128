<app-header-user-management activePage="users"></app-header-user-management>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Users</div>
        </div>
        <div class="page-twb-button">
          <button type="button" class="btn btn-green btn-big" (click)="openUserAddModal()"><fa-icon type="solid" icon="plus"></fa-icon>Add New User</button>
        </div>
      </div>
      <form [formGroup]="userForm" (ngSubmit)="searchUser()">
        <div class="row">
          <div class="col-12 col-sm-10">
            <div class="row">
              <!-- Choose client -->
              @if (isAdminRole || allTenants.length>1) {
                <div class="col-12 col-md-4">
                  <label>Choose client</label>
                  <dropdown-menu
                    [menuList]="tenantList"
                    [selectedItem]="selectedTenant"
                    [fullWidth]="true"
                    (selectItem)="selectTenant($event)"
                  ></dropdown-menu>
                </div>
              }

              <!-- Search user -->
              <div class="col-12 col-md-4">
                <div class="btn-group dropdown">
                  <label for="textSearch">Search user by email</label>
                  <input name="query" class="form-control custom-input"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
                    formControlName="email" autofocus>
                </div>
              </div>

              <!-- Optional filters for user type -->
              <div class="col-12 col-md-4">
                <label>User type</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="dropdown-basic">
                    <div class="menu-selected">{{selectedRoleLabel}}</div>
                    <div class="menu-arrow"><span><fa-icon type="solid" icon="chevron-down"></fa-icon></span>
                  </div>
                </div>
                <div class="dropdown-menu menu-content dropdown-content" id="dropdown-basic" *dropdownMenu
                  (click)="$event.stopImmediatePropagation()">
                  @for (role of allRoles; track role; let idx = $index) {
                    <div formArrayName="allroles" class="normal-checkbox modal-fix">
                      <input type="checkbox" (click)="selectSearchRole(role)" [formControlName]="idx">
                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                      <div class="checkbox-label">
                        <ng-container>
                          {{role.roleName}}
                        </ng-container>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <button type="submit" class="btn btn-green btn-big full-width offset-label-top"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
        </div>
      </div>
    </form>
  </div>
</div>

@if (loadingFromScratch) {
  <div class="center-item">
    <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
  </div>
}

@if (!loadingFromScratch) {
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="container-title">All users</div>
        <div class="table-responsive">
          <table class="custom-table">
            <thead>
              <th>
                <div class="padding">User email</div>
              </th>
              <th>
                <div class="padding">User rights</div>
              </th>
              <th class="last sticky">
                <div class="padding"></div>
              </th>
            </thead>
            <tbody>
              @for (result of searchResult; track result) {
                <tr>
                  <td>{{result.email}}</td>
                  <td>
                    <ng-container>
                      {{getUserRoleName(result.roles)}}
                    </ng-container>
                  </td>
                  <td class="text-center sticky">
                    <a class="edit-btn" (click)="openEditModal(result)">
                      <fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit
                    </a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <div style="text-align: center;">
            @if (this.lastSearchEvaluatedId !== '') {
              <button class="btn btn-green large-btn show-more-btn" (click)="this.handleClickShowMore()"
                ><span [ngClass]="this.loadingContainer ? 'rotating' : ''"><fa-icon type="solid" icon="ellipsis"></fa-icon></span>Show more</button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  <!-- Loading icon -->
  @if (loadingContainer && !loadingFromScratch) {
    <div class="center-item">
      <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
    </div>
  }
</div>

<!-- Add user modal -->
<custom-modal
  modalName="addUserModal"
  modalTitle="Add new user"
  modalSize="md"
  modalOrder="0"
  >
  <ng-container>
    <div class="custom-modal-body">
      <form name="form" [formGroup]="addForm">

        <div class="container-title" style="padding: 0">User information</div>
        <label>E-mail</label>
        <input class="custom-input form-control" formControlName="email" type="text">
        <label>Full Name</label>
        <input class="custom-input form-control" formControlName="fullname" type="text">

        <div class="container-title">Environment access</div>
        <div class="row checkbox-list">
          @for (env of allEnviroments; track env) {
            <div class="col-12 col-sm-6">
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="selectedEnvironment.includes(env.id)" (click)="toggleEnvironment(env.id)">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">{{env.name}}</div>
              </div>
            </div>
          }
        </div>

        <div class="container-title">User rights</div>
        <div class="row checkbox-list">
          @for (role of allRoles; track role) {
            <div class="col-12 col-sm-6">
              <div class="normal-checkbox modal-fix radio-style">
                <input type="radio" [checked]="selectedUserRole.includes(role.id)" (click)="selectUserRole(role.id)">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">
                  <ng-container>
                    {{role.roleName}}
                  </ng-container>
                </div>
              </div>
            </div>
          }
        </div>

        @if (selectedUserRole === 'CLEANER') {
          <div>
            <div class="container-title">Hotel access</div>
            <div class="row checkbox-list">
              @for (env of allEnviroments; track env) {
                @if (selectedEnvironment.includes(env.id)) {
                  <div class="col-12 col-sm-6" style="margin-bottom: 15px;">
                    <div class="view-desc">{{env.name}}</div>
                    @for (hotel of env.hotels; track hotel) {
                      <div>
                        <div class="normal-checkbox modal-fix">
                          <input type="checkbox" [checked]="selectedHotels.includes(hotel.id)" (click)="toggleHotel(hotel.id)">
                          <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                          <div class="checkbox-label">{{hotel.name}}</div>
                        </div>
                      </div>
                    }
                  </div>
                }
              }
            </div>
          </div>
        }

      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('defaultModal')">Cancel</button>
      <button type="button" class="btn btn-green" (click)="addUser()" [disabled]="!addForm.valid || !isValidAddForm">Add new user</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Edit user modal -->
<custom-modal
  modalName="editUserModal"
  modalTitle="Edit user"
  modalSize="md"
  modalOrder="0"
  >
  @if (selectedUser.name) {
    <div class="custom-modal-body">
      <form name="form" [formGroup]="editForm">
        <div class="profile-container">
          <div class="profile-avatar">
            <div class="avatar">{{createAvatar(selectedUser.name)}}</div>
          </div>
          <div class="profile-info">
            <div class="info-item">
              <span class="prefix">Full name: </span>
              <strong>{{selectedUser.name}}</strong>
            </div>
            <div class="info-item">
              <span class="prefix">E-mail: </span>
              <strong>{{selectedUser.email}}</strong>
            </div>
            <div class="row info-item">
              <div class="col-12 col-sm-6">
                <span class="prefix">Created on: </span>
                <strong>{{selectedUser.createddate | date: 'dd.MM.yyyy'}}</strong>
              </div>
              <div class="col-12 col-sm-6">
                <span class="prefix">Last login: </span>
                <strong>{{selectedUser.lastlogintime | date: 'dd.MM.yyyy'}}</strong>
              </div>
            </div>
            <div class="info-item">
              <div class="title-inline-btn" style="margin-bottom: 10px;">
                <div class="tib-title">
                  <span class="prefix">Account status</span>
                </div>
                <div class="tib-badge">
                  @if (selectedUser.enabled) {
                    <div class="badge success">Enabled</div>
                  }
                  @if (!selectedUser.enabled) {
                    <div class="badge error">Disabled</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-title">Environment access</div>
        <div class="row checkbox-list">
          @for (env of allEnviroments; track env) {
            <div class="col-12 col-sm-6">
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="selectedEnvironment.includes(env.id)" (click)="toggleEnvironment(env.id)">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">{{env.name}}</div>
              </div>
            </div>
          }
        </div>
        <div class="container-title">User rights</div>
        <div class="row checkbox-list">
          @for (role of allRoles; track role) {
            <div class="col-12 col-sm-6">
              <div class="normal-checkbox modal-fix radio-style">
                <input type="radio" [checked]="selectedUserRole.includes(role.id)" (click)="selectUserRole(role.id)">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">
                  <ng-container>
                    {{role.roleName}}
                  </ng-container>
                </div>
              </div>
            </div>
          }
        </div>
        @if (selectedUserRole === 'CLEANER') {
          <div>
            <div class="container-title">Hotel access</div>
            <div class="row checkbox-list">
              @for (env of allEnviroments; track env) {
                @if (selectedEnvironment.includes(env.id)) {
                  <div class="col-12 col-sm-6" style="margin-bottom: 15px;">
                    <div class="view-desc">{{env.name}}</div>
                    @for (hotel of env.hotels; track hotel) {
                      <div>
                        <div class="normal-checkbox modal-fix">
                          <input type="checkbox" [checked]="selectedHotels.includes(hotel.id)" (click)="toggleHotel(hotel.id)">
                          <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                          <div class="checkbox-label">{{hotel.name}}</div>
                        </div>
                      </div>
                    }
                  </div>
                }
              }
            </div>
          </div>
        }
        @if (checkEditUserIsCurrent()) {
          <div>
            <div class="container-title" style="margin-bottom: 15px;">Change password</div>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPassword()">
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="password" id="password" autofocus>
                <label>Current password</label>
              </div>
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="newPassword" id="newPassword" autofocus>
                <label>New password</label>
              </div>
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword" autofocus>
                <label>Confirm new password</label>
              </div>
              <div class="password-criteria">
                <p>New password must contain at least:</p>
                <ul>
                  <li>At least 8 characters—the more characters, the better</li>
                  <li>A mixture of both uppercase and lowercase letters</li>
                  <li>Contains at least one number and one special character</li>
                </ul>
              </div>
              <button type="submit" [disabled]="updatingPassword" class="btn btn-green">Update password</button>
            </form>
          </div>
        }
      </form>
    </div>
    <div class="custom-modal-footer">
      @if (!checkEditUserIsCurrent()) {
        @if (!selectedUser.enabled) {
          <button class="btn btn-outline-green" (click)="selectedUser.enabled = !selectedUser.enabled">
            <fa-icon type="solid" icon="user-check"></fa-icon>Enable account
          </button>
        }
        @if (selectedUser.enabled) {
          <button class="btn btn-outline-green" (click)="selectedUser.enabled = !selectedUser.enabled">
            <fa-icon type="solid" icon="user-slash"></fa-icon>Disable account
          </button>
        }
      }
      <button type="submit" class="btn btn-outline-green" (click)="openConfirmResetPassword()">Reset Password</button>
      <button type="submit" class="btn btn-green" (click)="editUser()">Save</button>
    </div>
  }
</custom-modal>

<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
  </div>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{loadingElements.title}}</div>
    @if (loadingElements.desc) {
      <div class="alert-desc">{{loadingElements.desc}}</div>
    }
  </div>
</custom-modal>

<!-- Confirm reset password alert -->
<custom-modal
  modalName="confirmResetPasswordAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
  >
  <div class="custom-modal-body">
    <div class="alert-title">Reset password</div>
    <div class="alert-desc">Are you sure you want to reset this account password to default password?</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('confirmResetPasswordAlert');openCustomModal('editUserModal')">No</button>
    <button type="button" class="btn btn-green" (click)="resetPassword()">Yes</button>
  </div>
</custom-modal>

<!-- Default alert -->
<custom-modal
  modalName="defaultAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="warning"
  >
  <div class="custom-modal-body">
    <div class="alert-title"></div>
    <div class="alert-desc"></div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('defaultAlert')">Cancel</button>
  </div>
</custom-modal>