import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrl: './custom-modal.component.sass'
})
export class CustomModalComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  @Input() modalSize:string = "";
  @Input() modalTitle:string = "";
  @Input() modalName:string = "";
  @Input() backdropClose:boolean = true;
  @Input() modalOrder:string = "0";
  @Input() alertType:string = "success";
  @Input() position:string = "center";
  @Output() modalEvent = new EventEmitter<string>();

  openModal:boolean = false;
  openBackdrop:boolean = false;
  modalZIndex:number = 700;
  closeModal:boolean = false;
  pendingRemoveCloseAlert:any;

  ngOnInit(): void {
    if(this.modalOrder !== "0") {
      this.modalZIndex = 700 + Number(this.modalOrder);
    }
  }

  toggleModal(action:string) {
    const getBody = document.querySelector("body");
    if(getBody) {
      switch (action) {
        case "open":
          if(this.pendingRemoveCloseAlert) {
            clearTimeout(this.pendingRemoveCloseAlert);
            this.closeModal = false;
            this.openModal = false;
          }
          this.openModal = true;
          this.openBackdrop = true;
          if(!getBody.classList.contains("disable-scroll")) {
            getBody.classList.add("disable-scroll");
          }
          break;
      
        case "close":
          if(getBody.classList.contains("disable-scroll")) {
            getBody.classList.remove("disable-scroll");
          }
          this.openBackdrop = false;
          this.closeModal = true;
          this.pendingRemoveCloseAlert = setTimeout(() => {
            this.openModal = false;
            this.closeModal = false;
          }, 500);
          this.openModal = false;
          this.modalEvent.emit("closeModal");
          break;
        default:
          console.error("Invalid modal request!")
          break;
      }
    }
  }
}
