<app-header activePage="locks"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Axis Lock UI</h1>
        </div>
      </div>
      <form [formGroup]="lockDetail">
        <div class="row">
          <div class="col-6 col-md-2" style="margin-bottom: 10px">
            @if (hotels.length > 1) {
              <div>
                <label>Hotel</label>
                <dropdown-menu
                  [menuList]="hotelList"
                  [selectedItem]="selectedHotel"
                  [fullWidth]="true"
                  (selectItem)="selectHotel($event)"
                ></dropdown-menu>
              </div>
            }
          </div>
          <div class="col-6 col-md-3" style="margin-bottom: 10px">
            @if (!isCreateNewCode) {
              <label>Reservation</label>
              <input class="form-control custom-input searchQuery" formControlName="reservation">
            }
          </div>
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12 col-md-6" style="margin-bottom: 10px">
                <label>Select doors</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedDoor()}" dropdownToggle aria-controls="optionalFilter">
                    <div class="menu-selected">
                      @if (selectAllDoors) {
                        <div class="filter-content">
                          <div class="filter-item">
                            <div class="filter-label">All doors</div>
                          </div>
                        </div>
                      }
                      @if (!selectAllDoors) {
                        @for (filter of getMenuFilter(); track filter; let i = $index) {
                          @if (filter.checked) {
                            <div class="filter-content">
                              <div class="filter-item">
                                <div class="filter-label">{{filter.label}}</div>
                                <div (click)="removeFilter(filter, $event)">
                                  <fa-icon type="solid" icon="xmark"></fa-icon>
                                </div>
                              </div>
                            </div>
                          }
                        }
                        @if (getAllFilter().length > 1) {
                          <div class="filter-content">
                            <span class="filter-item">
                              <span class="filter-label"> + {{getAllFilter().length - 1}}</span>
                            </span>
                          </div>
                        }
                      }
                    </div>
                    <div class="menu-arrow">
                      <fa-icon type="solid" icon="chevron-down"></fa-icon>
                    </div>
                  </div>
                  <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                    @if (getAllFilter().length) {
                      <div class="menu-tooltip-list">
                        <div class="menu-title">Selected doors</div>
                        @for (selectedDoor of getAllFilter(); track selectedDoor) {
                          <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                            {{selectedDoor.label}}
                          </div>
                        }
                      </div>
                    }
                    <div class="scroll-menu">
                      <div class="normal-checkbox" (click)="setAllDoors($event)">
                        <input type="checkbox" [checked]="selectAllDoors">
                        <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        <div class="checkbox-label">All</div>
                      </div>
                      @for (door of doorsList; track door; let i = $index) {
                        @if (!['floor', 'room', 'elevator'].includes(door.type)) {
                          <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                            <input type="checkbox" [checked]="door.checked">
                            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                            <div class="checkbox-label">{{door.label}}</div>
                          </div>
                        }
                      }
                      @for (floor of allFloors; track floor) {
                        @for (door of doorsList; track door; let i = $index) {
                          <!-- Elevator -->
                          @if (door.type === 'elevator' && door.floor === floor) {
                            <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                              <input type="checkbox" [checked]="door.checked">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{door.label}}</div>
                            </div>
                          }
                          <!-- Rooms with entrances -->
                          @if (door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)) {
                            <div class="accordion">
                              <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                              <div class="normal-checkbox main-checkbox" style="height: 32.84px">
                                <div class="checkbox-content" (click)="changeDoor(i,$event)">
                                  <input type="checkbox" [checked]="door.checked">
                                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                  <div class="checkbox-label">{{door.label}}</div>
                                </div>
                                <label class="accordion-arrow" for="floor{{door.floor}}">
                                  <fa-icon type="solid" icon="chevron-right"></fa-icon>
                                </label>
                              </div>
                              <div class="accordion-content">
                                @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                  @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                    <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                      <input type="checkbox" [checked]="roomDoor.checked">
                                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                      <div class="checkbox-label">{{roomDoor.label}}</div>
                                    </div>
                                  }
                                }
                              </div>
                            </div>
                          }
                        }
                        <!-- Rooms without entrances -->
                        @if (missingEntranceFloors.includes(floor)) {
                          <div class="accordion">
                            <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                            <div class="normal-checkbox main-checkbox">
                              <div class="checkbox-content">
                                <input type="checkbox" disabled>
                                <div class="checkbox-box disabled"><fa-icon type="solid" icon="check"></fa-icon></div>
                                <div class="checkbox-label">
                                  Floor {{floor}}
                                  <div class="checkbox-desc">(No entrance)</div>
                                </div>
                              </div>
                              <label class="accordion-arrow" for="floor{{floor}}">
                                <fa-icon type="solid" icon="chevron-right"></fa-icon>
                              </label>
                            </div>
                            <div class="accordion-content">
                              @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                  <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                    <input type="checkbox" [checked]="roomDoor.checked">
                                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                    <div class="checkbox-label">{{roomDoor.label}}</div>
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-bottom: 10px">
                <label>Name</label>
                <input class="form-control custom-input" [ngClass]="{error: doorCodeName.invalid}" formControlName="name">
              </div>
            </div>
          </div>
          <div class="col-6 col-md-2" style="margin-bottom: 10px">
            <label>Door code</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Input code" required class="form-control" [ngClass]="{error: doorCode.invalid}" formControlName="doorCode">
              <button type="button" class="datepicker-btn" (click)="reRollDoorCode()"  [ngClass]="{disabled: !isCreateNewCode}">
                <fa-icon type="solid" icon="arrows-rotate"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-6 col-md-3" style="margin-bottom: 10px">
            @if (!isCreateNewCode) {
              <label class="empty-label-for-btn"></label>
              <button class="btn btn-green input-height full-width" (click)="openEventLog()">Event log</button>
            }
          </div>
          <div class="col-12 col-md-7">
            <div class="row" [ngClass]="{disabled:deactivate}">
              <div class="col-sm-6" style="margin-bottom: 10px">
                <label>Start date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <div class="datepicker-input">
                      <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                        [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                        (bsValueChange)="dateChange('startDate')"
                        [dateCustomClasses]="datepickerCustom"
                        [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                      <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                        <fa-icon type="regular" icon="calendar-days"></fa-icon>
                      </button>
                    </div>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="startDate"
                      alignment="center"
                      [fullWidth]="true"
                      (selectDate)="setDateFromDropdown($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" style="margin-bottom: 10px">
                <label>End date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <div class="datepicker-input">
                      <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                        bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                        (bsValueChange)="dateChange('endDate')"
                        [dateCustomClasses]="datepickerCustom"
                        [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                      <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                        <fa-icon type="regular" icon="calendar-days"></fa-icon>
                      </button>
                    </div>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="endDate"
                      alignment="center"
                      [fullWidth]="true"
                      (selectDate)="setDateFromDropdown($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            @if (!isCreateNewCode) {
              <div class="normal-checkbox" style="margin-top: 10px" (click)="setDeactivate($event)">
                <input type="checkbox" [checked]="deactivate">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">De-Activate</div>
              </div>
            }
          </div>
          <div class="col-12">
            <div class="right-btns">
              <button class="btn btn-green input-height" (click)="saveDoorCode()" [disabled]="lockDetail.invalid || !isFormValid || !checkSelectedDoor()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Save</button>
              <button class="btn btn-green input-height" (click)="backToLock()"><fa-icon type="solid" icon="ban"></fa-icon>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>