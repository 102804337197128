<div class="main-container">
  <div class="activity-log-filter">
    <div class="filter-item">
      <div style="width: 160px;display: inline-block;">
        <label>Sort</label>
        <dropdown-menu
          menuId="sortList"
          [menuList]="sortList"
          [selectedItem]="selectedSort"
          (selectItem)="selectSort($event)"
          adaptiveType="modal"
          [fullWidth]="true"
        ></dropdown-menu>
      </div>
    </div>
  </div>
  @if(activityLogData.length) {
    <div class="wrapper-activity-log">
      @for (item of activityLogDataFiltered; track item.id) {
        <div class="activity-item">
          <div class="active-item-header">
            <div
              class="activity-avatar"
              [class]="'avatar-' + this.getBgColorAvatarNumber(item.author.name)"
              >
              {{ this.getSortAuthorName(item.author.name) }}
            </div>
            <div>
              <div class="activity-category">
                <div class="full-name">{{ item.author.name }}</div>
              </div>
              <div class="activity-status">
                <div class="status">
                  {{ this.convertActionName(item.action) }}
                </div>
              </div>
              <div class="activity-date">
                <div class="date">
                  {{ utils.convertISOFullDate(item.timestamp) }}
                </div>
              </div>
            </div>
          </div>
          <!-- TYPE 0 -->
          @if (this.getActivityItemTypeNumber(item.action) === 0) {
            @if (item.data !== null) {
              <div class="wrapper-activity">
                <div
                  class="expand-wrapper"
                  [class]="{ 'expand-component': item.expanded }"
                  >
                  <div class="pre-log">
                    {{ this.renderLogUI(item.data) }}
                  </div>
                  <div
                    class="expand-trigger"
                    [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
                    >
                    <div
                      class="expand-trigger-inner"
                      (click)="item.expanded = !item.expanded"
                      >
                      Read more
                    </div>
                  </div>
                </div>
                <p
                  [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
                  class="collapse-trigger"
                  (click)="item.expanded = !item.expanded"
                  >
                  Read less
                </p>
              </div>
            }
          }
          <!-- TYPE 1 -->
          @if (this.getActivityItemTypeNumber(item.action) === 1) {
            <div class="wrapper-activity">
              <div
                class="row activity-detail expand-wrapper"
                [class]="{ 'expand-component': item.expanded }"
              >
                @if (item.data.beforeChange === null) {
                  <div
                    class="col-sm-1 detail-left"
                    >
                    (Empty)
                  </div>
                }
                @if (item.action === 'UPDATE_LANGUAGE') {
                  <div
                    class="col-sm-1 detail-left"
                    >
                    <div class="pre-log">
                      <br/>
                      {{ item.data.beforeChange }}
                    </div>
                  </div>
                }
                @if (item.data.beforeChange !== null && item.action !== 'UPDATE_LANGUAGE') {
                  <div
                    class="col-sm-5 detail-left"
                    >
                    <div class="pre-log">
                      @if (item.action === 'UPDATE_LANGUAGE') {
                        <br/>
                      }
                      {{ item.action === "UPDATE_LANGUAGE" ? item.data.beforeChange : this.renderLogUI(item.data.beforeChange) }}
                    </div>
                  </div>
                }
                <div class="col-sm-1 detail-center">
                  <fa-icon type="solid" icon="arrow-right"></fa-icon>
                </div>
                <!-- After change -->
                @if (item.data.beforeChange === null) {
                  <div
                    class="col-sm-10 detail-right"
                    >
                    <div class="pre-log">
                      {{ this.renderLogUI(item.data.afterChange) }}
                    </div>
                  </div>
                }
                @if (item.action === 'UPDATE_LANGUAGE') {
                  <div
                    class="col-sm-1 detail-right"
                    >
                    <div class="pre-log">
                      <br/>
                      {{ item.data.afterChange }}
                    </div>
                  </div>
                }
                @if (item.data.beforeChange !== null && item.action !== 'UPDATE_LANGUAGE') {
                  <div
                    class="col-sm-6 detail-right"
                    >
                    <div class="pre-log">
                      {{ this.renderLogUI(item.data.afterChange) }}
                    </div>
                  </div>
                }
                <div
                  class="expand-trigger"
                  [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
                  >
                  <div
                    class="expand-trigger-inner"
                    (click)="item.expanded = !item.expanded"
                    >
                    Read more
                  </div>
                </div>
              </div>
              <p
                [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
                class="collapse-trigger"
                (click)="item.expanded = !item.expanded"
                >
                Read less
              </p>
            </div>
          }
          <!-- TYPE 2 -->
          @if (this.getActivityItemTypeNumber(item.action) === 2) {
            <div class="wrapper-activity">
              <div
                class="expand-wrapper"
                [class]="{ 'expand-component': item.expanded }"
                >
                {{ item.data.afterChange.comment }}
                <div
                  class="expand-trigger"
                  [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
                  >
                  <div
                    class="expand-trigger-inner"
                    (click)="item.expanded = !item.expanded"
                    >
                    Read more
                  </div>
                </div>
              </div>
              <p
                [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
                class="collapse-trigger"
                (click)="item.expanded = !item.expanded"
                >
                Read less
              </p>
            </div>
          }
          <!-- TYPE 4 -->
          @if (this.getActivityItemTypeNumber(item.action) === 4) {
            <div class="wrapper-activity">
              <div
                class="expand-wrapper"
                [class]="{ 'expand-component': item.expanded }"
                >
                {{ this.getStringType4(item) }}
                <div
                  class="expand-trigger"
                  [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
                  >
                  <div
                    class="expand-trigger-inner"
                    (click)="item.expanded = !item.expanded"
                    >
                    Read more
                  </div>
                </div>
              </div>
              <p
                [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
                class="collapse-trigger"
                (click)="item.expanded = !item.expanded"
                >
                Read less
              </p>
            </div>
          }
          <!-- TYPE 6 -->
          @if (this.getActivityItemTypeNumber(item.action) === 6) {
            <div class="wrapper-activity">
              <div class="row activity-detail">
                <div class="col-sm-3 detail-left">
                  Room {{ item.data.sourceRoomLabel }}
                </div>
                <div class="col-sm-1 detail-center">
                  <fa-icon type="solid" icon="arrow-right"></fa-icon>
                </div>
                <div class="col-sm-8 detail-right">
                  Room {{ item.data.destinationRoomLabel }}
                </div>
              </div>
            </div>
          }
          <!-- TYPE 7 -->
          @if (this.getActivityItemTypeNumber(item.action) === 7) {
            <div class="wrapper-activity">
              <div class="row activity-detail">
                <div class="col-12">
                  User: {{ item.data.user }}
                  @if (item.action === 'USER_OPEN_EMAIL') {
                    <div>
                      Email type: {{convertActionNameCamelCase(item.data.emailType)}}
                    </div>
                  }
                  @if (item.action === 'USER_OPEN_LINK_ACCESS') {
                    <div>
                      Message source: {{ convertActionNameCamelCase(item.data.messageSource) }}
                      <br>Guest Comms Type: {{convertActionNameCamelCase(item.data.guestCommsType)}}
                      <br>Link type: {{convertActionNameCamelCase(item.data.linkType)}}
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          <!-- TYPE 8 -->
          @if (this.getActivityItemTypeNumber(item.action) === 8) {
            <div class="wrapper-activity">
              <div class="row activity-detail">
                <div class="col-12">
                  @if (item.data.user) {
                    <div>
                      User: {{item.data.user}}
                    </div>
                  }
                  @if (item.data.firstName) {
                    <div>
                      First name: {{item.data.firstName}}
                    </div>
                  }
                  @if (item.data.lastName) {
                    <div>
                      Last name: {{item.data.lastName}}
                    </div>
                  }
                  @if (item.data.email) {
                    <div>
                      Email: {{item.data.email}}
                    </div>
                  }
                  @if (item.data.dateOfBirth) {
                    <div>
                      Date of birth: {{item.data.dateOfBirth}}
                    </div>
                  }
                  @if (item.data.isoCountryCode) {
                    <div>
                      Dial code: {{item.data.isoCountryCode}}
                    </div>
                  }
                  @if (item.data.mobile) {
                    <div>
                      Phone number: {{item.data.mobile}}
                    </div>
                  }
                  @if (item.data.nationality) {
                    <div>
                      Nationality: {{item.data.nationality}}
                    </div>
                  }
                  @if (item.data.completedSsn) {
                    <div>
                      Completed SSN: {{item.data.completedSsn}}
                    </div>
                  }
                  @if (item.data.ssn) {
                    <div>
                      SSN: {{item.data.ssn}}
                    </div>
                  }
                  @if (item.data.passportNumber) {
                    <div>
                      Passport number: {{item.data.passportNumber}}
                    </div>
                  }
                  @if (item.data.purposeOfVisit) {
                    <div>
                      Purpose of visit: {{item.data.purposeOfVisit}}
                    </div>
                  }
                  @if (item.data.address) {
                    <div>
                      Address: {{item.data.address}}
                    </div>
                  }
                  @if (item.data.city) {
                    <div>
                      City: {{item.data.city}}
                    </div>
                  }
                  @if (item.data.postalCode) {
                    <div>
                      Postal code: {{item.data.postalCode}}
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          <!-- TYPE 9 -->
          @if (this.getActivityItemTypeNumber(item.action) === 9) {
            <div class="wrapper-activity">
              <div
                class="row activity-detail expand-wrapper"
                [class]="{ 'expand-component': item.expanded }"
              >
                <div class="col-12">Room {{item.data.afterChange.roomLabel}}</div>
                <div
                  class="detail-right"
                  [class]="item.data.beforeChange && item.data.beforeChange.labels.length ? 'col-sm-5' : 'col-sm-3'"
                >
                  <div class="pre-log">
                    @if(item.data.beforeChange && item.data.beforeChange.labels.length) {
                      @for (label of renderLabels(item.data.beforeChange.labels); track label) {
                        <div>{{label}}</div>
                      }
                    } @else {
                      <div>(Empty)</div>
                    }
                  </div>
                </div>
                <div class="col-sm-2 detail-center">
                  <fa-icon type="solid" icon="arrow-right"></fa-icon>
                </div>
                <!-- After change -->
                <div
                  class="detail-right"
                  [class]="item.data.afterChange.labels.length ? 'col-sm-5' : 'col-sm-3'"
                >
                  <div class="pre-log">
                    @if(item.data.afterChange.labels.length) {
                      @for (label of renderLabels(item.data.afterChange.labels); track label) {
                        <div>{{label}}</div>
                      }
                    } @else {
                      <div>(Empty)</div>
                    }
                  </div>
                </div>
                <div
                  class="expand-trigger"
                  [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
                >
                  <div
                    class="expand-trigger-inner"
                    (click)="item.expanded = !item.expanded"
                  >
                    Read more
                  </div>
                </div>
              </div>
              <p
                [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
                class="collapse-trigger"
                (click)="item.expanded = !item.expanded"
                >
                Read less
              </p>
            </div>
          }
        </div>
      }
    </div>
  }
</div>