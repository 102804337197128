<div class="feature-container">
  <div class="feature-title modal-interaction">
    <div class="content-text">
      <h3>E-invoice operators</h3>
      <div class="feature-desc">Manage list of E-invoice operators used for Integrated invoice.</div>
    </div>
    <div class="content-action">
      <button class="btn btn-green" (click)="openEInvoiceOpsModal()"><fa-icon type="solid" icon="pen-to-square"></fa-icon>Manage</button>
    </div>
  </div>
</div>

<!-- E-invoice operator list modal -->
<custom-modal
  modalName="eInvoiceListModal"
  modalTitle="Manage E-invoice operators"
  modalSize="xl"
  modalOrder="10"
  position="top"
  (modalEvent)="checkUnsavedChange()"
>
  <ng-container>
    <div class="custom-modal-body" #modalBody>
      <div class="container-title" style="padding: 0">Current operators</div>
      <input type="text" class="custom-input form-control" placeholder="Search operators" style="margin: 15px 0; max-width: 400px;" [(ngModel)]="operatorSearchKeyword" (ngModelChange)="searchCurrentOperators()">
      <div class="mui-table">
        <table *ngIf="eInvoiceList.length">
          <thead>
            <tr>
              <th>Name</th>
              <th style="width: 190px">SWIFT code</th>
              <th style="width: 190px">Created</th>
              <th style="width: 320px">Current state</th>
              <th style="width: 86px">Active</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let operator of eInvoiceListFiltered; let i = index">
              <td>{{operator.name}}</td>
              <td style="max-width: 190px">{{operator.swiftCode}}</td>
              <td>{{convertDate(operator.created)}}</td>
              <td style="word-wrap: break-word;">
                @if(operator.active) {
                  <span>Active</span>
                } @else {
                  <span class="error-color">Deactivated</span> on {{convertDate(operator.deactivated)}}
                  <br>by <b>{{operator.deactivatedBy}}</b>
                }
              </td>
              <td class="center-icon">
                <div class="normal-checkbox">
                  <input type="checkbox" [checked]="operator.checked" (change)="toggleCurrentActive(operator.id, $event)">
                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="container-title" style="padding-top: 20px;">Add new operators</div>
      <ng-container *ngIf="eInvoiceAddList.length">
        <div class="mui-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th style="width: 250px">SWIFT code</th>
                <th style="width: 86px">Active</th>
                <th style="width: 51px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let operator of eInvoiceAddList; let i = index">
                <td>{{operator.name}}</td>
                <td>{{operator.swiftCode}}</td>
                <td class="center-icon">
                  <div class="normal-checkbox">
                    <input type="checkbox" [checked]="operator.active" (change)="editOperatorFromAddList(i, 'toggleActive', $event)">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                  </div>
                </td>
                <td class="center-icon">
                  <button class="btn btn-none-outline-green icon-only" (click)="editOperatorFromAddList(i, 'remove')">
                    <fa-icon type="solid" icon="xmark"></fa-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <form [formGroup]="addOperatorForm" (ngSubmit)="addOperatorToList()">
        <div class="add-operator-container">
          <div class="op-name">
            <label>Name</label>
            <input class="form-control custom-input" id="name" formControlName="name" [ngClass]="{'error': opName && opName.invalid && opName.touched}">
            @if(opName && opName.invalid && opName.touched) {
              <div class="input-error-msg">This field is required</div>
            }
          </div>
          <div class="op-swift">
            <label>SWIFT code</label>
            <input class="form-control custom-input" id="swiftCode" formControlName="swiftCode" [ngClass]="{'error': opSwiftCode && opSwiftCode.invalid && opSwiftCode.touched}">
            @if(opSwiftCode && opSwiftCode.invalid && opSwiftCode.touched) {
              <div class="input-error-msg">This field is required</div>
            }
          </div>
          <div class="op-active">
            <label>Active</label>
            <div class="normal-checkbox">
              <input type="checkbox" id="active" formControlName="active">
              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
            </div>
          </div>
          <div class="op-add">
            <button class="btn btn-green label-offset" type="submit"><fa-icon type="solid" icon="plus"></fa-icon>Add</button>
          </div>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('eInvoiceListModal')">Cancel</button>
      <button class="btn btn-green" (click)="saveOperators()">Save</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Add operators modal -->
<custom-modal
  modalName="addOperatorsModal"
  modalTitle="Add new operators"
  modalSize="md"
  modalOrder="0"
>
  <ng-container>
    <div class="custom-modal-body">
      <div style="margin-bottom: 15px; margin-top: -10px;">New operators have been successfully created in Nelson. However, you need to manually add those new operators to Fennoa as well. Here is a list of recently created operators for you to copy.</div>
      <ng-container *ngIf="eInvoiceAddList.length">
        <div class="mui-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th style="width: 250px">SWIFT code</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let operator of eInvoiceAddList; let i = index">
                <td>
                  <div class="text-copy-container">
                    <div class="copy-text">{{operator.name}}</div>
                    <div class="copy-icon">
                      <button class="btn btn-none-outline-green icon-only" (click)="copyText(operator.name)">
                        <fa-icon type="regular" icon="copy"></fa-icon>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="text-copy-container">
                    <div class="copy-text">{{operator.swiftCode}}</div>
                    <div class="copy-icon">
                      <button class="btn btn-none-outline-green icon-only" (click)="copyText(operator.swiftCode)">
                        <fa-icon type="regular" icon="copy"></fa-icon>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-green" (click)="closeCustomModal('addOperatorsModal')">Done</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Default modal -->
<custom-modal
  modalName="defaultModal"
  modalTitle="Default"
  modalSize=""
  modalOrder="0"
>
  <ng-container>
    <div class="custom-modal-body">
      
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('defaultModal')">Cancel</button>
    </div>
  </ng-container>
</custom-modal>

<!-----------CUSTOM ALERTS----------->
<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">{{resultElements.title}}</div>
      <div class="alert-desc">{{resultElements.desc}}</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
    </div>
  </ng-container>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">{{loadingElements.title}}</div>
      <div class="alert-desc" *ngIf="loadingElements.desc">{{loadingElements.desc}}</div>
    </div>
  </ng-container>
</custom-modal>
<!-- Unsaved change alert -->
<custom-modal
  modalName="unsavedChangeAlert"
  modalSize="alert-modal"
  modalOrder="11"
  [backdropClose]="false"
  alertType="question"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">Unsaved changes</div>
      <div class="alert-desc">There are unsaved changes, are you sure you want to discard them?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="actionUnsavedChange(false)">Go back</button>
      <button type="button" class="btn btn-green" (click)="actionUnsavedChange(true)">Discard changes</button>
    </div>
  </ng-container>
</custom-modal>