<app-header-user-management activePage="internal-tools"></app-header-user-management>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Internal tools (Nelson system)</div>
        </div>
      </div>
      <div class="notice-container">
        <div class="notice-icon">
          <fa-icon type="solid" icon="circle-info"></fa-icon>
        </div>
        <div class="notice-content">WARNING: Internal tools is only available to Nelson Manager users and is used for various modification to Nelson system. Please read the descriptions of each tools before using them.</div>
      </div>
    </div>
  </div>
  <div class="body-container">
    <div class="container-title">Features are under development</div>
  </div>
</div>