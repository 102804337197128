import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { ReportsService } from 'src/app/services/reports.service';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass','../../../styles.sass']
})
export class ReportsComponent implements OnInit {
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotelItem?: MenuType;
  selectedHotelInfo:any;

  selectedHotelSegment: any;
  datepickerOptions: any = CONSTANT.datepickerConfig;
  startDate: any = null;
  endDate: any = null;
  reportDate: any = null;
  minDateOfReportDate: any = null;

  isValidStartDate: boolean = false;
  isValidEndDate: boolean = false;
  downloadGuestsInHouseDisabled: boolean = true;
  downloadDisabled: boolean = true;
  isLoadingDownload: boolean = false;

  isOnTheBooksReport: boolean = false;
  isOnTheBooksByReservationReport: boolean = false;
  isBreakfastReport: boolean = false;
  isCleaningReport: boolean = false;
  isUnpaidExpediaReservationsReport: boolean = false;
  isUnpaidReservationsReport: boolean = false;
  isFranchisingReport: boolean = false;
  isGuestsReport: boolean = false;
  isGuestsMobileReport: boolean = false;
  isDailyAvailablityReport: boolean = false;
  isRevenueByCustomerReport: boolean = false;
  isRevenueReportNew: boolean = false;
  isSalesReport: boolean = false;
  isSalesForecastDailyReport: boolean = false;
  isSalesForecastPerSegmentDailyReport: boolean = false;
  isPaymentReport: boolean = false;
  isFinancialSummaryReport: boolean = false;

  requireHotelId: boolean = true;
  requireReportDate: boolean = false;
  requireStartDate: boolean = false;
  requireEndDate: boolean = false;
  fillTimeDisable: boolean = false;
  requireReportTime: boolean = false;

  userInfo:any;
  token:any = window.localStorage.getItem('jwt');
  isCleaner:boolean = false;

  reportTypes: any[] = CONSTANT.REPORT_TYPES.filter(report => this.hotelConfig.MUIfeature.reportType.includes(report.urlParam));
  reportList:MenuType[] = [];
  selectedReport?:MenuType;

  activeClassFilter:string='';

  status: object = {
            reportDate: false,
            startDate: false,
            endDate: false
          };
  currentReportType: any = this.hotels[0];

  datepickerCustom: DatepickerDateCustomClasses[];

  allListDisableReport: any [] = ['new-revenue', 'sales', 'payments'];
  disableHotel: boolean = false;

  constructor(
    private reportsService: ReportsService,
    private toast: CustomToastService,
    private storeService: StoreService,
    public utils: UTILS
  ) {
    this.datepickerCustom = [];
  }

  ngOnInit(): void {
    this.checkRoles();
    this.reportsService.getHotels().subscribe(
      hotels => {
        if(GlobalUserSession) {
          if(this.isCleaner) {
            // if(this.userInfo.hotels.length > 0) {
            //   let allowedHotels = hotels.filter((hotel: any) => {
            //     return this.userInfo.hotels.find((userHotel: any) => {
            //       return userHotel == hotel.label;
            //     });
            //   });
            //   this.hotels = allowedHotels;
            // } 
            // else {
            //   this.hotels = hotels;
            // }
            this.downloadDisabled = false;
          }
          this.hotels = hotels;
          const userRole = GlobalUserSession.role;
          this.reportTypes = this.reportTypes.filter(type => {
            return type.requiredRoles.includes(userRole)
          });
          
          let tmpReportList:MenuType[] = [];
          this.reportTypes.forEach((report:any) => {
            tmpReportList.push({
              label: report.label,
              value: report.urlParam,
              disabled: false
            })
          })
          this.reportList = tmpReportList;
          this.selectReport(tmpReportList[0])
        }
      });
  }
  checkRoles(): any {
    if(GlobalUserSession && ['CLEANER'].includes(GlobalUserSession.role)) {
      this.isCleaner = true;
    }
  }
  setupHotel() {
    let tmpHotelList:MenuType[] = [];
    if(this.checkSupportedAllHotel()) {
      tmpHotelList.push({
        label: "All",
        value: "all",
        disabled: false
      })
    }
    this.hotels.forEach((hotel:any) => {
      tmpHotelList.push({
        label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
        value: hotel.hotelId.toString(),
        disabled: false
      })
    })
    this.hotelList = tmpHotelList;
    this.selectHotel(tmpHotelList[0])
  }
  selectHotel(item:MenuType){
    this.selectedHotelItem = item;
    if(item.value === "all") {
      const tmpFindHotelInfo = this.hotels.find(hotel => hotel.hotelId === Number(item.value))
      if(tmpFindHotelInfo) {
        this.selectedHotelInfo = tmpFindHotelInfo
      }
    } else {
      this.selectedHotelInfo = undefined
    }
  }
  selectReportDate(): any{
    // if (this.isOnTheBooksReport || this.isOnTheBooksByReservationReport) {
    //   if (this.startDate && this.startDate.getTime() < this.reportDate.getTime()) {
    //     this.startDate = new Date(this.reportDate);
    //     this.endDate = new Date(this.reportDate);
    //   }
    // }
  }

  updateDatepicker() {
    let custom:any;
    let tmpTime:any;
    if(this.startDate) {
      custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      tmpTime = this.startDate.getTime() + 24*3600*1000;
    }
    if(this.startDate && this.endDate) {
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    }
    this.datepickerCustom = custom
  }

  disableSearch() {
    this.downloadDisabled = true;
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.endDate = new Date(this.startDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 24*3600*1000);
        }
        break;
      case 'reportDate':
        this.selectReportDate();
        break;
    }
    this.updateDatepicker();
    this.downloadBtnDisabled();
  }
  setDateFromDropdown(value:Date) {
    this.reportDate = value;
  }

  downloadBtnDisabled(): any{
    if (this.isCleaningReport) {
      if (this.reportDate && this.selectedHotelItem) {
        this.downloadDisabled = false;
      } else {
        this.downloadDisabled = true;
      }
    } else {
      if (this.startDate && this.endDate && this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
        this.downloadDisabled = false;
        document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
      } else {
        this.downloadDisabled = true;
      }
    }
  }
  lastWeek(event: any): any{
    this.setDates(this.lastWeekFunc());
    this.activeClassFilter='week';
  }
  lastMonth(event: any): any{
    this.setDates(this.lastMonthFunc());
    this.activeClassFilter='month';
  }
  startOfThisMonth(event: any): any{
    this.setDates(this.startOfThisMonthFunc());
    this.activeClassFilter='start-month';
  }
  startOfYear(event: any): any{
    this.setDates(this.startOfYearToLastWeek());
    this.activeClassFilter='start-year';
  }
  setDates(range: any): any{
    this.startDate = range[0];
    this.endDate = range[1];
  }
  lastWeekFunc(): any{
    if (this.isCleaningReport) {
      this.startDate = null;
      this.endDate = null;
      return [null, null]
    } else {
      let date = new Date;
      let endDate = new Date(date.setDate(date.getDate() - date.getDay()));
      let startDate = new Date(date.setDate(date.getDate() - date.getDay() - 6));
      return [ startDate, endDate ];
    }
  }
  lastMonthFunc(): any{
    if (this.isCleaningReport) {
      this.startDate = null;
      this.endDate = null;
      return [null, null]
    } else {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth() - 1 , 1, date.getHours(), date.getMinutes(), date.getSeconds());
      let endDate = new Date(date.getFullYear(), date.getMonth(), 0, date.getHours(), date.getMinutes(), date.getSeconds());
      return [ startDate, endDate ];
    }
  }
  startOfThisMonthFunc(): any{
    if (this.isCleaningReport) {
      this.startDate = null;
      this.endDate = null;
      return [null, null]
    } else {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes(), date.getSeconds());
      let endDate = null;
      return [ startDate, endDate ];
    }
  }
  startOfYearToLastWeek(): any{
    if (this.isCleaningReport) {
      this.startDate = null;
      this.endDate = null;
      return [null, null]
    } else {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), 0, 1, date.getHours(), date.getMinutes(), date.getSeconds());
      let endDate = new Date(date.setDate(date.getDate() - date.getDay()));
      startDate.setHours(date.getHours());
      startDate.setMinutes(date.getMinutes());
      startDate.setSeconds(date.getSeconds());
      endDate.setHours(date.getHours());
      endDate.setMinutes(date.getMinutes());
      endDate.setSeconds(date.getSeconds());
      return [ startDate, endDate ];
    }
  }
  selectReport(item:MenuType){
    if(this.allListDisableReport.includes(item.value)){
      this.disableHotel = true;
    }
    else {
      this.disableHotel = false;
    }
    this.selectedReport = item;
    this.updateStateButtons(item);
    this.getRequireFields();
    this.downloadGuestsBtnDisabled();

    if (this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isSalesReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport || this.isCleaningReport) {
      if (this.isCleaningReport) {
        this.startDate = null;
        this.endDate = null;
        this.fillTimeDisable = false;
        this.requireReportTime = true;
        this.minDateOfReportDate = new Date();
        this.reportDate = new Date();
      }
    } else {
      this.minDateOfReportDate = null;
      this.reportDate = null;
      this.fillTimeDisable = false;
    }
    this.setupHotel();
    this.downloadBtnDisabled();
  }
  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
  updateStateButtons(reportItem:MenuType) {
    // Update state buttons when selecting report option
    this.isOnTheBooksReport = reportItem.value === 'on-the-books';
    this.isOnTheBooksByReservationReport = reportItem.value === 'on-the-books-by-reservation';
    this.isBreakfastReport = reportItem.value === 'breakfast';
    this.isCleaningReport = reportItem.value === 'cleaning-list';
    this.isUnpaidExpediaReservationsReport = reportItem.value === 'unpaid-expedia-reservations';
    this.isUnpaidReservationsReport = reportItem.value === 'unpaid-reservations';
    this.isGuestsReport = reportItem.value === 'guests';
    this.isGuestsMobileReport = reportItem.value === 'guests-mobile';
    this.isDailyAvailablityReport = reportItem.value === 'daily-availability';
    this.isRevenueByCustomerReport = reportItem.value === 'revenue-by-customer';
    this.isRevenueReportNew = reportItem.value === 'new-revenue';
    this.isSalesReport = reportItem.value === 'sales';
    this.isSalesForecastDailyReport = reportItem.value === 'sales-forecast-daily';
    this.isSalesForecastPerSegmentDailyReport = reportItem.value === 'sales-forecast-per-segment-daily';
    this.isPaymentReport = reportItem.value === 'payments';
    this.isFinancialSummaryReport = reportItem.value === 'financial-summary';
  }
  getRequireFields(): any{
    this.requireHotelId = this.isRevenueByCustomerReport || this.isBreakfastReport || this.isCleaningReport || this.isUnpaidExpediaReservationsReport || this.isUnpaidReservationsReport || this.isFranchisingReport || this.isGuestsReport || this.isGuestsMobileReport || this.isDailyAvailablityReport || this.isFinancialSummaryReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport;

    this.requireReportDate = this.isSalesReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isCleaningReport;

    this.requireReportTime = this.isSalesReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isCleaningReport;

    this.requireStartDate = this.isRevenueByCustomerReport || this.isCleaningReport || this.isRevenueReportNew || this.isSalesReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport || this.isBreakfastReport || this.isUnpaidExpediaReservationsReport || this.isUnpaidReservationsReport || this.isFranchisingReport || this.isGuestsReport || this.isGuestsMobileReport || this.isDailyAvailablityReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isPaymentReport || this.isFinancialSummaryReport;

    this.requireEndDate = this.isRevenueByCustomerReport || this.isRevenueReportNew || this.isSalesReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport || this.isBreakfastReport || this.isUnpaidExpediaReservationsReport || this.isUnpaidReservationsReport || this.isFranchisingReport || this.isGuestsReport || this.isGuestsMobileReport || this.isDailyAvailablityReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isPaymentReport || this.isFinancialSummaryReport;
  }
  downloadGuestsBtnDisabled(): any{
    // Disable Download guests button when the 'Guests Report' and 'Guests Mobile Numbers Report' label are selected
    if (this.isGuestsReport || this.isGuestsMobileReport) {
      this.downloadGuestsInHouseDisabled = false;
    } else {
      this.downloadGuestsInHouseDisabled = true;
    }
  }
  fileNameDate(date: any): any{
    return date ? formatDate(date, 'yyyyMMdd', "en-us") : "X";
  }
  downloadGuestsInHouse(event: any): any{
    if (this.downloadGuestsInHouseDisabled) {
      return;
    }
    this.isLoadingDownload = true;
    let tmpUrlParam: any, params: any;
    let reportSelected = this.reportTypes.find((reportType) => {
      return this.selectedReport ? reportType.urlParam === this.selectedReport.value : false;
    });
    if (this.requireHotelId && this.selectedHotelItem) {
      params = '&hotelId=' + this.selectedHotelItem.value;
    }
    if (reportSelected && reportSelected.urlParam == "guests") {
      tmpUrlParam = "guests-inhouse";
    } else {
      tmpUrlParam = "guests-inhouse-mobile";
    }
    let jwt = window.localStorage.getItem('jwt');
    let url = `/api/management/secure/reports/${tmpUrlParam}?authToken=${jwt}${params}`;
    this.reportsService.reportsExport(url).subscribe(
      response => {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.setAttribute("download", tmpUrlParam + '-report-'
          + (this.requireHotelId && this.selectedHotelInfo ? this.selectedHotelInfo.label.toLowerCase() + '-' : '')
          + moment().format('YYYYMMDD-HHmmss')
          + '.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.isLoadingDownload = false;
    }, error => {
      if (error.status === 406) {
        this.toast.error("The server is busy. Please try again.", "Error!");
      } else {
        this.toast.error("Download report failed!", "Error!");
      }
      this.isLoadingDownload = false;
    });
  }
  downloadReport(event: any): any{
    if (this.downloadDisabled) {
      return;
    }
    this.isLoadingDownload = true;
    let reportDate: any, params = '';
    if (this.startDate && this.requireStartDate) {
      params += '&startDate=' + formatDate(moment(this.startDate, 'DD.MM.YYYY').toDate(), "yyyy-MM-dd", "en-us");
    }
    if (this.endDate && this.requireEndDate) {
      params += '&endDate=' + formatDate(moment(this.endDate, 'DD.MM.YYYY').toDate(), "yyyy-MM-dd", "en-us");
    }
    if (this.requireReportDate) {
      if(!this.reportDate) {
        this.reportDate = new Date();
      }
      reportDate = moment(this.reportDate);
      params += '&reportDate=' + formatDate(moment(reportDate, 'DD.MM.YYYY').toDate(), "yyyy-MM-dd", "en-us");
      params += '&time=' + formatDate(this.reportDate, "HH:mm", "en-US");
    }
    if (this.requireHotelId && this.selectedHotelItem && this.selectedHotelItem.value !== "all") {
      params += '&hotelId=' + this.selectedHotelItem.value;
    }
    let reportSelected = this.reportTypes.find((reportType) => {
      return reportType.urlParam === this.selectedReport?.value;
    });
    let jwt = window.localStorage.getItem('jwt');
    if(reportSelected) {
      let url = `/api/management/secure/reports/${reportSelected.urlParam}?authToken=${jwt}${params}`;
      this.reportsService.reportsExport(url).subscribe(
        response => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(response);
          link.setAttribute("download", (this.isFinancialSummaryReport ? 'sale-summary-' : reportSelected.urlParam + '-report-')
          + (this.selectedHotelItem && this.selectedHotelItem.value === "all" ? "all-" : (this.requireHotelId && this.selectedHotelItem ? this.utils.hotelDisplay(this.hotelConfig, this.hotels, this.selectedHotelItem.value).toLowerCase() + '-' : ''))
          + (this.requireReportDate ? this.fileNameDate(this.reportDate) + '-' : '')
          + (this.requireStartDate ? this.fileNameDate(this.startDate) + '-' : '')
          + (this.requireEndDate ? this.fileNameDate(this.endDate) : '')
          + '.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoadingDownload = false;
        }, error => {
          if (error.status === 406) {
            this.toast.error("The server is busy. Please try again.", "Error!");
          } else {
            this.toast.error("Download report failed!", "Error!");
          }
          this.isLoadingDownload = false;
        }
      );
    }
  }

  checkSupportedAllHotel() {
    return this.isRevenueByCustomerReport || this.isFinancialSummaryReport || this.isOnTheBooksReport || this.isOnTheBooksByReservationReport || this.isDailyAvailablityReport || this.isSalesForecastDailyReport || this.isSalesForecastPerSegmentDailyReport
  }
}
