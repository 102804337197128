<app-header activePage="customers"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">

      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-title">Customer card</div>
        </div>
      </div>

      @if (customerNotFound) {
        <inline-alert alertType="error" alertMessage="Customer not found or there is a new customer who just recently booked a reservation but has not paid or confirmed yet."></inline-alert>
      }

      @if (!customerNotFound) {
        <div class="row">
          <div class="col-12">
            <div class="row">
              <!-- Contact -->
              <div class="col-sx-12 col-sm-6 col-md-4">
                <h4><b>Contact Infomation</b></h4>
                <div class="row attr-title">
                  <div class="col-6"><b>Email: </b></div>
                  <div class="col-6 truncate-long-text" tooltip="{{customerCard.customer.email}}"
                    placement="right">
                    {{ customerCard.customer.email? customerCard.customer.email : '' }}
                  </div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Full name: </b></div>
                  <div class="col-6">{{ customerCard.customer.firstName }} {{ customerCard.customer.lastName
                  }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Mobile: </b></div>
                  <div class="col-6">{{ customerCard.customer.mobile }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Nationality: </b></div>
                  <div class="col-6">{{ customerCard.customer.nationality ?
                  getCustomerNationality(customerCard.customer.nationality) : ''}}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>SSN: </b></div>
                  <div class="col-6">{{ customerCard.customer.ssn }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Passport number: </b></div>
                  <div class="col-6">{{ customerCard.customer.passportNumber }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Address: </b></div>
                  <div class="col-6">{{ customerCard.customer.address }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Postal code: </b></div>
                  <div class="col-6">{{ customerCard.customer.postalCode }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>City: </b></div>
                  <div class="col-6">{{ customerCard.customer.city }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-6"><b>Country: </b></div>
                  <div class="col-6">{{ customerCard.customer.country ?
                  getCustomerNationality(customerCard.customer.country) : ''}}</div>
                </div>
              </div>
              <!-- Status -->
              <div class="col-sx-12 col-sm-6 col-md-4 status-col">
                <h4><b>Status & Statistics</b></h4>
                @if (isStatusStatistics) {
                  <div>
                    <!-- All Member features needs to be disabled.  -->
                    <div [ngClass]="{hidden: !hotelConfig.feature.membership}" class="row attr-title">
                      <div class="col-6"><b>Member since: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.memberSince ?
                      (customerCard.statusStatistics.memberSince | date:'dd.MM.yyyy') : 'na' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Lifetime bookings: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.lifeTimeBookings ?
                        customerCard.statusStatistics.lifeTimeBookings : customerCard.statusStatistics.lifeTimeBookings }}
                        ({{ customerCard.statusStatistics.asMember ? customerCard.statusStatistics.asMember : 0 }} as
                      member)</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Lifetime spend: </b></div>
                      @if (customerCard.statusStatistics.lifeTimeSpend) {
                        <div class="col-6">{{
                        formatCurrency(customerCard.statusStatistics.lifeTimeSpend) }}</div>
                      }
                      @if (!customerCard.statusStatistics.lifeTimeSpend) {
                        <div class="col-6">0</div>
                      }
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Visits as guest only: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.visitAsGuest }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Subscribed: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.subscribed ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Last stay: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.lastStay | date:'dd.MM.yyyy' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Discount codes: </b></div>
                      <div class="col-6 truncate-long-text" tooltip="{{customerCard.statusStatistics.discountCodes}}" placement="right">
                      {{ truncateDiscountCodesForStatus(customerCard.statusStatistics.discountCodes) }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-6"><b>Cancellation rate: </b></div>
                      <div class="col-6">{{ customerCard.statusStatistics.cancellationRate ?
                      customerCard.statusStatistics.cancellationRate : 0 }} %</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-3 col-md-4"><b>Blocked: </b></div>
                      <div class="col-1 col-sm-1 col-md-1">{{ customerCard.customer.state === 'BLOCKED' ? 'Yes' : 'No' }}
                      </div>
                      <div class="col-4">
                        <a class="edit-btn" (click)="editBlocklist()">
                          <fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit
                        </a>
                      </div>
                    </div>
                  </div>
                }
                @if (!isStatusStatistics) {
                  <div>
                    <div class="row">
                      <!-- Loading icon -->
                      @if (loading) {
                        <div class="center-item">
                          <ng-lottie
                            [options]="options"
                            width="200px"
                            height="200px"
                          ></ng-lottie>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
              <!-- Comment -->
              <div class="col-sx-12 col-sm-12 col-md-4">
                <h4><b>Comments</b></h4>
                <div class="comments-customer-info customer-card-comment-view comment-width">
                  @for (comment of customerCard.commentSearchResult.results; track comment) {
                    <div>
                      <div class=" wrapper-comment">
                        <div class="detail-comments">
                          <div class="comment-format">{{ comment.created | date:'dd.MM.yyyy HH:mm' }}</div>
                          <div class="comment-format">{{ comment.email }}</div>
                          <div class="comment-title">{{ comment.comment }}</div>
                        </div>
                      </div>
                    </div>
                  }
                  @if (customerCard.commentSearchResult.total > (commentOffset + commentLimit)) {
                    <div class="comment-title see-more-comment"
                    (click)="showMoreComments()">See more comments</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  </div>


  <!-- Bookings -->
  @if (!customerNotFound) {
    <div class="wrapper-outer-table">
      <div class="outer-table">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="container-title">Bookings</div>
            </div>
          </div>
          @if (!loading) {
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="custom-table-container">
                  <table class="table table-bordered table-hover table-striped table-bookings custom-table">
                    <thead>
                      <tr>
                        <th class="reservation">Reservation</th>
                        <th class="hotel">Hotel</th>
                        <th class="check-in">Check in</th>
                        <th class="check-out">Check out</th>
                        <th class="status">Status</th>
                        <th class="as-member">As member</th>
                        <th class="discount-code">Discount code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- At bookings table, booking row should be clickable and link to booking reservation detail page. -->
                      @for (booking of customerCard.bookingSearchResult.results; track booking) {
                        <tr class="customer-row"
                          [routerLink]="['/reservations/'+ booking.uuid]">
                          <td>{{ booking.reservationCode }}</td>
                          <td>{{ booking.hotelLabel }}</td>
                          <td>{{ booking.checkIn | date:'dd.MM.yyyy' }}</td>
                          <td>{{ booking.checkOut | date:'dd.MM.yyyy' }}</td>
                          <td>{{ booking.status }}</td>
                          <td>{{ booking.member ? 'Yes' : 'No' }}</td>
                          <td class="truncate-long-text" tooltip="{{booking.discountCode}}" placement="right">{{
                          truncateDiscountCodeForBookings(booking.discountCode) }}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                @if (customerCard.bookingSearchResult.total > (bookingOffset + bookingLimit)) {
                  <div class="past-booking text-center"
                    (click)="goToPastBooking()">
                    <a>Past booking >></a></div>
                  }
                </div>
                <div class=" row-bottom show-back">
                  <div class="col-12 text-left" >
                    <label class="customer-total-count" (click)="backToCustomers()"><fa-icon type="solid" icon="chevron-left"></fa-icon> Back</label>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }

    <!-- Loading icon -->
    @if (loading) {
      <div class="center-item">
        <ng-lottie
          [options]="options"
          width="200px"
          height="200px"
        ></ng-lottie>
      </div>
    }
    <br />
  </div>
  <br />